/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from "../include/header";
import Footer from "../include/footer";
import myProfileSerivces from '../../services/myProfile';
import subscribeService from "../../services/subscribe"
import PageLoader from '../pageLoader'
import SeoTags from '../seo/seoTags'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import { Auth } from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
require('dotenv').config()



var isHomePage = false;
const BecomeAverifiedDealer = () => {
   const { setShowLoginModal, setUserProfileData } = useContext(Auth);
   const [loaded, setLoaded] = useState(false);
   const [verified, setVerified] = useState(false);
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   const token = loginUser ? loginUser.access_token : false;
   const [planInfo, setPlanInfo] = useState();
   const { addToast } = useToasts();
   const navigate = useNavigate();


   const [profile, setProfile] = useState();
   const [formData, setFormData] = useState();
   const [selectedServices, setSelectedServices] = useState();
   const [selectedSpecialities, setSelectedSpecialities] = useState();
   const [filterString, setFilterString] = useState('');
   const [filterPhoneString, setFilterPhoneString] = useState('');
   const [filterCityString, setFilterCityString] = useState('');
   const [countries, setCountries] = useState([]);
   const [filteredCountries, setFilteredCountries] = useState([]);
   const [filteredPhoneCodes, setFilteredPhoneCodes] = useState([]);
   const [cities, setCities] = useState([]);
   const [filteredCities, setFilteredCities] = useState([]);
   const [tempDefaultCurrency, setTempDefaultCurrency] = useState();
   const [countryFlag, setCountryFlag] = useState();
   const [phoneCode, setPhoneCode] = useState();
   const [city, setCity] = useState();
   const [validationError, setValidationError] = useState();
   const [scrollTo, setScrollTo] = useState(0);
   const [consentTC, setConsentTC] = useState(false);
   const { t } = useTranslation();
   const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
         confirmButton: 'btn btn-success',
         cancelButton: 'mr-2 btn btn-danger'
      },
      buttonsStyling: false
   })

   // Check if user is already subscribed 
   if (loginUser && loginUser?.issubscribed) {
      swalWithBootstrapButtons.fire(
         t('Info'),
         t('You are subscribed already'),
         'warning'
      )
      navigate('/');
   }

   // Fetch subscription plan info 
   const getPlanInfo = async () => {
      await myProfileSerivces
         .subscriptionPlanInfo()
         .then(response => {
            setLoaded(true);
            if (response.success) {
               setPlanInfo(response.data);

            } 
         })
         .catch(error => {
            setLoaded(true);
            setVerified(false);
         })

   }

   const scrollToBusinessForm = () => {
      if (window.scrollY < 700) {
         window.scroll(0, 800)
      }
   }

   // Fetch cities form server
   const getCities = async (country_code, city_id) => {
      await myProfileSerivces
         .getCities(country_code)
         .then(response => {
            setLoaded(true);
            if (response.success) {
               setCities(response.result)
               setFilteredCities(response.result)
               if (city_id) {
                  let tempData = response.result.find(c => parseInt(c.id) === parseInt(city_id));
                  if (typeof (tempData) !== undefined) {
                     setCity(tempData)

                  } else {
                     setCity(response.result[0]);

                  }

               } else {
                  let tempData = response.result[0];
                  setCity(tempData)
               }

            } else {
               addToast(response.message, { appearance: 'error' })
            }


         })
         .catch(error => {
            setLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {

                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }

            }
         })
      // window.scrollTo(0, scrollTo);
   }

   // Fecth user profile data 
   const getEditProfileData = async () => {

      await myProfileSerivces
         .editProfile()
         .then(response => {
            setLoaded(true);
            if (response.success) {
               setProfile(response.data);
               setFormData(response.data.user);
               setTempDefaultCurrency(response.data.user.currency_symbol);
               setSelectedServices(response.data.selectedServices);
               setSelectedSpecialities(response.data.selectedSpecialities);
               setFilteredCountries(response.data.country);
               setFilteredPhoneCodes(response.data.country);
               setCountries(response.data.country);

               getCities(response.data.user.country_code, response.data.user.city_id)
               if (response.data.user.country_code) {
                  let tempData = response.data.country.find(p => String(p.country_code) === String(response.data.user.country_code));
                  setCountryFlag(tempData);
               } else {
                  let tempData = response.data.country[0];
                  setCountryFlag(tempData);
               }
               if (response.data.user.phone_code) {
                  let tempData = response.data.country.find(p => String(p.phone_code) === String(response.data.user.phone_code));
                  if (tempData !== undefined) {
                     setPhoneCode(tempData);
                  } else {
                     setPhoneCode(response.data.country[0]);
                  }
               } else {
                  let tempData = response.data.country[0];
                  setPhoneCode(tempData);
               }
               if (response.data.user.issubscribed) {
                  const UpdatedUser = {
                     ...loginUser,
                     issubscribed: true,
                  }
                  window.localStorage.setItem('loggedUser', JSON.stringify(UpdatedUser))
                  // addToast('User subscribed successfully',{ appearance: 'success' })
                  // window.scrollTo(0, 500);
               }
            } else {
               // addToast(response.message,{ appearance: 'error' })
            }

         })
         .catch(error => {
            setLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  // addToast(error.response.data.message, { appearance: 'error' })
               }

            }
         })


   }

   // to check validation error
   const hasErrorFor = (field) => {
      if (validationError) {

         const error = validationError.find(element => String(element.field) === String(field));
         if (error)
            return true;
      } else {
         return false;
      }
   }

   // render validation error
   const renderErrorFor = (field) => {
      if (hasErrorFor(field)) {
         const error = validationError.find(element => String(element.field) === String(field));
         return (
            <div className="error-wrapper">
               <span className='invalid-feedback'>
                  <strong>{error.message}</strong>
               </span>
            </div>
         )
      }
   }

   // comman handle for form element`s onChange   
   const handleFromChange = (e) => {
      const { name, value: newValue, type } = e.target;
      const value = type === 'number' ? +newValue : newValue;
      if (String(name) === 'country_code') {
         getCities(value)
      }
      setFormData({
         ...formData,
         [name]: value ? value : '',
      });
   }

   // local country filter 
   const handlefilter = (e) => {

      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = countries.filter(item => {
         return item.name.toLowerCase().includes(lowercasedFilter);
      });
      setFilteredCountries(filteredData)

   }

   // local phone code filter 
   const handlePhonefilter = (e) => {

      var str = e.target.value || '';
      // console.log(str);
      setFilterPhoneString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = countries.filter(item => {
         return item.phone_code.toLowerCase().includes(lowercasedFilter);
      });
      setFilteredPhoneCodes(filteredData)

   }

   // phone code handler 
   const handlePhoneCodeSelect = (e) => {

      const pcode = e.currentTarget.dataset.value;
      const selectedFilter = countries.find(c => String(c.phone_code) === String(pcode));
      if (selectedFilter !== undefined) {
         setPhoneCode(selectedFilter);
      }
   }

   // onChange handler on country select  
   const handleCountrySelect = (e) => {
      const pcode = e.currentTarget.dataset.value;
      const pflag = e.currentTarget.dataset.image;
      const pname = e.currentTarget.dataset.name;
      setCountryFlag({
         ...countryFlag,
         country_code: pcode,
         name: pname,
         flag: pflag
      })
      let tempData = countries.find(p => String(p.country_code) === String(pcode));
      setPhoneCode(tempData);
      setScrollTo(500)
      getCities(pcode, null)

   }

   // Update user profile 
   const updateUserProfile = (e) => {
      e.preventDefault();
      setLoaded(false);

      // saveCurrency(formData.default_currency)
      myProfileSerivces.setToken(token);
      myProfileSerivces
         .updateBusinessProfile(formData)
         .then(response => {
            if (response.success) {
               setLoaded(true);
               setFormData(response.data);
               const UpdatedUser = {
                  ...loginUser,
                  first_name: response.data.first_name,
                  last_name: response.data.last_name,
                  picture: response.data.photo,
                  default_currency: response.data.default_currency,
                  currency_symbol: response.data.currency_symbol,
                  country_code: response.data.country_code,
                  city_id: city?.id,
                  address: city?.name + ' , ' + countryFlag?.name
               }

               window.localStorage.setItem('loggedUser', JSON.stringify(UpdatedUser))
               setUserProfileData({
                  first_name: response.data.first_name,
                  last_name: response.data.last_name,
                  image: response.data.photo,
               })
               // console.log('asa');
               handleSubscribe();

            } else {
               addToast(response.result, { appearance: 'error' })
            }
         })
         .catch(error => {
            if (error.response) {
               setLoaded(true);
               if (error.response.status === 422) {
                  setValidationError(
                     error.response.data.errors
                  )
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }

            }
         })
   }


   useEffect(() => {
      myProfileSerivces.setToken(token);
      if (token) {
         getEditProfileData();
      }
      getPlanInfo()
   }, [token])

   const newData = {
      amount: planInfo?.price,
      plan_type: planInfo?.plan_type,
      currency_code: planInfo?.currency_code,
   }

   // purchase subscription plan
   const handleSubscribe = () => {
      setLoaded(false);
      subscribeService.setToken(token);
      subscribeService
         .purchasePlan(newData)
         .then(res => {
            if (res.success) {
               window.open(res.data.redirection_link, "_self")
               setLoaded(true);
            }
         })
         .catch(error => {
            setLoaded(true);
         })

   }

   // local city filter 
   const handleCityfilter = (e) => {

      var str = e.target.value || '';
      setFilterCityString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = cities.filter(item => {
         return item.name.toLowerCase().includes(lowercasedFilter);
      });
      setFilteredCities(filteredData)

   }

   //onChange handler for city   
   const handleCityChange = (e) => {
      const value = e.currentTarget.dataset.value;
      const find = cities.find(c => String(c.id) === String(value));
      if (find !== undefined) {
         setCity(find);
      }
   }

   return (
      <>
         <SeoTags title={`Miltrade | Become a verified dealer`}
            description={''}
            category={''}
            url={window.location.href}
            image={''}
         />
         <Header
            data={isHomePage} checkAuth={false}
         />
         <PageLoader isLoaded={loaded} />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                           <Breadcrumb.Item active>{t("Become a verified dealer")}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="become-a-verified-dealer-page">
               <Container fluid>
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={6}>
                        <div className="title">
                           <h3>{t("Become a verified dealer")}</h3>
                           <p>{t("Upgrade your profile and become a verified dealer")} <br /> {t("with many great benefits for")} <span>{t("only")} {planInfo?.formatted_price} -{planInfo?.plan_type} </span> </p>
                        </div>
                        <div className="list">
                           <ul>
                              <li><i className="icon-right"></i> {t("Post unlimited listings in all categories")} </li>
                              <li><i className="icon-right"></i> {t("Get a verified badge all over Miltrade")}</li>
                              <li><i className="icon-right"></i> {t("Create a business profile with website redirect")} </li>
                              <li><i className="icon-right"></i> {t("Get listed in our Dealers & Services overview")} </li>
                           </ul>
                        </div>
                        <div className="more-information">
                           <h3>{t("No strings attached")}</h3>
                           <p>{t("you can cancel your subscription at any time")}</p>
                           <div>
                              <Link target="_blank" to="/support?page=dealers-services-section&title=Dealers and Services section">{t("More information")}</Link>
                              {!loginUser && <button className="ml-3 btn btn-primary signIn-btn" onClick={() => setShowLoginModal(true)}>{t("Sign in")}</button>}
                           </div>
                        </div>
                     </Col>
                     <Col xs={12} sm={12} md={12} lg={6}>
                        <Row className="no-gutters">
                           <div className="verified-dealer-plan">
                              <div className="plan-box">
                                 <div className="title">{t("GET UNLIMITED BENEFITS")}</div>
                                 <div className="plan-body">
                                    <Image src="/images/check_mark.png" alt="user" />
                                    <h3>{t("Verified Dealer")}</h3>
                                    <p>{t("Receive a great selection of extra benefits as a Verified Dealer")}</p>
                                 </div>
                                 <div className="membership-plan">
                                    <h4> {planInfo?.formatted_price} </h4>
                                    <h6>{planInfo?.plan_type} {t("Membership")}</h6>
                                 </div>
                                 <div className="plan-text">
                                    <ul>
                                       <li>
                                          <i className="icon-right"></i> <span> {t("Post an unlimited amount of listings in all product categories")}</span>
                                       </li>
                                       <li>
                                          <i className="icon-right"></i> <span> {t("Create a business profile including a website redirect")} </span>
                                       </li>
                                       <li>
                                          <i className="icon-right"></i> <span> {t("Get listed in our exclusive Dealers & Services directory")}</span>
                                       </li>
                                       <li>
                                          <i className="icon-right"></i> <span> {t("Get a verified badge that will be visible all over Mitrade")}</span>
                                       </li>
                                    </ul>
                                 </div>
                                 {!loginUser && <div className="box-footer" onClick={() => setShowLoginModal(true)}><button className="btn btn-primary">{t("No contract - Cancel anytime")}</button></div>}
                                 {loginUser && <div className="box-footer" onClick={() => scrollToBusinessForm(true)}><button className="btn btn-primary">{t("No contract - Cancel anytime")}</button></div>}
                              </div>
                           </div>
                           {/* </Col> */}
                           {/* <Col xs={12} sm={12} md={6} lg={3}> */}
                           <div className="unverified-dealer-plan verified-dealer-plan">
                              <div className="plan-box">
                                 <div className="title">{t("STANDARD OPTION")}</div>
                                 <div className="plan-body">
                                    <Image src="/favicon.ico" alt="user" />
                                    <h3>{t("Private Seller")}</h3>
                                    <p>{t("A normal Miltrade seller account with standard options only.")}</p>
                                 </div>
                                 <div className="membership-plan">

                                  <span>{t("Free Listings")}</span>
                                    <h6>{t("without extra benefits")}</h6>
                                 </div>
                                 <div className="plan-text">
                                    <ul>
                                       <li>
                                          <i className="icon-right"></i> <span> {t("Post an unlimited amount of listings in all product categories")}</span>
                                       </li>
                                       <li>
                                          <i className="icon-right"></i> <span> {t("Create a profile page with your listings")} </span>
                                       </li>
                                       <li>
                                          <i className="icon-right"></i> <span> {t("Upgrade your listings to featured ads for extra visibility")}</span>
                                       </li>
                                    </ul>
                                 </div>
                                 <div className="unverified-dealer-footer box-footer"><button className="btn btn-primary"> {t("NO CONTRACT")} </button></div>
                              </div>
                           </div>
                        </Row>
                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="become-a-verified-dealer-form">
               <Container fluid>
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={12}>
                        {loginUser ? (<div className="form-heading">
                           <h1>{t("Want to become a verified dealer?")}</h1>
                           <p>{t("Please complete the form below to apply. We always confirm your request")} <br />
                              {t("within 48 hours. You can select what information you would like to show")} <br />
                              {t("on your new business profile once it has been activated.")}</p>

                        </div>) : (
                           <div className="form-heading">
                              <h1>{t("Want to become a verified dealer?")}</h1>

                              <p>{t("Only dealers with excisting accounts can upgrade their account. Please")} <br />
                                 <span className="cp hoverLogin" onClick={() => setShowLoginModal(true)} >{t("register")}</span>  {t("or")} <span className="cp hoverLogin" onClick={() => setShowLoginModal(true)} >{t("sign in")}</span> {t("to your account to start the upgrading process.")}
                              </p>
                           </div>
                        )}

                     </Col>
                     {(loginUser && !profile?.user?.issubscribed) && (
                        <Col xs={12} sm={12} md={12} lg={12}>
                           <div className="become-a-verified-form">
                              <Form onSubmit={updateUserProfile}>
                                 <Form.Row className="justify-content-center">
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("Your company or dealer name")} <span>*</span></Form.Label>
                                          <Form.Control type="text"
                                             placeholder={t("Company / dealer name")}
                                             name="company_name"
                                             className={`${hasErrorFor('website') ? 'is-invalid' : 'valid'}`}
                                             onChange={handleFromChange}
                                             value={formData?.company_name}
                                          />
                                       </Form.Group>
                                       {renderErrorFor('company_name')}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridEmail">
                                          <Form.Label>{t("Your VAT number")}</Form.Label>
                                          <Form.Control type="text"
                                             onChange={handleFromChange}
                                             value={formData?.vat}
                                             name="vat"
                                             placeholder={t("VAT number")} />
                                       </Form.Group>
                                    </Col>
                                    {renderErrorFor('vat')}
                                 </Form.Row>
                                 <Form.Row className="justify-content-center">

                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("Your business email")} <span></span></Form.Label>
                                          <Form.Control type="text"
                                             className={`form-control3 ${hasErrorFor('business_email') ? 'is-invalid' : 'valid'}`}
                                             onChange={handleFromChange}
                                             value={formData?.business_email}
                                             name="business_email"
                                             placeholder={t("Business email")} />
                                       </Form.Group>
                                       {renderErrorFor('business_email')}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("Your postal code")} <span>*</span></Form.Label>
                                          <Form.Control className={`form-control3 ${hasErrorFor('postal_code') ? 'is-invalid' : 'valid'}`}
                                             onChange={handleFromChange}
                                             value={formData?.postal_code}
                                             name="postal_code"
                                             placeholder={t("Postal code")} />
                                       </Form.Group>
                                       {renderErrorFor('postal_code')}
                                    </Col>
                                 </Form.Row>
                                 <Form.Row className="justify-content-center">
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("Your address detail")} <span>*</span></Form.Label>
                                          <Form.Control type="text"
                                             className={`form-control3 ${hasErrorFor('business_address') ? 'is-invalid' : 'valid'}`}
                                             onChange={handleFromChange}
                                             value={formData?.business_address}
                                             name={"business_address"}
                                             placeholder={t("Street name + house number")} />
                                       </Form.Group>
                                       {renderErrorFor('business_address')}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("Your other address detail")} <span></span></Form.Label>
                                          <Form.Control type="text"
                                             className={`form-control3 ${hasErrorFor('business_address_1') ? 'is-invalid' : 'valid'}`}
                                             onChange={handleFromChange}
                                             value={formData?.business_address_1}
                                             name="business_address_1"
                                             placeholder={t("Address 2")} />
                                       </Form.Group>
                                       {renderErrorFor('business_address_1')}
                                    </Col>

                                 </Form.Row>
                                 <Form.Row className="justify-content-center">
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridState">
                                          <Dropdown className="form-control profileDropDown">
                                             <div className="subCategoryWrapper">
                                                <Dropdown.Toggle className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
                                                   <div className={`dropbtn`}>{countryFlag && (
                                                      <>
                                                         <Image className="flag-image" src={countryFlag?.flag} alt={countryFlag?.flag} />
                                                         <span> {countryFlag?.name}</span>
                                                      </>
                                                   )}</div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                   <div className="mobile-code-search">
                                                      <div className="form-group">
                                                         <input value={filterString} onChange={handlefilter} placeholder={t("search list")} type="text" className="form-control" />
                                                      </div>
                                                   </div>
                                                   {filteredCountries?.map((country, i) =>
                                                      <Dropdown.Item onClick={handleCountrySelect}
                                                         data-image={country?.flag}
                                                         data-name={country?.name}
                                                         data-value={country?.country_code}
                                                         key={i} > <img src={country?.flag} alt={country?.name} /> {country?.name}
                                                      </Dropdown.Item>
                                                   )}
                                                </Dropdown.Menu>
                                             </div>
                                          </Dropdown>
                                       </Form.Group>
                                       {renderErrorFor('country_code')}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Dropdown className="form-control profileDropDown">
                                             <div className="subCategoryWrapper">

                                                <Dropdown.Toggle className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
                                                   <div className={`dropbtn `}>{city && (
                                                      <><i className="icon-city mx-2"></i> <span> {city?.name}</span> </>
                                                   )}</div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                   <div className="mobile-code-search">
                                                      <div className="form-group">
                                                         <input value={filterCityString} onChange={handleCityfilter} placeholder={t("search city")} type="text" className="form-control" />
                                                      </div>
                                                   </div>
                                                   {filteredCities?.map((city, i) =>
                                                      <Dropdown.Item onClick={handleCityChange}
                                                         data-name={city?.name}
                                                         data-value={city.id}
                                                         key={i} > {city?.name}
                                                      </Dropdown.Item>
                                                   )}
                                                </Dropdown.Menu>
                                             </div>
                                          </Dropdown>
                                       </Form.Group>
                                       {renderErrorFor('city_id')}
                                    </Col>

                                 </Form.Row>
                                 <Form.Row className="justify-content-center">
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("Your company phone number")} <span>*</span></Form.Label>
                                          <div className="input-group ">
                                             <Dropdown>
                                                <div className="becomeVerifedUser">
                                                   <Dropdown.Toggle disabled={true} className="mobile-number-left-dropdown " variant="success" id="dropdown-basic">
                                                      <div className={`dropbtn `}>{phoneCode && (
                                                         <>
                                                            <Image className="flag" src={phoneCode?.flag} width="20px" />
                                                            <span> {phoneCode?.phone_code}</span>
                                                         </>
                                                      )}</div>

                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu>
                                                      <div className="mobile-code-search">
                                                         <form className="">
                                                            <div className="form-group">
                                                               {/* <div className="category-search-icon"><i className="icon-search"></i></div> */}
                                                               <input value={filterPhoneString} onChange={handlePhonefilter} placeholder="search list" type="text" id="formBasic" className="form-control2 form-control" />
                                                            </div>
                                                         </form>
                                                      </div>
                                                      {filteredPhoneCodes?.map((code, i) =>
                                                         <Dropdown.Item onClick={handlePhoneCodeSelect}
                                                            data-image={code.flag}
                                                            data-value={code?.phone_code}
                                                            key={i} > {code?.phone_code}
                                                         </Dropdown.Item>
                                                      )}
                                                   </Dropdown.Menu>
                                                </div>
                                             </Dropdown>

                                             <Form.Control
                                                className={`form-control2 ${hasErrorFor('phone') ? 'is-invalid' : 'valid'}`}
                                                type='number'
                                                name="phone"
                                                min="0"
                                                onChange={handleFromChange}
                                                value={formData?.phone}
                                                placeholder={t("Enter phone number")}
                                             />

                                          </div>

                                       </Form.Group>
                                       {renderErrorFor('phone') || renderErrorFor('phone_code')}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={3}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("Your website")} <span>*</span></Form.Label>
                                          <Form.Control type="text"
                                             className={`${hasErrorFor('website') ? 'is-invalid' : 'valid'}`}
                                             onChange={handleFromChange}
                                             value={formData?.website}
                                             name="website"
                                             placeholder="eg:https://www.domain.com" />
                                       </Form.Group>
                                       {renderErrorFor('website')}
                                    </Col>
                                 </Form.Row>
                                 <Form.Row className="justify-content-center">
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                       <Form.Group controlId="formGridtext">
                                          <Form.Label>{t("About your company")}<span className="required-star">*</span></Form.Label>
                                          <Form.Control type="text"
                                             className={`business_description ${hasErrorFor('business_description') ? 'is-invalid' : 'valid'}`}
                                             onChange={handleFromChange}
                                             as="textarea"
                                             rows={9}
                                             value={formData?.business_description}
                                             name="business_description"
                                             placeholder={t("Enter a description about your company and its services and specialties. This text will be shown on your dealer page and can be edited afterwards at any time.")} />
                                       </Form.Group>
                                       {renderErrorFor('business_description')}
                                    </Col>

                                 </Form.Row>
                                 <Form.Row className="justify-content-left mt-4">
                                    <Col xs={12} sm={12} md={6} lg={9} className="offset-lg-3">
                                       <div className="form-check">
                                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={() => setConsentTC(!consentTC)} />
                                          <p className="check">
                                             {t("I have read the")} <Link className='terms_conditions_link' to="/support?page=terms-of-service&title=Terms%20of%20service&topic=terms-and-conditions&ttitle=Terms%20and%20Conditions"> {t("terms & conditions")}</Link> {t("and I confirm that the information provided is correct.")} </p>

                                       </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                       <div className="update-profile-submit mt-2">
                                          <Button variant="primary" disabled={!consentTC} type="submit">{t("Continue to payment method")}</Button>
                                       </div>
                                    </Col>
                                 </Form.Row>
                              </Form>
                           </div>
                        </Col>
                     )}

                  </Row>
               </Container>
            </div>
         </div>
         <Footer />
      </>
   );
}
export default BecomeAverifiedDealer;
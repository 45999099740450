/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Encriptor from "../../services/encriptor"
import Picker from 'emoji-picker-react';
import { isMobile } from "react-device-detect";
import { useToasts } from 'react-toast-notifications';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const Conversation = ({ hasThreads,
	chatThredsMessages,
	sendMessages,
	socket,
	activeThread,
	setChatThreds,
	setAddData,
	scrollChat,
	inThreadMessageCount,
	setInThreadMessageCount,
	markThreadUnarchive,
	markThreadArchive,
	setConversationPageNumber,
	conversationPageNumber,
	chatInLineLoaded,
	setChatInLineLoaded,
	newPageData,
	setNewPageData,
	lastPage,
}) => {
	// console.log(chatThredsMessages);
	const { t } = useTranslation();
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const [message, setMessage] = useState('');
	const [showEmojiBlock, setShowEmojiBlock] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const [imagePreviewModal, setImagePreviewModal] = useState(false);
	const [imagePreview, setImagePreview] = useState(false);
	const [scrollBtn, setScrollBtn] = useState(true);
	const node = useRef(null);
	var typingTimeout = 0;
	const messagesEndRef = useRef(null)
	const upperChatRef = useRef(null)
	const bodyBox = useRef()
	const { addToast } = useToasts()
	const [nav, setNav] = useState(false);





	// send message 
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!message) {
			addToast(t("You can't send empty message"), { appearance: 'error' })
			return
		}
		setMessage('');
		setIsTyping(false);
		sendMessages(chatThredsMessages?.id, message, new Date(), loginUser?.id, loginUser?.username, loginUser?.picture, chatThredsMessages?.user_id, 'text', moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS") + 'Z');
		setChatThreds(prevState => {
			let currentThread = prevState.filter((thread) => parseInt(thread.id) === parseInt(activeThread))
			let remainingArr = prevState.filter((thread) => parseInt(thread.id) !== parseInt(activeThread))
			return [...currentThread, ...remainingArr]
		})
		setAddData(prevState => {
			let currentThread = prevState.filter((thread) => parseInt(thread.id) === parseInt(activeThread))
			let remainingArr = prevState.filter((thread) => parseInt(thread.id) !== parseInt(activeThread))
			return [...currentThread, ...remainingArr]
		})
		scrollToBottom();
	}

	// scroll to bottom handler 
	const scrollToBottom = () => {
		setInThreadMessageCount(0)
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
	}

	const handleClick = e => {
		if (node && node?.current?.contains(e.target)) {
			setShowEmojiBlock(true)
			return false;
		}
		// outside click 
		setShowEmojiBlock(false)
	};

	const handleOnTyping = (data) => {
		if (parseInt(data.receiver_id) === parseInt(loginUser?.id)) {
			setIsTyping(data.threadId)
			setChatThreds((prevState) => {
				return prevState.map((thread) => {
					if (parseInt(thread.user_id) === parseInt(data.user_id)) {
						thread = {
							...thread,
							isTyping: true
						}
					}
					return thread
				})
			})
		}
	}

	const handleLoadMorePage = (page) => {
		if (page + 1 <= lastPage) {
			setChatInLineLoaded(true);
			return page + 1;
		} else {
			setChatInLineLoaded(false);
			return page;
		}
	}


	// handle bottom scorll 
	const handleScroll = e => {
		let element = e?.target
		if (parseInt(element?.scrollTop) === 0) {
			setConversationPageNumber((prv) => handleLoadMorePage(prv))
		}
		if (parseInt(element?.scrollTop) > 300) {
			setNewPageData(false);
		}
		if (element) {
			if ((element?.scrollHeight - element?.scrollTop) - element?.clientHeight <= 100) {
				setInThreadMessageCount(0)
				setScrollBtn(false);
			} else {
				setScrollBtn(true)
			}
		}
	}

	useEffect(() => {


		socket.on('onTyping', handleOnTyping);

		socket.on('onTypingEnd', (data) => {
			if (parseInt(data.receiver_id) === parseInt(loginUser?.id)) {
				setIsTyping(false)
				setChatThreds((prevState) => {
					return prevState.map((thread) => {
						if (parseInt(thread.user_id) === parseInt(data.user_id)) {
							thread = {
								...thread,
								isTyping: false
							}
						}
						return thread
					})
				})
			}
			if (isMobile) {
				setNav(true);
			}
		});

		// add when mounted
		document.addEventListener("mousedown", handleClick);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener("mousedown", handleClick);
			socket.off('onTyping', handleOnTyping)
			socket.disconnect();
		};
	}, [])


	useEffect(() => {
		const element = bodyBox?.current;
		if (conversationPageNumber <= 1) {

			if (typeof element != 'undefined') {
				if ((element?.scrollHeight - element?.scrollTop) - element?.clientHeight <= 100) {
					setInThreadMessageCount(0)
					setScrollBtn(false);
					scrollToBottom()
				} else {
					setScrollBtn(true);
				}
			}
			if (scrollChat) {
				scrollToBottom()
			}
		} else {
			if (newPageData) upperChatRef?.current?.scrollIntoView();
		}

	}, [chatThredsMessages])

	if (!chatThredsMessages) {
		return (
			<>
				<div className="text-center">
					<Image src="../images/no_chat.webp" className="img-fluid" alt="user" />
				</div>
			</>

		)
	}

	// arranging chat into groipng of date  
	const chekIsDiffentDate = (index) => {
		const messages = chatThredsMessages.messages;
		if (index === 0) {
			return true;
		}
		const d1 = new Date(messages[index - 1].message_time);
		const d2 = new Date(messages[index].message_time);
		return (
			d1.getFullYear() !== d2.getFullYear() ||
			d1.getMonth() !== d2.getMonth() ||
			d1.getDate() !== d2.getDate()
		);

	}
	// formate chat tome tp timeago
	const dateToFromNowDaily = (myDate) => {

		// get from-now for this date
		// var fromNow = moment( myDate ).fromNow();
		var fromNow = moment(myDate).format("MMMM Do YY")

		// ensure the date is displayed with today and yesterday
		return moment(myDate).calendar(null, {
			// when the date is closer, specify custom values

			lastWeek: "[" + fromNow + "]",
			lastDay: '[Yesterday]',
			sameDay: '[Today]',
			nextDay: '[Tomorrow]',
			nextWeek: 'dddd',
			// when the date is further away, use from-now functionality             
			sameElse: function () {
				return "[" + fromNow + "]";
			}
		});
	}

	// handle emoji onselect 
	function onEmojiClick(emojiData, event) {
		let newMessage = message + ` ${emojiData.emoji}`;
		setMessage(newMessage);
	}
	// const onKeyUp = (event) => {
	// 	if (event.charCode === 13) {
	// 		handleSubmit()
	// 	}
	// }

	// handle select file in chat 
	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const image = e.target.files[0];
			sendMessages(chatThredsMessages?.id, image, new Date(), loginUser?.id, loginUser?.username, loginUser?.picture, chatThredsMessages?.user_id, "file", moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS") + 'Z');
		}
		setIsTyping(false);
	};


	const onTyping = (e) => {

		const tData = {
			threadId: chatThredsMessages?.id,
			receiver_id: String(chatThredsMessages?.user_id),
			user_id: String(loginUser?.id)
		}
		if (!e.target.value) {
			socket.emit('onTypingEnd', tData, error => {
				if (error) {
					alert(error)
				}
			})
		} else {
			socket.emit("onTyping", tData, (error) => {
				if (error) {
					alert(error);
				}
			});
		}
		// if(typingTimeout) clearTimeout(typingTimeout);
		typingTimeout = setTimeout(() => {
			socket.emit('onTypingEnd', tData, (error) => {
				if (error) {
					console.log(error);
				}
			});
		}, 4000);
	};

	// onChange handler for message  
	const handleMessageChange = e => {
		setNewPageData(false);
		setMessage(e.target.value);
		onTyping(e);

	}





	return (
		<>
			<div className="_3Y32P undefined">
				{hasThreads ? (
					<div className="_30PXV undefined">

						<div className='chat-conversation-head-wrapper'>
							<div className='chat-conversation-head-user-info'>
								<div className='image-wrapper'>
									<a className="" rel="nofollow noreferrer" target="_blank" href={`/seller/profile/${Encriptor.encrypt(chatThredsMessages.user_id)}`} >
										{chatThredsMessages?.issubscribed ? (
											<div >
												<Image src={chatThredsMessages?.user_photo} className="img-fluid user-img" alt="user" />
												<Image src="../../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-image-chat" />
											</div>
										) : (
											<div >
												<Image src={chatThredsMessages?.user_photo ? chatThredsMessages?.user_photo : "../../images/user.png"} className="img-fluid" alt="user" />
											</div>
										)}
									</a>
								</div>
								<div className='user-info'>
									<div className='user-name'>
										<a rel="nofollow noreferrer" data-aut-id="" target="_blank" href={`/seller/profile/${Encriptor.encrypt(chatThredsMessages.user_id)}`} >{chatThredsMessages?.user_name}</a>
									</div>
									<div className='msg-time'>
										<div>
											<span className="online-offline">
												{
													(String(chatThredsMessages.user_id) !== String(loginUser.id)
														&& parseInt(isTyping) === parseInt(activeThread))
														? <span> {t("Typing")}...</span>
														: chatThredsMessages.online
															? <span style={{ color: '#1dbf73' }}> {t("Online")}</span>
															: <span> {t("Offline")}</span>
												}
											</span>
											<span className="chat-local-time"> <span className="local-timeicon">/</span> {t("Local Time")}:</span>
											<span className="time-dat">{chatThredsMessages?.user_time}</span>
										</div>
									</div>
								</div>
							</div>
							<div className='chat-conversation-head-options'>
								<Dropdown>
									<Dropdown.Toggle variant="success" id="dropdown-basic"></Dropdown.Toggle>

									<Dropdown.Menu>
										{chatThredsMessages?.archived ? (
											<Dropdown.Item onClick={(e) => { markThreadUnarchive(chatThredsMessages?.id) }}>{t("Unarchive Chat")}</Dropdown.Item>
										) : (
											<Dropdown.Item onClick={(e) => { markThreadArchive(chatThredsMessages?.id) }}>{t("Archive Chat")}</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>

						<div className="ragnarok-2S8ff" id="scroll-box" onScroll={handleScroll} ref={bodyBox} >

							{chatThredsMessages?.messages?.map((chat, i) => (
								<div key={i} className="ragnarok-35f8M undefined ragnarok-1MK7L ragnarok-2v3nj">

									{chekIsDiffentDate(i) ? <div className="ragnarok-2_8cP"><span className="ragnarok-1QM0A"><span className="ragnarok-wRai- ragnarok-1xsrj"><span>{dateToFromNowDaily(chat.message_time)}</span></span></span></div> : ""}
									{String(chat.user_id) !== String(loginUser.id) && (
										<span className="ragnarok-2AwjI ragnarok-14ZSF ragnarok-lk4oE">
											<img src={chat.user_photo} alt="user" width="24px" height="24px" className="rounded-circle" />
										</span>
									)}
									{i === 1 && (<div ref={upperChatRef} />)}
									<div className="ragnarok-37zic">
										{(i <= 0 && chatInLineLoaded) && <div>
											<div className={`inline-loader`}>
												<Spinner animation="border" variant="success" />
											</div>
										</div>}
										{String(chat?.user_id) === String(loginUser?.id) ? (
											<pre data-aut-id="textMsg" className="ragnarok-lEiy2 ragnarok-3VE-5 ragnarok-2EwVK ragnarok-yRrIQ">
												<span className="">
													{String(chat?.type) === "text" ? (chat.message) : (
														<Image src={chat.thumb_xs ? chat.thumb_xs : chat.message} className="cp mb-0" width="200px" alt={`file`} onClick={() => {
															setImagePreviewModal(true);
															setImagePreview(chat.message);
														}} />
													)}
												</span>
												<div className="ragnarok-yyf2M">
													<span className="ragnarok-1Rg2M"><span>{moment(chat.message_time).format('LT')}</span> </span>
													<span className="seen-icon"><i className={`icon-chat_right_mark_${chat.read ? 'double' : 'single'}`}></i></span>
												</div>
											</pre>
										) : (
											<div className="my-msgs">

												<pre data-aut-id="textMsg" className="ragnarok-lEiy2 ragnarok-1xzkP ragnarok-2EwVK ragnarok-xf4H_ ragnarok-4VKW_ ragnarok-3n1PN">

													<span className="ragnarok-1L-E4">

														{String(chat?.type) === "text" ? (chat.message) : (
															<Image src={chat.thumb_xs ? chat.thumb_xs : chat.message} className="cp mb-0" width="200px" alt={'file'} onClick={() => {
																setImagePreviewModal(true);
																setImagePreview(chat.message);
															}} />
														)}
													</span>
													<div className="ragnarok-yyf2M">
														<span className="ragnarok-1Rg2M"><span>{moment(chat.message_time).format('LT')}</span></span>
														{/* <span className="seen-icon"><i className={`icon-chat_right_mark_${chat.read ?'double':checkISRead(chat)}`}></i></span> */}
													</div>
												</pre>
											</div>
										)}
										{chatThredsMessages?.post_id > 0 && (
											<div data-aut-id="adBanner" className="ragnarok-1YvTf ragnarok-LXfzN" >
												<a className="ragnarok-2Atbw" target="_blank" href={`/product/detail/${chatThredsMessages.slug}`} rel="noreferrer">
													<div className="ragnarok-2yYqL">
														<div className="ragnarok-2wH7y">{chatThredsMessages?.post_title}</div>
														<div className="ragnarok-2wH7y">
															{chatThredsMessages?.post_id > 0 ? chatThredsMessages?.open_to_offer ? 'Open To Offer' : chatThredsMessages.formatted_price : ''}
														</div>
													</div>
												</a>
											</div>
										)}


									</div>
								</div>
							))}
							<div ref={messagesEndRef} />
							{scrollBtn && (<div className="sbottom">
								{inThreadMessageCount > 0 && (<span className="badge badge-danger message-counter-badge">{inThreadMessageCount}</span>)}
								<span className="down-arrow" onClick={scrollToBottom}>
									<i className="fa fa-angle-double-down"></i></span>
							</div>)}

						</div>
						<div className="rui-MAv-w ragnarok-1kq5P ragnarok-2OrR4">
							<div className="ragnarok-1d4Le">
								<div className="ragnarok-3Ggdw">
									<Form onSubmit={handleSubmit}>
										<div className="_111rB">

											<div className="ragnarok-2bdxa text-message-wrap">
												<div className="input-group">
													<textarea placeholder="Type a message here" value={message} onChange={handleMessageChange} data-aut-id="inputBox" className="ragnarok-1Ki8W form-control" type="text" />
												</div>
												<div className="chat-attachments">
													<div ref={node} className="chat-ext">
														<span className="chat-emoji"><i className="fa fa-smile-o"></i></span>
														<div>
															{showEmojiBlock && (
																<Picker onEmojiClick={onEmojiClick} />
															)}
														</div>
													</div>
													<div className="image-upload1 file-upload"><label htmlFor="file-input"><i className="icon-camera"></i></label><input id="file-input" type="file" name="profile" onChange={onSelectFile} /></div>
												</div>
												{/* <div className="input-group mb-3">
													<textarea placeholder="Type a message here" value={message} onChange={handleMessageChange} data-aut-id="inputBox" className="ragnarok-1Ki8W form-control" type="text" />
												</div> */}
												<div className='chat-submit-btn'>
													<button className="btn btn btn-link" type="submit" onClick={handleSubmit} id="button-addon2"><i className="fa fa-paper-plane"></i></button>
												</div>
												<span className="ragnarok-2LHVl ragnarok-VSLmZ" data-aut-id="btnStartRecording">

												</span>


											</div>
										</div>

									</Form>
								</div>
							</div>
						</div>
					</div>

				) : (<div className="_30PXV undefined">
					<div className="ragnarok-3t5nL ragnarok-3IP53 ragnarok-2RlGI " >
						<p> {t("No messages")}</p>
					</div>
				</div>)}
			</div>

			<Modal
				size="lg"
				show={imagePreviewModal} onHide={() => setImagePreviewModal(false)}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<div className="report-ad-modal">
						<Image src={imagePreview} width="100%" />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="report-add-footer">
						<Button variant="secondary close-btn" onClick={() => setImagePreviewModal(false)}>{t("Close")}</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}
export default Conversation
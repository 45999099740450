import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import PageLoader from '../../pageLoader';
import ShareIt from '../../shareIt/share'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const ReferAndEarn = ({ active }) => {
	const { t } = useTranslation();
	const [copiedLink, setCopiedLink] = useState(false);
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;

	const handleCopy = () => {
		setCopiedLink(true); setTimeout(() => {
			setCopiedLink(false)
		}, 3000);
	}
	if (active === "Invite a friend") {
		return (
			<>
				<PageLoader isLoaded={true} />
				<div className="p-4">
					<div className="refer-earn">
						<div className="title">
							<h1>{t('Refer your friends')}</h1>
						</div>
						<div className="refer-earn-desc">
							<p>{t('Invite your friends to join Miltrade and earn 3 credit for every person who creates an account and')} <br />{t('lists an item for sale. Just copy the link below and start sharing!')}</p>
						</div>
						<div className="refer-earn-image">
							<Image src="/images/refer_and_earn.webp" width="50%" alt="share" className="img-fluid" />
						</div>

						<div className="refer-earn-link text-center cp">
							{/* <div className="refer-earn-link"> */}
							<p className="mb-2">{loginUser?.referral_link}</p>

							<CopyToClipboard text={loginUser?.referral_link}
								onCopy={handleCopy}>
								<span><i className="fa fa-copy "></i></span>
							</CopyToClipboard>
						</div>

						{copiedLink && (
							<div className="refer-earn-socila-link">
								<span className="badge badge-success">{t('Refferal Link has been copied')}</span>
							</div>
						)}

						<div className="refer-earn-socila-link">
							<ShareIt share={true} shareUrl={loginUser?.referral_link} title={t('Register on Miltrade using this link and get refferal points .')} />
						</div>
					</div>
				</div>
			</>
		)
	}
	return (
		<>
		</>
	)
}
export default ReferAndEarn;
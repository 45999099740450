import React,{useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Encriptor from '../../services/encriptor'
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



const GirdView = ({posts,LikeUnlikeToggleButton,FollowUnfollowToggle}) => {
   const [showNumberOfItems,setShowNumberOfItems] = useState(8);
   const loginUser =  window.localStorage.getItem('loggedUser')? JSON.parse(window.localStorage.getItem('loggedUser')):false;
  
   if(posts){
      return(
           <>
           <div className="grid-post">
              <Row>
                 {posts?.slice(0, showNumberOfItems)?.map((post,i)=>(
                       
                          <Col xs={6} key={i} sm={6} md={4} lg={3} className="all-post-dispaly">
                                <div className="product-display mt-3">
                                   <div className="card">
                                      {post?.featured && (<div className="featured"><span>FEATURED</span></div>)} 
                                      {loginUser && parseInt(post?.user_id) !== parseInt(loginUser?.id) && (
                                               <div className="heart cp">
                                                  <span>
                                                  {post.likedbyme  ? (
                                                     <div className="red_favourite" onClick={() =>LikeUnlikeToggleButton(post.postid)} > <i className="icon-red_favourite active" aria-hidden="true"></i></div> 
                                                     ) : (
                                                     <div className="favourite1" onClick={() =>LikeUnlikeToggleButton(post.postid)} > <i className="icon-favourite" aria-hidden="true"></i></div> 
                                                     )}
                                                  </span>
                                               </div>
                                            )}
                                      <div className="img-card">
                                      <div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)}</Link> </span></div>
                                         <Link to={`/product/detail/${post.slug}`} >
                                            <Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"}   alt={post?.slug}className="img-fluid" />
                                         </Link>
                                      </div>
                                      <div className="cards">
                                         <h5 className="Product-title">
                                         <Link to={`/product/detail/${post?.slug}`} >
                                            <OverlayTrigger
                                                  placement="bottom"
                                                  delay={{ show: 250, hide: 400 }}
                                                  overlay={
                                                  <Tooltip id={`tooltip-${post?.id}`}>
                                                     {`${post?.title}`}
                                                  </Tooltip>
                                                  }
                                               >
                                               {(post?.title?.length) > 20 ? (
                                                  
                                                  <div className="">
                                                  
                                                     {`${post?.title}`.slice(0, 20)} ...
                                                  
                                                  </div>
                                               
                                               ) : (
                                               <div className="">
                                                     {`${post?.title}`}
                                               </div>
                                               ) }
                                            </OverlayTrigger>
                                        </Link> 
                                      </h5>
                                         <div className="Product-desc">
                                         <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                            <Tooltip className="large-tooltip" id={`tooltip-${post?.id}`}>
                                               {`${post?.short_description} `}
                                            </Tooltip>
                                            }
                                         >
                                         {(post?.short_description.length) > 60 ? (
                                            
                                            <div className="">
                                               {`${post?.short_description}`.slice(0, 60)} ...
                                            </div>
                                         
                                         ) : (
                                         <div className="">
                                               {`${post?.short_description}`}
                                         </div>
                                         ) }
                                         </OverlayTrigger>
                                         </div>
                                         <div className="price-location">
                                            <ul>
                                               <li className="price">{post?.open_to_offer?('Make an offer'):(post?.formatted_price)}</li>
                                               <li className="location">
                                                  <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={
                                                        <Tooltip id={`tooltip-${post?.id}`}>
                                                           {`${post?.address} `}
                                                        </Tooltip>
                                                        }
                                                     >
                                                     {(post?.address.length) > 10 ? (
                                                        
                                                        <div className="">
                                                           <i className="icon-location" aria-hidden="true"></i> {`${post?.address}`.slice(0, 10)} ...
                                                        </div>
                                                     
                                                     ) : (
                                                     <div className="">
                                                           <i className="icon-location" aria-hidden="true"></i> {`${post?.address}`}
                                                     </div>
                                                     ) }
                                                  </OverlayTrigger>
  
                                               </li>
                                            </ul>
                                         </div>
                                         <div className="post-owner-details">
                                         <Row>
                                            <Col xs={4} sm={4} md={3} lg={3} className="p-0 pl-1">
                                            
                                               <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                                     <div className='item-seller'>
                                                           {post?.user?.issubscribed && (
                                                              <div className="verified-dealer">
                                                                 <Image src="../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />  
                                                              </div>
                                                           )}
                                                     
                                                     <div className={`seller-image-${post?.user?.issubscribed ?'verified':'unverified'}`}>
                                                        <Image src={post?.user?.photo} alt="1" className="img-fluid" />
                                                     </div>
                                                  </div>
                                               </Link> 
                                            </Col>
                                            <Col xs={8} sm={8} md={9} lg={5} className="p-0">
                                               <div className={`seller-desc ${(loginUser && parseInt(post?.user_id) !== parseInt(loginUser?.id)) ?'':'seller-desc1'}`}>
                                                  <h3>
                                                     <div className="">
                                                        <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                                           <OverlayTrigger
                                                                 placement="bottom"
                                                                 delay={{ show: 250, hide: 400 }}
                                                                 overlay={
                                                                 <Tooltip id={`tooltip-${post?.user?.id}`}>
                                                                    {`${post?.user?.username} `}
                                                                 </Tooltip>
                                                                 }
                                                              >
                                                              {(post?.user?.username.length) > 8 ? (
                                                                 
                                                                 <div className="">
                                                                    {`${post?.user?.username} `.slice(0, 8)} ...
                                                                 </div>
                                                              
                                                              ) : (
                                                              <div className="">
                                                                 {`${post?.user?.username}`}
                                                              </div>
                                                              ) }
                                                           </OverlayTrigger>
                                                        </Link>
                                                     </div>
                                                  </h3>
                                                  {post?.user?.issubscribed ? ( <h6>Verified Seller</h6>):(<h6 className="private-seller">Private Seller</h6>)}
                                                  {/* {loginUser && post?.user_id != loginUser?.id && (
                                                      <div onClick={()=>FollowUnfollowToggle(post.user_id)} className="followbtn">
                                                           <span className="cp">{post?.user?.followedbyme ?'Unfollow':'Follow'}</span>
                                                     </div>
                                                  )} */}
                                               </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={4} className="pl-1 pr-2">         
                                               <div className="view">
                                            <Link ><i className="icon-star_fill" aria-hidden="true"></i> <span> {parseFloat(post?.user?.averagerating).toFixed(1)}</span></Link> ({Encriptor.kformatter(post?.user?.review_count)})
                                            <div className="profile-visit mt-1"><Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`}>Visit Profile</Link></div>
                                               </div>
                                            </Col>
                                         </Row>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </Col>
                       
                    ))}
                
                 
        </Row>
        <Row>
               <Col>
                    {posts.length > showNumberOfItems  &&(<div  className="loadmorebutton py-5"><button onClick={()=>setShowNumberOfItems(showNumberOfItems+8)} type="button" className="btn btn-primary py-2"> View more <i className="icon-dropdown"></i></button></div>) }
                </Col>
        </Row>
        </div>
           </>
        )
     }
   return (
      <>
         No posts found...
      </>
   )
}
export default GirdView;
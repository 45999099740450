/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from 'react';
import ListView from './listView';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GridView from './gridView';
// import { Spinner } from 'react-bootstrap';


const MyPosts = ({ view, posts, deletePost, markSoldPost, renewPost, setPage, setInlineLoader, inlineLoader }) => {
    // Observer to handle scroll to bottom 
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            console.log(target.isIntersecting);
            setInlineLoader(true);
            setPage((prv) => (prv + 1));
        }
    }, []);
    const loader = useRef(null);
    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);
    return (
        <>
            {posts?.length > 0 ? (
                view === 'grid' ?
                    (<GridView posts={posts} deletePost={deletePost} markSoldPost={markSoldPost} renewPost={renewPost} />) :
                    (<ListView posts={posts} deletePost={deletePost} markSoldPost={markSoldPost} renewPost={renewPost} />)
            ) : (
                <>
                    <Row>
                        <Col>
                            <div className="text-center">
                                <Image src="../images/no_listings_found.webp" className="img-fluid" alt="user" />
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            <div ref={loader} />
            {/* {inlineLoader && (
                <div className={`inline-loader`}>
                    <Spinner animation="border" variant="success" />
                </div>
            )} */}
        </>
    )


}

export default MyPosts;
import { isMobile } from "react-device-detect";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import { useState } from "react";
import { Button, Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DealersMobileFilter = ({
  sortBy,
  filterOption,
  filterCountries,
  handleClearFilter,
  handleFiltersSelect,
  showNumberOfFilters,
  setShowNumberOfFilters,
  filterCount,
  countries,
  filterString,
  filterTOSString,
  filterTOS,
  fiteredServices,
  filterSpecialities,
  filterSpecialitiesString,
  fiteredSpecialities,
  handleSortBy,
}) => {

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSort, setIsSort] = useState(false);


  const filtersToggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const sortToggleDrawer = () => {
    setIsSort((prevState) => !prevState)
  }


  // Sortby filter handler 
  const handleSortByFilter = (filter) => {
    handleSortBy(filter);
    setIsSort((prevState) => !prevState);

  }

  // get active Class  
  const activeSortByClass = (filter) => {
    if (String(sortBy) === filter)
      return 'active';
    else return '';
  }

 

  return (
    <>
      {isMobile ? (
        <div className='mobile-filter'>
          <div className="filter-label">

            <div onClick={sortToggleDrawer} className={`filter-name cp ${sortBy === '' ? '' : 'active'}`}><i className="icon-sort"></i> {t("Sort")}</div>

            <div onClick={filtersToggleDrawer} className={`filter-name cp ${filterCount > 0 ? 'active' : ''}`}> <i className="icon-side_baar"></i> {t("Filters")} </div>

          </div>


          <Drawer open={isSort} onClose={sortToggleDrawer} direction='bottom' className='Sort_filters' >
            <div className="Sort_filter_wrapper">
              <ul>
                <li className={activeSortByClass('dealer name A-Z')} onClick={(e) => { handleSortByFilter('Dealer name A-Z') }} >{t("Dealer name A-Z")}</li>
                <li className={activeSortByClass('dealer name Z-A')} onClick={(e) => { handleSortByFilter('dealer name Z-A') }} >{t("Dealer name Z-A")}</li>
                <li className={activeSortByClass('country A-Z')} onClick={(e) => { handleSortByFilter('country A-Z') }} >{t("Country A-Z")}</li>
                <li className={activeSortByClass('country Z-A')} onClick={(e) => { handleSortByFilter('country Z-A') }} >{t("Country Z-A")}</li>
                <li className={activeSortByClass('reviews ascending')} onClick={(e) => { handleSortByFilter('reviews ascending') }} >{t("Reviews ascending")}</li>
                <li className={activeSortByClass('reviews descending')} onClick={(e) => { handleSortByFilter('reviews descending') }} >{t("Reviews descending")}</li>
              </ul>

            </div>
          </Drawer>

          <Drawer open={isOpen} onClose={filtersToggleDrawer} direction='bottom' className='filters_wrapper' >
            <div className="top_heading">
              <h5>{t('Filter by')} {filterCount > 0 && (<span className="badge badge-danger filter-custom-badge">{filterCount}</span>)}</h5>
              <h6 className="cp" onClick={handleClearFilter}>{t("Clear all")} </h6>
            </div>
            <div className="filter_type">
              <Tab.Container id="left-tabs-example" defaultActiveKey="filter-1">
                <Row className="no-gutters">
                  <Col sm={6} xs={6}>
                    <Nav variant="pills" className="flex-column left-result-wrapper">
                      <div className="filter_category">
                        <Nav.Link eventKey={`filter-1`} >{t("Country")} <span className={filterOption.find(f => String(f.id) === 'country') ? 'active items-selected-check' : 'items-selected-check'}>{filterOption.find(f => String(f.id) === 'country') ? <i className="icon-right"></i> : ''}</span></Nav.Link>
                        <Nav.Link eventKey={`filter-2`} >{t("Type of Service")} <span className={filterOption.find(f => String(f.id) === 'services') ? 'active items-selected-check' : 'items-selected-check'}>{filterOption.find(f => String(f.id) === 'services') ? <i className="icon-right"></i> : ''}</span></Nav.Link>
                        <Nav.Link eventKey={`filter-3`} >{t("Specialities")} <span className={filterOption.find(f => String(f.id) === 'specialities') ? 'active items-selected-check' : 'items-selected-check'}>{filterOption.find(f => String(f.id) === 'specialities') ? <i className="icon-right"></i> : ''}</span></Nav.Link>
                      </div>
                    </Nav>
                  </Col>
                  <Col sm={6} xs={6}>
                    <Tab.Content>

                      <Tab.Pane eventKey={`filter-1`} className="category-mobile-filter" >
                        <Card >
                          <Card.Body>
                            <div className="filter-search-wrapper">
                              <div className="category-search-icon">
                                <i className="icon-search"></i>
                              </div>
                              <Form.Control type="text" value={filterString} onChange={filterCountries} placeholder={t("search")} />
                            </div>
                            <div className="category-name right-result-wrapper">
                              <ul>
                                {countries?.slice(0, showNumberOfFilters).map((option, i) => (
                                  <li key={i} data-filter_value={option.code} data-filter_id="country" onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.code)) ? 'active' : ''}><i className="icon-right"></i> {option.name}</li>
                                ))}
                                <div className="loadmorebutton">
                                  {countries?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                                </div>
                              </ul>
                            </div>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                      <Tab.Pane eventKey={`filter-2`} className="category-mobile-filter" >
                        <Card >
                          <Card.Body>
                            <div className="filter-search-wrapper">
                              <div className="category-search-icon">
                                <i className="icon-search"></i>
                              </div>
                              <Form.Control type="text" value={filterTOSString} onChange={filterTOS} placeholder={t("search type of service")} />
                            </div>
                            <div className="category-name right-result-wrapper">
                              <ul>
                                {fiteredServices?.slice(0, showNumberOfFilters).map((option, i) => (
                                  <li key={i} data-filter_value={option.id} data-filter_id="services" onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id) && f.id === 'services') ? 'active' : ''}><i className="icon-right"></i> {option.title}</li>
                                ))}
                                <div className="loadmorebutton">
                                  {fiteredServices?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                                </div>
                              </ul>
                            </div>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                      <Tab.Pane eventKey={`filter-3`} className="category-mobile-filter" >
                        <Card >
                          <Card.Body>
                            <div className="filter-search-wrapper">
                              <div className="category-search-icon">
                                <i className="icon-search"></i>
                              </div>
                              <Form.Control type="text" value={filterSpecialitiesString} onChange={filterSpecialities} placeholder={t("search specialities")} />
                            </div>
                            <div className="category-name right-result-wrapper">
                              <ul>
                                {fiteredSpecialities?.slice(0, showNumberOfFilters).map((option, i) => (
                                  <li key={i} data-filter_value={option.id} data-filter_id="specialities" onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id) && f.id === 'specialities') ? 'active' : ''}><i className="icon-right"></i> {option.title}</li>
                                ))}
                                <div className="loadmorebutton">
                                  {fiteredSpecialities?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                                </div>
                              </ul>
                            </div>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>

                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              
            </div>
            <div className="close_apply_btn">
              <Button onClick={() => { setIsOpen((prevState) => !prevState) }} className="close-btn">{t("Close")}</Button>
            </div>
          </Drawer>
        </div>
      ) : (
        <></>
      )
      }
    </>
  );
}




export default DealersMobileFilter;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import profileService from '../../../services/myProfile'
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../pageLoader'
import moment from 'moment';
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';
// import { Button } from 'bootstrap';


const MySubscription = ({ active }) => {
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const token = loginUser.access_token;
	const { t } = useTranslation();
	const [subscriptionData, setSubscriptionData] = useState();
	const [showNumberOfItems, setShowNumberOfItems] = useState(10);
	const [loaded, setLoaded] = useState(false);
	const navigate = useNavigate();

	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			cancelButton: 'mr-2 btn btn-danger'
		},
		buttonsStyling: false
	})

	// cancel subscription 
	const cancelSubscriptionPlan = async () => {
		await swalWithBootstrapButtons.fire({
			title: t('Are you sure?'),
			text: t('You wont be able to get any refund !'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: t('Yes, Do it!'),
			cancelButtonText: t('No, cancel!'),
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
				if (subscriptionData?.subscribed_via !== "PAYPAL") {
					if (subscriptionData?.subscribed_with_device === "iOS") {
						swalWithBootstrapButtons.fire(
							t('You have purchased your subscription from your apple account'),
							t('Login to your app store account and cancel it from there to cancel your active subscriptions Thanks'),
							'info'
						)
					} else {
						window.open('https://play.google.com/store/account/subscriptions')
					}
				} else {
					setLoaded(false)
					profileService
						.cancelSubscription()
						.then(response => {
							setLoaded(true);
							if (response.success) {
								swalWithBootstrapButtons.fire(
									t('Done!'),
									t('Subscription cancelled successfully.'),
									'success'
								)
								const loginuserUpdated = {
									...loginUser,
									issubscribed: false
								}
								window.localStorage.setItem('loggedUser', JSON.stringify(loginuserUpdated))
								navigate('/become-a-verified-dealer')

							} else {
								swalWithBootstrapButtons.fire(
									t('Error'),
									response.result,
									'error'
								)
							}
						})
						.catch(error => {
							setLoaded(true);
							if (error.response) {
								swalWithBootstrapButtons.fire(
									t('Error!'),
									t('Unable to unsubscribe this user.'),
									'error'
								)
							}
						})
				}



			} else if (
				/* Read more about handling dismissals below */
				result.dismiss === Swal.DismissReason.cancel
			) {
				swalWithBootstrapButtons.fire(
					t('Cancelled'),
					t('Your Subscription is safe :)'),
					'error'
				)
			}
		})

	}

	// get subscription plan info 
	const getSubscriptionData = async () => {
		setLoaded(false)
		await profileService
			.subscriptionInfo()
			.then(response => {
				setLoaded(true);
				if (response.success) {
					setSubscriptionData(response.data)
				} else {
					swalWithBootstrapButtons.fire(
						t('Error'),
						response.result,
						'error'
					)
				}
			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {
					// addToast(error.response.data.message, { appearance: 'error' })
				}
			})
	}

	// get  subscription pdf  
	const getSubscriptionPDF = async (id) => {
		await profileService
			.subscriptionPDF(id)
			.then(response => {
				setLoaded(true);
				if (response.success) {

					const linkSource = response.data.pdf;
					const downloadLink = document.createElement("a");
					const fileName = response.data.name;

					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
				} else {
					swalWithBootstrapButtons.fire(
						t('Error'),
						response.result,
						'error'
					)
				}
			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {
					swalWithBootstrapButtons.fire(
						t('Error'),
						error.response.data.message,
						'error'
					)
				}
			})
	}

	useEffect(() => {
		if (active === "Verified Dealer Settings" && !loginUser?.issubscribed) {
			swalWithBootstrapButtons.fire(
				t('Error'),
				t('Unauthorized Acces'),
				'error'
			)
			navigate('/');
		}
		profileService.setToken(token);
		if (loginUser?.issubscribed) {
			getSubscriptionData();
		}


	}, [token])

	if (active === "Verified Dealer Settings") {
		return (
			<>
				<PageLoader isLoaded={loaded} />
				<div className="p-4">
					<div className="subscription">
						<div className="title">
							<h4> {t('Your next subscription renewal date is')} <span>{moment(subscriptionData?.subscription_renewal_date).format("MMM DD, YYYY ")} </span> <span className="cancel-subscription cp" onClick={cancelSubscriptionPlan}> {t('Cancel subscription')} </span></h4>
							<div ></div>
						</div>
						<div className="title2">
							<h5>{subscriptionData?.money_saved_text} </h5>
						</div>
					</div>

					<div className="invoice-detail table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th scope="col">{('Date')}</th>
									<th scope="col">{('Amount paid')}</th>
									<th scope="col">{('Paid Via')}</th>
									<th scope="col">{('Action')}</th>
								</tr>
							</thead>
							<tbody>
								{subscriptionData?.subscription_payment?.slice(0, showNumberOfItems).map((p, i) => (
									<tr key={i}>
										<td>{moment(p?.date).format("DD-MM-YYYY")}</td>
										<td>{p?.amount}</td>
										<td>{p?.paid_via}</td>
										<td> <span className='cp inovice-btn' onClick={() => getSubscriptionPDF(p?.id)} > {t('Download invoice')}</span> </td>
									</tr>
								))}

							</tbody>
						</table>
						{subscriptionData?.subscription_payment?.length > showNumberOfItems && (<div className="loadmorebutton py-5"><button onClick={() => setShowNumberOfItems(showNumberOfItems + 5)} type="button" className="btn btn-primary py-2"> {t('View more')} <i className="icon-dropdown"></i></button></div>)}
					</div>
				</div>
			</>
		)
	}
	return (
		<>
		</>
	)
}
export default MySubscription;
/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;

const baseUrl = prefix + '/home';
const baseUrl1 = prefix;

let token = null

const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}

const homePage = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/homedata`, config)
    return request.then(response => response.data)
}
const warTheaterSlider = () => {
    
    const request = axios.get(baseUrl + `/threatreofwarslider`)
    return request.then(response => response.data)
}
const warTheaterFilters = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/theatreofwarfilters`, config)
    return request.then(response => response.data)
}
const theatreOfWarData = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/theatreofwardata`, data, config)
    return request.then(response => response.data)
}
const renderPages = (page) => {
    const request = axios.get(baseUrl + `/pages/${page}`)
    return request.then(response => response.data)
}
const getMessageCount = () => {
    var config = {
        headers: { Authorization: token }
    }
    const request = axios.get(baseUrl1 + `/unreadMessageCount`, config)
    return request.then(response => response.data)
}




export default { setToken, homePage, warTheaterSlider, warTheaterFilters, theatreOfWarData, renderPages, getMessageCount }
import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Encriptor from "../../../../services/encriptor";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PhoneModal from '../../../comman/connections/phoneModal'
import { Auth } from '../../../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';





const ListView = ({ posts, LikeUnlikeToggleButton, goToChat }) => {
   const { t } = useTranslation();
   const { setShowLoginModal } = useContext(Auth);
   const [phoneModal, setPhoneModal] = useState(false);
   // eslint-disable-next-line no-unused-vars
   const [phone, setPhone] = useState(0);

   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;

   if (posts) {
      return (
         <>
            <div className="list-post-sections-views other-seller">
               <Row className="no-gutters">
                  <Col xs={12} sm={12} md={12} lg={12}>
                     <div className="list-post-sections-views">
                        {posts?.map((post, i) => (
                           <div className="product-list-post mt-3">
                              <Row className="no-gutters">
                                 <Col xs={12} sm={12} md={3} lg={3}>
                                    {post?.featured && (<div className="featured"><span>{t('FEATURED')}</span></div>)}
                                    {(loginUser && (String(post?.user_id) !== String(loginUser?.id))) && (
                                       <div className="heart">
                                          <span>
                                             {post.likedbyme ? (
                                                <div className="red_favourite" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-red_favourite active" aria-hidden="true"></i></div>
                                             ) : (
                                                <div className="favourite1" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-favourite" aria-hidden="true"></i></div>
                                             )}
                                          </span>
                                       </div>
                                    )}
                                    <div className="list-post-image">
                                       <div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)} </Link> </span></div>
                                       <Link to={`/product/detail/${post.slug}`} ><Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" /> </Link>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={(loginUser && (String(post?.user_id) === String(loginUser?.id))) ? 9 : 7} lg={(loginUser && (String(post?.user_id) === String(loginUser?.id))) ? 9 : 7}>
                                    <h5 className="Product-title">
                                       <Link to={`/product/detail/${post?.slug}`} >
                                          <OverlayTrigger
                                             placement="bottom"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={
                                                <Tooltip id={`tooltip-${post?.id}`}>
                                                   {`${post?.title}`}
                                                </Tooltip>
                                             }
                                          >
                                             {(post?.title?.length) > 100 ? (

                                                <div className="">

                                                   {`${post?.title}`.slice(0, 100)} ...

                                                </div>

                                             ) : (
                                                <div className="">
                                                   {`${post?.title}`}
                                                </div>
                                             )}
                                          </OverlayTrigger>
                                       </Link>
                                    </h5>
                                    <div className="Product-desc">
                                       {post?.short_description && (
                                          <OverlayTrigger
                                             placement="bottom"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={
                                                <Tooltip className="tooltiptext" id={`tooltip-${post?.id}`}>
                                                   {`${post?.short_description} `}
                                                </Tooltip>
                                             }
                                          >
                                             {(post?.short_description.length) > 150 ? (

                                                <div className="">
                                                   {`${post?.short_description}`.slice(0, 150)} ...
                                                </div>

                                             ) : (
                                                <div className="">
                                                   {`${post?.short_description}`}
                                                </div>
                                             )}
                                          </OverlayTrigger>

                                       )}

                                    </div>
                                    <div className="price-location">
                                       <ul className='d-flex'>
                                          <li className=""><strong>{post?.open_to_offer ? (`${t('Open to offers')}`) : (post?.formatted_price)}</strong></li>
                                          <li className="location">
                                             <div className="country_location g-1">
                                                <img alt={post?.country_flag} src={post?.country_flag} />
                                                <span>{post?.address} </span>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </Col>
                                 {loginUser ? (
                                    (String(post?.user_id) !== String(loginUser?.id)) && (<Col xs={12} sm={12} md={2} lg={2} className="owner-details">
                                       <div>
                                          <div className="seller-chat-call-button">
                                             <ul>
                                                <li className="view-listing-page cp">  <Link to={`/product/detail/${post.slug}`} >{t('view listing')} </Link></li>
                                                <li><span onClick={() => goToChat(post)} className="send-message-list cp"> {t('send message')}</span></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </Col>)
                                 ) : (
                                    <Col xs={12} sm={12} md={12} lg={2} className="owner-details">
                                       <div>
                                          <div className="seller-chat-call-button">
                                             <ul>
                                                <li className="view-listing-page cp">  <Link to={`/product/detail/${post.slug}`} >{t('view listing')} </Link></li>
                                                <li><span onClick={() => setShowLoginModal(true)} className="send-message-list cp"> {t('Send message')}</span></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </Col>
                                 )}
                              </Row>
                           </div>
                        ))}
                       
                     </div>
                  </Col>
               </Row>
            </div>
            <PhoneModal phone={phone} phoneModal={phoneModal} setPhoneModal={setPhoneModal} />


         </>
      )
   }
   return (
      <>
         <div className="text-center my-3">
            <div className="spinner-border" role="status">
               <span className="sr-only">{t('Loading...')}</span>
            </div>
         </div>

      </>
   )
}

export default ListView;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Header, Footer } from "../index";
import walletServices from "../../services/wallet";
import WalletTransection from "./walletTransactions";
import PaypalExpressBtn from 'react-paypal-express-checkout';
import MyLoader from '../myloader'
import PageLoader from '../pageLoader'
import SeoTags from '../seo/seoTags';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2'
import { Auth } from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
require('dotenv').config()




const isHomePage = false;
const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
const token = loginUser.access_token;

const Wallet = () => {
	const { t } = useTranslation();
	const { setWalletBalance, walletBalance } = useContext(Auth);

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
	const [walletData, setWalletData] = useState();
	const [creditRate, setCreditRate] = useState();
	const [showLoader, setShowLoader] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [currencySymbol, setCurrencySymbol] = useState();
	const [currencyCode, setcurrencyCode] = useState();
	const [isLoaded, setIsLoaded] = useState(false);
	const [paymentModal, setPaymentModal] = useState(false);

	const [totalAmount, setTotalAmount] = useState('');
	const [creditPackages, setCreditPackages] = useState([]);
	const [creditPackagesInfo, setCreditPackagesInfo] = useState([]);
	const [page, setPage] = useState(1);

	const [loaderdata, setLoaderdata] = useState({
		title: "",
		message: "",
	});
	const [credit, setCredit] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	let currency = currencyCode;
	let env = process.env.REACT_APP_PAYPAL_ENV;
	const buttonStyles = {
		size: 'medium',
		color: 'black',
		tagline: false,
		shape: 'rect',
		label: 'pay'
	}

	const client = {
		sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
		production: process.env.REACT_APP_PAYPAL_CLIENT_ID

	}
	const location = useLocation();
	const navigate = useNavigate();
	const query = new URLSearchParams(location.search);
	const status = query.get('status') ? query.get('status') : false;
	const paidCredit = query.get('credit') ? query.get('credit') : false;

	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			// cancelButton: 'mr-2 btn btn-danger'
		},
		buttonsStyling: false
	})

	useEffect(() => {
		getCreditPackages()
		getUserWalletTransection(page)
		if (status === "succeeded") {
			setCompleted(true);
			setCredit(paidCredit)
			navigate('/wallet')
		} else if (status === "failed") {
			navigate('/wallet');
			swalWithBootstrapButtons.fire(
				t('Payment Faild!'),
				t("Payment has been failed"),
				'error'
			)
		}
	}, [location]);

	const getUserWalletTransection = async (page) => {

		walletServices.setToken(token);
		await walletServices
			.getWalletInfo(page)
			.then(response => {
				setIsLoaded(true);
				if (response.success) {
					setWalletData(response.data);
					window.localStorage.setItem('total_balance', response.wallet_balance);
					setWalletBalance(response.wallet_balance);
					setCurrencySymbol(response.currency_symbol);
					setcurrencyCode(response.currency_code);
					setCreditRate(response.credit_rate);

				}
			})
			.catch(error => {
				setIsLoaded(true);
			})

		if (page > 1) {
			window.scrollTo(0, 500);
		}
		else {
			window.scrollTo(0, 0);
		}
	}
	const getCreditPackages = async () => {

		walletServices.setToken(token);
		await walletServices
			.creditPackages()
			.then(response => {
				setIsLoaded(true);
				if (response.success) {

					setCreditPackages(response.data.map(d => ({ ...d, counter: 0 })));

					setCreditPackagesInfo(response.data);
				}
			})
			.catch(error => {
				setIsLoaded(true);
			})

		if (page > 1) {
			window.scrollTo(0, 500);
		}
		else {
			window.scrollTo(0, 0);
		}
	}

	const CreditWallet = async (data) => {

		const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
		const token = loginUser.access_token;
		walletServices.setToken(token);
		setIsLoaded(false);


		const newWalletCredit = {
			total_amount: totalAmount,
			credit: credit,
			payment_method: 'paypal',
			payment_data: data,
		}


		setLoaderdata({
			title: t('Please wait'),
			message: t('Please wait processing your payment'),
		});
		setShowLoader(true)


		await walletServices
			.creditWallet(newWalletCredit)
			.then(response => {

				setIsLoaded(true);
				if (response.success) {
					const newData = [response.data].concat(walletData.data);
					const updatedWalletData = {
						...walletData,
						data: newData
					}
					setCreditPackages(creditPackagesInfo.map(d => ({ ...d, counter: 0 })));
					setWalletData(updatedWalletData);
					window.localStorage.setItem('total_balance', response.wallet_balance);
					setWalletBalance(response.wallet_balance);
					setShowLoader(false)
					setTotalAmount(0)
					setCompleted(true);
					setPaymentModal(false);
					setPaymentModal('')
				}

			})
			.catch(error => {
				setIsLoaded(true);
				setShowLoader(false)
				setPaymentModal(false)
			})
	}


	const onSuccess = (payment) => {
		CreditWallet(payment);
		// You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
	}

	const onCancel = (data) => {
		// console.log('The payment was cancelled!', data);
		swalWithBootstrapButtons.fire(
			t('Payment Faild!'),
			t("The payment was cancelled!"),
			'error'
		)
	}

	const onError = (err) => {
		swalWithBootstrapButtons.fire(
			t('Error!'),
			t("Error occured in paypal"),
			'error'
		)
	}

	const hideModal = () => {
		setCompleted(false)
		setCredit('');
	}

	const handlePaymentMethod = async (e) => {
		setIsLoaded(false);
		if (credit === '' || parseInt(credit) === 0) {
			setIsLoaded(true);
			swalWithBootstrapButtons.fire(
				t('Error!'),
				t("Please select a credit"),
				'error'
			)
		} else {
			if (e.currentTarget.dataset.value === 'paypal') {
				setPaymentMethod(e.currentTarget.dataset.value)
				setPaymentModal(true)
				setIsLoaded(true);
			} else {


				const data = {
					amount: parseFloat(totalAmount).toFixed(2),
					payment_method: e.currentTarget.dataset.value,
					credit: credit,
					success_url: 'wallet',
					failed_url: 'wallet',
				}

				setPaymentMethod(e.currentTarget.dataset.value)
				const stripe = await stripePromise;

				walletServices.setToken(token);
				const response = await walletServices.createPaymentIntent(data);
				const session = await response.data;
				const result = await stripe.redirectToCheckout({
					sessionId: session.session_id,
				});

				if (result.error) {
					swalWithBootstrapButtons.fire(
						t('Payment server error!'),
						result.error.message,
						'error'
					)
				}


			}

		}

		// setPaymentModal(true);
	}

	const handlePage = pageId => {
		setIsLoaded(false);
		setPage(pageId)
		getUserWalletTransection(pageId);
	}

	// const onClickMinusPack = (pack) => {
	// 	console.log(pack);
	// }

	// const onClickPlusePack = (pack) => {
	// 	console.log(pack);
	// }

	const handleClick = (e, pack, type, i) => {
		e.preventDefault()

		if (pack && type === "+") {
			const newpack = {
				...pack,
				counter: pack?.counter + 1,
			}
			let newData = creditPackages.map(p => parseInt(p.id) === parseInt(pack.id) ? newpack : p);
			let amount = 0;
			let totalCredits = 0;
			newData.forEach(element => {
				totalCredits = totalCredits + (parseInt(element.credit) * element.counter);
				amount = amount + (parseFloat(element.amount) * element.counter);

			});
			setCreditPackages(newData);
			setCredit(totalCredits);
			setTotalAmount(amount);

		}
		if (pack && type === "-") {
			if (pack.counter > 0) {
				const newpack = {
					...pack,
					counter: pack?.counter - 1,
				}
				let newData = creditPackages.map(p => parseInt(p.id) === parseInt(pack.id) ? newpack : p);

				let totalCredits = 0;
				let amount = 0;
				newData.forEach(element => {
					totalCredits = totalCredits + (parseInt(element.credit) * element.counter);
					amount = amount + (parseFloat(element.amount) * element.counter);

				});
				setCreditPackages(newData);
				setCredit(totalCredits);
				setTotalAmount(amount);

			}

		}
	}


	return (
		<>
			<SeoTags title={`Miltrade | Wallet `}
				description={''}
				category={''}
				url={window.location.href}
				image={''}
			/>
			{/* Start of header section */}
			<Header isHomePage={isHomePage} checkAuth={true} />
			{/* end of header section */}
			<div className="footer-bottom1">
				<div className="breadcrum">
					<Container>
						<Row>
							<Col>
								<Breadcrumb>
									<Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
									<Breadcrumb.Item active>{t("Wallet")}</Breadcrumb.Item>
								</Breadcrumb>
							</Col>
						</Row>
					</Container>
				</div>
				<PageLoader isLoaded={isLoaded} />
				<div className="wallet">
					<Container>
						<div className="total-blance">
							<Row>
								<Col xs={12} sm={12} md={6} lg={6} >
									<div className="wallet-text-heading">
										<div className="wallet-icon"> <span> <i className="icon-wallet"></i></span> <p>{t("Your wallet")}</p></div>

									</div>
								</Col>
								<Col xs={12} sm={12} md={6} lg={6} >
									<div className="blance-display justify-content-center justify-content-sm-center justify-content-md-end">
										<div className="wallet-text">
											<div> <strong>{t("CURRENT BALANCE")}</strong>
												<span className="blance-text"><Image src="/images/coins.svg" alt="miltrade_credits" width="18px" /> <h4>{walletBalance ? walletBalance : loginUser?.total_balance} </h4></span> </div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Container>
					<Container>
						<div className="about-wallet-text">
							<Row>
								<Col xs={12} sm={12} md={12} lg={12} >
									<p>{t("Welcome to your Miltrade wallet. Your wallet is your virtual wallet filled with Miltrade")} <br /> {t("credits which you can use to pay for featuring your listings.")}</p>
									<p> {t("Select the number of credits you would like to add to your wallet and your")} <br /> {t("desired payment method to upgrade your wallet balance.")} </p>
									<div className="btn1">
										<Link to={'/support?page=miltrade-wallet&title=Miltrade Wallet'}>
											<span className="cp">{t("More information")}</span>
										</Link>
									</div>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<div className="miltrade-blance">
					<Container>
						<div className="add-money-wallet">
							<Row>
								<Col xs={12} sm={12} md={12} lg={6} >
									<div className="money-add-text">
										<p >{t("Add Credit to your wallet")}</p>
										<span>{t("Select the number of credits you would like to add to your wallet by clicking the + or - next to the desired amount.")} </span>
										<div className="cradit-amount-section">
											<div className="cradit-amount">
												<div className="cradit">
													<ul>
														{creditPackages?.map((pack, i) => (
															<li key={i}>
																<div className="cradit-amo">{pack?.title}</div>
																<div className="cradit-amoun">
																	{i > 0 && (<span className="discount-amount"> {currencySymbol} {(creditRate * parseFloat(pack?.credit))}</span>)}
																	<span className="amount"> {currencySymbol} {parseFloat(pack?.amount)}</span>
																	<span onClick={(e) => handleClick(e, pack, "-")} className="cp minus-circle"><i className="fa fa-minus-circle" aria-hidden="true"></i></span>
																	<span className="amount-plus-minus"> {pack?.counter} </span>
																	<span onClick={(e) => handleClick(e, pack, "+")} className="cp plus-circle"><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
																</div>
															</li>
														))}
													</ul>
												</div>

											</div>
										</div>
									</div>
								</Col>
								<Col xs={12} sm={12} md={12} lg={6} >
									<div className="amount-total-credits">
										<p>{t("Total amount of credits")} : <span>{credit ? credit : 0}</span></p>
										<p className="total-costs"> {t("Total costs")} : <span>{currencySymbol} {totalAmount ? totalAmount : 0}</span></p>
									</div>

								</Col>

							</Row>
						</div>
					</Container>
					<Container>
						<div className="payment-methods">
							<Row>
								<Col xs={12} sm={12} md={12} lg={12} >
									<div className="recommended">
										<h5>{t("Select a Payment method")}</h5>
										<ul>
											<li className='mb-2'>
												<PaypalExpressBtn env={env}
													client={client}
													currency={currency ? currency : 'EUR'}
													total={totalAmount ? totalAmount : 0.00}
													style={buttonStyles}
													onError={onError}
													onSuccess={onSuccess}
													onCancel={onCancel} />
											</li>

											<li><span onClick={handlePaymentMethod} data-value="card" className={`wallet-card-btn cp ${paymentMethod === 'card' ? 'active' : ''}`}><Image src="/images/payment-methods/card1.svg" alt="card" width="20px" className="mr-1" /> Credit Card</span></li>
											{currencyCode === "EUR" && (
												<>
													<li><span onClick={handlePaymentMethod} data-value="ideal" className={`wallet-card-btn cp ${paymentMethod === 'ideal' ? 'active' : ''}`}><Image src="/images/payment-methods/ideal.webp" alt="ideal" width="20px" className="mr-1" /> iDEAL</span></li>
													<li><span onClick={handlePaymentMethod} data-value="sofort" className={`wallet-card-btn cp ${paymentMethod === 'sofort' ? 'active' : ''}`}><Image src="/images/payment-methods/sofort.webp" alt="sofort" width="20px" className="mr-1" /> Sofort</span></li>
													<li><span onClick={handlePaymentMethod} data-value="bancontact" className={`wallet-card-btn cp ${paymentMethod === 'bancontact' ? 'active' : ''}`}><Image src="/images/payment-methods/bancontact.webp" alt="bancontact" width="20px" className="mr-1" /> Bancontact</span></li>
													<li><span onClick={handlePaymentMethod} data-value="giropay" className={`wallet-card-btn cp ${paymentMethod === 'giropay' ? 'active' : ''}`}><Image src="/images/payment-methods/giropay.webp" alt="giropay" width="20px" className="mr-1" /> giropay</span></li>
												</>
											)}
										</ul>
									</div>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				{walletData?.data?.length > 0 && (

					<div className="blance-history-heading">
						<Container>
							<div className="history-heading">
								<Row>
									<Col xs={12} sm={12} md={12} lg={12} >
										<h3>{t("Your transaction history")}</h3>
									</Col>
									<Col xs={6} sm={6} md={6} lg={6} >
										<h6 className="transactions">{t("Description")}</h6>
									</Col>
									<Col xs={6} sm={6} md={6} lg={6} >
										<h6 className="amount">{t("Amount")}</h6>
									</Col>

								</Row>
							</div>
						</Container>
					</div>
				)}
				<div className="history">
					<Container>
						<div className="blance-history">
							<Row>
								<Col xs={12} sm={12} md={12} lg={12}>
									<WalletTransection tranactions={walletData} handlePage={handlePage} />
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Modal show={completed} onHide={hideModal} dialogClassName="modal-90w animation_congratulations-modal">
								<Modal.Body>
									<div className="animation_congratulations-img">
										<Image src="/images/animation/animation_congratulations.gif" className="img-fluid" alt="user" />
									</div>
									<div className="cong">
										<h3>{t("Congratulations !")}</h3>
										<p>{`${t("Wallet is credited with")} ${credit} ${t("credits")}`}</p>
									</div>
								</Modal.Body>
							</Modal>
						</Col>
					</Row>
				</Container>

				<MyLoader showLoader={showLoader}
					title={loaderdata.title}
					description={loaderdata.message}
				/>
			</div>
			<Footer />
		</>
	)
}
export default Wallet;
import { createRoot } from "react-dom/client";
import './assets/css/App.css';
import App from './App';
import '../node_modules/font-awesome/css/font-awesome.css';
import { BrowserRouter } from "react-router-dom";
import AuthContext from "./Contexts/AuthContext";
import { HelmetProvider } from "react-helmet-async";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LangContextProvider } from "./Contexts/langContext";

const container = document.getElementById('app');
const root = createRoot(container);
const google_app_id = process.env.REACT_APP_GOOGLE_APP_ID;

root.render(
  <GoogleOAuthProvider clientId={google_app_id}>
    <BrowserRouter>
      <AuthContext>
        <LangContextProvider>
          <HelmetProvider >
            <App />
          </HelmetProvider>
        </LangContextProvider>
      </AuthContext>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();



/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router";
import registrationServices from "../services/registration";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from "./include/header";
import Footer from "./include/footer";
import SeoTags from './seo/seoTags'
import Swal from 'sweetalert2'
import { Auth } from '../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';






const EmailConfirmation = () => {
  const { t } = useTranslation();
  const { setShowLoginModal } = useContext(Auth);
  const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;

  const navigate = useNavigate();
  const { token } = useParams();
  const [isVerified, setIsverified] = useState(false);

  // verify email 
  const verifyEmail = () => {
    var newToken = {
      token: token
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        // cancelButton: 'mr-2 btn btn-danger'
      },
      buttonsStyling: false
    })
    registrationServices
      .verifyEmail(newToken)
      .then(response => {
        if (response.success) {

          setIsverified(true);
          navigate('/')
          swalWithBootstrapButtons.fire({
            title: t('Your email address is now verified!'),
            text: t("Thank you for verifying your email address. You can now log in to your account and complete the registration process."),
            icon: 'success',
            confirmButtonText: t('Login'),
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/')
              setShowLoginModal(true)
            } else if (
              result.dismiss === Swal.DismissReason.cancel
            ) {
              navigate('/')
            }
          })



        }
      })
      .catch(error => {
        if (error.response) {
          setTimeout(function () {
            swalWithBootstrapButtons.fire(
              '',
              error.response.data.message,
              'error'
            )
            navigate('/')
          }, 1000);
        }
      })
  }

  useEffect(() => {
    if (!loginUser) {
      verifyEmail()
    } else {
      navigate('/')
    }
  }, [])






  return (
    <>
      <SeoTags title={'Miltrade | Email confirmation'}
        description={''}
        category={''}
        url={window.location.href}
        image={''}
      />
      <Header data="true" checkAuth={false} />
      <div className="footer-bottom1">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              {!isVerified && (
                <div className="login-modal">
                  <div className="modal-body-heading text-center">
                    <div className="alert alert-info" role="alert">
                      {t("Please wait verifing your email")}
                      <div className="spinner-border ml-4" role="status">
                        <span className="sr-only">{t("Loading...")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}


export default EmailConfirmation;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Header, Footer } from "../../../index";
import sellerProfile from '../../../../services/sellerProfile'
import { useToasts } from 'react-toast-notifications'
import Encriptor from "../../../../services/encriptor";
import PageLoader from '../../../pageLoader'
import Connections from '../../../comman/connections/connection';
import chatService from "../../../../services/chat"
import ShareIt from '../../../shareIt/share'
import SeoTags from '../../../seo/seoTags'
import 'bootstrap-daterangepicker/daterangepicker.css';
import categoryService from "../../../../services/productCategories";
import StarRatings from 'react-star-ratings';
import Swal from 'sweetalert2'
import { Auth } from '../../../../Contexts/AuthContext';
import { t } from 'i18next';
import { langContext, useLangContext } from '../../../../Contexts/langContext';
var isHomePage = false;
const SellerProfile = () => {
   const { setShowLoginModal } = useContext(Auth);
   const { lang } = useLangContext(langContext);
   const [validationError, setValidationError] = useState();
   const [categoryFilterData, setCategoryFilterData] = useState();
   const [filterOption, setFilterOption] = useState([]);

   const [reviewModal, setReviewModal] = useState(false);
   const [profile, setProfile] = useState([]);
   const [posts, setPosts] = useState();
   const [filteredPosts, setFilteredPosts] = useState([]);
   const [isLoaded, setIsLoaded] = useState(false);
   const [connectionModal, setConnectionModal] = useState(false);
   const [connectionData, setConnectionData] = useState();
   const [share, setShare] = useState(false);
   const page = 1;
   const [fiveStar, setFiveStar] = useState({
      status: false,
      count: 0,
   });
   const [fourStar, setFourStar] = useState({
      status: false,
      count: 0,
   });
   const [threeStar, setThreeStar] = useState({
      status: false,
      count: 0,
   });
   const [twoStar, setTwoStar] = useState({
      status: false,
      count: 0,
   });
   const [oneStar, setOneStar] = useState({
      status: false,
      count: 0,
   });
   const [ratingForm, setRatingForm] = useState({
      comment: '',
      seller_communication: '',
      item_as_described: '',
      shipping_time: '',
   });
   const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
         confirmButton: 'btn btn-success',
         cancelButton: 'mr-2 btn btn-danger'
      },
      buttonsStyling: false
   })
   const [showComment, setShowComment] = useState(false);

   const { addToast } = useToasts();
   const { id } = useParams();
   const navigate = useNavigate();
   var userid = Encriptor.decrypt(id);
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;

   // get user profile with reviews api 
   const getProfile = async (id, categoryID = null, options = [], sortby = "most recent", page = null, review_filter = []) => {
      setIsLoaded(false);
      const formData = new FormData();

      if (options.length > 0) {
         for (let index = 0; index < options.length; index++) {
            const element = options[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }
      if (categoryID) {
         formData.append('category_id', categoryID);
      }
      if (sortby) {
         formData.append('review_sort_by', sortby);
      }
      if (review_filter.length > 0) {
         for (let index = 0; index < review_filter.length; index++) {
            const element = review_filter[index];
            formData.append(`review_filter[]`, element);
         }
      }
      formData.append('id', id);


      if (page) {
         formData.append('page', page);
      }
      sellerProfile
         .sellerProfile(formData)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setProfile(response.data);
               setPosts(response.data.postdata);
               setFilteredPosts(response.data.postdata)
               setOneStar({
                  ...oneStar,
                  count: response.data.reviews.one_star,
                  status: review_filter.includes(1) ? true : false
               })
               setTwoStar({
                  ...twoStar,
                  count: response.data.reviews.two_star,
                  status: review_filter.includes(2) ? true : false
               })
               setThreeStar({
                  ...threeStar,
                  count: response.data.reviews.three_star,
                  status: review_filter.includes(3) ? true : false

               })
               setFourStar({
                  ...fourStar,
                  count: response.data.reviews.four_star,
                  status: review_filter.includes(4) ? true : false

               })
               setFiveStar({
                  ...fiveStar,
                  count: response.data.reviews.five_star,
                  status: review_filter.includes(5) ? true : false
               })

            }
            else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
      window.scrollTo(0, 0);
   }

   // get filter categories from api 
   const getFilterCategory = () => {
      categoryService.fiterMenu('All categories', lang)
         .then(res => {
            if (res.success) {
               setCategoryFilterData(res.data)
            }
         })
         .catch(err => {
            setCategoryFilterData([])
         })
   }

   useEffect(() => {
      sellerProfile.setToken(token);
      getFilterCategory()
      getProfile(userid)
   }, [userid])


   // follow unfollow handler api  
   const FollowUnfollowToggle = (id) => {
      sellerProfile.setToken(token);
      setIsLoaded(false);
      if (profile.followedbyme) {
         sellerProfile.unFollowUser(id)
            .then(res => {
               setIsLoaded(true);
               setProfile({
                  ...profile,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               })
               addToast(t('User Unfollowed'), { appearance: 'success' })
            }).catch(err => {
               setIsLoaded(true);
            })
      } else {
         sellerProfile.followUser(id)
            .then(res => {
               setIsLoaded(true);
               setProfile({
                  ...profile,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               })
               addToast(t('User Followed'), { appearance: 'success' })
            }).catch(err => {
               setIsLoaded(true);
            })
      }
      const newdata = { ...profile, followedbyme: !profile.followedbyme }
      setProfile(newdata);
   }

   // show followers handler 
   const handleFollowers = (e) => {
      setIsLoaded(false);
      sellerProfile
         .getFollowers(userid)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               // connectionData, setConnectionData
               setConnectionData({
                  ...connectionData,
                  title: 'Followers',
                  data: response.data,
               })
               setConnectionModal(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // show Followings handler
   const handleFollowings = (e) => {
      setIsLoaded(false);
      sellerProfile
         .getFollowings(userid)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setConnectionData({
                  ...connectionData,
                  title: 'Followings',
                  data: response.data,
               })
               setConnectionModal(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // filter base on reveiw
   const handleFilterReviews = (event) => {
      getProfile(userid, null, filterOption, event.target.value, page)
   }

   // filter base on stars
   const handleStarFilter = (activeStatus, value) => {
      if (parseInt(value) === 1) {
         setOneStar({
            ...oneStar,
            status: activeStatus
         })
         filterByTheseStars(activeStatus, twoStar.status, threeStar.status, fourStar.status, fiveStar.status)
      }
      if (parseInt(value) === 2) {
         setTwoStar({
            ...twoStar,
            status: activeStatus
         })
         filterByTheseStars(oneStar.status, activeStatus, threeStar.status, fourStar.status, fiveStar.status)
      }
      if (parseInt(value) === 3) {
         setThreeStar({
            ...threeStar,
            status: activeStatus
         })
         filterByTheseStars(oneStar.status, twoStar.status, activeStatus, fourStar.status, fiveStar.status)
      }
      if (parseInt(value) === 4) {
         setFourStar({
            ...fourStar,
            status: activeStatus
         })
         filterByTheseStars(oneStar.status, twoStar.status, threeStar.status, activeStatus, fiveStar.status)
      }
      if (parseInt(value) === 5) {
         setFiveStar({
            ...fiveStar,
            status: activeStatus
         })
         filterByTheseStars(oneStar.status, twoStar.status, threeStar.status, fourStar.status, activeStatus)
      }

   }

   const filterByTheseStars = (one, two, three, four, five) => {
      var review_filter = []
      if (one) {
         review_filter.push(1)
      }
      if (two) {
         review_filter.push(2)
      }
      if (three) {
         review_filter.push(3)
      }
      if (four) {
         review_filter.push(4)
      }
      if (five) {
         review_filter.push(5)
      }
      getProfile(userid, null, filterOption, null, page, review_filter)

   }

   // onchange rating handler 
   const changeRating = (newRating, name) => {
      setRatingForm({
         ...ratingForm,
         [name]: newRating
      })
   }

   // onchange comment handler 
   const handleCommentChange = (e) => {
      e.preventDefault();
      setRatingForm({
         ...ratingForm,
         'comment': e.target.value
      })
   }
   // handle submit review 
   const handleSumbitReview = (e) => {
      e.preventDefault();
      const data = {
         comment: ratingForm.comment,
         item_as_described: ratingForm.item_as_described,
         seller_communication: ratingForm.seller_communication,
         shipping_time: ratingForm.shipping_time
      }

      setIsLoaded(false);
      sellerProfile.setToken(token)
      sellerProfile
         .reviewUser(data, userid)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setShowComment(false);
               setReviewModal(false)
               addToast(t('Thank you for review'), { appearance: 'success' })
               getProfile(userid)
            } else {
               // setReviewModal(false)
               swalWithBootstrapButtons.fire(
                  t('Erorr'),
                  response.message,
                  'error'
               )
            }

         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  setValidationError(
                     error.response.data.errors
                  )
               }
               else {
                  //  addToast(error.response.data.message, { appearance: 'error' })
                  setReviewModal(false);
                  swalWithBootstrapButtons.fire(
                     '',
                     error.response.data.message,
                     'warning'
                  )
               }

            }
         })

   }

   // check error for element 
   const hasErrorFor = (field) => {
      if (validationError) {

         const error = validationError.find(element => String(element.field) === String(field));
         if (error)
            return true;
      } else {
         return false;
      }
   }

   // render error for element
   const renderErrorFor = (field) => {
      if (hasErrorFor(field)) {
         const error = validationError.find(element => String(element.field) === String(field));
         return (
            <div className="error-wrapper">
               <span className='invalid-feedback'>
                  <strong>{error.message}</strong>
               </span>
            </div>
         )
      }
   }

   // redirect to chat componet with thread_id
   const goToChat = () => {
      if (profile?.thread_id) {
         navigate('/messages', { state: { thread_id: profile?.thread_id } });


      }
      else {
         chatService.setToken(token)
         chatService
            .startUserChat(profile.id)
            .then(response => {
               setIsLoaded(true);
               if (response.success) {
                  navigate('/messages', { state: { thread_id: response.data.thread_id } });

                  // socket.emit('newChatThread',  { thread_id:  response.data.thread_id,profile } , (error) => {
                  //    if(error) {
                  //       alert(error);
                  //    }
                  // });
               } else {
                  addToast(response.message, { appearance: 'error' })
               }
            })
            .catch(error => {
               setIsLoaded(true);
               if (error.response) {
                  if (error.response.status === 422) {
                     addToast(error.response.data.data, { appearance: 'error' })
                  }
                  else {
                     addToast(error.response.data.message, { appearance: 'error' })
                  }
               }
            })
      }
   }

   return (
      <>
         {profile && (
            <SeoTags title={`Miltrade | Profile | reviews | ${profile?.username} `}
               description={''}
               category={''}
               url={window.location.href}
               image={''}
            />
         )}
         {/* Start of header section */}
         <Header
            data={isHomePage}
            checkAuth={false}
         />
         <PageLoader isLoaded={isLoaded} />
         <div className="footer-bottom1">
            <div className="breadcrum other-seller-profile-breadcrum">
               <Container fluid>
                  <Row>
                     <Col xs={12} sm={6} md={6} lg={8}>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
                           {isLoaded && (<Breadcrumb.Item active>{`${profile?.username} `}</Breadcrumb.Item>)}
                        </Breadcrumb>
                     </Col>
                     <Col xs={12} sm={6} md={6} lg={4}>
                        {(loginUser && parseInt(loginUser?.id) === parseInt(profile.id)) && (
                           <div className="invite-friend" >
                              <div className='cp' onClick={() =>
                                 setShare(!share)} ><i className="icon-invite"></i>  {t('Invite a friend and earn 3 credits')}
                                 <ShareIt share={share} shareUrl={process.env.REACT_APP_PUBLIC_URL} title={t('Register on Miltrade using this link and get refferal points.')} />
                              </div>
                           </div>
                        )}
                     </Col>
                  </Row>
               </Container>
            </div>
            {posts && (
               <>
                  <div className="seller-profile-page">
                     <Container fluid>
                        <div className="seller-profile-top-detail">
                           <Row>
                              <Col xs={12} sm={12} md={7} lg={5}>
                                 <div className="profile-detail">
                                    <Row className="no-gutters">
                                       <Col xs={6} sm={3} md={6} lg={3}>
                                          <div className='item-seller'>
                                             {profile?.issubscribed ? (
                                                <>
                                                   <div className="verified-dealer">
                                                      <Image src="../../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
                                                   </div>
                                                   <div className="verified-seller-profile">
                                                      <Image src={profile?.photo} alt={`${profile?.username} `} className="img-fluid" />
                                                   </div>
                                                </>
                                             ) : (
                                                <>
                                                   <div className="unverified-seller-profile">
                                                      <Image src={profile?.photo} alt={`${profile?.username} `} className="img-fluid" />
                                                   </div>
                                                </>
                                             )}
                                          </div>
                                       </Col>
                                       <Col xs={6} sm={6} md={6} lg={9}>
                                          <div className="seller-name">
                                             <h3>{`${profile?.username} `}</h3>
                                             <p>
                                                {profile?.issubscribed && (
                                                   <>
                                                      <Image src="/images/check_mark.png" alt="user" className="img-fluid" />
                                                      {t('Verified Dealer')}
                                                   </>
                                                )}
                                             </p>
                                             <h6> <i className="icon-location"></i>{profile?.address}</h6>
                                          </div>
                                          <div className="seller-btn">
                                             <ul>

                                                <li>
                                                   {parseInt(loginUser.id) !== parseInt(profile?.id) && (
                                                      loginUser ? (
                                                         <span onClick={() => FollowUnfollowToggle(profile.id)} className="cp">{profile.followedbyme ? t('Unfollow') : t('+ Follow')}</span>

                                                      ) : (
                                                         <span onClick={() => setShowLoginModal(true)} className="cp">{t('+ Follow')}</span>

                                                      )
                                                   )}
                                                </li>
                                                {parseInt(loginUser.id) !== parseInt(profile?.id) && (
                                                   <li>
                                                      {loginUser ? (
                                                         <span onClick={goToChat} className="cp message-send">{t('Send message')}</span>

                                                      ) : (
                                                         <span onClick={() => setShowLoginModal(true)} className="cp message-send">{t('Send message')}</span>

                                                      )}
                                                   </li>
                                                )}
                                                {loginUser && parseInt(loginUser.id) === parseInt(profile?.id) && (
                                                   <Link to={`/profile/edit`}>
                                                      <li><span className="cp message-send">{t('Edit profile')}</span></li>
                                                   </Link>
                                                )}
                                             </ul>
                                          </div>
                                       </Col>
                                    </Row>
                                 </div>
                              </Col>
                              <Col xs={12} sm={5} md={3} lg={4}>
                                 <div className="seller-follow">
                                    <ul>
                                       <li className="cp" onClick={handleFollowers}>
                                          <p>{Encriptor.kformatter(profile?.followers)}</p>
                                          <span>{t('Followers')}</span>
                                       </li>
                                       <li className="cp" onClick={handleFollowings} >
                                          <p>{Encriptor.kformatter(profile?.followings)}</p>
                                          <span>{t('Following')}</span>
                                       </li>
                                    </ul>
                                 </div>
                              </Col>
                              <Col xs={12} sm={7} md={2} lg={3}>
                                 <Link to={`/seller/profile/${Encriptor.encrypt(profile?.id)}`}>
                                    <div className="list-view"> <span className="cp"> {t('View listings')}</span></div>
                                 </Link>
                              </Col>
                           </Row>
                        </div>
                     </Container>
                     <Container fluid>
                        <div className="seller-overall-review-score">
                           <Row className="no-gutters">
                              <Col xs={12} sm={12} md={7} lg={7}>
                                 <div className="seller-review-information">
                                    <Row className="no-gutters">
                                       <Col xs={12} sm={6} md={6} lg={6}>
                                          <h4 className="title">{t('Overall review score')}</h4>
                                          <p>{t('Communication')}</p>
                                          <p>{t('Description accuracy')}</p>
                                          <p>{t('Shipping time')}</p>
                                       </Col>
                                       <Col xs={12} sm={6} md={6} lg={6}>
                                          <div className="reating-section">
                                             <h5 className="star">
                                                <StarRatings
                                                   rating={profile?.averagerating}
                                                   starRatedColor="#FFE156"
                                                   starHoverColor="#FFE156"
                                                   // changeRating={changeRating}
                                                   numberOfStars={5}
                                                   starSpacing="1px"
                                                   starDimension="20px"

                                                />
                                                <span> {parseFloat(profile?.averagerating).toFixed(1)}</span>
                                             </h5>
                                             <div>
                                                <StarRatings
                                                   rating={profile?.reviews?.avg_seller_communication}
                                                   starRatedColor="#FFE156"
                                                   starHoverColor="#FFE156"
                                                   // changeRating={changeRating}
                                                   numberOfStars={5}
                                                   starSpacing="1px"
                                                   starDimension="15px"

                                                />
                                                <span> {parseFloat(profile?.reviews?.avg_seller_communication).toFixed(1)}</span>
                                             </div>
                                             <div>
                                                <StarRatings
                                                   rating={profile?.reviews?.avg_item_as_described}
                                                   starRatedColor="#FFE156"
                                                   starHoverColor="#FFE156"
                                                   // changeRating={changeRating}
                                                   numberOfStars={5}
                                                   starSpacing="1px"
                                                   starDimension="15px"

                                                />
                                                <span> {parseFloat(profile?.reviews?.avg_item_as_described).toFixed(1)}</span>
                                             </div>
                                             <div>
                                                <StarRatings
                                                   rating={profile?.reviews?.avg_shipping_time}
                                                   starRatedColor="#FFE156"
                                                   starHoverColor="#FFE156"
                                                   // changeRating={changeRating}
                                                   numberOfStars={5}
                                                   starSpacing="1px"
                                                   starDimension="15px"

                                                />
                                                <span> {parseFloat(profile?.reviews?.avg_shipping_time).toFixed(1)}</span>
                                             </div>
                                          </div>
                                       </Col>
                                    </Row>
                                 </div>
                              </Col>
                              <Col xs={12} sm={12} md={5} lg={5}>
                                 {!profile.isreviewdbyme ? (
                                    <div className="seller-write-review">
                                       <h4 className="name">Review {profile?.username} </h4>
                                       <div className="review-desc">
                                          <h5>
                                             {t('Reviews can help other Miltrade users in trusting you as a seller or buyer. Have you sold or bought an item? Ask your buyer or seller to review you!')}
                                          </h5>
                                          <p>
                                             {t('You can review other users on their own profile page.')}
                                          </p>
                                       </div>
                                       <div className="review-btn"><span className="cp" onClick={() => setReviewModal(true)} >{t('Write a review')} </span></div>
                                    </div>
                                 ) : (
                                    <div className="seller-write-review">
                                       <h4 className="name">{parseInt(loginUser.id) !== parseInt(profile?.id) ? t('Write a review') : t('Ask for review')}</h4>
                                       <div className="review-desc">
                                          <h5>
                                             {parseInt(loginUser.id) !== parseInt(profile?.id) ? t('Share the experiences youve have had with this Miltrade user and help others! You can leave a review either as a seller or a buyer')
                                                : t('Reviews can help other Miltrade users in trusting you as a seller or buyer. Have you sold or bought an item? Ask your buyer or seller to review you!')}
                                          </h5>
                                          <p>
                                             {parseInt(loginUser.id) !== parseInt(profile?.id) ? t('Are you experiencing problems with someone? Please get in touch with the user first in order to try to solve the problem before posting a review.') :
                                                t('You can review other users on their own profile page.')}
                                          </p>
                                       </div>
                                       {(loginUser && parseInt(loginUser.id) !== parseInt(profile?.id)) && (<span className="reviewreason">{profile?.reviewreason}</span>)}
                                       {!loginUser && parseInt(loginUser.id) !== parseInt(profile?.id) && (<div className="review-btn"><span className="cp" onClick={() => setShowLoginModal(true)} >{t('Write a review')} </span></div>)}
                                    </div>
                                 )}

                              </Col>
                           </Row>
                        </div>
                     </Container>

                  </div>
                  <Container fluid>
                     <div className="filter-reating-star">
                        <Row className="no-gutters">
                           <Col xs={12} sm={12} md={8} lg={8}>
                              <div className="btns-filter">
                                 <ul>
                                    <li><span onClick={() => handleStarFilter(!fiveStar?.status, 5)} className={`cp ${fiveStar?.status ? 'active' : ''}`}>5 {t('stars')} ({fiveStar?.count}) </span></li>
                                    <li><span onClick={() => handleStarFilter(!fourStar?.status, 4)} className={`cp ${fourStar?.status ? 'active' : ''}`}>4 {t('stars')} ({fourStar?.count}) </span></li>
                                    <li><span onClick={() => handleStarFilter(!threeStar?.status, 3)} className={`cp ${threeStar?.status ? 'active' : ''}`}>3 {t('stars')} ({threeStar?.count})</span></li>
                                    <li><span onClick={() => handleStarFilter(!twoStar?.status, 2)} className={`cp ${twoStar?.status ? 'active' : ''}`}>2 {t('stars')} ({twoStar?.count})</span></li>
                                    <li><span onClick={() => handleStarFilter(!oneStar?.status, 1)} className={`cp ${oneStar?.status ? 'active' : ''}`}>1 {t('stars')} ({oneStar?.count})</span></li>
                                 </ul>
                              </div>
                           </Col>
                           <Col xs={12} sm={12} md={4} lg={4}>
                              <div className="review-filter-search">
                                 <div className="sort-by-search">
                                    <Form>
                                       <span className="dropdown-icon"></span>
                                       <span className="flter-sort-by"><i className="icon-sort"></i></span>
                                       <Form.Group controlId="exampleForm.ControlSelect1">
                                          <Form.Control defaultValue={''} onChange={(e) => handleFilterReviews(e)} className="form-control1" as="select">
                                             <option value="" disabled hidden>{t('Sort By')}</option>
                                             <option value="most recent">{t('Most recent')}</option>
                                             <option value="score descending">{t('Score descending')}</option>
                                             <option value="score ascending">{t('Score ascending')}</option>

                                          </Form.Control>
                                       </Form.Group>
                                    </Form>
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  </Container>
                  <Container fluid>
                     {profile?.reviews?.data.length > 0 ? (
                        (profile?.reviews?.data?.map((review, i) => (
                           <div className="review-list">
                              <Row className="no-gutters">
                                 <Col xs={12} sm={12} md={12} lg={4}>
                                    <div className="title-list">
                                       <h4>{t('Overall review score')}:</h4>
                                       <p>{t('Communication')}</p>
                                       <p>{t('Description accuracy')}</p>
                                       <p>{t('Shipping time')}</p>
                                    </div>
                                    <div className="title-list-star">
                                       <h5 className="star">
                                          <StarRatings
                                             rating={parseInt(review?.averagerating)}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             // changeRating={changeRating}
                                             numberOfStars={1}
                                             starSpacing="1px"
                                             starDimension="25px"

                                          />
                                          <span>{parseInt(review?.averagerating)}</span>
                                       </h5>
                                       <p>
                                          <StarRatings
                                             rating={parseInt(review?.seller_communication)}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             // changeRating={changeRating}
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </p>
                                       <p>
                                          <StarRatings
                                             rating={parseInt(review?.item_as_described)}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </p>
                                       <p>
                                          <StarRatings
                                             rating={parseInt(review?.shipping_time)}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </p>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={6} lg={5}>
                                    <div className="seller-review-desc">
                                       <h2>"</h2>
                                       {/* <h4>Very good seller</h4> */}
                                       <p>{review?.comment}</p>
                                       <h3>"</h3>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={6} lg={3}>
                                    <div className="review-seller-detail">
                                       <h4>Written by:</h4>
                                       <Link to={`/seller/profile/${Encriptor.encrypt(review?.user_id)}`}>
                                          <div className="user-review">
                                             <div className="item-seller">
                                                {review?.issubscribed && (
                                                   <div className="verified-dealer">
                                                      <img src="../../images/animation/verified_dealer.svg" alt={review?.user_name} className="img-fluid verified-dealer-imag" />
                                                   </div>
                                                )}

                                                <div className={review?.issubscribed ? 'verified-seller-profile' : 'unverified-seller-profile'}>
                                                   <img src={review?.user_photo} alt={review?.user_name} className="img-fluid" />
                                                </div>
                                             </div>
                                          </div>
                                       </Link>
                                       <div className="user-review-detail">
                                          <div className="seller-name">
                                             <h3><Link to={`/seller/profile/${Encriptor.encrypt(review?.user_id)}`}> {review?.user_name}  </Link>   </h3>
                                             {review?.issubscribed && (<p><img src="/images/check_mark.png" alt="user" className="img-fluid" />{t('Verified Dealer')}</p>)}
                                             <h6> <i className="icon-location"></i>{review?.address}</h6>
                                          </div>
                                          {/* <div className="seller-btn">
                           <ul>
                              <li><span className="cp">Send message</span></li>
                           </ul>
                        </div> */}
                                       </div>
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        )))
                     ) : (

                        <div className="text-center">
                           <Image src="../../images/no_review.webp" className="img-fluid" alt="user" />
                           <p>{t('Not yet reviewed')}</p>
                        </div>

                     )}
                     { }

                  </Container>
                  <Modal
                     size="lg"
                     show={reviewModal}
                     onHide={() =>
                        setReviewModal(false)}
                     aria-labelledby="example-modal-sizes-title-lg"
                  >
                     <Modal.Header closeButton>
                        <h4>{t('Review seller')}</h4>
                     </Modal.Header>

                     <Modal.Body>
                        <div className="report-ad-modal">
                           <Form onSubmit={handleSumbitReview}>
                              <Row>
                                 <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="mb-3">
                                       <h3>{t('Rate This Dealer')}</h3>
                                       <span>{t('Tell others What You think')}</span>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={12} lg={12}>
                                    <Row>
                                       <Col xs={12} sm={12} md={6} lg={6}>
                                          <div>
                                             <h6>{t('Sellers communication')}</h6>
                                          </div>
                                       </Col>
                                       <Col xs={12} sm={12} md={6} lg={6}>
                                          <div className="dealer-rat">
                                             <StarRatings
                                                rating={ratingForm.seller_communication ? ratingForm?.seller_communication : 0}
                                                starRatedColor="#FFE156"
                                                starHoverColor="#FFE156"
                                                changeRating={changeRating}
                                                numberOfStars={5}
                                                starSpacing="2px"
                                                starDimension="30px"
                                                name='seller_communication'
                                             />
                                             {renderErrorFor('seller_communication')}
                                          </div>
                                       </Col>
                                       <Col xs={12} sm={12} md={6} lg={6}>
                                          <div>
                                             <h6>{t('Item as described')}</h6>
                                          </div>
                                       </Col>
                                       <Col xs={12} sm={12} md={6} lg={6}>
                                          <div className="dealer-rat">
                                             <StarRatings
                                                rating={ratingForm.item_as_described ? ratingForm?.item_as_described : 0}
                                                starRatedColor="#FFE156"
                                                starHoverColor="#FFE156"
                                                changeRating={changeRating}
                                                numberOfStars={5}
                                                starSpacing="2px"
                                                starDimension="30px"
                                                name='item_as_described'
                                             />
                                             {renderErrorFor('item_as_described')}
                                          </div>
                                       </Col>
                                       <Col xs={12} sm={12} md={6} lg={6}>
                                          <div>
                                             <h6>Shipping time</h6>
                                          </div>
                                       </Col>
                                       <Col xs={12} sm={12} md={6} lg={6}>
                                          <div className="dealer-rat">
                                             <StarRatings
                                                rating={ratingForm.shipping_time ? ratingForm?.shipping_time : 0}
                                                starRatedColor="#FFE156"
                                                starHoverColor="#FFE156"
                                                changeRating={changeRating}
                                                numberOfStars={5}
                                                starSpacing="2px"
                                                starDimension="30px"
                                                name='shipping_time'
                                             />
                                             {renderErrorFor('shipping_time')}
                                          </div>
                                       </Col>
                                    </Row>
                                 </Col>
                                 <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="write-a-review">
                                       {showComment &&
                                          <Link onClick={() => setShowComment(!showComment)} className="link" >{t('Write a review')}</Link>
                                       }
                                       {!showComment &&
                                          <>
                                             <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Control as="textarea" rows={3} onChange={handleCommentChange} value={ratingForm?.comment} placeholder={t('Describe your experience (optional)')} />
                                                {renderErrorFor('comment')}
                                             </Form.Group>
                                             <Button className="mt-4" variant="primary" type="submit">{t('Submit')}</Button>
                                          </>
                                       }

                                    </div>
                                 </Col>
                              </Row>
                           </Form>
                        </div>
                     </Modal.Body>
                  </Modal>
               </>
            )}
            {connectionData && (
               <Connections
                  title={connectionData?.title}
                  data={connectionData?.data}
                  connectionModal={connectionModal}
                  setConnectionModal={setConnectionModal}
               />
            )}
         </div>
         <Footer />
      </>
   )
}
export default SellerProfile;
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Encriptor from '../../../services/encriptor'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

const ListView = ({ favPosts, UnlikePost }) => {
   const [showNumberOfItems, setShowNumberOfItems] = useState(10);
   const { t } = useTranslation();
   if (favPosts) {
      return (
         <>
            <div className="list-post-sections-views">
               <div className="list-post-sections-views">
                  {favPosts?.slice(0, showNumberOfItems)?.map((post, i) => (
                     <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="product-list-post mt-3">
                           <Row className="no-gutters">
                              <Col xs={12} sm={12} md={3} lg={3}>
                                 <div className="post-list-image">
                                    {post?.sold ? (
                                       <div className="featured"><span className="sold">{t("SOLD")}</span></div>
                                    ) : (
                                       (post?.deleted) ? (
                                          <>
                                             <div className="featured"><span className="expired">{t("DELETED")}</span></div>
                                          </>
                                       ) : (
                                          <>
                                             {post?.expired ? (<div className="featured"><span className="expired" >{t("EXPIRED")}</span></div>) : (
                                                <>
                                                   {post?.featured && (<div className="featured"><span >{t("FEATURED")}</span></div>)}
                                                </>
                                             )}

                                          </>
                                       )
                                    )}
                                    <div className="list-post-image">
                                       <div className="un_favourite my-fav"><Link onClick={() => UnlikePost(post?.postid)} ><i className="icon-cancel"></i></Link></div>
                                       <div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)} </Link> </span></div>
                                       <Link to={`/product/detail/${post.slug}`} ><Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" /></Link>
                                    </div>
                                 </div>
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={7}>
                                 <div className="post-list-desc">
                                    <h5 className="Product-title">
                                       <Link to={`/product/detail/${post?.slug}`} >
                                          <OverlayTrigger
                                             placement="bottom"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={
                                                <Tooltip id={`tooltip-${post?.id}`}>
                                                   {`${post?.title}`}
                                                </Tooltip>
                                             }
                                          >
                                             {(post?.title?.length) > 100 ? (

                                                <div className="">

                                                   {`${post?.title}`.slice(0, 100)} ...

                                                </div>

                                             ) : (
                                                <div className="">
                                                   {`${post?.title}`}
                                                </div>
                                             )}
                                          </OverlayTrigger>
                                       </Link>
                                    </h5>
                                    <div className="Product-desc">
                                       <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                             <Tooltip className="tooltiptext" id={`tooltip-${post?.id}`}>
                                                {`${post?.short_description} `}
                                             </Tooltip>
                                          }
                                       >
                                          {(post?.short_description.length) > 150 ? (

                                             <div className="">
                                                {`${post?.short_description}`.slice(0, 150)} ...
                                             </div>

                                          ) : (
                                             <div className="">
                                                {`${post?.short_description}`}
                                             </div>
                                          )}
                                       </OverlayTrigger>
                                    </div>
                                    <div className="price-location">
                                       <ul className='d-flex'>
                                          <li className=""><strong>{post?.open_to_offer ? (`${t('Open to offers')}`) : (post?.formatted_price)}</strong></li>
                                          <li className="location">
                                             <div className="country_location g-1">
                                                <img alt={post?.country_flag} src={post?.country_flag} />
                                                <span>{post?.address} </span>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </Col>
                              <Col xs={12} sm={12} md={3} lg={2} className="owner-details">
                                 <div className="post-list-owner">
                                    <div className="owner-detail">
                                       <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                          <div className='item-seller'>
                                             {post?.user?.issubscribed && (
                                                <div className="verified-dealer">
                                                   <Image src="../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
                                                </div>
                                             )}

                                             <div className={`seller-image-${post?.user?.issubscribed ? `${t('verified')}` : `${t('unverified')}`}`}>
                                                <Image src={post?.user?.photo} alt="1" className="img-fluid" />
                                             </div>
                                          </div>
                                       </Link>
                                    </div>
                                    <div className="seller-desc">
                                       <h3>
                                          <div className="">
                                             <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                                <OverlayTrigger
                                                   placement="bottom"
                                                   delay={{ show: 250, hide: 400 }}
                                                   overlay={
                                                      <Tooltip id={`tooltip-${post?.user?.id}`}>
                                                         {`${post?.user?.username} `}
                                                      </Tooltip>
                                                   }
                                                >
                                                   {(post?.user?.username.length) > 15 ? (

                                                      <div className="">
                                                         {`${post?.user?.username} `.slice(0, 15)} ...
                                                      </div>

                                                   ) : (
                                                      <div className="">
                                                         {`${post?.user?.username} `}
                                                      </div>
                                                   )}
                                                </OverlayTrigger>
                                             </Link>
                                          </div>
                                       </h3>
                                       {post?.user?.issubscribed ? (<h6>{t('Verified Seller')}</h6>) : (<h6 className="private-seller" >{t("Private Seller")}</h6>)}
                                    </div>
                                    <div className="view mb-1">
                                       <Link ><i className="icon-star_fill" aria-hidden="true"></i> <span>{parseFloat(post?.user?.averagerating).toFixed(1)}</span></Link>({Encriptor.kformatter(post?.user?.review_count)})
                                       <div className="profile-visit mt-1"><Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`}>{t("Visit Profile")}</Link></div>
                                    </div>
                                 </div>
                              </Col>
                           </Row>
                        </div>
                     </Col>
                  ))}
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="loadmorebutton mt-5">
                           {favPosts.length > showNumberOfItems && (<div className="loadmorebutton py-5"><button onClick={() => setShowNumberOfItems(showNumberOfItems + 5)} type="button" className="btn btn-primary py-2"> {t("View more")} <i className="icon-dropdown"></i></button></div>)}

                        </div>
                     </Col>
                  </Row>
               </div>

            </div>
         </>
      )
   }
   return (
      <>
      </>
   )

}
export default ListView;



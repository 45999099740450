/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from 'react-bootstrap/Form';





import { useTranslation } from 'react-i18next';



const UpperFilter = ({
  HandleAdFilter,
  adsFilter,
  setView,
  view,
  handleSortBy
}) => {

  const { t } = useTranslation();

  return (
    <div className="mb-0">
                  <Row className="no-gutters-row-only">
                    <Col xs={12} sm={6} md={5} lg={6}>
                      <div className='filterby' style={{ padding: 0 }}>
                        <div className="ads-btn post-filter">
                          <ul>
                            <li className="filter-by"><span>{t("Filter by")} :</span></li>
                            <li className="myfilter"><span onClick={() => HandleAdFilter('all')} className={adsFilter === 'all' ? 'link btn btn-primary ads active' : 'link'}  >{t("All listings")}</span></li>
                            <li className="myfilter"><span onClick={() => HandleAdFilter('verified_ads')} className={adsFilter === 'verified_ads' ? 'link btn btn-primary active' : 'link'}  >{t("Verified Dealer listings")}</span></li>
                          </ul>
                        </div>
                      </div>
                    </Col>

                    <Col xs={0} sm={0} md={6} lg={3} className="grid-list-view-icon-mobile">
                      <div className="grid-list-view-icon">
                        <ul>
                          <li><span onClick={() => setView('grid')} className={view === 'grid' ? 'link  active' : 'link'} ><i className="icon-grid_view"></i></span></li>
                          <li><span onClick={() => setView('list')} className={view === 'list' ? 'link  active' : 'link'} ><i className="icon-list_view"></i></span></li>
                        </ul>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} className=" mt-1">
                      <div className="vendor-directory right-filter-search">
                        <div className="sort-by-search">
                          <Form>
                            <span className="dropdown-icon"></span>
                            <span className="flter-sort-by"><i className="icon-sort"></i></span>
                            <Form.Group onChange={handleSortBy} controlId="exampleForm.ControlSelect1 cp">
                              <Form.Control as="select" defaultValue={''}>
                                <option value="" disabled hidden>{t("Sort By")}</option>
                                <option value="New">{t("Newest items")}</option>
                                <option value="Popular">{t("Most popular")}</option>
                                <option value="lowtohigh">{t("Price-Low to High")}</option>
                                <option value="hightolow">{t("Price-High to Low")}</option>
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
  )
}

export default UpperFilter;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import { Header, Footer } from "../../index";
import MyPosts from './myPosts'
import MyReviews from './myReviews'
import sellerProfile from '../../../services/sellerProfile'
import myProfileSerivces from '../../../services/myProfile';
import postsServices from '../../../services/posts'
import { useToasts } from 'react-toast-notifications'
import Encriptor from "../../../services/encriptor";
import productSerivces from '../../../services/posts';
import PageLoader from '../../pageLoader'
import Connections from '../../comman/connections/connection';
import chatService from "../../../services/chat"
import { isDesktop, isMobile } from "react-device-detect";
import SeoTags from '../../seo/seoTags'
import 'bootstrap-daterangepicker/daterangepicker.css';
import categoryService from "../../../services/productCategories";
import StarRatings from 'react-star-ratings';
import Swal from 'sweetalert2'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Auth } from '../../../Contexts/AuthContext';
import MobileFilter from '../../comman/mobileFilter';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../../../Contexts/langContext';
import SideFilter from '../../comman/SideFilter';



var isHomePage = false;
const ViewProfile = () => {
   const { t } = useTranslation();
   const { setShowLoginModal, setWalletBalance } = useContext(Auth);
   const [sidebar, setSidebar] = useState({
      leftOpen: isMobile ? false : true,
   });
   let leftOpen = sidebar.leftOpen ? 'open' : 'closed';
   const toggleSidebar1 = (event) => {
      let key = `leftOpen`;
      setSidebar({ [key]: !sidebar[key] });
   }
   const [categoryFilterData, setCategoryFilterData] = useState();
   const [filterOption, setFilterOption] = useState([]);
   const [filteredList, setFilteredList] = useState([]);
   const [showNumberOfFilters, setShowNumberOfFilters] = useState(8);
   const [minDate, setMinDate] = useState('');
   const [maxDate, setMaxDate] = useState('');
   const [rangeError, setRangeError] = useState('');
   const [currentViewType, setCurrentViewType] = useState('grid');
   const [profile, setProfile] = useState([]);
   const [filterString, setFilterString] = useState('');
   const [posts, setPosts] = useState();
   const [allPostCount, setAllPostCount] = useState(0);
   const [activePostCount, setActivePostCount] = useState(0);
   const [expiredPostCount, setExpiredPostCount] = useState(0);
   const [soldPostCount, setSoldPostCount] = useState(0);
   const [deletedPostCount, setDeletedPostCount] = useState(0);
   const [filterBy, setFilterBy] = useState('ViewAll');
   const [filteredPosts, setFilteredPosts] = useState([]);
   const [isLoaded, setIsLoaded] = useState(false);
   const [connectionModal, setConnectionModal] = useState(false);
   const [connectionData, setConnectionData] = useState();
   const [filterCount, setFilterCount] = useState(0);
   const [page, setPage] = useState(0);
   const [inlineLoader, setInlineLoader] = useState(false);
   const [sortBy] = useState('');
   const { addToast } = useToasts();
   const navigate = useNavigate();
   const currentItem = 'posts';
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   var userid = loginUser.id;
   var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;

   const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
         confirmButton: 'btn btn-success',
         cancelButton: 'mr-2 btn btn-danger'
      },
      buttonsStyling: false
   })
   const { lang } = useLangContext(langContext);

   // get user profile with listings data 
   const getProfile = async () => {
      setIsLoaded(false);
      const formData = new FormData();

      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }
      formData.append('id', userid);

      myProfileSerivces
         .myProfile(formData, 1, lang)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setProfile(response.data);
               setPosts(response.data.postdata);
               setFilteredPosts(response.data.postdata)
               setAllPostCount(response.data.postdata?.length);
               setExpiredPostCount(response.data.postdata.filter(item => item.expired && !item.sold && !item.deleted)?.length);
               setSoldPostCount(response.data.postdata.filter(item => item.sold)?.length);
               setActivePostCount(response.data.postdata.filter(item => (item.deleted == false && item.expired == false && item.sold == false ))?.length);
					setDeletedPostCount(response.data.postdata.filter(item => item.deleted)?.length);

            }
            else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })

   }
   useEffect(() => {
      if (page > 1)
         loadMorePosts();
   }, [page])

   const loadMorePosts = async () => {
      if (page > profile?.last_page && page > 1) {
         setInlineLoader(false);
         return false;
      }
      const formData = new FormData();

      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }
      formData.append('id', userid);

      myProfileSerivces
         .myProfile(formData, page, lang)
         .then(response => {
            setInlineLoader(false);
            if (response.success) {
               const updatedPost = posts.concat(response.data.postdata);
               setPosts(updatedPost);
               setFilteredPosts(updatedPost)
            }
            else {
               setInlineLoader(false);
            }
         })
         .catch(error => {
            setInlineLoader(false);
         })

   }

   // validation min max range checker 
   const checkErrorInRange = (minval, maxval) => {
      let errorss = '';
      if (maxval < minval) {
         errorss = t('To year must be greater or equals than from year');
      }
      if (maxval < 1500) {
         errorss = t('To year should be greater than or equals 1500');
      }
      if (minval > new Date().getFullYear()) {
         errorss = t('To year should be lesser than ') + new Date().getFullYear();
      }
      if (maxval.length < 4 || maxval.length > 4) {
         errorss = t('To year should be 4 digits');
      }
      if (maxval === '') {
         errorss = t('Please enter to year');
      }
      if (minval < 1500) {
         errorss = t('From year should be greater than or equals 1500');
      }
      if (minval > new Date().getFullYear()) {
         errorss = t('From year should be lesser than or equals ') + new Date().getFullYear();
      }
      if (minval.length < 4 || minval.length > 4) {
         errorss = t('From year should be 4 digits');
      }
      if (minval === '') {
         errorss = t('Please enter from year');
      }
      if (minval === '') {
         errorss = t('Please enter from year');
      }
      return errorss;
   }

   // get filter categories 
   const getFilterCategory = () => {
      categoryService.fiterMenu('All categories', lang)
         .then(res => {
            if (res.success) {
               setCategoryFilterData(res.data)
            }
         })
         .catch(err => {
            setCategoryFilterData([])
         })
   }

   useEffect(() => {
      getFilterCategory()
      myProfileSerivces.setToken(token);
   }, [userid])
   useEffect(() => {
      getProfile()
   }, [filterOption])

   // handle serach listing filter 
   const handlefilter = (e) => {

      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const data = posts.filter(item => {
         return item.title.toLowerCase().includes(lowercasedFilter);
      });
      var status = filterBy;
      HandleFilterByStatusWithSearch(status, data)


   }

   // handle follow /unfollow user 
   const FollowUnfollowToggle = (id) => {
      sellerProfile.setToken(token);
      setIsLoaded(false);
      if (profile.followedbyme) {
         sellerProfile.unFollowUser(id)
            .then(res => {
               setIsLoaded(true);
               setProfile({
                  ...profile,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               })
               addToast(t('User Unfollowed'), { appearance: 'success' })
            }).catch(err => {
               setIsLoaded(true);
               // console.log('unfollow error');
            })
      } else {
         sellerProfile.followUser(id)
            .then(res => {
               setIsLoaded(true);
               setProfile({
                  ...profile,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               })
               addToast(t('User Followed'), { appearance: 'success' })
            }).catch(err => {
               setIsLoaded(true);
               console.log('follow error');
            })
      }
      const newdata = { ...profile, followedbyme: !profile.followedbyme }
      setProfile(newdata);
   }

   // handle like/unlike  user 
   const LikeUnlikeToggleButton = (postId) => {
      const featuredPost = filteredPosts.find(n => n.postid === postId)
      postsServices.setToken(token)
      setIsLoaded(false);
      if (featuredPost.likedbyme) {
         postsServices.unLikePost(postId)
            .then(res => {
               setIsLoaded(true);
               const changedPost = { ...featuredPost, likedbyme: res.data.likedbyme, likes: res.data.likes }
               setFilteredPosts(filteredPosts.map(post => parseInt(post.postid) !== parseInt(postId) ? post : changedPost));
               addToast(t('Listing Unliked'), { appearance: 'success' })
            })
            .catch(err => {
               setIsLoaded(true);
               console.log('error in unlikes');
            })
      } else {
         postsServices.likePost(postId)
            .then(res => {
               setIsLoaded(true);
               const changedPost = { ...featuredPost, likedbyme: res.data.likedbyme, likes: res.data.likes }
               setFilteredPosts(filteredPosts.map(post => parseInt(post.postid) !== parseInt(postId) ? post : changedPost));
               addToast(t('Listing Liked'), { appearance: 'success' })
            })
            .catch(err => {
               setIsLoaded(true);
               console.log('error in unlikes');
            })
      }
   }

   // show follower modal
   const handleFollowers = (e) => {
      setIsLoaded(false);
      sellerProfile
         .getFollowers(userid)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               // connectionData, setConnectionData
               setConnectionData({
                  ...connectionData,
                  title: 'Followers',
                  data: response.data,
               })
               setConnectionModal(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // show followings modal
   const handleFollowings = (e) => {
      setIsLoaded(false);
      sellerProfile
         .getFollowings(userid)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setConnectionData({
                  ...connectionData,
                  title: 'Followings',
                  data: response.data,
               })
               setConnectionModal(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // redirect to chat 
   const goToChat = (post) => {
      // console.log(post);
      if (post.thread_id) {
         navigate('/messages', { state: { thread_id: post.thread_id } });
      }
      else {
         chatService.setToken(token)
         chatService
            .startChat(post.postid)
            .then(response => {
               setIsLoaded(true);
               if (response.success) {
                  navigate('/messages', { state: { thread_id: post.thread_id } });
               } else {
                  addToast(response.message, { appearance: 'error' })
               }
            })
            .catch(error => {
               setIsLoaded(true);
               if (error.response) {
                  if (error.response.status === 422) {
                     addToast(error.response.data.data, { appearance: 'error' })
                  }
                  else {
                     addToast(error.response.data.message, { appearance: 'error' })
                  }
               }
            })
      }
   }

   // handle filter country list 
   const filterCountries = (e, options) => {
      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = options.filter(item => {
         return item.value.toLowerCase().includes(lowercasedFilter);
      });
      const newData = {
         id: e.currentTarget.dataset.filter_id,
         data: filteredData,
      }
      if (filteredList.length > 0) {
         let filter = filteredList.find(f => String(f.id) === String(e.currentTarget.dataset.filter_id));
         if (typeof filter !== "undefined") {
            var filtereddd = filteredList.map(f => String(f.id) === String(e.currentTarget.dataset.filter_id) ? newData : f);
            setFilteredList(filtereddd)
         } else {
            setFilteredList(filteredList.concat(newData))
         }
      } else {
         setFilteredList(filteredList.concat(newData))
      }
   }

   // handle select filter 
   const handleFiltersSelect = (e) => {
      const id = e.target.dataset.filter_id;
      const value = e.target.dataset.filter_value;
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)
            getProfile(userid, null, filtereddd)
         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
            getProfile(userid, null, filterOption.concat(newData))
         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)
         getProfile(userid, null, filterOption.concat(newData))
      }
   }

   // handle date range input form 
   const handleFirstRangeInput = (id, value) => {

      value = value || '';
      setMinDate(value);
      const minval = value;
      const maxval = maxDate;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      let filter = filterOption.find(f => (String(f.id) === String(id)));
      if (typeof filter !== "undefined") {
         const filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (typeof filter !== "undefined") {
               var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)

            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)

            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)

         }
      }
   }

   // handle date range input to 
   const handleSecondRangeInput = (id, value) => {
      value = value || '';
      setMaxDate(value);
      const minval = minDate;
      const maxval = value;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      let filter = filterOption.find(f => (String(f.id) === String(id)));
      if (typeof filter !== "undefined") {
         var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            const filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (filter !== undefined) {
               const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)

            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)
            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
         }
      }
   }

   // handle date range picker 
   const handleDatepickerValue = (event, picker) => {
      var id = event.currentTarget.dataset.filter_id;
      var startDate = picker.startDate.format('MM/DD/YYYY');
      var endDate = picker.endDate.format('MM/DD/YYYY');
      var value = `${startDate} to ${endDate}`;
      if (event.currentTarget.dataset.type === 'date') {
         value = picker.startDate.format('MM/DD/YYYY')
      }
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)
            getProfile(userid, null, filtereddd, sortBy, page)
         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
            getProfile(userid, null, filterOption.concat(newData), sortBy, page)

         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)
         getProfile(userid, null, filterOption.concat(newData), sortBy, page)
      }
   }

   // delete post 
   const deletePost = (post_id) => {
      productSerivces.setToken(token)
      swalWithBootstrapButtons.fire({
         title: t('Are you sure?'),
         text: t("You won't be able to revert this!"),
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: t('Yes, delete it!'),
         cancelButtonText: t('No, cancel!'),
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            setIsLoaded(false);
            productSerivces.deletePost(post_id)
               .then(response => {
                  setIsLoaded(true);
                  if (response.success) {
                     setFilteredPosts(filteredPosts.filter(p => parseInt(p.postid) !== parseInt(post_id)))
                     setPosts(posts.filter(p => parseInt(p.postid) !== parseInt(post_id)));
                     swalWithBootstrapButtons.fire(
                        t('Deleted!'),
                        t('Your Listing has been deleted.'),
                        'success'
                     )
                  } else {
                     swalWithBootstrapButtons.fire(
                        t('Error!'),
                        t('Unable to delete this Listing.'),
                        'error'
                     )
                  }

               })
               .catch(error => {

                  setIsLoaded(true);
                  swalWithBootstrapButtons.fire(
                     t('Error!'),
                     error.response.data.message,
                     'error'
                  )
               })

         } else if (
            result.dismiss === Swal.DismissReason.cancel
         ) {
            swalWithBootstrapButtons.fire(
               t('Cancelled'),
               t('Your Listing is safe :)'),
               'error'
            )
         }
      })
   }

   // mark post as sold  
   const markSoldPost = (post_id) => {
      productSerivces.setToken(token)
      setIsLoaded(false);
      productSerivces.markSoldPost(post_id)
         .then(response => {
            setIsLoaded(true);

            const currentPost = filteredPosts.find(p => parseInt(p.postid) === parseInt(post_id));
            if (typeof (currentPost) != 'undefined') {
               const updatedPost = {
                  ...currentPost,
                  can_delete: false,
                  can_edit: false,
                  expired: true,
                  sold: true

               }
               let ds = filteredPosts.map(p => parseInt(p.postid) === parseInt(post_id) ? updatedPost : p);
               setFilteredPosts(ds);
            }
            addToast(t('Listing has been marked Sold'), { appearance: 'success' })
         })
         .catch(error => {

            setIsLoaded(true);
            swalWithBootstrapButtons.fire(
               t('Error!'),
               error.response.data.message,
               'error'
            )
         })


   }

   // renew post for boost 
   const renewPost = (post_id, rate) => {
      if (!(loginUser.issubscribed) && (parseInt(profile.wallet_balance) < parseInt(rate))) {
         navigate('/wallet')
         swalWithBootstrapButtons.fire({
            title: t('Not enough credit in wallet ..!'),
            text: `Please add ${parseInt(rate) - parseInt(profile.wallet_balance)} credit to renew listing`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'ok',
            closeOnClickOutside: false,
            allowOutsideClick: false,
            reverseButtons: true
         }).then((result) => {
            if (result.isConfirmed) {
            } else if (
               result.dismiss === Swal.DismissReason.cancel
            ) {

            }
         })
      } else {
         productSerivces.setToken(token)
         const data = {
            id: post_id,
            rate: rate,
         }
         setIsLoaded(false);
         productSerivces.renewPost(data)
            .then(response => {

               setIsLoaded(true);
               const updated_wallet_balance = parseInt(profile?.wallet_balance) - parseInt(rate);

               const updatedLoginUser = {
                  ...loginUser,
                  wallet_balance: updated_wallet_balance
               }
               if (!loginUser.issubscribed) {

                  setWalletBalance(updated_wallet_balance);
                  window.localStorage.setItem('total_balance', updated_wallet_balance);
                  window.localStorage.setItem('loggedUser', JSON.stringify(updatedLoginUser))
               }

               swalWithBootstrapButtons.fire({
                  title: t('Listing Renewed!'),
                  text: t(`Your Listing is live now !!`),
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonText: 'ok',
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  reverseButtons: true
               }).then((result) => {
                  if (result.isConfirmed) {
                     window.location.reload();
                  } else if (
                     result.dismiss === Swal.DismissReason.cancel
                  ) {
                     window.location.reload();
                  }
               })
            })
            .catch(error => {
               // console.log(error)
               setIsLoaded(true);
               swalWithBootstrapButtons.fire(
                  t('Error!'),
                  error.response.data.message,
                  'error'
               )
            })
      }




   }

   // handle local filter based on status
   const HandleFilterByStatus = (status) => {
      setFilterBy(status);
      if (status === 'ViewAll') {
         setFilteredPosts(posts)
      }
      if (status === 'ActiveAds') {
         const filtered = posts.filter(item => (item.deleted == false && item.expired == false && item.sold == false ));
         setActivePostCount(filtered?.length);
         setFilteredPosts(filtered)
      }
      if (status === 'ExpiredAds') {
         const filtered = posts.filter(item => item.expired && !item.sold && !item.deleted);
         setExpiredPostCount(filtered?.length);
         setFilteredPosts(filtered)
      }
      if (status === 'SoldAds') {
         const filtered = posts.filter(item => item.sold);
         setSoldPostCount(filtered?.length);
         setFilteredPosts(filtered)
      }
      if (status === 'DeletedAds') {
         const filtered = posts.filter(item => item.deleted == true);
         setDeletedPostCount(filtered?.length);
			setFilteredPosts(filtered)
		}

   }

   // handle local search filter based on status
   const HandleFilterByStatusWithSearch = (status, data) => {

      if (status === 'ViewAll') {
         setFilteredPosts(data)
      }
      if (status === 'ActiveAds') {
         var filtered = data.filter(item => (item.deleted == false && item.expired == false && item.sold == false ));
         setActivePostCount(filtered?.length);
         setFilteredPosts(filtered)
      }
      if (status === 'ExpiredAds') {
         const filtered = data.filter(item => item.expired && !item.sold && !item.deleted);
         setExpiredPostCount(filtered?.length);
         setFilteredPosts(filtered)
      }
      if (status === 'SoldAds') {
         const filtered = data.filter(item => item.sold);
         setSoldPostCount(filtered?.length);
         setFilteredPosts(filtered)
      }
      if (status === 'DeletedAds') {
			const filtered = data.filter(item => item.deleted);
			setDeletedPostCount(filtered?.length);
			setFilteredPosts(filtered)
		}

   }

   // handle clear filter 
   const handleClearFilter = (e) => {
      setFilterOption([]);
      setFilteredList([]);
      setMinDate('');
      setMaxDate('');
      setFilterCount(0)
   }

   //  get valid url 
   const getValidUrl = (url = "") => {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");

      if (/^(:\/\/)/.test(newUrl)) {
         return `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
         return `http://${newUrl}`;
      }

      return newUrl;
   };



   return (
      <>
         {profile && (
            <SeoTags title={`Miltrade | My Profile `}
               description={''}
               category={''}
               url={window.location.href}
               image={''}
            />
         )}
         {/* Start of header section */}
         <Header
            data={isHomePage}
            checkAuth={true}
         />

         <PageLoader isLoaded={isLoaded} />
         <div className="footer-bottom1">
            <div className="breadcrum other-seller-profile-breadcrum">
               <Container fluid>
                  <Row>
                     <Col xs={12} sm={6} md={6} lg={8}>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                           {isLoaded && (<Breadcrumb.Item active>{`${profile?.username} `}</Breadcrumb.Item>)}
                        </Breadcrumb>
                     </Col>
                     <Col xs={12} sm={6} md={6} lg={4}>
                        {(loginUser && parseInt(loginUser?.id) === parseInt(profile.id)) && (
                           <div className="invite-friend" >
                              <Link to="/setting?page=Invite a friend">
                                 <p className='cp' ><i className="icon-invite"></i>  {t("Invite a friend and earn 3 credits")}
                                 </p>
                              </Link>
                           </div>
                        )}
                     </Col>
                  </Row>
               </Container>
            </div>
            {posts && (
               <>

                  <div className="seller-profile-page">
                     <Container fluid>
                        <div className="seller-profile-top-detail">
                           <Row>
                              <Col xs={12} sm={12} md={7} lg={5}>
                                 <div className="profile-detail">
                                    <Row className="no-gutters">
                                       <Col xs={6} sm={3} md={5} lg={3}>
                                          <div className='item-seller'>
                                             {profile?.issubscribed ? (
                                                <>
                                                   <div className="verified-dealer">
                                                      <Image src="../../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
                                                   </div>
                                                   <div className="verified-seller-profile">
                                                      <Image src={profile?.photo} alt={`${profile?.username} `} className="img-fluid" />
                                                   </div>
                                                </>
                                             ) : (
                                                <>
                                                   <div className="unverified-seller-profile">
                                                      <Image src={profile?.photo} alt={`${profile?.username} `} className="img-fluid" />
                                                   </div>
                                                </>
                                             )}
                                          </div>
                                       </Col>
                                       <Col xs={6} sm={6} md={7} lg={9}>
                                          <div className="seller-name">
                                             <h3>{`${profile?.username} `}</h3>
                                             <p>
                                                {profile?.issubscribed && (
                                                   <>
                                                      <Image src="/images/check_mark.png" alt="user" className="img-fluid" />
                                                      <span>{t("Verified Dealer")}</span>
                                                   </>
                                                )}
                                             </p>
                                             <h6> <i className="icon-location"></i>{profile?.address}</h6>
                                          </div>
                                          <div className="seller-btn">
                                             <ul>
                                                <li>
                                                   {parseInt(loginUser.id) !== parseInt(profile?.id) && (
                                                      loginUser ? (
                                                         <span onClick={() => FollowUnfollowToggle(profile.id)} className="cp">{profile.followedbyme ? `${t('Unfollow')}` : `+ ${t('Follow')}`}</span>

                                                      ) : (
                                                         <span onClick={() => setShowLoginModal(true)} className="cp">+ {t("Follow")}</span>

                                                      )
                                                   )}
                                                </li>
                                                <Link to={`/profile/edit`}>
                                                   <li><span className="cp message-send">{t("Edit profile")}</span></li>
                                                </Link>
                                                <li>
                                                   <Link to={`/setting`}>
                                                      <span className="cp message-send">{t("My Settings")}</span>
                                                   </Link>
                                                </li>
                                             </ul>
                                          </div>
                                       </Col>
                                    </Row>
                                 </div>
                              </Col>
                              <Col xs={12} sm={5} md={5} lg={4}>
                                 <div className="seller-follow">
                                    <ul>
                                       <li className="cp" onClick={handleFollowers}>
                                          <p>{Encriptor.kformatter(profile?.followers)}</p>
                                          <span>{profile?.followers <= 1 ? `${t('follower')}` : `${t('followers')}`}</span>
                                       </li>
                                       <li className="cp" onClick={handleFollowings} >
                                          <p>{Encriptor.kformatter(profile?.followings)}</p>
                                          <span>{t("Following")}</span>
                                       </li>
                                    </ul>
                                 </div>
                              </Col>
                              <Col xs={12} sm={7} md={6} lg={3}>

                                 <div className="seller-review-section">
                                    <div className="review-text">
                                       <h4>{t("Review score")}: </h4>
                                       <p>{t("Communication")} </p>
                                       <p>{t("Description")}</p>
                                       <p>{t("Shipping")}</p>
                                       <Link to={`/seller/review/${Encriptor.encrypt(profile?.id)}`}>
                                          <div className="view-all-review">{t("view all reviews")} ({profile?.review_count})</div>
                                       </Link>
                                    </div>
                                    <div className="review-reating">
                                       <h5>
                                          <span><i className="fa fa-star" aria-hidden="true"></i> {profile?.averagerating}</span>
                                          <span className="total-review"> ({profile?.review_count})</span>
                                       </h5>
                                       <div className="review">
                                          <StarRatings
                                             rating={profile?.reviews?.avg_seller_communication}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </div>
                                       <div className="review">
                                          <StarRatings
                                             rating={profile?.reviews?.avg_item_as_described}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </div>
                                       <div className="review">
                                          <StarRatings
                                             rating={profile?.reviews?.avg_shipping_time}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"

                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </div>
                                    </div>
                                 </div>

                              </Col>
                           </Row>
                        </div>
                     </Container>
                     {loginUser && profile?.issubscribed && (
                        <Container fluid>
                           <div className="seller-contact-detail-services">
                              <Row className="no-gutters">
                                 <Col xs={12} sm={12} md={12} lg={8}>
                                    {(loginUser && parseInt(loginUser.id) === parseInt(profile?.id)) && (<div className="edit-info"><Link to="/profile/edit"> {t("Edit info")}</Link></div>)}
                                    <div className="seller-contact-information">
                                       <Row className="no-gutters">
                                          <Col xs={12} sm={12} md={12} lg={4}>
                                             <h4 className="title">{t("Contact information")}</h4>
                                             <div className="phone-no"><i className="fa fa-phone" aria-hidden="true"></i> <a href={`tell:${profile?.phone}`}> {profile?.phone}</a></div>
                                             <div className="email"><i className="icon-email" aria-hidden="true"></i> <a href={`mailto:${profile?.business_email}`}> {profile?.business_email}</a></div>
                                             <div className="address">
                                                <div className="icon">
                                                   <i className="icon-location"></i>
                                                </div>
                                                <div className="add">
                                                   <p>{profile?.business_address} {profile?.business_address_1}</p>
                                                </div>
                                             </div>
                                          </Col>
                                          <Col xs={12} sm={12} md={12} lg={8}>
                                             <h4 className="title">{t("About dealer")}</h4>
                                             <p className="desc">{profile?.business_description}</p>
                                             {profile?.website && (
                                                <div className="website-link">
                                                   <a target="_blank" href={getValidUrl(profile?.website)} rel="noreferrer"><i className="fa fa-link" aria-hidden="true"></i> {profile?.website}</a>
                                                </div>
                                             )}
                                          </Col>
                                       </Row>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={12} lg={4}>
                                    <div className="seller-services-information">
                                       {(loginUser && parseInt(loginUser.id) === parseInt(profile?.id)) && (<div className="edit-info"><Link to="/profile/edit"> {t("Edit info")}</Link></div>)}
                                       <h4 className="title">{t("Services & Specialities")}</h4>
                                       {profile?.specialities.length > 0 && profile?.specialities.length > 0 ? (
                                          <>
                                             <div className="service-list">
                                                <ul>
                                                   {profile?.services.slice(0, 4).map((service, i) => (
                                                      <li key={i}><i className="icon-right"></i> {service.title}</li>
                                                   )
                                                   )}

                                                   {profile?.services.length > 4 && (

                                                      <OverlayTrigger
                                                         placement="bottom"
                                                         delay={{ show: 250, hide: 400 }}
                                                         overlay={
                                                            <Tooltip id={`tooltip-more-services`}>
                                                               <ul>
                                                                  {profile?.services.slice(4, profile?.services?.length).map((service, ik) => (
                                                                     <li key={ik}> <i className="icon-right"></i> {service.title}</li>
                                                                  )
                                                                  )}
                                                               </ul>


                                                            </Tooltip>
                                                         }
                                                      >
                                                         <li className="more-services">+ {profile?.services.length - 4} {t("More")}</li>
                                                      </OverlayTrigger>
                                                   )}

                                                </ul>
                                             </div>
                                             <div className="specialities-list">
                                                <ul>
                                                   {profile?.specialities.slice(0, 4).map((service, i) => (
                                                      <li key={i}><i className="icon-right"></i> {service.title}</li>
                                                   )
                                                   )}
                                                   {profile?.specialities.length > 4 && (

                                                      <OverlayTrigger
                                                         placement="bottom"
                                                         delay={{ show: 250, hide: 400 }}
                                                         overlay={
                                                            <Tooltip id={`tooltip-more-services`}>
                                                               <ul>
                                                                  {profile?.specialities.slice(4, profile?.specialities?.length).map((service, ik) => (
                                                                     <li key={ik}> <i className="icon-right"></i> {service.title}</li>
                                                                  )
                                                                  )}
                                                               </ul>
                                                            </Tooltip>
                                                         }
                                                      >
                                                         <li className="more-services">+ {profile?.specialities.length - 4} {t("More")}</li>
                                                      </OverlayTrigger>
                                                   )}

                                                </ul>
                                             </div>
                                          </>
                                       ) : (
                                          <div className="specialities-list1">
                                             <ul>
                                                <li> <i className="icon-right"></i> {t("Not provided")}</li>

                                             </ul>
                                          </div>
                                       )}


                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </Container>
                     )}
                  </div>
                  <MobileFilter
                     setView={setCurrentViewType}
                     view={currentViewType}
                     categoryFilterData={categoryFilterData}
                     filterOption={filterOption}
                     filterCountries={filterCountries}
                     filterCount={filterCount}
                     handleClearFilter={handleClearFilter}
                     handleFiltersSelect={handleFiltersSelect}
                     filteredList={filteredList}
                     showNumberOfFilters={showNumberOfFilters}
                     setShowNumberOfFilters={setShowNumberOfFilters}
                     handleFirstRangeInput={handleFirstRangeInput}
                     handleSecondRangeInput={handleSecondRangeInput}
                     maxDate={maxDate}
                     minDate={minDate}
                     rangeError={rangeError}
                     handleDatepickerValue={handleDatepickerValue}
                  // HandleAdFilter={HandleAdFilter}

                  />
                  <div className="verified-seller-profile-section seller-information-detail usr-prof">
                     <div className="filterby">
                        <div className="main-layout" id='layout'>
                           {isDesktop && (
                              <SideFilter
                                 categoryFilterData={categoryFilterData}
                                 filteredList={filteredList}
                                 filterOption={filterOption}
                                 filterCountries={filterCountries}
                                 showNumberOfFilters={showNumberOfFilters}
                                 handleFiltersSelect={handleFiltersSelect}
                                 setShowNumberOfFilters={setShowNumberOfFilters}
                                 handleFirstRangeInput={handleFirstRangeInput}
                                 minDate={minDate}
                                 maxDate={maxDate}
                                 handleSecondRangeInput={handleSecondRangeInput}
                                 rangeError={rangeError}
                                 handleDatepickerValue={handleDatepickerValue}
                                 handleClearFilter={handleClearFilter}
                                 leftOpen={leftOpen}
                                 toggleSidebar1={toggleSidebar1}
                                 categoryID={''}
                                 filterCount={filterCount}
                              />
                           )}
                           <div className="product-right-section">
                              <div className='content'>
                                 {isDesktop && (
                                    <div className="mb-0">
                                       <Row className="no-gutters-row-only">
                                          <Col xs={12} sm={6} md={7} lg={6}>
                                             <div className="ads-btn">
                                                <ul>
                                                   <li><span onClick={() => HandleFilterByStatus('ViewAll')} className={filterBy === 'ViewAll' ? ' btn btn-primary ads active filters-l cp' : 'filters-l cp'}  >{t("All")} ({allPostCount})</span></li>
                                                   <li><span onClick={() => HandleFilterByStatus('ActiveAds')} className={filterBy === 'ActiveAds' ? ' btn btn-primary active filters-l cp' : 'filters-l cp'}  >{t("Active")}  ({activePostCount})</span></li>
                                                   <li><span onClick={() => HandleFilterByStatus('ExpiredAds')} className={filterBy === 'ExpiredAds' ? ' btn btn-primary active filters-l cp' : 'filters-l cp'}  >{t("Expired")} ({expiredPostCount})</span></li>
                                                   <li><span onClick={() => HandleFilterByStatus('SoldAds')} className={filterBy === 'SoldAds' ? ' btn btn-primary active filters-l' : 'filters-l cp'}   >{t("Sold")} ({soldPostCount})</span></li>
                                                   <li><span onClick={() => HandleFilterByStatus('DeletedAds')} className={filterBy === 'DeletedAds' ? 'link btn btn-primary active filters-l cp' : 'filters-l cp'}   >{t("Deleted")} ({deletedPostCount})</span></li>
                                                </ul>
                                             </div>
                                          </Col>

                                          {currentItem === "posts" && (
                                             <>
                                                <Col xs={12} sm={12} md={2} lg={2}>
                                                   <div className="grid-list-view">
                                                      <ul>
                                                         <li>
                                                            <span onClick={() =>
                                                               setCurrentViewType('grid')} className={`link ads ${currentViewType === 'grid' ? 'active' : ''}`}  ><i className="icon-grid_view"></i></span>
                                                         </li>
                                                         <li>
                                                            <span onClick={() =>
                                                               setCurrentViewType('list')} className={`link ads ${currentViewType === 'list' ? 'active' : ''} ml-3`}  ><i className="icon-list_view"></i></span>
                                                         </li>
                                                      </ul>
                                                   </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={4}>
                                                   <div className="search-seller-post">
                                                      <Form>
                                                         <div className="input-group">
                                                            <input type="text" className="form-control" value={filterString} onChange={handlefilter} placeholder={t("Search by profile listing")} />
                                                            <div className="input-group-append">
                                                               <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="icon-search"></i></button>
                                                            </div>
                                                         </div>
                                                      </Form>
                                                   </div>
                                                </Col>
                                             </>
                                          )}
                                       </Row>
                                    </div>
                                 )}
                                 <div className="all-product">
                                    {currentItem === 'posts' ?
                                       <MyPosts
                                          posts={filteredPosts}
                                          LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                                          view={currentViewType}
                                          deletePost={deletePost}
                                          renewPost={renewPost}
                                          markSoldPost={markSoldPost}
                                          goToChat={goToChat}
                                          setPage={setPage}
                                          setInlineLoader={(val) => { setInlineLoader(val) }}
                                          inlineLoader={inlineLoader}
                                       />
                                       :
                                       <MyReviews setProfile={setProfile} profile={profile} userid={userid} />
                                    }

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </>
            )}
            {connectionData && (
               <Connections
                  title={connectionData?.title}
                  data={connectionData?.data}
                  connectionModal={connectionModal}
                  setConnectionModal={setConnectionModal}
               />
            )}
         </div>
         <Footer />
      </>
   )
}
export default ViewProfile;
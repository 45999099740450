import React from 'react'
import 'bootstrap-daterangepicker/daterangepicker.css';
import ImageGallery from 'react-image-gallery';





const ProductImages  = ({featuredImage,postSlug,pictures}) =>{
	var tempImages= [];
	// if(featuredImage){
	// 	const newImage = {
	// 			  original: featuredImage,
	// 			  thumbnail:featuredImage,
	// 			  originalAlt:`${postSlug}-0`
	// 		}
	// 	tempImages.push(newImage)
		
	// }
	if(pictures){
		pictures.forEach((element,i) => {
			const newImage = {
				  original: element.filename,
				  thumbnail: element.thumbnail,
				  originalAlt:`${postSlug}-${i+1}`
			}
			tempImages.push(newImage)
		});
	}
	
	const image = [
		
		{
			original: '/images/productDefault.svg',
			thumbnail: '/images/productDefault.svg',
			originalAlt:`${postSlug}-0`
		},
		
	];
	var renderImages = tempImages.length > 0 ?tempImages:image ;
	return(
		<div className="product-detail-slider">
			<ImageGallery items={renderImages} /> 
		</div>
	)
}

export default ProductImages;
import React, { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import profileService from '../../../services/myProfile'
import { useToasts } from 'react-toast-notifications'
import PageLoader from '../../pageLoader'
import { useTranslation } from 'react-i18next';



const ChangePassword = ({ active }) => {
	const { t } = useTranslation();
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const token = loginUser.access_token;
	const [formData, setFormData] = useState({
		old_password: '',
		password: '',
		password_confirmation: '',
	});
	const [loaded, setLoaded] = useState(true);
	const [validationError, setValidationError] = useState();
	const { addToast } = useToasts();

	// handle comman form change  
	const handleFromChange = (e) => {
		const { name, value: newValue, type } = e.target;
		const value = type === 'number' ? +newValue : newValue;
		setFormData({
			...formData,
			[name]: value,
		});
		setValidationError();
	}

	// check has  validation error 
	const hasErrorFor = (field) => {
		if (validationError) {

			const error = validationError.find(element => String(element.field) === String(field));
			if (error)
				return true;
		} else {
			return false;
		}
	}

	//  render validation error for element
	const renderErrorFor = (field) => {
		if (hasErrorFor(field)) {
			const error = validationError.find(element => String(element.field) === String(field));
			return (
				<div className="error-wrapper">
					<span className='invalid-feedback'>
						<strong>{error.message}</strong>
					</span>
				</div>
			)
		}
	}

	// handle Form submit 
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoaded(false);
		profileService.setToken(token);
		profileService
			.changePassword(formData)
			.then(response => {
				setLoaded(true);
				if (response.success) {
					setFormData({
						old_password: '',
						password: '',
						password_confirmation: '',
					});
					addToast(response.message, { appearance: 'success' })

				} else {
					addToast(response.result, { appearance: 'error' })
				}
			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {
					if (error.response.status === 422) {
						setValidationError(
							error.response.data.errors
						)
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}



	if (active === 'Change Password') {
		return (
			<>

				<PageLoader isLoaded={loaded} />
				<div className="p-4">
					<div className="verified-dealer-form notification">
						<div className="title">
							<h1>{t('Change your password')} </h1>
						</div>
						<div className="desc">
							<p>{t('If you want to change your password, please use the field below to')} <br /> {('enter your current and new password.')}</p>
						</div>
						<div className="login-form">
							<Form onSubmit={handleSubmit}>
								<Row className="no-gutters justify-content-center">
									<Col xs={12} sm={12} md={8} lg={6} >
										<div className="form-group">
											<div className="material-textfield mt-4">
												<span className="icon-password"></span>
												<input placeholder=" "
													name="old_password"
													className={`${hasErrorFor('old_password') ? 'is-invalid' : 'valid'}`}
													onChange={handleFromChange}
													value={formData?.old_password}
													type="text" />
												<label>{t('Type your current password')} *</label>
											</div>
											{renderErrorFor('old_password')}
										</div>
									</Col>
								</Row>
								<Row className="no-gutters justify-content-center">
									<Col xs={12} sm={12} md={8} lg={6} >
										<div className="form-group">
											<div className="material-textfield mt-4">
												<span className="icon-password"></span>
												<input placeholder=" "
													name="password"
													className={`${hasErrorFor('password') ? 'is-invalid' : 'valid'}`}
													onChange={handleFromChange}
													value={formData?.password}
													type="password" />
												<label>{t('Type your new password')} *</label>
											</div>
											{renderErrorFor('password')}
										</div>
									</Col>
								</Row>
								<Row className="no-gutters justify-content-center">
									<Col xs={12} sm={12} md={8} lg={6} >
										<div className="form-group">
											<div className="material-textfield mt-4">
												<span className="icon-password"></span>
												<input placeholder=" "
													name="password_confirmation"
													className={`${hasErrorFor('password_confirmation') ? 'is-invalid' : 'valid'}`}
													onChange={handleFromChange}
													value={formData?.password_confirmation}
													type="password" />
												<label>{t('Confirm your new password')} *</label>
											</div>
											{renderErrorFor('password_confirmation')}
										</div>
									</Col>

									<Col xs={12} sm={12} md={12} lg={12} >
										<div className="large-button mt-3"><Button variant="primary" type="submit"> {t('Submit')}</Button></div>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
				</div>

			</>
		)
	}
	return (
		<>
		</>
	)

}
export default ChangePassword;



import React, { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import profileService from '../../../services/myProfile'
import { useToasts } from 'react-toast-notifications'
import PageLoader from '../../pageLoader'
import { useTranslation } from 'react-i18next';

const Support = ({ active }) => {
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const token = loginUser.access_token;
	const { t } = useTranslation();
	const [formData, setFormData] = useState();
	const [loaded, setLoaded] = useState(true);
	const [validationError, setValidationError] = useState();
	const { addToast } = useToasts();

	// handle support form change 
	const handleFromChange = (e) => {
		const { name, value: newValue, type } = e.target;
		const value = type === 'number' ? +newValue : newValue;
		setFormData({
			...formData,
			[name]: value,
		});
	}

	// check element has error
	const hasErrorFor = (field) => {
		if (validationError) {

			const error = validationError.find(element => String(element.field) === String(field));
			if (error)
				return true;
		} else {
			return false;
		}
	}

	// render error for element
	const renderErrorFor = (field) => {
		if (hasErrorFor(field)) {
			const error = validationError.find(element => String(element.field) === String(field));
			return (
				<div className="error-wrapper">
					<span className='invalid-feedback'>
						<strong>{error.message}</strong>
					</span>
				</div>
			)
		}
	}

	// submit support ticket api 
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoaded(false);
		profileService.setToken(token);
		const newData = {
			...formData,
			name: `${loginUser.username}`,
			email: `${loginUser.email}`,
		}
		profileService
			.postSupportTicket(newData)
			.then(response => {
				setLoaded(true);
				if (response.success) {
					setFormData({
						...newData,
						phone: '',
						description: '',
					});
					setValidationError()
					addToast(response.message, { appearance: 'success' })

				} else {
					addToast(response.result, { appearance: 'error' })
				}
			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {
					if (error.response.status === 422) {
						setValidationError(
							error.response.data.errors
						)
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}
	if (active === "Support") {
		return (
			<>
				<PageLoader isLoaded={loaded} />
				<div className="p-4">
					<div className="verified-dealer-form">
						<div className="title">
							<h3>{t('Contact Us')}</h3>
							<p>{t('Fill the contact form below')}</p>
						</div>
						<div className="login-form">
							<Form onSubmit={handleSubmit}>
								<Row className="no-gutters">
									<Col xs={12} sm={12} md={12} lg={6} >
										<div className="form-group">
											<div className="material-textfield mt-4">
												<span className="icon-name"></span>
												<input placeholder=" "
													name="name"
													className={`${hasErrorFor('name') ? 'is-invalid' : 'valid'}`}
													// onChange={handleFromChange} 
													readOnly={true}
													value={`${loginUser?.username}`}
													type="text" />
												<label>{t('Name')} *</label>
											</div>
											{renderErrorFor('name')}
										</div>
									</Col>
									<Col xs={12} sm={12} md={12} lg={6} >
										<div className="form-group">
											<div className="material-textfield mt-4">
												<span className="icon-email"></span>
												<input placeholder=" "
													className={`${hasErrorFor('email') ? 'is-invalid' : 'valid'}`}
													// onChange={handleFromChange} 
													value={loginUser?.email}
													name="email"
													id="email"
													type="text" />
												<label>{t('Email')} *</label>
											</div>
											{renderErrorFor('email')}
										</div>
									</Col>
									<Col xs={12} sm={12} md={12} lg={6} >
										<div className="form-group">
											<div className="material-textfield mt-4">
												<span className="icon-mobile"></span>
												<input placeholder=" "
													className={`${hasErrorFor('phone') ? 'is-invalid' : 'valid'}`}
													onChange={handleFromChange}
													value={formData?.phone}
													name="phone"
													id="email"
													type="number" />
												<label>{t('Enter your Phone')}*</label>
											</div>
											{renderErrorFor('phone')}
										</div>
									</Col>


									<Col xs={12} sm={12} md={12} lg={6} >
										<div className="form-group">
											<div className="material-textfield mt-4">
												<span className="icon-company_description"></span>
												<div className="textarea1">
													<textarea className={`${hasErrorFor('description') ? 'is-invalid' : 'valid'}`}
														placeholder=" "
														name="description"
														onChange={handleFromChange}
														value={formData?.description}
														rows="3"></textarea>
													<label>{t('Enter Company Description')}*</label>
												</div>

											</div>
											{renderErrorFor('description')}
										</div>
										{/*  */}
									</Col>
									<Col xs={12} sm={12} md={12} lg={12} >
										<div className="large-button mt-3"><Button variant="primary" type="submit"> Submit</Button></div>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
				</div>
			</>
		)
	}
	return (
		<>
		</>
	)
}
export default Support;
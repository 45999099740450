/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from "../include/header";
import Footer from "../include/footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Notification from './emailNotification/notification'
import ChangePassword from './changePassword/changePassword'
import Support from './support/support'
import MySubscription from './mySubscription/mySubscription'
import ReferAndEarn from './referAndEarn/referAndEarn'
import EditProfile from './editProfile/editProfile'
import PageLoader from '../pageLoader'
import SeoTags from '../seo/seoTags'
import { isMobile } from "react-device-detect";
import { useTranslation } from 'react-i18next';


const Setting = () => {
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const [currentItem, setCurrentItem] = useState('Profile settings')
	const { t } = useTranslation();
	const [loaded, setLoaded] = useState(false)
	const [nav, setNav] = useState(false)

	const handleClick = (e) => {
		e.preventDefault();
		navigate('/setting',
			{
				state: { page: e.target.getAttribute("data-id") }
			});
		if (isMobile) {
			setNav(true);
		}
	}

	var navigate = useNavigate();
	var location = useLocation();
	const query = new URLSearchParams(location?.search);
	const page = query.get('page') ? query.get('page') : false;



	useEffect(() => {
		if (location?.state?.page) {
			setCurrentItem(location?.state?.page)
		} else {
			setCurrentItem(page ? page : 'Profile settings')
		}
		setLoaded(true)
	}, [location])


	return (
		<>
			<SeoTags title={`Miltrade | Settings | ${currentItem}`}
				description={''}
				category={''}
				url={window.location.href}
				image={''}
			/>
			{/* Start of header section */}
			<Header isHomePage={false} checkAuth={true} />
			{/* end of header section */}
			<div className="footer-bottom-setting">
				<div className="setting">
					<Row className="no-gutters">
						<Col>
							<div className="breadcrum breadcrum1">
								<Container fluid>
									<Row>
										<Col xs={10} sm={10} md={11} lg={11} >
											<Breadcrumb>
												<Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
												<Breadcrumb.Item >{t('Settings')}</Breadcrumb.Item>
												<Breadcrumb.Item active>{currentItem}</Breadcrumb.Item>
											</Breadcrumb>
										</Col>
										<Col xs={2} sm={2} md={11} lg={1} >
											<button className="setting-nav openbtn" onClick={() => { setNav(!nav); }}>☰</button>
										</Col>
									</Row>
								</Container>
							</div>
						</Col>
					</Row>

					<Row className={`no-gutters wrapper-setting ${nav ? "setting-sidepanel" : "setting-sidepanel1"}`}>
						<span className={`setting-arrow-cross cp ${nav ? "setting-sids" : "setting-sids1"}`} onClick={() => { setNav(!nav) }} >×</span>
						<div className={`left-bar-setting ${nav ? "setting-width1" : "setting-width2"}`}>
							<div className="setting-list">
								<div id="mySidepanel" className="sidepanel" >
									<ul>
										<li><div className="icon"><i className="icon-account"></i> </div><Link data-id="Profile settings" onClick={handleClick} className={currentItem === 'Profile settings' ? 'link active' : 'link'} to="/">{t('Profile settings')}</Link></li>
										<li><div className="icon"><i className="icon-password"></i></div><Link data-id="Change Password" onClick={handleClick} className={currentItem === 'Change Password' ? 'link active' : 'link'} to="/">{t('Change Password')}</Link> </li>
										<li><div className="icon"><i className="icon-wallet"></i> </div><Link to="/wallet"> Miltrade wallet</Link></li>
										<li><div className="icon"><i className="icon-invite"></i></div> <Link data-id="Invite a friend" onClick={handleClick} className={currentItem === 'Invite a friend' ? 'link active' : 'link'} to="/">{t('Invite a friend')}</Link></li>

										{/* {!loginUser?.issubscribed && (<li><div className="icon"><i className="icon-dealers_directory"></i></div><Link  to="/become-a-verified-dealer">Become a Verified Dealer</Link> </li>)} */}
										{loginUser.issubscribed && (
											<li><div className="icon"><i className="icon-verified_user"></i></div> <Link data-id="Verified Dealer Settings" onClick={handleClick} className={currentItem === 'Verified Dealer Settings' ? 'link active' : 'link'} to="/">{t('Verified Dealership')}</Link></li>
										)}
										<li><div className="icon"><i className="icon-email_notifications"></i> </div><Link data-id="Notification preferences" onClick={handleClick} className={currentItem === 'Notification preferences' ? 'link active' : 'link'} to="/">{t('Notification preferences')}</Link></li>
										{/* <li><div className="icon"><i className="icon-support"></i></div> <Link data-id="Support" onClick={handleClick}  className={currentItem=='Support'?'link active':'link' } to="/support">Help & Support</Link></li> */}
										<li><div className="icon"><i className="icon-support"></i></div> <Link data-id="Support" className={currentItem === 'Support' ? 'link active' : 'link'} to="/support">{t('Help & Support')}</Link></li>

									</ul>
								</div>

							</div>

						</div>
						<div className="right-bar-setting setting2">
							{loaded ? (
								<>
									{/* <Dealer planInfo={planInfo}  active={currentItem} verified={verified} handleSubscribe={handleSubscribe}/> */}
									<Notification active={currentItem} />
									<ChangePassword active={currentItem} />
									<Support active={currentItem} />
									<MySubscription active={currentItem} />
									<ReferAndEarn active={currentItem} />
									<EditProfile active={currentItem}
									/>
								</>
							) : (<PageLoader isLoaded={false} />)}

						</div>
					</Row>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Setting;



/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/purchase';

let token = null

const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}
const purchasePlan = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/plan`, newObject, config)
    return request.then(response => response.data)
}

const recurringPayment = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/success`, newObject, config)
    return request.then(response => response.data)
}




export default { setToken, purchasePlan, recurringPayment }
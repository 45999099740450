import React from 'react';
import Modal from 'react-bootstrap/Modal'; 
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';





const PhoneModal = ({phone,phoneModal,setPhoneModal}) =>{

  
    const { t } = useTranslation();
    return (
        <>
                <Modal
                    size="lg"
                    show={phoneModal} onHide={() => setPhoneModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <h4>{t("Phone Number")}</h4>
                    </Modal.Header>    
                        <Modal.Body>
                            <div className="report-ad-modal">
                                <div className="show-phone-no">
                                <span> {phone} </span>
                                </div>
                                <div className="safety-tips">
                                    <p><Image src="/images/alert.webp" width='30' alt="flag" className="img-fluid" /> {t("Safety Tips")}</p>
                                </div>
                                <ul>
                                    <li>
                                    <div className="wrapper-add-report">
                                        <div className="icon">
                                            <i className="icon-right"></i>
                                        </div>
                                        <div className="text">
                                            <span>{t("The advertisements are published by individuals or professionals and don't directly concern Miltrade. Our site is a channel for connecting people. So please check the quality of the product or service before buying or renting it")}.</span>
                                        </div>
                                    </div>
                                    
                                    </li>
                                    <li>
                                    <div className="wrapper-add-report">
                                        <div className="icon">
                                            <i className="icon-right"></i>
                                        </div>
                                        <div className="text mt-3">
                                            <strong> {t("Important")}:</strong> 
                                        <span> {t("Don't send money remotely for a product or for a service")}.</span>
                                        </div>
                                    </div>
                                    
                                    </li>
                                </ul>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <div className="report-add-footer">
                            <Button variant="secondary close-btn" onClick={() => setPhoneModal(false)}>{t("Close")}</Button>
                            <a className="call-now-btn" href={`tel:${phone}`}><i className="icon-call"></i> {t("Call Now")} !</a>
                        </div>
                      </Modal.Footer>
                </Modal>
            </>
        )
    }

export default PhoneModal;
/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/users';
const baseUrl1 = prefix;

let token = null;


const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}

const getWalletInfo = (page) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/usertransactions?page=${page}`, config)
    return request.then(response => response.data,)
}

const creditWallet = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/updatewallet`, newObject, config)
    return request.then(response => response.data)
}
// const createPaymentIntent = (newObject) => {
//     var config = {
//         headers: token?{ Authorization: token }:{},
//     }
//     const request = axios.post(baseUrl1+`/create/payment/intent`,newObject,config)
//     return request.then(response =>  response.data)
// }
const createPaymentIntent = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl1 + `/create/payment/request`, newObject, config)
    return request.then(response => response.data)
}
const createPaymentDetails = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl1 + `/submit/payment/details`, newObject, config)
    return request.then(response => response.data)
}
const creditPackages = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/credit_packages`, config)
    return request.then(response => response.data)
}



export default { getWalletInfo, creditWallet, setToken, createPaymentIntent, createPaymentDetails, creditPackages }
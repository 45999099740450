/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../../assets/css/sidebar.css";
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap-daterangepicker/daterangepicker.css';

import "react-month-picker/css/month-picker.css";
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';



const DealersSideFilter = ({
  sortBy,
  filterOption,
  filterCountries,
  handleClearFilter,
  handleFiltersSelect,
  showNumberOfFilters,
  setShowNumberOfFilters,
  filterCount,
  countries,
  filterString,
  filterTOSString,
  filterTOS,
  fiteredServices,
  filterSpecialities,
  filterSpecialitiesString,
  fiteredSpecialities,
  handleSortBy,
  toggleSidebar,
  leftOpen,
}) => {

  const { t } = useTranslation();
  const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
  
  return (
    <div className="sidebar">
      <div id='left' className={leftOpen} >
        <div className='vendor-directory1'>
          <div className='content left-sidebar'>
            <div className="product-filter-section">
              <div className="clear-filter">
                <Row className="no-gutters">
                  <Col xs={6} sm={6} md={6} lg={4} className="p-0 m-0">
                    <span className="filters filter-custom-badge-wrapper cp"><i className="icon-side_baar mr-1"></i> {t("Filters")} {filterCount > 0 && (<span className="badge badge-danger filter-custom-badge">{filterCount}</span>)}</span>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={8} className="p-0 m-0">
                    <span className="clear-all cp" onClick={handleClearFilter} >{t("Clear all")}</span>
                  </Col>
                </Row>
              </div>
            </div>
            <Accordion >
              <Card>
                <Card.Header className="">
                  <Accordion.Toggle as={Button} variant="link" eventKey="0" >{t("Country")} <span className={filterOption.find(f => String(f.id) === "country") ? 'active' : ''}>{filterOption.find(f => String(f.id) === "country") ? <i className="icon-right"></i> : ''}</span></Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0" >
                  <Card.Body>

                    <div className="filter-search-wrapper">
                      <div className="category-search-icon">
                        <i className="icon-search"></i>
                      </div>
                      <Form.Control type="text" value={filterString} onChange={filterCountries} placeholder={t("search")} />
                    </div>

                    <div className="category-name">
                      <ul>
                        {countries?.slice(0, showNumberOfFilters).map((option, i) => (
                          <li key={i} data-filter_value={option.code} data-filter_id="country" onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.code)) ? 'active' : ''}><i className="icon-right"></i> {option.name}</li>
                        ))}
                        <div className="loadmorebutton">
                          {countries?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                        </div>
                      </ul>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="">
                  <Accordion.Toggle as={Button} variant="link" eventKey="1" >{t("Type of Service")} <span className={filterOption.find(f => f.id === "services") ? 'active' : ''}>{filterOption.find(f => f.id === "services") ? <i className="icon-right"></i> : ''}</span></Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1" >
                  <Card.Body>
                    <div className="filter-search-wrapper">
                      <div className="category-search-icon">
                        <i className="icon-search"></i>
                      </div>
                      <Form.Control type="text" value={filterTOSString} onChange={filterTOS} placeholder={t("search type of service")} />
                    </div>

                    <div className="category-name">
                      <ul>
                        {fiteredServices?.slice(0, showNumberOfFilters).map((option, i) => (
                          <li key={i} data-filter_value={option.id} data-filter_id="services" onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id) && f.id === 'services') ? 'active' : ''}><i className="icon-right"></i> {option.title}</li>
                        ))}
                        <div className="loadmorebutton">
                          {fiteredServices?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                        </div>
                      </ul>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header className="">
                  <Accordion.Toggle as={Button} variant="link" eventKey="2" >{t("Specialities")} <span className={filterOption.find(f => f.id === "specialities") ? 'active' : ''}>{filterOption.find(f => f.id === "specialities") ? <i className="icon-right"></i> : ''}</span></Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2" >
                  <Card.Body>
                    <div className="filter-search-wrapper">
                      <div className="category-search-icon">
                        <i className="icon-search"></i>
                      </div>
                      <Form.Control type="text" value={filterSpecialitiesString} onChange={filterSpecialities} placeholder={t("search specialities")} />
                    </div>
                    <div className="category-name">
                      <ul>
                        {fiteredSpecialities?.slice(0, showNumberOfFilters).map((option, i) => (
                          <li key={i} data-filter_value={option.id} data-filter_id="specialities" onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id) && f.id === 'specialities') ? 'active' : ''}><i className="icon-right"></i> {option.title}</li>
                        ))}
                        <div className="loadmorebutton">
                          {fiteredSpecialities?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                        </div>
                      </ul>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <div className="">
              {!loginUser && (<Link to={`/become-a-verified-dealer`} className="becoame-a-dealer" > {t("Become a verified dealer")}</Link>)}
              {loginUser && !loginUser.issubscribed && (<Link to={`/become-a-verified-dealer`} className="becoame-a-dealer" > {t("Become a verified dealer")}</Link>)}

            </div>
            <div className="">
              <Link to={`/support?page=verified-dealers&title=Verified Dealers`} className="how-it-work" ><i className="icon-about_miltrade"></i> {t("How does it work?")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealersSideFilter;

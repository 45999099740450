import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from "./include/header";
import Footer from "./include/footer";
import SeoTags from './seo/seoTags'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';





const PayPalError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'mr-2 btn btn-danger'
    },
    buttonsStyling: false
  })
 


  useEffect(() => {
    async function authPayment() {
      swalWithBootstrapButtons.fire(
        t('Payment Cancelled'),
        t('PayPal payment Cancelled'),
        'error'
      )
      window.localStorage.setItem('issubscribed', false);
      navigate('/become-a-verified-dealer', { state: { to: 'dealer' } });
    }
    authPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);






  return (
    <>
      <SeoTags title={`Miltrade | Payment faild`}
        description={''}
        category={''}
        url={window.location.href}
        image={''}
      />
      <Header
        data={false} checkAuth={true}
      />
      <div className="footer-bottom1">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="login-modal">
                <div className="modal-body-heading text-center">
                  <div className="alert alert-info" role="alert">
                    {t("PayPal Transaction Cancelled")} ..
                    <div className="spinner-border ml-4" role="status">
                      <span className="sr-only">{t("Loading...")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}


export default PayPalError;


import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const ProductCategories = ({allProductCategories,handleMainCategory,selectedMainCategory}) => {
    return(
        <div className="item-listing">
            <Row>
                {allProductCategories.map((category, index) => (
                    <Col className="cp" key={index}  xs={12} sm={6} md={3} lg={3}>
                            <div onClick={handleMainCategory} data-id={category.id}  className={String(selectedMainCategory) === String(category.id)?'card-box active':'card-box'  }>
                                <img width="70px" src={category.icon} alt={'category'}/>
                                <h4>{category.name}</h4>
                            </div>
                    </Col>
                ))}
            </Row>
        </div>
    
    )
} 

export default ProductCategories;
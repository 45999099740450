import React from 'react';
import Modal from 'react-bootstrap/Modal'; 
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Encriptor from '../../../services/encriptor'
import StarRatings from 'react-star-ratings';
import { useTranslation } from 'react-i18next';




const Connections = ({title,data,connectionModal,setConnectionModal}) =>{
    const navigate = useNavigate();
    const handleOnClick = (link) =>{
        setConnectionModal(false)
        navigate(link)
    }
    const { t } = useTranslation();
    return (
        <><div className="profile-followers-modal">
            <Modal className="followers-modal" size="" show={connectionModal} onHide={() => setConnectionModal(false)} aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg"><h3>{title}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                 {data?.length > 0 ?(
                     data?.map((user,i)=>(
                        <div key={i} className="followers-box m-3">
                            <Link onClick={()=>handleOnClick(`/seller/profile/${Encriptor.encrypt(user.id)}`)}>
                                <Row>
                                    <Col xs={3} sm={3} md={2} lg={2} >
                                        
                                            <div className="wrapp-image-section">
                                                {user.issubscribed ? (
                                                    <>
                                                    <div className="verified-image-followers">
                                                        <Image src="../../images/animation/verified_dealer.svg" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="followers-image-verified">
                                                    <Image src={user?.photo} alt={user?.username} className="img-fluid"/>
                                                    </div>
                                                    </>
                                                ):(
                                                    <>
                                                        <div className="followers-image-unverified">
                                                           <Image src={user?.photo} alt={user?.username} className="img-fluid"/>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                    </Col>
                                    <Col xs={9} sm={9} md={10} lg={10} >
                                        <div className="name">
                                            {/* {user?.first_name} {user?.last_name} */}
                                            {user?.username}
                                        </div>
                                        <div className="followers-list">
                                            <ul>
                                                <li> {Encriptor.kformatter(user?.followers)} {user?.followers <=1 ?`${t("Follower")}`:`${t("Followers")}`}</li>
                                                <li> {Encriptor.kformatter(user?.postcount)} {user?.postcount <=1  ? `${t("Listing")}`:`${t("Listings")}`}</li>
                                            </ul>
                                        </div>
                                        <div className="clearfix"></div>
                                       
                                    </Col>
                                    <Col xs={3} sm={3} md={2} lg={2} ></Col>
                                    <Col xs={12} sm={12} md={10} lg={10} >
                                        <div className="locations">
                                                <ul>
                                                    <li className="location"><i className="icon-location"></i> {user?.address}</li>
                                                    <li className="star-rating"> 
                                                        <div className="dealer-rat">
                                                            <StarRatings
                                                                rating={Math.round(user?.averagerating)}
                                                                starRatedColor="#FFE156"
                                                                starHoverColor="#FFE156"
                                                                numberOfStars={5}
                                                                starSpacing="2px"
                                                                starDimension="25px"
                                                                name='seller_communication'
                                                                />
                                                        </div>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    ))
                 ):(
                     <>
                        
                        <div className="text-center">
                            <Image  src={`/images/no_${title}.png`} className="img-fluid" alt="user"  />
                            <p>No {title} </p>
                        </div>
                    </>
                     
                 )}   
                
                </Modal.Body>
            </Modal>
            </div>
            </>
        )
    }

export default Connections;
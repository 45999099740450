/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Header, Footer } from "../../../index";
import MyPosts from './myPosts'
import sellerProfile from '../../../../services/sellerProfile'
import postsServices from '../../../../services/posts'
import { useToasts } from 'react-toast-notifications'
import Encriptor from "../../../../services/encriptor";
import PageLoader from '../../../pageLoader'
import Connections from '../../../comman/connections/connection';
import chatService from "../../../../services/chat"
import ShareIt from '../../../shareIt/share'
import { isDesktop, isMobile } from "react-device-detect";
import SeoTags from '../../../seo/seoTags'
import 'bootstrap-daterangepicker/daterangepicker.css';
import categoryService from "../../../../services/productCategories";
import StarRatings from 'react-star-ratings';
import { io } from "socket.io-client";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import { Auth } from '../../../../Contexts/AuthContext';
import MobileFilter from '../../../comman/mobileFilter';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../../../../Contexts/langContext';
import SideFilter from '../../../comman/SideFilter';

require('dotenv').config()


var isHomePage = false;

const SellerReviews = () => {
   const { t } = useTranslation();

   const { setShowLoginModal } = useContext(Auth);
   const toggleSidebar1 = (event) => {
      let key = `leftOpen`;
      setSidebar({ [key]: !sidebar[key] });
   }
   const [sidebar, setSidebar] = useState({
      leftOpen: isMobile ? false : true,
   });
   let leftOpen = sidebar.leftOpen ? 'open' : 'closed';
   const [categoryFilterData, setCategoryFilterData] = useState();
   const [filterOption, setFilterOption] = useState([]);
   const [filteredList, setFilteredList] = useState([]);
   const [showNumberOfFilters, setShowNumberOfFilters] = useState(6);
   const [minDate, setMinDate] = useState('');
   const [maxDate, setMaxDate] = useState('');
   const [rangeError, setRangeError] = useState('');
   const [currentItem, setCurrentItem] = useState('posts');
   const [currentViewType, setCurrentViewType] = useState('grid');
   const [profile, setProfile] = useState([]);
   const [filterString, setFilterString] = useState('');
   const [posts, setPosts] = useState();
   const [filteredPosts, setFilteredPosts] = useState([]);
   const [isLoaded, setIsLoaded] = useState(false);
   const [connectionModal, setConnectionModal] = useState(false);
   const [connectionData, setConnectionData] = useState();
   const [filterCount, setFilterCount] = useState(0);
   const [page, setPage] = useState(0);
   const [inlineLoader, setInlineLoader] = useState(false);


   // added for suggestion list 
   // const [predefinedMessage, setPredefinedMessage] = useState([]);
   const [predefinedMessageModal, setPredefinedMessageModal] = useState(false);
   const [customMessageForm, setCustomMessageForm] = useState({
      message: ''
   });

   const [share, setShare] = useState(false);
   const { addToast } = useToasts();
   const { id } = useParams();
   const navigate = useNavigate();
   var userid = Encriptor.decrypt(id);
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;

   let ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;
   // const io = require("socket.io-client");
   var socket = io(ENDPOINT, {
      transports: ['websocket']
   });
   const { lang } = useLangContext(langContext);

   // get user profile 
   const getProfile = async () => {
      setIsLoaded(false);

      const formData = new FormData();

      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }
      formData.append('id', userid);
      sellerProfile
         .sellerProfile(formData, 1, lang)
         .then(response => {
            if (response.success) {
               getFilterCategory()
               setProfile(response.data);
               setPosts(response.data.postdata);
               setFilteredPosts(response.data.postdata)
               // setPredefinedMessage(response.data?.predefined_message);
               setCustomMessageForm({
                  ...customMessageForm,
                  message: t('Hello') + ', ' + response.data?.username + ' \n',
               });
            }
            else {
               setIsLoaded(true);
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);

            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else if (error.response.status === 400 && error.response.data.user_not_found) {
                  navigate('/user-not-found');
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }
   // get user profile 
   const fetchMorePosts = async () => {
      if (page > profile?.last_page && page > 1) {
         setInlineLoader(false);
         return false;
      }

      const formData = new FormData();

      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }
      formData.append('id', userid);
      sellerProfile
         .sellerProfile(formData, page, lang)
         .then(response => {
            setInlineLoader(false);
            if (response.success) {
               const updatedPost = posts.concat(response.data.postdata);
               setPosts(updatedPost);
               setFilteredPosts(updatedPost)
            }
            else {
               setInlineLoader(false);
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setInlineLoader(false);
         })
   }

   // custom validations
   const checkErrorInRange = (minval, maxval) => {
      let errorss = '';
      if (maxval < minval) {
         errorss = t('To year must be greater or equals than from year');
      }
      if (maxval < 1500) {
         errorss = t('To year should be greater than or equals 1500');
      }
      if (minval > new Date().getFullYear()) {
         errorss = t('To year should be lesser than ') + new Date().getFullYear();
      }
      if (maxval.length < 4 || maxval.length > 4) {
         errorss = t('To year should be 4 digits');
      }
      if (maxval === '') {
         errorss = t('Please enter to year');
      }
      if (minval < 1500) {
         errorss = t('From year should be greater than or equals 1500');
      }
      if (minval > new Date().getFullYear()) {
         errorss = t('From year should be lesser than or equals ') + new Date().getFullYear();
      }
      if (minval.length < 4 || minval.length > 4) {
         errorss = t('From year should be 4 digits');
      }
      if (minval === '') {
         errorss = t('Please enter from year');
      }
      return errorss;
   }

   // get filters from server  
   const getFilterCategory = () => {
      categoryService.fiterMenu('All categories', lang)
         .then(res => {
            setIsLoaded(true);
            if (res.success) {
               setCategoryFilterData(res.data)
            }
         })
         .catch(err => {
            setIsLoaded(true);
            setCategoryFilterData([])
         })
   }

   useEffect(() => {
      sellerProfile.setToken(token);
      setIsLoaded(true);
      getProfile()
   }, [userid, filterOption])

   useEffect(() => {
      if (page > 1)
         fetchMorePosts();
   }, [page])

   // handle listing  locally 
   const handlefilter = (e) => {
      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = posts.filter(item => {
         return item.title.toLowerCase().includes(lowercasedFilter);
      });
      setFilteredPosts(filteredData)
   }

   // follow /unfollow handler api 
   const FollowUnfollowToggle = (id) => {
      sellerProfile.setToken(token);
      setIsLoaded(false);
      if (profile.followedbyme) {
         sellerProfile.unFollowUser(id)
            .then(res => {
               setIsLoaded(true);
               setProfile({
                  ...profile,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               })
               addToast(t('User Unfollowed'), { appearance: 'success' })
            }).catch(err => {
               setIsLoaded(true);
            })
      } else {
         sellerProfile.followUser(id)
            .then(res => {
               setIsLoaded(true);
               setProfile({
                  ...profile,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               })
               addToast(t('User Followed'), { appearance: 'success' })
            }).catch(err => {
               setIsLoaded(true);
            })
      }
      const newdata = { ...profile, followedbyme: !profile.followedbyme }
      setProfile(newdata);
   }

   // like /unlike handler api 
   const LikeUnlikeToggleButton = (postId) => {
      const featuredPost = filteredPosts.find(n => n.postid === postId)
      postsServices.setToken(token)
      setIsLoaded(false);
      if (featuredPost.likedbyme) {
         postsServices.unLikePost(postId)
            .then(res => {
               setIsLoaded(true);
               const changedPost = { ...featuredPost, likedbyme: res.data.likedbyme, likes: res.data.likes }
               setFilteredPosts(filteredPosts.map(post => parseInt(post.postid) !== parseInt(postId) ? post : changedPost));
               addToast(t('Post Unliked'), { appearance: 'success' })
            })
            .catch(err => {
               setIsLoaded(true);
            })
      } else {
         postsServices.likePost(postId)
            .then(res => {
               setIsLoaded(true);
               const changedPost = { ...featuredPost, likedbyme: res.data.likedbyme, likes: res.data.likes }
               setFilteredPosts(filteredPosts.map(post => parseInt(post.postid) !== parseInt(postId) ? post : changedPost));
               addToast(t('Post Liked'), { appearance: 'success' })
            })
            .catch(err => {
               setIsLoaded(true);
            })
      }
   }

   // show Followers handler 
   const handleFollowers = (e) => {
      setIsLoaded(false);
      sellerProfile
         .getFollowers(userid)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               // connectionData, setConnectionData
               setConnectionData({
                  ...connectionData,
                  title: 'Followers',
                  data: response.data,
               })
               setConnectionModal(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // show Followings handler 
   const handleFollowings = (e) => {
      setIsLoaded(false);
      sellerProfile
         .getFollowings(userid)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setConnectionData({
                  ...connectionData,
                  title: 'Followings',
                  data: response.data,
               })
               setConnectionModal(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // Open Chat box
   const goToChat = () => {

      if (profile?.thread_id) {
         navigate('/messages',
            { state: { thread_id: profile?.thread_id } }
         );

      } else {
         setPredefinedMessageModal(true);
      }
   }

   // handle custom text to chat 
   const handleCustomTextChange = (e) => {
      const { name, value: newValue, type } = e.target;
      const value = type === 'number' ? +newValue : newValue;
      setCustomMessageForm({
         ...customMessageForm,
         [name]: value,
      });
   }



   // set message to chat box
   const sendCustomMessage = (e) => {
      e.preventDefault();
      sendPredefinedMessage('CUSTOM', null)
   }

   // sent pd message to user 
   const sendPredefinedMessage = (type, message) => {
      let tempId = (Math.floor(Math.random() * 100) + 1).toString() + "_" + Date.now().toString();

      const formData = new FormData();
      formData.append(`unique_id`, tempId)
      if (type === "CUSTOM") {
         if (customMessageForm.message < 1) {
            addToast(t('Please type a message to send'), { appearance: 'error' })
         }

         formData.append(`message`, customMessageForm.message)
      }
      else {
         formData.append(`message`, message)
      }

      setIsLoaded(false);
      chatService.setToken(token)
      chatService
         .startUserChat(profile.id, formData)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setIsLoaded(true);
               const newThread = response.data
               console.log("newThread", newThread);
               socket.emit('newChatThread', newThread, (error) => {
                  if (error) {
                     alert(error);
                  }
               });

               setProfile({
                  ...profile,
                  thread_id: response.data.thread_id
               });

               setPredefinedMessageModal(false)
               addToast(t('Successfully sent your message to') + profile?.username, { appearance: 'success' })

            } else {
               addToast(response.message, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // local filter fro contries 
   const filterCountries = (e, options) => {
      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = options.filter(item => {
         return item.value.toLowerCase().includes(lowercasedFilter);
      });
      const newData = {
         id: e.currentTarget.dataset.filter_id,
         data: filteredData,
      }
      if (filteredList.length > 0) {
         let filter = filteredList.find(f => String(f.id) === String(e.currentTarget.dataset.filter_id));
         if (filter !== undefined) {
            var filtereddd = filteredList.map(f => String(f.id) === String(e.currentTarget.dataset.filter_id) ? newData : f);
            setFilteredList(filtereddd)
         } else {
            setFilteredList(filteredList.concat(newData))
         }
      } else {
         setFilteredList(filteredList.concat(newData))
      }
   }

   // onselect handler for filters 
   const handleFiltersSelect = (e) => {
      const id = e.target.dataset.filter_id;
      const value = e.target.dataset.filter_value;
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)

         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)

         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)

      }
   }

   // input handler for FROM 
   const handleFirstRangeInput = (id, value) => {
      value = value || '';
      setMinDate(value);
      const minval = value;
      const maxval = maxDate;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      let filter = filterOption.find(f => (String(f.id) === String(id)));
      if (typeof filter !== "undefined") {
         const filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (typeof filter !== "undefined") {
               const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)

            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)

            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)

         }
      }
   }

   // input handler for TO 
   const handleSecondRangeInput = (id, value) => {
      value = value || '';
      setMaxDate(value);
      const minval = minDate;
      const maxval = value;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      let filter = filterOption.find(f => (String(f.id) === String(id)));
      if (typeof filter !== "undefined") {
         var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (typeof filter !== "undefined") {
               const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)

            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)

            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)

         }
      }
   }

   // date range picker handler 
   const handleDatepickerValue = (event, picker) => {
      var id = event.currentTarget.dataset.filter_id;
      var startDate = picker.startDate.format('MM/DD/YYYY');
      var endDate = picker.endDate.format('MM/DD/YYYY');
      var value = `${startDate} to ${endDate}`;
      if (event.currentTarget.dataset.type === 'date') {
         value = picker.startDate.format('MM/DD/YYYY')
      }
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)

         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)


         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)

      }
   }

   const getValidUrl = (url = "") => {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");

      if (/^(:\/\/)/.test(newUrl)) {
         return `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
         return `http://${newUrl}`;
      }

      return newUrl;
   };

   // clear filter handler 
   const handleClearFilter = (e) => {
      setFilterOption([]);
      setFilteredList([]);
      setMinDate('');
      setMaxDate('');
      getProfile()
      setFilterCount(0)
   }

   // local listing filter by type  
   const HandleAdFilter = (adType) => {
      setCurrentItem(adType)
      if (adType === 'posts') {
         const new_data = posts;
         setFilteredPosts(new_data);
      } else if (adType === 'sold') {
         const new_data = posts?.filter(p => p.sold)

         setFilteredPosts(new_data);
      } else {
         setFilteredPosts(posts)
      }
   }



   return (
      <>
         {profile && (
            <SeoTags title={`Miltrade | Profile | ${isLoaded ? profile?.username : '--'} `}
               description={''}
               category={''}
               url={window.location.href}
               image={''}
            />
         )}
         {/* Start of header section */}
         <Header
            data={isHomePage}
            checkAuth={false}
         />
         <PageLoader isLoaded={isLoaded} />
         <div className="footer-bottom1">
            <div className="breadcrum other-seller-profile-breadcrum">
               <Container fluid>
                  <Row>
                     <Col xs={12} sm={6} md={6} lg={8}>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >Home</Breadcrumb.Item>
                           {isLoaded && (<Breadcrumb.Item active>{`${profile?.username} `}</Breadcrumb.Item>)}
                        </Breadcrumb>
                     </Col>
                     <Col xs={12} sm={6} md={6} lg={4}>
                        {(loginUser && parseInt(loginUser?.id) === parseInt(profile.id)) && (
                           <div className="invite-friend">
                              <p className='cp' onClick={() =>
                                 setShare(!share)} ><i className="icon-invite"></i>  Invite a friend and earn 3 credits
                                 <ShareIt share={share} shareUrl={process.env.REACT_APP_PUBLIC_URL} title="Register on Miltrade using this link and get refferal points." />
                              </p>
                           </div>
                        )}
                     </Col>
                  </Row>
               </Container>
            </div>
            {posts && (
               <>
                  <div className="seller-profile-page">
                     <Container fluid>
                        <div className="seller-profile-top-detail">
                           <Row>
                              <Col xs={12} sm={12} md={7} lg={5}>
                                 <div className="profile-detail">
                                    <Row className="no-gutters">
                                       <Col xs={6} sm={3} md={5} lg={3}>
                                          <div className='item-seller'>
                                             {profile?.issubscribed ? (
                                                <>
                                                   <div className="verified-dealer">
                                                      <Image src="../../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
                                                   </div>
                                                   <div className="verified-seller-profile">
                                                      <Image src={profile?.photo} alt={`${profile?.username} `} className="img-fluid" />
                                                   </div>
                                                </>
                                             ) : (
                                                <>
                                                   <div className="unverified-seller-profile">
                                                      <Image src={profile?.photo} alt={`${profile?.username} `} className="img-fluid" />
                                                   </div>
                                                </>
                                             )}
                                          </div>
                                       </Col>
                                       <Col xs={6} sm={6} md={7} lg={9}>
                                          <div className="seller-name">
                                             <h3>{`${profile?.username} `}</h3>
                                             <p>
                                                {profile?.issubscribed && (
                                                   <>
                                                      <Image src="/images/check_mark.png" alt="user" className="img-fluid" />
                                                      <span> {t('Verified Dealer')}</span>
                                                   </>
                                                )}
                                             </p>
                                             <h6> <i className="icon-location"></i>{profile?.address}</h6>
                                          </div>
                                          <div className="seller-btn">
                                             <ul>
                                                <li>
                                                   {parseInt(loginUser.id) !== parseInt(profile?.id) && (
                                                      loginUser ? (
                                                         <span onClick={() => FollowUnfollowToggle(profile.id)} className="cp">{profile.followedbyme ? t('Unfollow') : t('+ Follow')}</span>

                                                      ) : (
                                                         <span onClick={() => setShowLoginModal(true)} className="cp">{t('+ Follow')}</span>

                                                      )
                                                   )}
                                                </li>
                                                <li>
                                                   {parseInt(loginUser.id) !== parseInt(profile?.id) && (
                                                      loginUser ? (
                                                         <span onClick={goToChat} className="cp message-send">{t('Send message')}</span>

                                                      ) : (
                                                         <span onClick={() => setShowLoginModal(true)} className="cp message-send">{t('Send message')}</span>

                                                      )
                                                   )}
                                                </li>
                                             </ul>
                                          </div>
                                       </Col>
                                    </Row>
                                 </div>
                              </Col>
                              <Col xs={12} sm={5} md={5} lg={4}>
                                 <div className="seller-follow">
                                    <ul>
                                       <li className="cp" onClick={handleFollowers}>
                                          <p>{Encriptor.kformatter(profile?.followers)}</p>
                                          <span>{profile?.followers <= 1 ? t('Follower') : t('Followers')}</span>
                                       </li>
                                       <li className="cp" onClick={handleFollowings} >
                                          <p>{Encriptor.kformatter(profile?.followings)}</p>
                                          <span>{t('Following')}</span>
                                       </li>
                                    </ul>
                                 </div>
                              </Col>
                              <Col xs={12} sm={7} md={6} lg={3}>

                                 <div className="seller-review-section">
                                    <div className="review-text">
                                       <h4>{t('Review score')}: </h4>
                                       <p>{t('Communication')} </p>
                                       <p>{t('Description')}</p>
                                       <p>{t('Shipping')}</p>
                                       <Link to={`/seller/review/${Encriptor.encrypt(profile?.id)}`}>
                                          <div className="view-all-review">{t('view all reviews')} ({profile?.review_count})</div>
                                       </Link>
                                    </div>
                                    <div className="review-reating">
                                       <h5>
                                          <span><i className="fa fa-star" aria-hidden="true"></i> {profile?.averagerating}</span>
                                          <span className="total-review"> ({profile?.review_count})</span>
                                       </h5>
                                       <div className="review">
                                          <StarRatings
                                             rating={profile?.reviews?.avg_seller_communication}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             // changeRating={changeRating}
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </div>
                                       <div className="review">
                                          <StarRatings
                                             rating={profile?.reviews?.avg_item_as_described}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             // changeRating={changeRating}
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </div>
                                       <div className="review">
                                          <StarRatings
                                             rating={profile?.reviews?.avg_shipping_time}
                                             starRatedColor="#FFE156"
                                             starHoverColor="#FFE156"
                                             // changeRating={changeRating}
                                             numberOfStars={5}
                                             starSpacing="1px"
                                             starDimension="15px"

                                          />
                                       </div>
                                    </div>
                                 </div>

                              </Col>
                           </Row>
                        </div>
                     </Container>
                     {profile?.issubscribed && (
                        <Container fluid>
                           <div className="seller-contact-detail-services">
                              <Row className="no-gutters">
                                 <Col xs={12} sm={12} md={12} lg={8}>
                                    {(loginUser && parseInt(loginUser.id) === parseInt(profile?.id)) && (<div className="edit-info"><Link to="/profile/edit"> {t('Edit info')}</Link></div>)}
                                    <div className="seller-contact-information">
                                       <Row className="no-gutters">
                                          <Col xs={12} sm={12} md={12} lg={4}>
                                             <h4 className="title">{t('Contact information')}</h4>
                                             <div className="phone-no"><i className="fa fa-phone" aria-hidden="true"></i> <a href={`tell:${profile?.phone}`}> {profile?.phone}</a></div>
                                             <div className="email"><i className="icon-email" aria-hidden="true"></i> <a href={`mailto:${profile?.business_email}`}> {profile?.business_email}</a></div>
                                             <div className="address">
                                                <div className="icon">
                                                   <i className="icon-location"></i>
                                                </div>
                                                <div className="add">
                                                   <p>{profile?.business_address} {profile?.business_address_1}</p>

                                                </div>
                                             </div>
                                          </Col>
                                          <Col xs={12} sm={12} md={12} lg={8}>
                                             <h4 className="title">{t('About dealer')}</h4>
                                             <p className="desc">{profile?.business_description ? profile?.business_description : t('This is more text about ABC Militaria. We are located in Netherlands and specialized in restoring Willys Jeeps and other WW2 Vehicles. Please visit our website and feel free to contact us! all good by the way gou')}</p>
                                             {profile?.website && (
                                                <div className="website-link">
                                                   <a target="_blank" href={getValidUrl(profile?.website)} rel="noreferrer"><i className="fa fa-link" aria-hidden="true"></i> {profile?.website}</a>
                                                </div>
                                             )}
                                          </Col>
                                       </Row>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={12} lg={4}>
                                    <div className="seller-services-information">
                                       {(loginUser && parseInt(loginUser.id) === parseInt(profile?.id)) && (<div className="edit-info"><Link to="/profile/edit"> {t('Edit info')}</Link></div>)}
                                       <h4 className="title">{t('Services & Specialities')}</h4>
                                       {profile?.specialities.length > 0 && profile?.specialities.length > 0 ? (
                                          <>
                                             <div className="seller-services-information-wrapper">
                                                <div className="service-list">
                                                   <ul>
                                                      {profile?.services.slice(0, 4).map((service, i) => (
                                                         <li key={i}><i className="icon-right"></i> {service.title}</li>
                                                      )
                                                      )}

                                                      {profile?.services.length > 4 && (

                                                         <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                               <Tooltip id={`tooltip-more-services`}>
                                                                  <ul>
                                                                     {profile?.services.slice(4, profile?.services?.length).map((service, ik) => (
                                                                        <li key={ik}> <i className="icon-right"></i> {service.title}</li>
                                                                     )
                                                                     )}
                                                                  </ul>


                                                               </Tooltip>
                                                            }
                                                         >
                                                            <li className="more-services">+ {profile?.services.length - 4} {t('More')}</li>
                                                         </OverlayTrigger>
                                                      )}

                                                   </ul>
                                                </div>
                                                <div className="specialities-list">
                                                   <ul>
                                                      {profile?.specialities.slice(0, 4).map((service, i) => (
                                                         <li key={i}><i className="icon-right"></i> {service.title}</li>
                                                      )
                                                      )}
                                                      {profile?.specialities.length > 4 && (

                                                         <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                               <Tooltip id={`tooltip-more-services`}>
                                                                  <ul>
                                                                     {profile?.specialities.slice(4, profile?.specialities?.length).map((service, ik) => (
                                                                        <li key={ik}> <i className="icon-right"></i> {service.title}</li>
                                                                     )
                                                                     )}
                                                                  </ul>


                                                               </Tooltip>
                                                            }
                                                         >
                                                            <li className="more-services">+ {profile?.specialities.length - 4} More</li>
                                                         </OverlayTrigger>
                                                      )}

                                                   </ul>
                                                </div>
                                             </div>
                                          </>
                                       ) : (
                                          <div className="specialities-list1">
                                             <ul>
                                                <li className="text-center not-provided">  {t('Not provided')}</li>

                                             </ul>
                                          </div>
                                       )}

                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        </Container>
                     )}
                  </div>
                  <MobileFilter
                     setView={setCurrentViewType}
                     view={currentViewType}
                     categoryFilterData={categoryFilterData}
                     filterOption={filterOption}
                     filterCountries={filterCountries}
                     filterCount={filterCount}
                     handleClearFilter={handleClearFilter}
                     handleFiltersSelect={handleFiltersSelect}
                     filteredList={filteredList}
                     showNumberOfFilters={showNumberOfFilters}
                     setShowNumberOfFilters={setShowNumberOfFilters}
                     handleFirstRangeInput={handleFirstRangeInput}
                     handleSecondRangeInput={handleSecondRangeInput}
                     maxDate={maxDate}
                     minDate={minDate}
                     rangeError={rangeError}
                     handleDatepickerValue={handleDatepickerValue}
                     HandleAdFilter={HandleAdFilter}

                  />
                  <div className="verified-seller-profile-section seller-information-detail other-seller-pro">
                     <div className="filterby">


                        <div className="main-layout" id='layout'>
                           {isDesktop && (
                              <SideFilter
                                 categoryFilterData={categoryFilterData}
                                 filteredList={filteredList}
                                 filterOption={filterOption}
                                 filterCountries={filterCountries}
                                 showNumberOfFilters={showNumberOfFilters}
                                 handleFiltersSelect={handleFiltersSelect}
                                 setShowNumberOfFilters={setShowNumberOfFilters}
                                 handleFirstRangeInput={handleFirstRangeInput}
                                 minDate={minDate}
                                 maxDate={maxDate}
                                 handleSecondRangeInput={handleSecondRangeInput}
                                 rangeError={rangeError}
                                 handleDatepickerValue={handleDatepickerValue}
                                 handleClearFilter={handleClearFilter}
                                 leftOpen={leftOpen}
                                 toggleSidebar1={toggleSidebar1}
                                 categoryID={''}
                                 filterCount={filterCount}
                              />
                           )}
                           <div className="product-right-section">
                              <div className='content'>
                                 {isDesktop && (
                                    <div className="mb-0">
                                       <Row className="no-gutters-row-only">
                                          <Col xs={12} sm={6} md={7} lg={6}>
                                             <div className="ads-btn">
                                                <Link onClick={() =>
                                                   HandleAdFilter('posts')} className={currentItem === 'posts' ? 'link btn btn-primary ads active' : 'link'}  >{`${posts?.length <= 1 ? t('Listing') : t('Listings')} (${posts?.length}) `}</Link>
                                                <Link to={`/seller/review/${Encriptor.encrypt(profile?.id)}`} className={currentItem === 'reviews' ? 'link btn btn-primary active' : 'link'}  >{`${profile?.review_count <= 1 ? t('Review') : t('Reviews')} (${profile?.review_count})`}</Link>
                                             </div>
                                          </Col>

                                          {currentItem === "posts" && (
                                             <>
                                                <Col xs={12} sm={12} md={2} lg={2}>
                                                   <div className="grid-list-view">
                                                      <ul>
                                                         <li>
                                                            <span onClick={() =>
                                                               setCurrentViewType('grid')} className={`link ads ${currentViewType === 'grid' ? 'active' : ''}`}  ><i className="icon-grid_view"></i></span>
                                                         </li>
                                                         <li>
                                                            <span onClick={() =>
                                                               setCurrentViewType('list')} className={`link ads ${currentViewType === 'list' ? 'active' : ''} ml-3`}  ><i className="icon-list_view"></i></span>
                                                         </li>
                                                      </ul>
                                                   </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={4}>
                                                   <div className="search-seller-post">
                                                      <Form>
                                                         <div className="input-group">
                                                            <input type="text" className="form-control" value={filterString} onChange={handlefilter} placeholder="Search by profile posts" />
                                                            <div className="input-group-append">
                                                               <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="icon-search"></i></button>
                                                            </div>
                                                         </div>
                                                      </Form>
                                                   </div>
                                                </Col>
                                             </>
                                          )}
                                       </Row>
                                    </div>
                                 )}
                                 <div className="all-product">
                                    <MyPosts
                                       posts={filteredPosts}
                                       LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                                       view={currentViewType}
                                       goToChat={goToChat}
                                       setPage={setPage}
                                       setInlineLoader={(val) => { setInlineLoader(val) }}
                                       inlineLoader={inlineLoader}
                                    />


                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </>
            )}
            {connectionData && (
               <Connections
                  title={connectionData?.title}
                  data={connectionData?.data}
                  connectionModal={connectionModal}
                  setConnectionModal={setConnectionModal}
               />
            )}


            {/* // Suggestion modal */}
            <Modal
               size="md"
               show={predefinedMessageModal}
               onHide={() =>
                  setPredefinedMessageModal(false)}
               aria-labelledby="example-modal-sizes-title-lg"
            >
               <Modal.Header closeButton>
                  <h4>{t('Send a message to')} {profile?.username} </h4>
               </Modal.Header>

               <Modal.Body>
                  <div className="report-ad-modal">
                     <Form onSubmit={sendCustomMessage}>
                        <Form.Group controlId="exampleForm.ControlTextarea1" className="mt-3">
                           <Form.Label>{t(' ')} </Form.Label>
                           <Form.Control onChange={handleCustomTextChange} name="message" value={customMessageForm.message} as="textarea" rows={5} />
                        </Form.Group>
                        <div className="custom-message-btn text-center mt-3"><Button variant="primary" type="submit">{t('Send message')}</Button></div>
                     </Form>
                  </div>
               </Modal.Body>

            </Modal>
         </div>
         <Footer />
      </>
   )
}

export default SellerReviews;
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ListView from './listView';
import Image from 'react-bootstrap/Image';
import GridView from './gridView';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
// import { Spinner } from 'react-bootstrap';


const MyPosts = ({ view, posts, LikeUnlikeToggleButton, goToChat, setPage, setInlineLoader, inlineLoader }) => {
    // Observer to handle scroll to bottom 
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            console.log(target.isIntersecting);
              setInlineLoader(true);
              setPage((prv) => (prv + 1));
        }
    }, []);
    const loader = useRef(null);
    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    return (
        <>
            {posts?.length > 0 ? (
                view === 'grid' ?
                    (<GridView posts={posts}
                        LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                        goToChat={goToChat}


                    />) :
                    (<ListView posts={posts}
                        LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                        goToChat={goToChat}


                    />)
            ) : (
                <>
                    <div className="text-center">
                        <Image src="../../images/no_ads.webp" className="img-fluid" alt="user" />
                        {/* <p>No Item Found..</p> */}
                    </div>
                </>
            )}
            {/* {inlineLoader && (
                <div className={`inline-loader`}>
                    <Spinner animation="border" variant="success" />
                </div>
            )} */}
            <div ref={loader} />
        </>
    )


}

export default MyPosts;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../assets/css/sidebar.css";
import Header from "../include/header";
import Footer from "../include/footer";
import Encriptor from "../../services/encriptor"
import dealerServices from "../../services/sellerProfile"
import dealerFilterService from "../../services/productCategories"
import PageLoader from '../pageLoader'
import Connections from '../comman/connections/connection'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
   isDesktop,
   isMobile,

} from "react-device-detect";
import SeoTags from '../seo/seoTags'
import { Auth } from '../../Contexts/AuthContext';
import DealersMobileFilter from '../comman/dealersMobileFilter';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DealersSideFilter from '../comman/DealersSideFilter';



var isHomePage = false;
const Dealers = () => {
   const { setMenuCategory } = useContext(Auth);
   const [sidebar, setSidebar] = useState({
      leftOpen: isMobile ? false : true,
      // rightOpen: true,
   });
 
   var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;
   const toggleSidebar1 = (event) => {
      let key = `leftOpen`;
      setSidebar({ [key]: !sidebar[key] });
   }
   let leftOpen = sidebar.leftOpen ? 'open' : 'closed';
   const [services, setServices] = useState();
   const [fiteredServices, setFiteredServices] = useState();
   const [specialities, setSpecialities] = useState();
   const [fiteredSpecialities, setFiteredSpecialities] = useState();
   const [countries, setCountries] = useState();
   const [dealers, setDealers] = useState([]);
   const [allData, setAllData] = useState();
   const [loaded, setLoaded] = useState(false);
   const [showNumberOfFilters, setShowNumberOfFilters] = useState(6);

   const [filterOption, setFilterOption] = useState([]);
   const [filterTOSString, setFilterTOSString] = useState('');
   const [filterSpecialitiesString, setFilterSpecialitiesString] = useState('');
   const [filterString, setFilterString] = useState('');
   const [connectionData] = useState();
   const [connectionModal, setConnectionModal] = useState(false);
   const [filterCount, setFilterCount] = useState(0);
   const [page, setPage] = useState(1);
   const [sortBy, setSortBy] = useState('');
   const [inlineLoader, setInlineLoader] = useState(false);
   const loader = useRef(null);
   const { t } = useTranslation();
   // get dealers from server on initial call 
   const getDealers = () => {
      setLoaded(false);
      const formData = new FormData();
      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            if (element.id === "services") {
               formData.append(`service_id[]`, element.value);
            }
            if (element.id === "specialities") {
               formData.append(`speciality_id[]`, element.value);
            }
            if (element.id === "country") {
               formData.append(`country_code[]`, element.value);
            }
         }
      }
      if (page) {
         formData.append(`page`, page);
      }
      if (sortBy) {
         formData.append(`sortby`, sortBy);
      }
      dealerFilterService.dealerFilter(formData)
         .then(res => {
            setLoaded(true);
            if (res.success)
               setDealers(res.data)


         })
         .catch(err => {
            setLoaded(true);
         })
   }

   // On sroll end fetch more dealers from server 
   const fetchMoreDealers = () => {
      if (page > dealers?.last_page) {
         setInlineLoader(false);
         return false;
      }
      const formData = new FormData();
      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            if (element.id === "services") {
               formData.append(`service_id[]`, element.value);
            }
            if (element.id === "specialities") {
               formData.append(`speciality_id[]`, element.value);
            }
            if (element.id === "country") {
               formData.append(`country_code[]`, element.value);
            }
         }
      }
      if (page) {
         formData.append(`page`, page);
      }
      if (sortBy) {
         formData.append(`sortby`, sortBy);
      }

      dealerFilterService.dealerFilter(formData)
         .then(res => {
            setInlineLoader(false);
            if (res.success)
               setDealers(res.data)
         })
         .catch(err => {
            setInlineLoader(false);
            // console.log('eooer');
         })
   }

   useEffect(() => {
      if (page > 1)
         fetchMoreDealers()
   }, [page])

   useEffect(() => {
      getDealers()
   }, [filterOption, sortBy])

   useEffect(() => {
      setMenuCategory('dealers');
      dealerServices.setToken(token);
      dealerServices.dealerService()
         .then(res => {
            setLoaded(true);
            if (res.success) {
               setAllData(res.data);
               setServices(res.data.services);
               setFiteredServices(res.data.services);
               setSpecialities(res.data.specialities);
               setFiteredSpecialities(res.data.specialities);
               setCountries(res.data.countries)
            }
         })
         .catch(err => {
            setLoaded(true);
         })

      window.scrollTo(0, 0);
   }, [])

   // dynamic filters handling 
   const handleFiltersSelect = (e) => {
      const id = e.target.dataset.filter_id;
      const value = e.target.dataset.filter_value;
      const newData = {
         id: id,
         value: value,
      }

      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterCount(filtereddd.length)
            setFilterOption(filtereddd);

         }
         else {
            setFilterOption(filterOption.concat(newData));

            setFilterCount(filterOption.concat(newData).length)
         }
      } else {
         setFilterOption(filterOption.concat(newData));

         setFilterCount(filterOption.concat(newData).length)
      }



   }

   // local country filter handling
   const filterCountries = (e) => {
      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = allData.countries.filter(item => {
         return item.name.toLowerCase().includes(lowercasedFilter);
      });
      setCountries(filteredData);
   }

   // local TOS filter handling
   const filterTOS = (e) => {
      var str = e.target.value || '';
      setFilterTOSString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = services.filter(item => {
         return item.title.toLowerCase().includes(lowercasedFilter);
      });
      setFiteredServices(filteredData);
   }

   // local specialities filter handling
   const filterSpecialities = (e) => {
      var str = e.target.value || '';
      setFilterSpecialitiesString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = specialities.filter(item => {
         return item.title.toLowerCase().includes(lowercasedFilter);
      });
      setFiteredSpecialities(filteredData);
   }

   // handle sortby
   const handleSortBy = (sortBy) => {
      setSortBy(sortBy);
   }

   // handle clear filter 
   const handleClearFilter = (e) => {
      setPage(1);
      setFilterOption([]);
      setSortBy('')
      setFilterCount(0)

   }

   // observer to handle on scroll to ref  
   const handleObserver = useCallback((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
         setInlineLoader(true);
         setPage((prv) => (prv + 1));
      }
   }, []);

   useEffect(() => {
      const option = {
         root: null,
         rootMargin: "20px",
         threshold: 0
      };
      const observer = new IntersectionObserver(handleObserver, option);
      if (loader.current) observer.observe(loader.current);
   }, [handleObserver]);



   return (
      <>
         <SeoTags title={`Miltrade | Dealers & Services`}
            description={''}
            category={''}
            url={window.location.href}
            image={''}
         />
         <Header
            data={isHomePage}
            checkAuth={false}

         />
         <DealersMobileFilter
            sortBy={sortBy}
            filterOption={filterOption}
            filterCountries={filterCountries}
            handleClearFilter={handleClearFilter}
            handleFiltersSelect={handleFiltersSelect}
            showNumberOfFilters={showNumberOfFilters}
            setShowNumberOfFilters={setShowNumberOfFilters}
            filterCount={filterCount}
            countries={countries}
            filterString={filterString}
            filterTOSString={filterTOSString}
            filterTOS={filterTOS}
            fiteredServices={fiteredServices}
            filterSpecialities={filterSpecialities}
            filterSpecialitiesString={filterSpecialitiesString}
            fiteredSpecialities={fiteredSpecialities}
            handleSortBy={handleSortBy}
         />
         <PageLoader isLoaded={loaded} />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                           <Breadcrumb.Item active>{t("Dealers & Services")}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="main-layout" id='layout'>
               {isDesktop && (
                  <DealersSideFilter
                     sortBy={sortBy}
                     filterOption={filterOption}
                     filterCountries={filterCountries}
                     handleClearFilter={handleClearFilter}
                     handleFiltersSelect={handleFiltersSelect}
                     showNumberOfFilters={showNumberOfFilters}
                     setShowNumberOfFilters={setShowNumberOfFilters}
                     filterCount={filterCount}
                     countries={countries}
                     filterString={filterString}
                     filterTOSString={filterTOSString}
                     filterTOS={filterTOS}
                     fiteredServices={fiteredServices}
                     filterSpecialities={filterSpecialities}
                     filterSpecialitiesString={filterSpecialitiesString}
                     fiteredSpecialities={fiteredSpecialities}
                     handleSortBy={handleSortBy}
                     toggleSidebar={toggleSidebar1}
                     leftOpen={leftOpen}
                  />
               )}
               <div className="product-right-section">
                  <div className='content'>
                     {isDesktop && (
                        <div className="mb-0">
                           <Row className="no-gutters-row-only">
                              <Col xs={12} sm={12} md={12} lg={12} className=" mb-4">
                                 <div className="vendor-directory right-filter-search">
                                    <div className="sort-by-search">
                                       <Form>
                                          <span className="dropdown-icon"></span>
                                          <span className="flter-sort-by"><i className="icon-sort"></i></span>
                                          <Form.Group onChange={handleSortBy} controlId="exampleForm.ControlSelect1 cp">
                                             <Form.Control onChange={(e) => { handleSortBy(e.target.value) }} defaultValue={''} as="select">
                                                <option value="" disabled hidden>{t("Sort By")}</option>
                                                <option value="dealer name A-Z">{t("Dealer name A-Z")}</option>
                                                <option value="dealer name Z-A">{t("Dealer name Z-A")}</option>
                                                <option value="country A-Z">{t("Country A-Z")}</option>
                                                <option value="country Z-A">{t("Country Z-A")}</option>
                                                <option value="reviews ascending">{t("Reviews ascending")}</option>
                                                <option value="reviews descending">{t("Reviews descending")}</option>
                                             </Form.Control>
                                          </Form.Group>
                                       </Form>
                                    </div>
                                 </div>
                              </Col>
                           </Row>
                        </div>
                     )}
                     <div className='all-product'>
                        {dealers?.data?.length > 0 ? (
                           <>
                              <div className="vendor-item-box">

                                 <>
                                    <Row className="wrapper-dealer-card-box">
                                       {dealers?.data?.map((dealer, i) => (
                                          <Col key={i} xs={12} sm={6} md={6} lg={4}>
                                             <div className="dealer-card-box">
                                                <Row>
                                                   <Col xs={3} sm={5} md={3} lg={3} className="p-0 pl-1">

                                                      <div className="item-seller">
                                                         {dealer?.issubscribed && (
                                                            <div className="verified-dealer">
                                                               <img src="../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
                                                            </div>
                                                         )}
                                                         <div className={`seller-image-${dealer?.issubscribed ? `${t("verified")}` : `${t("unverified")}`}`}>
                                                            <Link to={`/seller/profile/${Encriptor.encrypt(dealer?.id)}`} >
                                                               <Image src={dealer?.photo} alt={`${dealer?.company_name}`} className="img-fluid" />
                                                            </Link>  </div>
                                                      </div>

                                                   </Col>
                                                   <Col xs={5} sm={7} md={5} lg={5} className="p-0">
                                                      <div className="seller-desc ">
                                                         <div className="name">
                                                            <Link to={`/seller/profile/${Encriptor.encrypt(dealer?.id)}`} >
                                                               <OverlayTrigger
                                                                  placement="bottom"
                                                                  delay={{ show: 250, hide: 400 }}
                                                                  overlay={
                                                                     <Tooltip id={`tooltip-${dealer?.id}`}>
                                                                        {`${dealer?.company_name}`}
                                                                     </Tooltip>
                                                                  }
                                                               >
                                                                  {(dealer?.company_name?.length) > 13 ? (

                                                                     <div className="">

                                                                        {`${dealer?.company_name}`.slice(0, 13)} ...

                                                                     </div>

                                                                  ) : (
                                                                     <div className="">
                                                                        {`${dealer?.company_name}`}
                                                                     </div>
                                                                  )}
                                                               </OverlayTrigger>

                                                            </Link>
                                                         </div>
                                                         {dealer?.issubscribed ? (<h6>{t("Verified Dealer")}</h6>) : (<h6 className="private-seller" >{t("Private Seller")}</h6>)}
                                                         <div className="view">
                                                            <div className="locat">
                                                               <i className="icon-location" aria-hidden="true"></i>
                                                               <OverlayTrigger
                                                                  placement="bottom"
                                                                  delay={{ show: 250, hide: 400 }}
                                                                  overlay={
                                                                     <Tooltip id={`tooltip-${dealer?.id}`}>
                                                                        {`${dealer?.address}`}
                                                                     </Tooltip>
                                                                  }
                                                               >
                                                                  {(dealer?.address?.length) > 14 ? (
                                                                     <span>
                                                                        {`${dealer?.address}`.slice(0, 14)} ...
                                                                     </span>

                                                                  ) : (
                                                                     <span>
                                                                        {`${dealer?.address}`}
                                                                     </span>
                                                                  )}
                                                               </OverlayTrigger>

                                                            </div>
                                                         </div>
                                                      </div>
                                                   </Col>
                                                   <Col xs={4} sm={12} md={4} lg={4} className="">
                                                      <div className="view">
                                                         <div className="profile-visit"><Link to={`/seller/profile/${Encriptor.encrypt(dealer?.id)}`} >Visit Profile</Link></div>
                                                      </div>
                                                      <div className="seller-desc">
                                                         <div className="star-rating">
                                                            <Link ><i className="icon-star_fill" aria-hidden="true"></i> <span> {dealer?.rating} </span></Link> ({Encriptor.kformatter(dealer?.review_count)})
                                                         </div>
                                                      </div>
                                                   </Col>
                                                   <Col xs={12} sm={12} md={12} lg={12} className="">
                                                      <div className="dealer-services">
                                                         <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} className="dealer-service-left p-0">
                                                               <div>
                                                                  <h4>{t("Services")}</h4>
                                                                  {(dealer?.services && dealer?.services.length > 0) ? (
                                                                     <ul>
                                                                        {dealer?.services.slice(0, 2).map((service, i) => (
                                                                           <li key={i}><i className="icon-right"></i> {service.title}</li>
                                                                        )
                                                                        )}
                                                                        {dealer?.services.length > 2 && (
                                                                           <OverlayTrigger
                                                                              placement="bottom"
                                                                              delay={{ show: 250, hide: 400 }}
                                                                              overlay={
                                                                                 <Tooltip id={`tooltip-more-services`}>
                                                                                    <ul>
                                                                                       {dealer?.services.slice(2, dealer?.services?.length).map((service, ik) => (
                                                                                          <li key={ik}> <i className="icon-right"></i> {service.title}</li>
                                                                                       )
                                                                                       )}
                                                                                    </ul>


                                                                                 </Tooltip>
                                                                              }
                                                                           >
                                                                              <li className="more-services">+ {dealer?.services.length - 2} {t("More")}</li>
                                                                           </OverlayTrigger>

                                                                        )}
                                                                     </ul>
                                                                  ) : (
                                                                     <ul>
                                                                        <li className="text-center not-provided"> {t("Not provided")}</li>

                                                                     </ul>
                                                                  )}

                                                               </div>
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} className="dealer-service-right p-0">
                                                               <div>
                                                                  <h4>{t("Specialties")}</h4>
                                                                  {(dealer?.specialities && dealer?.specialities.length > 0) ? (
                                                                     <ul>
                                                                        {dealer?.specialities.slice(0, 2).map((service, i) => (
                                                                           <li key={i}><i className="icon-right"></i> {service.title}</li>
                                                                        )
                                                                        )}
                                                                        {dealer?.specialities.length > 2 && (

                                                                           <OverlayTrigger
                                                                              placement="bottom"
                                                                              delay={{ show: 250, hide: 400 }}
                                                                              overlay={
                                                                                 <Tooltip id={`tooltip-more-services`}>
                                                                                    <ul>
                                                                                       {dealer?.specialities.slice(2, dealer?.specialities?.length).map((service, ik) => (
                                                                                          <li key={ik}> <i className="icon-right"></i> {service.title}</li>
                                                                                       )
                                                                                       )}
                                                                                    </ul>


                                                                                 </Tooltip>
                                                                              }
                                                                           >
                                                                              <li className="more-services">+ {dealer?.specialities.length - 2} More</li>
                                                                           </OverlayTrigger>


                                                                        )}
                                                                     </ul>
                                                                  ) : (
                                                                     <ul>
                                                                        <li className="text-center not-provided"> {t("Not provided")}</li>
                                                                     </ul>
                                                                  )}

                                                               </div>
                                                            </Col>
                                                         </Row>
                                                      </div>
                                                   </Col>
                                                </Row>
                                             </div>
                                          </Col>
                                       ))}
                                    </Row>
                                 </>

                              </div>
                           </>
                        ) : (
                           <>
                              <div className="text-center">
                                 <Image src="../images/no_dealer.webp" className="img-fluid" alt="user" />
                              </div>
                           </>
                        )}
                     </div>
                  </div>
                  {inlineLoader && (
                     <div className={`inline-loader`}>
                        <Spinner animation="border" variant="success" />
                     </div>
                  )}
                  <div ref={loader} />

               </div>
            </div>

            {connectionData && (
               <Connections
                  title={connectionData?.title}
                  data={connectionData?.data}
                  connectionModal={connectionModal}
                  setConnectionModal={setConnectionModal}
               />
            )}
         </div>
         <Footer />
      </>
   );
}
export default Dealers;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useLocation, useNavigate } from "react-router-dom";

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from "./include/header";
import Footer from "./include/footer";

import { useToasts } from 'react-toast-notifications'
import PageLoader from './pageLoader'
import SeoTags from './seo/seoTags'
import registrationServices from "../services/registration";
import { Auth } from '../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';


var isHomePage = false;
const ResetPassword = () => {
      const { t } = useTranslation();
      const { setShowLoginModal } = useContext(Auth);
      const [resetPasswordForm, setResetPasswordForm] = useState({
            password: '',
            passwordConfirmation: '',
      });
      let location = useLocation();
      const navigate = useNavigate();
      const [hideShow, setHideShow] = useState(false);
      const [validationError, setValidationError] = useState();
      const [loaded, setLoaded] = useState(true);
      const urlParams = new URLSearchParams(location.search)
      var resetToken = urlParams.get('_tn');
      var resetEmail = urlParams.get('_ac');
      const loginUserCheck = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
      const { addToast } = useToasts();

      useEffect(() => {
            if (loginUserCheck) {
                  navigate('/');
            }

      }, [loginUserCheck])

      // handle onChange 
      const handleChange = (e) => {
            const { name, value: newValue, type } = e.target;
            const value = type === 'number' ? +newValue : newValue;
            setResetPasswordForm({
                  ...resetPasswordForm,
                  [name]: value,
            });
      }

      const handleSubmit = (e) => {
            e.preventDefault();

            var resetPass = {
                  token: resetToken,
                  email: resetEmail,
                  password: resetPasswordForm ? resetPasswordForm.password : '',
                  password_confirmation: resetPasswordForm ? resetPasswordForm.passwordConfirmation : '',
            }
            setLoaded(false)
            registrationServices
                  .resetPassword(resetPass)
                  .then(response => {
                        resetPass = {};
                        setResetPasswordForm();
                        setLoaded(true)
                        addToast(response.msg, { appearance: 'success' })

                        navigate('/')
                  })
                  .catch(error => {
                        setLoaded(true);
                        if (error.response) {
                              if (error.response.status === 422) {
                                    setValidationError(
                                          error.response.data.errors
                                    )
                              }
                              else {
                                    addToast(`${error.response.data.msg} ${t("Please check your email link again")}`, { appearance: 'error' })

                                    navigate('/')
                              }
                        }
                  })


      }

      //check validation error for element 
      const hasErrorFor = (field) => {
            if (validationError) {
                  const error = validationError.find(element => String(element.field) === String(field));
                  if (error)
                        return true;
            } else {
                  return false;
            }
      }

      //render element`s validation error
      const renderErrorFor = (field) => {
            if (hasErrorFor(field)) {
                  const error = validationError.find(element => String(element.field) === String(field));
                  return (
                        <div className="error-wrapper">
                              <span className='invalid-feedback'>
                                    <strong>{error.message}</strong>
                              </span>
                        </div>
                  )
            }
      }





      return (
            <>
                  <SeoTags title={`Miltrade | Sigin in`}
                        description={''}
                        category={''}
                        url={window.location.href}
                        image={''}
                  />
                  {/* Start of header section */}
                  <Header
                        data={isHomePage}
                        checkAuth={false}

                  />

                  {/* end of header section */}
                  <div className="footer-bottom1">
                        <div className="breadcrum">
                              <Container fluid>
                                    <Row>
                                          <Col>
                                                <Breadcrumb>
                                                      <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                                                      <Breadcrumb.Item active>{t("Sign In")}</Breadcrumb.Item>
                                                </Breadcrumb>
                                          </Col>
                                    </Row>
                              </Container>
                        </div>
                        <PageLoader isLoaded={loaded} />
                        <div className="contact_us_section">
                              <Container>
                                    <Row>
                                          <Col xs={12} sm={12} md={12} lg={3}>
                                                {/* <Image  className="blog-image" src="/images/slider-image/opening_up_the_border.webp" alt="image" className="img-fluid" />    */}

                                          </Col>
                                          <Col xs={12} sm={12} md={12} lg={6}>
                                                <div className="login-modal">

                                                      <div className="modal-body-heading">
                                                            <h3 className="mt-3">{t("Reset Password")}</h3>
                                                            <p>{t("Set your new password below")}.</p>
                                                      </div>
                                                      <div className="login-form">
                                                            <Form >
                                                                  <div className="form-group">
                                                                        <div className="material-textfield mt-4">
                                                                              <span className="icon-password"></span>
                                                                              <input placeholder=" "
                                                                                    id="password"
                                                                                    type={hideShow ? 'text' : 'password'}
                                                                                    name="password"
                                                                                    autoComplete="off"
                                                                                    className={`${hasErrorFor('password') ? 'is-invalid' : ''}`}
                                                                                    value={resetPasswordForm ? resetPasswordForm.password : ""}
                                                                                    onChange={handleChange} />
                                                                              <label >{t("Enter your password")}</label>
                                                                              <div onClick={() => setHideShow(!hideShow)} className="eye-icon cp"><i className={hideShow ? 'icon-eye_hide field-icon' : 'icon-eye field-icon'}></i></div>
                                                                        </div>
                                                                        {renderErrorFor('password')}
                                                                  </div>
                                                                  <div className="form-group">
                                                                        <div className="material-textfield mt-4">
                                                                              <span className="icon-password"></span>
                                                                              <input placeholder=" "
                                                                                    name="passwordConfirmation"
                                                                                    className={`${hasErrorFor('password_confirmation') ? 'is-invalid' : ''}`}
                                                                                    id="password_confirmation"
                                                                                    onChange={handleChange}
                                                                                    autoComplete="off"
                                                                                    value={resetPasswordForm ? resetPasswordForm.passwordConfirmation : ""}
                                                                                    type="password" />
                                                                              <label>{t("Confirm your password")}</label>
                                                                        </div>
                                                                        {renderErrorFor('password_confirmation')}
                                                                  </div>
                                                                  <div className="large-button mt-5"><Button variant="primary" onClick={handleSubmit}> Send</Button></div>
                                                            </Form>
                                                            <div className="social-login">
                                                                  <h5 className="mt-4">{t("Remember Password")} ? <a onClick={() => { setShowLoginModal(true) }} href="#">sign In</a></h5>
                                                            </div>
                                                      </div>

                                                </div>
                                                <Col xs={12} sm={12} md={12} lg={3}></Col>


                                          </Col>
                                    </Row>
                              </Container>

                        </div>
                  </div>
                  <Footer />
            </>
      );
}
export default ResetPassword;
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams, Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Header from "../../include/header";
import Footer from "../../include/footer";
import postsService from '../../../services/posts';
import reportService from '../../../services/report';
import sellerProfile from '../../../services/sellerProfile';
import postsServices from '../../../services/posts';
import Encriptor from "../../../services/encriptor";
import { useToasts } from 'react-toast-notifications'
import ProductDetailsTable from './productDetailsTable'
import ProductImages from './productImages'
import SimiliarAds from './similiarAds'
import RecentVisitedAds from './recentVisitedAds'
import chatService from "../../../services/chat"
import PageLoader from '../../pageLoader'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ShareIt from '../../shareIt/share'
import SeoTags from '../../seo/seoTags'
import { io } from "socket.io-client";
import { Auth } from '../../../Contexts/AuthContext';
import { t } from 'i18next';
import { langContext, useLangContext } from '../../../Contexts/langContext';



const ProductDetails = ({ setUserProfileData, userProfileData }) => {
   const { setShowLoginModal } = useContext(Auth);
   const { lang } = useLangContext(langContext);
   const [reportForm, setReportForm] = useState({
      comment: '',
      reason_id: '',
   });
   const [customMessageForm, setCustomMessageForm] = useState({
      message: ''
   });
   let ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;
   // const io = require("socket.io-client");

   var socket = io(ENDPOINT, {
      transports: ['websocket']
   });
   // var socket = io("http://127.0.0.1:5000");
   const [titleTxtcounter, setTitleTxtcounter] = useState(0);
   const [reportModal, setReportModal] = useState(false);
   const [reportReasons, setReportReasons] = useState([]);

   // const [predefinedMessage, setPredefinedMessage] = useState([]);
   const [predefinedMessageModal, setPredefinedMessageModal] = useState(false);
   const [phoneNumberModal, setphoneNumberModal] = useState(false);
   const [loaded, setLoaded] = useState(false);
   const [postData, setPostData] = useState('');
   const [similiarPosts, setSimiliarPosts] = useState(null);
   const [recentVisitedAds, setRecentVisitedAds] = useState();
   const [share, setShare] = useState(false);
   const [phoneShow, setPhoneShow] = useState(false);
   const [phoneClass, setPhoneClass] = useState(false);
   const { addToast } = useToasts();
   const navigate = useNavigate();
   var isHomePage = false;
   const { id } = useParams();
   const PostId = id;
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   const token = loginUser ? loginUser.access_token : null;
   if (loginUser?.id) {
      postsService.setToken(token);
   }

   useEffect(() => {
      getProductDetail(PostId);
      // if(token){
      //    getRecentVisitedPosts();
      // }
   }, [PostId])

   // get listing data 
   const getProductDetail = async (PostId) => {
      await postsService
         .getPost(PostId, lang)
         .then(response => {
            if (response.success) {
               // getSimilarPosts(PostId);
               setPostData(response.data);
               setRecentVisitedAds(response.data?.recent_visits);
               setSimiliarPosts(response.data?.similar_ads);
               // setPredefinedMessage(response.data?.predefined_message);
               setCustomMessageForm({
                  ...customMessageForm,
                  message: t('Hello') + ', ' + response.data?.user?.username + ' \n',
               });
               setLoaded(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }
            window.scrollTo(0, 0);
         })
         .catch(error => {
            setLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else if (error.response.status === 400 && error.response.data.not_listing_found) {
                  navigate('/listing-not-found');
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }

   // get simiilar Listings 
   // const getSimilarPosts = async (PostId) =>{
   // // postsService.setToken(token);
   //    setLoaded(false);
   //    await postsService
   //    .similarAds(PostId)
   //    .then(response => {
   //    if(response.success){
   //       setSimiliarPosts(response.data);
   //          setLoaded(true);
   //       }else
   //       {
   //          addToast(response.message,{ appearance: 'error' })
   //       }
   //    })
   //    .catch(error => {
   //       if(error.response){
   //          if(error.response.status === 422){
   //             addToast(error.response.data.data, { appearance: 'error' })
   //          }
   //          else
   //          {
   //             addToast(error.response.data.message, { appearance: 'error' })
   //          }
   //          }
   //       })
   // }

   // get recent visited listing
   // const getRecentVisitedPosts = async () =>{
   //    setLoaded(false);
   //    postsService.setToken(token);
   //    await postsService
   //    .recentVisitedPosts()
   //    .then(response => {
   //    if(response.success){
   //       setRecentVisitedAds(response.data);
   //          setLoaded(true);
   //       }else
   //       {
   //          addToast(response.message,{ appearance: 'error' })
   //       }
   //    })
   //    .catch(error => {
   //    setLoaded(true);
   //    if(error.response){
   //       if(error.response.status === 422){
   //          addToast(error.response.data.data, { appearance: 'error' })
   //       }
   //       else
   //       {
   //          addToast(error.response.data.message, { appearance: 'error' })
   //       }
   //       }
   //    })
   // }



   // redirect to featured post
   const goToFetured = (e) => {
      e.preventDefault();
      navigate(`/featured/${PostId}`)
   }


   // Report Listing form data handler
   const handleFromChange = (e) => {
      const { name, value: newValue, type } = e.target;
      const value = type === 'number' ? +newValue : newValue;
      if (name === "comment") {
         if (value.length <= 300) {
            setTitleTxtcounter(value.length);
            setReportForm({
               ...reportForm,
               [name]: value,
            });
         }
      } else {
         setReportForm({
            ...reportForm,
            [name]: value,
         });
      }

   }

   const handleCustomTextChange = (e) => {
      const { name, value: newValue, type } = e.target;
      const value = type === 'number' ? +newValue : newValue;
      setCustomMessageForm({
         ...customMessageForm,
         [name]: value,
      });
   }


   // Like  Unlike handlers 
   const LikeUnlikeToggleButton = () => {
      setLoaded(false);
      postsServices.setToken(token)
      if (postData.likedbyme) {
         postsServices.unLikePost(postData.postid)
            .then(res => {
               setLoaded(true);
               setPostData({
                  ...postData,
                  likedbyme: res.data.likedbyme,
                  likes: res.data.likes,
               });
               addToast(t('Listing Unliked'), { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
            })
      } else {
         postsServices.likePost(postData.postid)
            .then(res => {
               setLoaded(true);
               setPostData({
                  ...postData,
                  likedbyme: res.data.likedbyme,
                  likes: res.data.likes,
               });
               addToast(t('Listing Liked'), { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
            })
      }



   }

   // Follow  Unfollow handlers 
   const FollowUnfollowToggle = () => {
      var user = postData.user
      sellerProfile.setToken(token);
      setLoaded(false);
      if (user.followedbyme) {
         sellerProfile.unFollowUser(user.id)
            .then(res => {
               setLoaded(true);
               var changedUser = {
                  ...user,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               };
               setPostData({ ...postData, user: changedUser });
               addToast(t('User Unfollowed'), { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
            })
      } else {
         sellerProfile.followUser(user.id)
            .then(res => {
               setLoaded(true);
               var changedUser = {
                  ...user,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               };
               setPostData({ ...postData, user: changedUser });
               addToast(t('User Followed'), { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
            })
      }



   }

   // like follow similiar listings 
   const LikeUnlikeSimilarToggleButton = (postId) => {
      setLoaded(false);
      const post = similiarPosts.find(n => n.postid === postId)
      postsServices.setToken(token)
      if (post.likedbyme) {
         postsServices.unLikePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = {
                  ...post,
                  likedbyme: res.data.likedbyme,
                  likes: res.data.likes,
               }
               setSimiliarPosts(similiarPosts.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost));
               addToast(t('Listing Unliked'), { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
            })
      } else {
         postsServices.likePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = {
                  ...post,
                  likedbyme: res.data.likedbyme,
                  likes: res.data.likes,
               }
               setSimiliarPosts(similiarPosts.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost));
               addToast(t('Listing Liked'), { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
            })
      }
   }

   // Follow Unfollow similiar listings
   const FollowUnfollowSimilarAdsToggle = (userId) => {
      var post = similiarPosts.find(n => n.user_id === userId);
      var user = post.user;
      setLoaded(false);
      sellerProfile.setToken(token)
      if (user.followedbyme) {
         sellerProfile.unFollowUser(user.id)
            .then(res => {
               setLoaded(true);
               var changedUser = {
                  ...user,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               };
               const changedPost = { ...post, user: changedUser }
               setSimiliarPosts(similiarPosts.map(p => parseInt(p.user_id) !== parseInt(userId) ? p : changedPost));
               addToast(t('User UnFollowed'), { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
            })
      } else {
         sellerProfile.followUser(user.id)
            .then(res => {
               setLoaded(true);
               var changedUser = {
                  ...user,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               };
               const changedPost = { ...post, user: changedUser }
               setSimiliarPosts(similiarPosts.map(p => parseInt(p.user_id) !== parseInt(userId) ? p : changedPost));
               addToast(t('User Followed'), { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
            })
      }

   }

   // like unlike recent listings 
   const LikeUnlikeRecentToggleButton = (postId) => {
      setLoaded(false);
      const post = recentVisitedAds.find(n => n.postid === postId)
      postsServices.setToken(token)
      if (post.likedbyme) {
         postsServices.unLikePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = {
                  ...post,
                  likedbyme: res.data.likedbyme,
                  likes: res.data.likes,
               }
               setRecentVisitedAds(recentVisitedAds.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost));
               addToast(t('Listing Unliked'), { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
            })
      } else {
         postsServices.likePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = {
                  ...post,
                  likedbyme: res.data.likedbyme,
                  likes: res.data.likes,
               }
               setRecentVisitedAds(recentVisitedAds.map(p => p.postid !== postId ? p : changedPost));
               addToast(t('Listing Liked'), { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
            })
      }
   }

   // follow unfollow recent listings 
   const FollowUnfollowRecentAdsToggle = (userId) => {
      var post = recentVisitedAds.find(n => n.user_id === userId);
      var user = post.user;
      setLoaded(false);
      sellerProfile.setToken(token)
      if (user.followedbyme) {
         sellerProfile.unFollowUser(user.id)
            .then(res => {
               setLoaded(true);
               var changedUser = {
                  ...user,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               };
               const changedPost = { ...post, user: changedUser }
               setRecentVisitedAds(recentVisitedAds.map(p => parseInt(p.user_id) !== parseInt(userId) ? p : changedPost));
               addToast(t('User UnFollowed'), { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
            })
      } else {
         sellerProfile.followUser(user.id)
            .then(res => {
               setLoaded(true);
               var changedUser = {
                  ...user,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               };
               const changedPost = { ...post, user: changedUser }
               setRecentVisitedAds(recentVisitedAds.map(p => parseInt(p.user_id) !== parseInt(userId) ? p : changedPost));
               addToast(t('User Followed'), { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
            })
      }

   }

   // get  report listing reasons
   const getReporstReasons = async () => {

      await reportService
         .getReportReasons()
         .then(res => {
            if (res.success) {

               setReportReasons(res.data.reasons)
               // setTimeout(function() {
               setReportModal(true);

               // }.bind(this), 1000);
            }
         })
         .catch(error => {
            if (error.response) {

               addToast(t('Some Error Occured'), { appearance: 'error' });

            }
         })
   }

   // Report modal handler
   const handleReportModal = () => {
      setLoaded(false);
      getReporstReasons().then((response) => {
         setLoaded(true);
      })
         .catch(error => {
            setLoaded(true);
         })

   }

   // Report Listing 
   const ReportAdd = (e) => {
      e.preventDefault()
      setLoaded(false);
      reportService.setToken(token)
      reportService
         .reportPost(reportForm, postData?.postid)
         .then(res => {
            if (res.success) {
               setReportForm({
                  comment: '',
                  reason_id: '',
               });
               setPostData({
                  ...postData,
                  report_by_me: !postData.report_by_me
               })
               addToast(t('Listing Reported'), { appearance: 'success' });
               setLoaded(true);
               setReportModal(false);
            }
         })
         .catch(error => {
            if (error.response) {
               setLoaded(true);
               addToast(t('Some Error Occured'), { appearance: 'error' });
               setReportModal(false);
            }
         })
   }

   // Open Chat box
   const goToChat = () => {

      if (postData.thread_id) {
         navigate('/messages',{
            state: { thread_id: postData.thread_id }
          });

      } else {
         setPredefinedMessageModal(true);
      }
   }

   // Open Chat box
   // const setAndSendPredefinedMessage = (message) =>{ 
   //    sendPredefinedMessage('PREDEFINED',message)
   // }    

   // Open Chat box
   const sendCustomMessage = (e) => {
      e.preventDefault();
      sendPredefinedMessage('CUSTOM', null)
   }

   const sendPredefinedMessage = (type, message) => {
      let tempId = (Math.floor(Math.random() * 100) + 1).toString() + "_" + Date.now().toString();
      const formData = new FormData();
      formData.append(`unique_id`, tempId)
      if (type === "CUSTOM") {
         if (customMessageForm.message < 1) {
            addToast(t('Please type a message to send'), { appearance: 'error' })
         }

         formData.append(`message`, customMessageForm.message)
      }
      else {
         formData.append(`message`, message)
      }

      setLoaded(false);
      chatService.setToken(token)
      chatService
         .startChat(postData.postid, formData)
         .then(response => {
            setLoaded(true);
            if (response.success) {
               console.log(response.data);
               const newThread = response.data

               socket.emit('newChatThread', newThread, (error) => {
                  if (error) {
                     alert(error);
                  }
               });

               setPostData({
                  ...postData,
                  thread_id: response.data.thread_id
               });
               setPredefinedMessageModal(false)
               addToast(t('Successfully sent your message to') + postData?.user?.username, { appearance: 'success' })

            } else {
               addToast(response.message, { appearance: 'error' })
            }

         })
         .catch(error => {
            setLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }
            }
         })
   }





   return (
      <>
         {postData && (

            <SeoTags title={`Miltrade | ${postData?.slug.charAt(0).toUpperCase() + postData?.slug.slice(1)}`}
               description={postData?.description}
               category={postData?.category_name}
               url={window.location.href}
               image={postData?.featured_image?.sm}
            />

         )}

         <Header
            data={isHomePage}
            checkAuth={false}
         />
         <PageLoader isLoaded={loaded} />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
                           <Breadcrumb.Item linkProps={{ to: `/category/${postData?.category_slug}` }} linkAs={Link} to="/">{postData?.category_name}</Breadcrumb.Item>
                           <Breadcrumb.Item active>{postData.title}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="product-detail owl-carousel-arrow">
               <Container>
                  <Row>
                     <Col xs={12} sm={12} md={6} lg={7}>
                        <ProductImages featuredImage={postData?.featured_image?.sm} postSlug={postData?.slug} pictures={postData.picture} />
                     </Col>
                     <Col xs={12} sm={12} md={6} lg={5}>
                        {(loginUser.id === postData?.user?.id && !postData?.featured && !(postData?.expired)) && (
                           <div className="detail-box3 mb-3">
                              <Row>
                                 <Col xs={12} sm={12} md={4} lg={4}>
                                    <Image src="../../images/slider-image/featured_tag.webp" alt="1" className="img-fluid" />
                                 </Col>
                                 <Col xs={12} sm={12} md={8} lg={8}>
                                    <h3>{t('Reach More Buyers & Sell Faster with Featured Tag')}</h3>
                                    <p>{t('Upgrade your Ad a top position with featured tag')}</p>
                                    <div className="medium-button">
                                       <span as="button" onClick={goToFetured} className="btn btn-primary py-2" >{t('Sell Faster with Featured Tag')}</span>
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        )}
                        <div className="detail-box">
                           <div className="product-view-section px-3 ">
                              <ul>
                                 <li className="">
                                    <span>{t('listed')} {postData?.created_at}</span>
                                 </li>
                                 <li>
                                    <span className=""><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(postData?.visits)} {t('views')}</span>
                                 </li>
                              </ul>
                              {postData?.featured && <span className="post-view-featured-tag">{t('FEATURED')}</span>}
                           </div>
                           <div className="descripation pl-3">
                              <p>{postData?.title}</p>
                           </div>
                           <div className="price-detail">
                              <ul>
                                 <li className="pl-3"><span className={`${postData?.expired ? 'price-expired' : 'price'}`}> {postData?.open_to_offer ? ('Open to offers') : (postData?.formatted_price)}</span></li>
                                 <li className="share-icon pr-3">
                                    <span className="mr-1"><i className="icon-red_favourite" aria-hidden="true"></i>  {Encriptor.kformatter(postData.likes)} x {t('saved')} </span>
                                    {loginUser ? (loginUser.id !== postData?.user?.id && (
                                       <span className="">
                                          {postData.likedbyme ? (
                                             <div className="icon-box-heart cp" onClick={() => LikeUnlikeToggleButton()} >  <i className="icon-red_favourite active" aria-hidden="true"></i></div>
                                          ) : (
                                             <div className="icon-box2 cp" onClick={() => LikeUnlikeToggleButton()} > <i className="icon-favourite" aria-hidden="true"></i></div>
                                          )}
                                       </span>
                                    )) : (<span className="">

                                       <div className="icon-box2 cp" onClick={() => setShowLoginModal(true)} > <i className="icon-favourite" aria-hidden="true"></i></div>

                                    </span>)}
                                    <span className="icon-box2 cp" onClick={() => setShare(!share)}><i className="icon-invite"></i></span>
                                    <ShareIt share={share} shareUrl={postData?.post_link} title={t('Check out this item that I found on Miltrade. !')} />
                                 </li>
                              </ul>
                           </div>
                        </div>
                        {postData && (
                           <>
                              <div className="detail-box2 mt-3">
                                 <div className="seller-detail">
                                    <div className="about-seller">
                                       <div className="about-the-seller">
                                          <h4>{t('About the seller')}</h4>
                                       </div>
                                       <Row className="no-gutters">
                                          <Col xs={2} sm={2} md={2} lg={2} className="">
                                             <Link to={parseInt(loginUser.id) === parseInt(postData?.user?.id) ? `/profile` : `/seller/profile/${Encriptor.encrypt(postData?.user?.id)}`} >
                                                <div className='item-seller'>
                                                   {postData?.user?.issubscribed && (
                                                      <div className="verified-dealer">
                                                         <Image src="../../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-image1" />
                                                      </div>
                                                   )}
                                                   <div className={`seller-image-${postData?.user?.issubscribed ? 'verified' : 'unverified'}`}>
                                                      <Image src={postData?.user?.photo} alt={`${postData?.user?.username}`} className="img-fluid" />
                                                   </div>
                                                </div>
                                             </Link>
                                          </Col>
                                          <Col xs={7} sm={6} md={6} lg={6} className="p-0">
                                             <div className="seller-detail">
                                                <OverlayTrigger
                                                   placement="bottom"
                                                   delay={{ show: 250, hide: 400 }}
                                                   overlay={
                                                      <Tooltip id={`tooltip-${postData?.user?.id}`}>
                                                         {`${postData?.user?.username} `}
                                                      </Tooltip>
                                                   }
                                                >
                                                   {(postData?.user?.username.length) > 12 ? (

                                                      <div className="">
                                                         <Link to={parseInt(loginUser.id) === parseInt(postData?.user?.id) ? `/profile` : `/seller/profile/${Encriptor.encrypt(postData?.user?.id)}`} >
                                                            {`${postData?.user?.username} `.slice(0, 12)} ...
                                                         </Link>
                                                         <span className="seller-detail-reting"><i className="icon-star_fill" aria-hidden="true"></i> <span>{postData?.user?.averagerating} ({Encriptor.kformatter(postData?.user?.review_count)})</span></span>
                                                      </div>

                                                   ) : (
                                                      <div className="">
                                                         <Link to={parseInt(loginUser.id) === parseInt(postData?.user?.id) ? `/profile` : `/seller/profile/${Encriptor.encrypt(postData?.user?.id)}`} >
                                                            {`${postData?.user?.username}`}
                                                         </Link>
                                                         <span className="seller-detail-reting"><i className="icon-star_fill" aria-hidden="true"></i> <span>{postData?.user?.averagerating} ({Encriptor.kformatter(postData?.user?.review_count)})</span></span>
                                                      </div>
                                                   )}

                                                </OverlayTrigger>
                                                {postData?.user?.issubscribed ? (<h6>{t('Verified Dealer')}</h6>) : (<h6 className="private-seller">{t('Private Seller')}</h6>)}
                                                {/* <p>{`${Encriptor.kformatter(postData?.user?.followers)} followers, ${Encriptor.kformatter(postData?.user?.postcount)} posts `}</p> */}
                                             </div>
                                          </Col>
                                          {loginUser ? (
                                             <Col xs={3} sm={4} md={4} lg={4} className="">
                                                <div className="medium-button mt-0">
                                                   {(loginUser?.id && postData?.user?.id) && (
                                                      <button role="button" onClick={() => FollowUnfollowToggle(postData?.user?.id)} className="btn btn-primary product-detail-follow">{postData?.user?.followedbyme ? t('Unfollow') : t('Follow +')}</button>
                                                   )}
                                                </div>
                                                <div className="post-detail-profile-visit"><Link to={`/seller/profile/${Encriptor.encrypt(postData?.user?.id)}`}> {t('visit profile')}</Link></div>
                                             </Col>
                                          ) : (
                                             <Col xs={3} sm={4} md={4} lg={4} className="">
                                                <div className="medium-button mt-0">
                                                   <button role="button" onClick={() => setShowLoginModal(true)} className="btn btn-primary product-detail-follow">{t('Follow +')}</button>
                                                </div>
                                                <div className="post-detail-profile-visit"><Link to={`/seller/profile/${Encriptor.encrypt(postData?.user?.id)}`}> {t('visit profile')}</Link></div>
                                             </Col>
                                          )}

                                       </Row>
                                    </div>
                                    <div className="place-section1">
                                       <p>{`${Encriptor.kformatter(postData?.user?.followers)} ${postData?.user?.followers <= 1 ? t('follower') : t('followers')}, ${Encriptor.kformatter(postData?.user?.postcount)} ${postData?.user?.postcount <= 1 ? t('listing') : t('listings')} `}</p>
                                       <ul>


                                          <li className="location">
                                             <div className="country_location g-1">
                                                <img alt={postData?.country_flag} src={postData?.country_flag} />
                                                <span>{postData?.address} </span>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                    {loginUser ? (loginUser.id !== postData?.user?.id && (
                                       <div className="chat-call-button">
                                          <ul>
                                             <li><div onClick={goToChat} className="chat sp"><span className="btn btn-primary" role="button"><i className="icon-send_message"></i> {t('Send a message')}</span></div></li>
                                             {(<li className="views" onClick={() => setPhoneShow(!phoneShow)} >
                                                {/* <div className="call"><span className="btn btn-primary" role="button"><i className="icon-call"></i> {phoneShow?postData.phone:'Show phone number'}</span></div> */}
                                                <div onClick={() => setPhoneClass(!phoneClass)} className={`${phoneClass ? 'eseout' : 'pictureContainer'}`}>
                                                   <p className="phone-no" > <i className="icon-call"></i> {t('Show phone number')} </p>
                                                   <p className={`ph-no `}> <i className="icon-call"></i> {!postData.phone_hidden ? postData.phone : t('Number not available')}  </p>
                                                </div>

                                             </li>)}
                                          </ul>

                                       </div>

                                    )) : (
                                       <div className="chat-call-button">
                                          <ul>
                                             <li><div onClick={() => setShowLoginModal(true)} className="chat sp"><span className="btn btn-primary" role="button"><i className="icon-send_message"></i> {t('Send a message')}</span></div></li>
                                             {!postData.phone_hidden && (
                                                <li onClick={() => setShowLoginModal(true)} className="views"><div className="call"><span className="btn btn-primary" role="button"><i className="icon-call"></i> {t('Show phone number')}</span></div>
                                                </li>)}
                                          </ul>
                                       </div>
                                    )}

                                 </div>

                              </div>

                           </>
                        )}

                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="product-description">
               <Container>
                  <Row>
                     <Col xs={12} sm={12} md={6} lg={7}>
                        <div className="title">
                           <h3>{t('Item Description')}</h3>
                           <p style={{ 'whiteSpace': 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: postData?.description }} ></p>
                        </div>
                     </Col>
                     <Col xs={12} sm={12} md={6} lg={5}>
                        <div className="product-details-table">
                           <ProductDetailsTable postData={postData} handleReportModal={handleReportModal} />
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>

            <SimiliarAds LikeUnlikeSimilarToggleButton={LikeUnlikeSimilarToggleButton}
               FollowUnfollowSimilarAdsToggle={FollowUnfollowSimilarAdsToggle}
               similarAds={similiarPosts} />
            {recentVisitedAds && (
               <RecentVisitedAds LikeUnlikeRecentToggleButton={LikeUnlikeRecentToggleButton}
                  FollowUnfollowRecentAdsToggle={FollowUnfollowRecentAdsToggle}
                  recentVisitedAds={recentVisitedAds} />)}


            <Modal
               size="lg"
               show={reportModal}
               onHide={() =>
                  setReportModal(false)}
               aria-labelledby="example-modal-sizes-title-lg"
            >
               <Modal.Header closeButton>
                  <h4>{('Report Listing')} </h4>
               </Modal.Header>

               <Modal.Body>
                  <div className="report-ad-modal">
                     <Form onSubmit={ReportAdd}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                           <Form.Label>{t('Reason for report')}</Form.Label>
                           <Form.Control as="select" required name="reason_id" onChange={handleFromChange} className="form-control1">
                              <option value="" >{t('select a reason')}</option>
                              {reportReasons?.map((reason, i) => (
                                 <option value={reason.id} >{reason.title}</option>
                              ))}
                           </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1" className="mt-3">
                           <Form.Label>{t('Comment')}</Form.Label>
                           <Form.Control onChange={handleFromChange} name="comment" value={reportForm.comment} as="textarea" rows={4} />
                           <div className="character"> <span>{t('characters')} {titleTxtcounter}/{t('300')}</span></div>
                        </Form.Group>
                        <div className="report-ad-btn text-center mt-3"><Button variant="primary" type="submit">{t('Submit')} </Button></div>
                     </Form>
                  </div>
               </Modal.Body>
            </Modal>

            <Modal
               size="lg"
               show={phoneNumberModal}
               onHide={() =>
                  setphoneNumberModal(false)}
               aria-labelledby="example-modal-sizes-title-lg"
            >
               <Modal.Header closeButton>
                  <h4>{t('Phone Number')}</h4>
               </Modal.Header>

               <Modal.Body>
                  <div className="report-ad-modal">
                     <div className="show-phone-no">
                        <span> {postData?.phone} </span>
                     </div>
                     <div className="safety-tips">
                        <p><Image src="/images/alert.webp" width='30' alt="flag" className="img-fluid" /> {t('Safety Tips')}</p>
                     </div>
                     <ul>
                        <li>
                           <div className="wrapper-add-report">
                              <div className="icon">
                                 <i className="icon-right"></i>
                              </div>
                              <div className="text">
                                 <span>{t('The advertisements are published by individuals or professionals and dont directly concern Miltrade.Our site is a channel for connecting people. So please check the quality of the product or service before buying or renting it.')}</span>
                              </div>
                           </div>

                        </li>
                        <li>
                           <div className="wrapper-add-report">
                              <div className="icon">
                                 <i className="icon-right"></i>
                              </div>
                              <div className="text mt-3">
                                 <strong> {t('Important')}:</strong>
                                 <span> {t('Dont send money remotely for a product or for a service.')}</span>
                              </div>
                           </div>

                        </li>
                     </ul>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <div className="report-add-footer">
                     <Button variant="secondary close-btn" onClick={() => setphoneNumberModal(false)} >{t('Close')}</Button>
                     <a className="call-now-btn" href={`tel:${postData?.phone}`}><i className="icon-call"></i> {t('Call Now')} !</a>
                  </div>
               </Modal.Footer>

            </Modal>

            {/* // Suggestion modal */}
            <Modal
               size="md"
               show={predefinedMessageModal}
               onHide={() =>
                  setPredefinedMessageModal(false)}
               aria-labelledby="example-modal-sizes-title-lg"
            >
               <Modal.Header closeButton>
                  <h4>{t('Send a message to')} {postData?.user?.username} </h4>
               </Modal.Header>

               <Modal.Body>
                  <div className="report-ad-modal">
                     <Form onSubmit={sendCustomMessage}>
                        <Form.Group controlId="exampleForm.ControlTextarea1" className="mt-3">
                           <Form.Label>{t('Type your message')}</Form.Label>
                           <Form.Control onChange={handleCustomTextChange} name="message" value={customMessageForm.message} as="textarea" rows={5} />
                        </Form.Group>
                        <div className="custom-message-btn text-center mt-3"><Button variant="primary" type="submit">{t('Send message')}</Button></div>
                     </Form>


                  </div>
               </Modal.Body>

            </Modal>
         </div>
         <Footer />
      </>
   )
}
export default ProductDetails;
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from "./include/header";
import Footer from "./include/footer";
import profileService from '../services/myProfile'
import { useToasts } from 'react-toast-notifications'
import SeoTags from './seo/seoTags'
import { useTranslation } from 'react-i18next';
var isHomePage = false;
const ContactUs = () => {
   const { t } = useTranslation();
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   const [formData, setFormData] = useState({
      name: loginUser ? `${loginUser.username}` : '',
      email: loginUser ? `${loginUser.email}` : '',
      phone: loginUser ? `${loginUser.phone}` : ''
   });
   const [loaded, setLoaded] = useState(true);
   const [validationError, setValidationError] = useState();
   const { addToast } = useToasts();

   // handle form input change 
   const handleFromChange = (e) => {
      const { name, value: newValue, type } = e.target;
      const value = type === 'number' ? +newValue : newValue;
      setFormData({
         ...formData,
         [name]: value,
      });
   }

   // check element has error 
   const hasErrorFor = (field) => {
      if (validationError) {

         const error = validationError.find(element => String(element.field) === String(field));
         if (error)
            return true;
      } else {
         return false;
      }
   }

   // render validation error for element 
   const renderErrorFor = (field) => {
      if (hasErrorFor(field)) {
         const error = validationError.find(element => String(element.field) === String(field));
         return (
            <div className="error-wrapper">
               <span className='invalid-feedback'>
                  <strong>{error.message}</strong>
               </span>
            </div>
         )
      }
   }

   // handle form submit 
   const handleSubmit = (e) => {
      e.preventDefault();
      setLoaded(false);
      profileService
         .postSupportTicket(formData)
         .then(response => {
            setLoaded(true);
            if (response.success) {
               setFormData({
                  ...formData,
                  phone: '',
                  description: '',
               });
               setValidationError()
               addToast(response.message, { appearance: 'success' })

            } else {
               addToast(response.result, { appearance: 'error' })
            }
         })
         .catch(error => {
            setLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  setValidationError(
                     error.response.data.errors
                  )
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }

            }
         })
   }

   window.scrollTo(0, 0);
   return (
      <>
         <SeoTags title={`Miltrade | Contact us `}
            description={''}
            category={''}
            url={window.location.href}
            image={''}
         />
         {/* Start of header section */}
         <Header
            data={isHomePage}
            checkAuth={false}
         />

         {/* end of header section */}
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                           <Breadcrumb.Item active>{t("Contact Us")}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="contact_us_section">
               <Container>
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={4}>
                        {/* <Image className="" src="/images/product1.jpg"  alt="product-image" className="img-fluid"  /> */}
                        <div className="contact-info">
                           <h1>{t("Contact information")}: </h1>
                           <div className="email_id">
                              <Link to="mailto:info@miltrade.com" ><i className="icon-email mr-2"></i> info@miltrade.com</Link>
                           </div>
                           <div className="phone_number">
                              <Link to="tel:+097999 23531" ><i className="icon-mobile mr-2"></i> </Link>
                           </div>
                           <div className="address">
                              <i className="icon-city mr-2"></i>
                              Wilgehout 13, 3371KE Hardinxveld-Giessendam, Netherlands
                           </div>
                        </div>
                     </Col>
                     <Col xs={12} sm={12} md={12} lg={8}>
                        <div className="contact_us">
                           <div className="login-form">
                              <h1>{t("Leave us a message!")}</h1>
                              <Form onSubmit={handleSubmit}>
                                 <Row>
                                    <Col xs={12} sm={12} md={12} lg={6} >
                                       <div className="form-group">
                                          <div className="form-group">
                                             <div className="material-textfield mt-4">
                                                <span className="icon-name"></span>
                                                <input placeholder=" "
                                                   name="name"
                                                   className={`${hasErrorFor('name') ? 'is-invalid' : 'valid'}`}
                                                   onChange={handleFromChange}
                                                   value={formData?.name}
                                                   type="text" />
                                                <label>{t("Enter your full name")} *</label>
                                             </div>
                                             {renderErrorFor('name')}
                                          </div>
                                       </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} >
                                       <div className="form-group">
                                          <div className="material-textfield mt-4">
                                             <span className="icon-email"></span>
                                             <input placeholder=" "
                                                className={`${hasErrorFor('email') ? 'is-invalid' : 'valid'}`}
                                                onChange={handleFromChange}
                                                value={formData?.email}
                                                name="email"
                                                id="email"
                                                type="text" />
                                             <label>{t("Enter your email adress")} *</label>
                                          </div>
                                          {renderErrorFor('email')}
                                       </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} >
                                       <div className="form-group">
                                          <div className="material-textfield mt-4">
                                             <span className="icon-phone"></span>
                                             <input placeholder=" "
                                                className={`${hasErrorFor('phone') ? 'is-invalid' : 'valid'}`}
                                                onChange={handleFromChange}
                                                value={formData?.phone}
                                                name="phone"
                                                id="email"
                                                type="number" />
                                             <label>{t("Enter your phone number")} *</label>
                                          </div>
                                          {renderErrorFor('phone')}
                                       </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} >
                                       <div className="form-group">
                                          <div className="material-textfield mt-4">
                                             <span className="icon-company_description"></span>
                                             <div className="textarea1">
                                                <textarea className={`${hasErrorFor('description') ? 'is-invalid' : 'valid'}`}
                                                   placeholder="  "
                                                   name="description"
                                                   onChange={handleFromChange}
                                                   value={formData?.description}
                                                   rows="3"></textarea>
                                                <label>{t("Let us know how we can help you")} *</label>
                                             </div>

                                          </div>
                                          {renderErrorFor('description')}
                                       </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} >
                                       <div className="large-button mt-3"><Button variant="primary" type="submit"> Submit</Button></div>
                                    </Col>
                                 </Row>
                              </Form>
                           </div>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>
         </div>
         <Footer />
      </>
   );
}
export default ContactUs;
import React from 'react';
import { Helmet } from 'react-helmet-async';

const SeoTags = ({title,description,category,url,image}) =>{
    
    return (
        <>
            <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description?description:'Buy or sell your historic military collectibles on Miltrade and reach thousands of collectors and traders worldwide! Download our iOS or Android app today.'} />
                    <meta name="keywords" content={`miltrade,${category}`} />
                    <meta name="theme-color" content="#005630" />
                    
                    <meta itemProp="name" content="Miltrade" />
                    <meta itemProp="description" content={description?description:'Buy or sell your historic military collectibles on Miltrade and reach thousands of collectors and traders worldwide! Download our iOS or Android app today.'}/>
                    <meta itemProp="image" content={image?image:window.location.origin+'/images/seo.png'} />

                    <meta name="author" content="Miltrade" />
                    {/* <meta name="facebook-domain-verification" content="cmo4s5fj8lx916i0obxlyhrj8y8h9k" /> */}
                    <meta property="og:title" content={title} />
                    <meta property="og:url"                content={url} />
                    <meta property="og:type"               content="article" />
                    <meta property="og:description"        content={description?description:'Buy or sell your historic military collectibles on Miltrade and reach thousands of collectors and traders worldwide! Download our iOS or Android app today.'} />
                    <meta property="og:image"              content={image?image:window.location.origin+'/images/seo.png'} />
                    <meta property="og:site_name" content="Miltrade" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description?description:'Buy or sell your historic military collectibles on Miltrade and reach thousands of collectors and traders worldwide! Download our iOS or Android app today.'} />
                    <meta name="twitter:creator" content="@miltrade" />
                    <meta name="twitter:image:src"  content={image?image:window.location.origin+'/images/seo.png'}  />
                </Helmet>
        </>
        )
    }

export default SeoTags;
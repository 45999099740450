import { useTranslation } from 'react-i18next';
import './loader.css'

const Loader = () => {
    const { t } = useTranslation();
    return(
       
            <div className="full-page-loader">
                <div className="loader-inner-wrapper">
                    <p>{t("Please wait")}...</p>
                </div>
            </div>
    )
}

   
export default Loader;
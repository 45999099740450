/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import { useLocation } from 'react-router-dom';
import { Header, Footer } from "../index";
import categoryService from "../../services/productCategories";
import postService from "../../services/productCategories";
import { useToasts } from 'react-toast-notifications'
import postsServices from "../../services/posts";
import sellerProfile from "../../services/sellerProfile";
import PageLoader from '../pageLoader'
import SeoTags from '../seo/seoTags'
import { isDesktop, isMobile } from 'react-device-detect';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../../assets/css/sidebar.css";
import 'bootstrap-daterangepicker/daterangepicker.css';
import GridView from "../comman/views/grid";
import ListView from "../comman/views/list";
import "react-month-picker/css/month-picker.css";
import { Auth } from '../../Contexts/AuthContext';
import { Spinner } from 'react-bootstrap';
import MobileFilter from '../comman/mobileFilter';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../../Contexts/langContext';

import SideFilter from '../comman/SideFilter';
import UpperFilter from '../comman/UpperFilter';


const Home = () => {

  const { setMenuCategory } = useContext(Auth);
  const { lang } = useLangContext(langContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const referral_code = query.get('referral_code')
  const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
  var token = loginUser?.access_token;

  const [categoryFilterData, setCategoryFilterData] = useState([]);
  const [filterOption, setFilterOption] = useState([]);
  const [showNumberOfFilters, setShowNumberOfFilters] = useState(6);
  const [posts, setPosts] = useState();
  const [issubscribed, setIssubscribed] = useState(false);
  const [isSold, setIsSold] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sidebar, setSidebar] = useState({
    leftOpen: isMobile ? false : true,
  });
  const [filterCount, setFilterCount] = useState(0);
  const [filteredList, setFilteredList] = useState([]);
  const [page, setPage] = useState(0);
  const [adsFilter, setAdsFilter] = useState('all');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [inlineLoader, setInlineLoader] = useState(false);
  const [rangeError, setRangeError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [view, setView] = useState('grid');
  const { addToast } = useToasts();
  let leftOpen = sidebar.leftOpen ? 'open' : 'closed';
  const { t } = useTranslation();


  useEffect(() => {
    async function fetchFilterData() {
      postsServices.setToken(token);
      setIsLoaded(false);
      setMenuCategory('home');
      postService.setToken(token);
      try {
        const response = await categoryService.fiterMenu('home', lang);
        if (response.success) {
          setCategoryFilterData(response.data);
          setAdsFilter('all')
          setIsLoaded(true);

        } else {
          setIsLoaded(true);
        }
      } catch (error) {
        setIsLoaded(true);
      }


    }
    fetchFilterData();
    return () => {

    }
  }, [lang]);

  useEffect(() => {
    getPostData()
  }, [filterOption, sortBy, issubscribed, isSold, selectedCategory])
  
  useEffect(() => {
    if (page > 1)
      fetchMorePostData();
  }, [page])


  // production year range filter 
  const handleFirstRangeInput = (id, value) => {
    value = value || '';
    setMinDate(value);
    const minval = value;
    const maxval = maxDate;
    const error = checkErrorInRange(minval, maxval);
    setRangeError(error);
    let filter = filterOption.find(f => (String(f.id) === String(id)));
    if (typeof filter !== "undefined") {
      var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
      setFilterOption(filtereddd);
      handleTotalFilterCount(filtereddd.length)
    }
    if (error === '') {
      const newData = {
        id: id,
        value: `${minval} - ${maxval}`,
      }
      if (filterOption.length > 0) {
        let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
        if (typeof filter !== "undefined") {
          const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
          setFilterOption(filtereddd);
          handleTotalFilterCount(filtereddd.length)

        }
        else {
          setFilterOption(filterOption.concat(newData));
          handleTotalFilterCount(filterOption.concat(newData).length)

        }
      } else {
        setFilterOption(filterOption.concat(newData));
        handleTotalFilterCount(filterOption.concat(newData).length)
      }
    }

  }

  const handleSecondRangeInput = (id, value) => {
    value = value || '';
    setMaxDate(value);
    const minval = minDate;
    const maxval = value;
    const error = checkErrorInRange(minval, maxval);
    setRangeError(error);
    let filter = filterOption.find(f => (String(f.id) === String(id)));
    if (typeof filter !== "undefined") {
      var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
      setFilterOption(filtereddd);
      handleTotalFilterCount(filtereddd.length)
    }
    if (error === '') {
      const newData = {
        id: id,
        value: `${minval} - ${maxval}`,
      }
      if (filterOption.length > 0) {
        let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
        if (typeof filter !== "undefined") {
          const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
          setFilterOption(filtereddd);
          handleTotalFilterCount(filtereddd.length)

        }
        else {
          setFilterOption(filterOption.concat(newData));
          handleTotalFilterCount(filterOption.concat(newData).length)

        }
      } else {
        setFilterOption(filterOption.concat(newData));
        handleTotalFilterCount(filterOption.concat(newData).length)

      }
    }
  }

  // production year range filter custom validation
  const checkErrorInRange = (minval, maxval) => {
    let errorss = '';

    if (maxval < minval) {
      errorss = t('To year must be greater or equals than from year');
    }


    if (maxval < 1500) {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('To year should be greater than or equals 1500');
    }

    if (minval > new Date().getFullYear()) {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('To year should be lesser than ') + new Date().getFullYear();
    }

    if (maxval.length < 4 || maxval.length > 4) {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('To year should be 4 digits');
    }

    if (maxval === '') {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('Please enter to year');
    }

    if (minval < 1500) {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('From year should be greater than or equals 1500');
    }

    if (minval > new Date().getFullYear()) {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('From year should be lesser than or equals ') + new Date().getFullYear();
    }

    if (minval.length < 4 || minval.length > 4) {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('From year should be 4 digits');
    }

    if (minval === '') {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('Please enter from year');
    }

    if (minval === '') {
      // handlemin(selelectedOptionID[1].trim())
      errorss = t('Please enter from year');
    }
    return errorss;
  }

  // filter toggler 
  const toggleSidebar = (event) => {
    let key = `${event.currentTarget.parentNode.id}Open`;
    setSidebar({ [key]: !sidebar[key] });
  }

  // Get listing  from server
  const getPostData = () => {

    const formData = new FormData();

    if (filterOption.length > 0) {
      for (let index = 0; index < filterOption.length; index++) {
        const element = filterOption[index];
        formData.append(`cf[${element.id}][]`, element.value);
      }
    }
    if (sortBy) {
      formData.append('sortby', sortBy);
    }

    formData.append('page', 1);
    formData.append('verifiedListing', issubscribed);
    formData.append('soldPost', isSold);
    if (selectedCategory !== '') {
      formData.append('category_id', selectedCategory);
    }
    setIsLoaded(false);
    postsServices.searchPost(formData, lang)
      .then(res => {
        setIsLoaded(true);
        if (res.success) {
          // const updatedPost =  [...posts.data , res.data.data ];
          // console.log(updatedPost);

          setPosts(res.data);
          // setAllData(res.data);
          if (!issubscribed && !isSold) {
            // setAllPostCount(res.data.total)
            // setVerifiedPostCount(res.data.verified_dealer_post_count);
            // setSoldPostCount(res.data.sold_posts_count);
          }
        }
      })
      .catch(err => {
        setIsLoaded(true);
        console.log('eooer');
      })
  }

  // Get listing  from server
  const fetchMorePostData = () => {
    if (page > posts?.last_page && page > 1) {
      setInlineLoader(false);
      return false;
    }
    const formData = new FormData();

    if (filterOption.length > 0) {
      for (let index = 0; index < filterOption.length; index++) {
        const element = filterOption[index];
        formData.append(`cf[${element.id}][]`, element.value);
      }
    }
    if (sortBy) {
      formData.append('sortby', sortBy);
    }
    if (page) {
      formData.append('page', page);
    }
    if (selectedCategory !== '') {
      formData.append('category_id', selectedCategory);
    }
    formData.append('verifiedListing', issubscribed);
    formData.append('soldPost', isSold);
    postsServices.searchPost(formData, lang)
      .then(res => {
        setInlineLoader(false);
        if (res.success) {
          const updatedPost = posts.data.concat(res.data.data);
          setPosts((prv) => ({ ...res.data, data: updatedPost }));
          // setAllData((prv) => ({ ...res.data, data: updatedPost }));
          if (!issubscribed && !isSold) {
            // setAllPostCount(res.data.total)
            // setVerifiedPostCount(res.data.verified_dealer_post_count);
            // setSoldPostCount(res.data.sold_posts_count);
          }
        }
      })
      .catch(err => {
        setInlineLoader(false);
        console.log('eooer');
      })
  }

  // filter  selection handler
  const handleFiltersSelect = (e) => {
    const id = e.target.dataset.filter_id;
    const value = e.target.dataset.filter_value;
    const newData = {
      id: id,
      value: value,
    }
    if (filterOption.length > 0) {
      let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
      if (typeof filter !== "undefined") {
        var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
        setFilterOption(filtereddd);
        handleTotalFilterCount(filtereddd.length)

      }
      else {
        setFilterOption(filterOption.concat(newData));
        handleTotalFilterCount(filterOption.concat(newData).length)

      }
    } else {
      setFilterOption(filterOption.concat(newData));
      handleTotalFilterCount(filterOption.concat(newData).length)

    }
  }

  // filter datepicker handler 
  const handleDatepickerValue = (event, picker) => {

    var id = event.currentTarget.dataset.filter_id;
    var startDate = picker.startDate.format('MM/DD/YYYY');
    var endDate = picker.endDate.format('MM/DD/YYYY');
    var value = `${startDate} to ${endDate}`;
    if (event.currentTarget.dataset.type === 'date') {
      value = picker.startDate.format('MM/DD/YYYY')
    }
    const newData = {
      id: id,
      value: value,
    }
    if (filterOption.length > 0) {
      let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
      if (typeof filter !== "undefined") {
        var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
        setFilterOption(filtereddd);
        handleTotalFilterCount(filtereddd.length)

      }
      else {
        setFilterOption(filterOption.concat(newData));
        handleTotalFilterCount(filterOption.concat(newData).length)

      }
    } else {
      setFilterOption(filterOption.concat(newData));
      handleTotalFilterCount(filterOption.concat(newData).length)

    }

  }

  // Lke unlike handler 
  const LikeUnlikeToggleButton = (postId) => {
    const post = posts.data.find(n => n.postid === postId)
    setIsLoaded(false);
    postsServices.setToken(token)
    if (post.likedbyme) {
      postsServices.unLikePost(postId)
        .then(res => {
          setIsLoaded(true);
          const changedPost = { ...post, likedbyme: res.data.likedbyme, likes: res.data.likes }
          const postPost = posts.data.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost);

          setPosts({
            ...posts,
            data: postPost
          });
          addToast(t('Post Unliked'), { appearance: 'success' })
        })
        .catch(err => {
          setIsLoaded(true);
        })
    } else {
      postsServices.likePost(postId)
        .then(res => {
          setIsLoaded(true);
          const changedPost = { ...post, likedbyme: res.data.likedbyme, likes: res.data.likes }
          const postPost = posts.data.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost);

          setPosts({
            ...posts,
            data: postPost
          });
          addToast(t('Post Liked'), { appearance: 'success' })
        })
        .catch(err => {
          setIsLoaded(true);
        })
    }



  }

  // folllow  unfollow handler 
  const FollowUnfollowToggle = id => {
    const post = posts.find(p => p.user_id === id)
    const seller = post.user
    setIsLoaded(false);
    sellerProfile.setToken(token);

    if (seller.followedbyme) {
      sellerProfile.unFollowUser(id)
        .then(res => {
          setIsLoaded(true);
          const updatedSeller = {
            ...seller,
            followedbyme: res.data.followedbyme,
            followers: res.data.followers,
          }
          const changedPost = { ...post, user: updatedSeller }
          setPosts(posts.map(p => parseInt(p.user_id) !== parseInt(id) ? p : changedPost));
          addToast(t('Seller Unfollowed'), { appearance: 'success' })
        }).catch(err => {
          setIsLoaded(true);
          console.log('unfollow error');
        })
    } else {
      sellerProfile.followUser(id)
        .then(res => {
          setIsLoaded(true);
          const updatedSeller = {
            ...seller,
            followedbyme: res.data.followedbyme,
            followers: res.data.followers,
          }
          const changedPost = { ...post, user: updatedSeller }
          setPosts(posts.map(p => parseInt(p.user_id) !== parseInt(id) ? p : changedPost));
          addToast(t('Seller Followed'), { appearance: 'success' })
        }).catch(err => {
          setIsLoaded(true);
          console.log('follow error');
        })
    }
  }

  // Sortby filter handler 
  const handleSortBy = (e) => {
    setSortBy(e.target.value);
  }

  // country filter dropdown filter handler 
  const filterCountries = (e, options) => {
    var str = e.target.value || '';
    const lowercasedFilter = str.toLowerCase();
    const filteredData = options.filter(item => {
      return item.value.toLowerCase().includes(lowercasedFilter);
    });

    const newData = {
      id: e.currentTarget.dataset.filter_id,
      data: filteredData,
    }
    if (filteredList.length > 0) {
      let filter = filteredList.find(f => String(f.id) === e.currentTarget.dataset.filter_id);
      if (typeof filter !== "undefined") {
        var filtereddd = filteredList.map(f => String(f.id) === e.currentTarget.dataset.filter_id ? newData : f);
        setFilteredList(filtereddd)
      } else {
        setFilteredList(filteredList.concat(newData))
      }

    } else {
      setFilteredList(filteredList.concat(newData))
    }

  }

  //clear all applied filter  
  const handleClearFilter = (e) => {

    setFilterOption([]);
    setFilteredList([]);
    setSelectedCategory('');
    setMinDate('');
    setMaxDate('');
    getPostData()
    setFilterCount(0)
  }

  // handler for listing type 
  const HandleAdFilter = (adType) => {
    setAdsFilter(adType)
    var verified = false;
    var soldAds = false;
    if (adType === "verified_ads") {
      verified = true;
    }
    if (adType === "sold_ads") {
      soldAds = true;
    }
    setIssubscribed(verified)
    setIsSold(soldAds)
  }
  const loader = useRef(null);
  const handlePage = pageId => {
    setPage(pageId)
  }

  const handleSelectedCategory = (e) => {
    const category = e.target.dataset.filter_value;
    setSelectedCategory(category);
    setFilterCount(1);
  }

  const handleTotalFilterCount = (filerApplied) => {
    if (selectedCategory !== '') {
      setFilterCount(filerApplied + 1)
    } else {
      setFilterCount(filerApplied)
    }
  }

  // Observer to handle scroll to bottom 
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setInlineLoader(true);
      setPage((prv) => (prv + 1));
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <>
      <SeoTags title={'Miltrade | The international marketplace for military collectibles'}
        description={''}
        category={''}
        url={window.location.href}
        image={''} />

      <Header
        data={false}
        checkAuth={true}
        referral_code={referral_code}
      />
      <MobileFilter
        setView={setView}
        view={view}
        setSortBy={setSortBy}
        sortBy={sortBy}
        categoryFilterData={categoryFilterData}
        filterOption={filterOption}
        filterCountries={filterCountries}
        filterCount={filterCount}
        handleClearFilter={handleClearFilter}
        handleFiltersSelect={handleFiltersSelect}
        filteredList={filteredList}
        showNumberOfFilters={showNumberOfFilters}
        setShowNumberOfFilters={setShowNumberOfFilters}
        handleFirstRangeInput={handleFirstRangeInput}
        handleSecondRangeInput={handleSecondRangeInput}
        maxDate={maxDate}
        minDate={minDate}
        rangeError={rangeError}
        handleDatepickerValue={handleDatepickerValue}
        HandleAdFilter={HandleAdFilter}
        adsFilter={adsFilter}
        selectedCategory={selectedCategory}
        handleSelectedCategory={handleSelectedCategory}
      // allPostCount={allPostCount}
      // verifiedPostCount={verifiedPostCount}
      />

      <PageLoader isLoaded={isLoaded} />
      <div className="footer-bottom1">
        <div className="main-layout" id='layout'>
          {isDesktop && (
            <SideFilter
              categoryFilterData={categoryFilterData}
              filteredList={filteredList}
              filterOption={filterOption}
              filterCountries={filterCountries}
              showNumberOfFilters={showNumberOfFilters}
              handleFiltersSelect={handleFiltersSelect}
              setShowNumberOfFilters={setShowNumberOfFilters}
              handleFirstRangeInput={handleFirstRangeInput}
              minDate={minDate}
              maxDate={maxDate}
              handleSecondRangeInput={handleSecondRangeInput}
              rangeError={rangeError}
              handleDatepickerValue={handleDatepickerValue}
              handleClearFilter={handleClearFilter}
              leftOpen={leftOpen}
              toggleSidebar1={toggleSidebar}
              categoryID={''}
              filterCount={filterCount}
              selectedCategory={selectedCategory}
              handleSelectedCategory={handleSelectedCategory}
            />
          )}
          <div className="product-right-section">
            <div className='content'>
              {isDesktop && (

                <UpperFilter
                  HandleAdFilter={HandleAdFilter}
                  adsFilter={adsFilter}
                  setView={setView}
                  view={view}
                  handleSortBy={handleSortBy}
                />

              )}
              <div className="all-product">
                {posts?.data?.length > 0 ? (
                  view === 'grid' ?
                    (<GridView posts={posts}
                      LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                      FollowUnfollowToggle={FollowUnfollowToggle}
                      handlePage={handlePage}

                    />) :
                    (<ListView posts={posts}
                      LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                      FollowUnfollowToggle={FollowUnfollowToggle}

                    />)
                ) : (
                  <>
                    <div className="text-center">
                      <Image src="../images/search_results_no_listings.webp" className="img-fluid" alt="user" />

                    </div>
                  </>
                )}
                {inlineLoader && (
                  <div className={`inline-loader`}>
                    <Spinner animation="border" variant="success" />
                  </div>
                )}
                <div ref={loader} />
              </div>
            </div>
          </div>
        </div>
        <div className="app-download">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="app-download-image">
                  <Image src="images/mobile_image.webp" alt="blog" className="img-fluid" />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h3>{t("Download the Miltrade App")} </h3>
                <p>{t("The Miltrade App makes it even easier to list your items and browse through the large collection of listings on Miltrade. Download the app in the iOS Store or Google Play Store and start using Miltrade today!")}</p>
                <h5>{t("DOWNLOAD OUR APP TODAY")}</h5>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.miltrade.app" rel="noreferrer"><Image src="images/google-play.webp" alt="blog" className="img-fluid" /></a>
                <a target="_blank" className="ml-2" href="https://apps.apple.com/gb/app/Miltrade/id1562980510" rel="noreferrer"><Image src="images/app-store.webp" alt="blog" className="img-fluid" /></a>
                <div className="pb-2"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </div >
      <Footer />

    </>
  )
}

export default Home;

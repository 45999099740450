/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
require('dotenv').config()

const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix;

let token = null;


const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}

const sendMessage = (data, postID) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/posts/messages/${postID}`, data, config)
    return request.then(response => response.data,)
}

const userThreads = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/user/threads`, config)
    return request.then(response => response.data,)
}

const threadMessages = (thredId, page, lang) => {
    var config = {
        headers: token ? { Authorization: token, "App-Language": lang } : { "App-Language": lang },
    }
    const request = axios.get(baseUrl + `/posts/messages/${thredId}?page=${page}`, config)
    return request.then(response => response.data)
}

const sendMessages = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/posts/messages`, newObject, config)
    return request.then(response => response.data)
}

const startChat = (postID, newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/posts/startChat/${postID}`, newObject, config)
    return request.then(response => response.data)
}

const startUserChat = (userID, postData) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/users/startChat/${userID}`, postData, config)
    return request.then(response => response.data)
}

const refreshChat = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/posts/refreshChat`, config)
    return request.then(response => response.data)
}

const readMessages = (messageID) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/posts/markMessageRead/${messageID}`, config)
    return request.then(response => response.data)
}

const markOffline = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/user/mark/offline`, config)
    return request.then(response => response.data)
}

const markMessageStarred = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/user/markmessagestarred/${id}`, config)
    return request.then(response => response.data)
}

const markMessageUnstarred = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/user/markmessageunstarred/${id}`, config)
    return request.then(response => response.data)
}

const markThreadArchive = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/user/markthreadarchive/${id}`, config)
    return request.then(response => response.data)
}

const markThreadUnarchive = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/user/markthreadunarchive/${id}`, config)
    return request.then(response => response.data)
}



export default {
    sendMessage, userThreads, threadMessages, sendMessages, startChat, startUserChat,
    setToken, refreshChat, readMessages, markOffline, markMessageStarred, markMessageUnstarred,
    markThreadArchive, markThreadUnarchive
}
import React from 'react';
import ListView from './list';
import GridView from './grid'
import Image from 'react-bootstrap/Image';


const MyFavorites = ({ view, favPosts, UnlikePost }) => {
   return (
      <>
         {favPosts?.length > 0 ? (
            view === 'grid' ?
               (<GridView UnlikePost={UnlikePost} favPosts={favPosts}

               />) :
               (<ListView UnlikePost={UnlikePost} favPosts={favPosts}

               />)
         ) : (
            <>
               <div className="text-center">
                  <Image src="../images/no_favorites_found.webp" className="img-fluid" alt="user" />
               </div>
            </>
         )}

      </>
   )

}
export default MyFavorites;



/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/posts';

let token = null;


const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}

const getReportReasons = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/reportreasons`, config)
    return request.then(response => response.data,)
}

const reportPost = (newObject, postid) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/${postid}/report`, newObject, config)
    return request.then(response => response.data)
}



export default { getReportReasons, reportPost, setToken }
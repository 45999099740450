/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../../assets/css/sidebar.css";
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import "react-month-picker/css/month-picker.css";
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Auth } from '../../Contexts/AuthContext';
import { useLocation } from 'react-router-dom';



const SideFilter = ({
  selectedCategory,
  handleSelectedCategory,
  categoryFilterData,
  filteredList,
  filterOption,
  filterCountries,
  showNumberOfFilters,
  handleFiltersSelect,
  setShowNumberOfFilters,
  handleFirstRangeInput,
  minDate,
  maxDate,
  handleSecondRangeInput,
  rangeError,
  handleDatepickerValue,
  handleClearFilter,
  leftOpen,
  toggleSidebar1,
  categoryID,
  filterCount,
}) => {

  const { t } = useTranslation();
  const { menuCategories } = useContext(Auth);
  const location = useLocation();
  const routeName = location.pathname.split('/')[1];
  return (
    <div className="sidebar">

      <div id='left' className={leftOpen} >
        <div>
          <div className='content left-sidebar'>
            <div className="product-filter-section">
              <div className="clear-filter">
                <Row className="no-gutters">
                  <Col xs={6} sm={6} md={6} lg={4} className="p-0 m-0">
                    <span className="filters filter-custom-badge-wrapper cp"><i className="icon-side_baar mr-1"></i> {t("Filters")} {filterCount > 0 && (<span className="badge badge-danger filter-custom-badge">{filterCount}</span>)}</span>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={8} className="p-0 m-0">
                    <span className="clear-all cp" onClick={handleClearFilter} >Clear all</span>
                  </Col>
                </Row>
              </div>
            </div>
            {routeName === '' && (
              <Accordion >

                <div key={`category-subfilter`}>

                  <Card className='custom-accordian' >
                    <Card.Header className="">
                      <Accordion.Toggle as={Button} variant="link" eventKey={`category-subfilter`} >{t('Category')} <span className={selectedCategory !== '' ? 'active' : ''}>{selectedCategory !== '' ? <i className="icon-right"></i> : ''}</span></Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`category-subfilter`}>
                      <Card.Body>

                        <div className="category-name">

                          <ul>
                            {menuCategories?.map((option, i) => (
                              <li key={`filtered_list-${i}`} data-filter_value={option.slug} onClick={(e) => handleSelectedCategory(e)} className={(String(selectedCategory) === String(option.slug)) ? 'active' : ''}><i className="icon-right"></i> {option.name}</li>
                            ))
                            }

                          </ul>

                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                </div>

              </Accordion>
            )}
            <Accordion >
              {categoryFilterData?.sub_category_fields?.map((filter, i) => (
                <div key={`custom-subfilter-${i}`}>
                  {String(categoryID) === 'militaria' && String(filter.id) === '4' ? null : (
                    <Card className={String(filter.type) === "select" ? '' : 'dateRangeOverflow'} key={i}>
                      <Card.Header className="">
                        <Accordion.Toggle as={Button} variant="link" eventKey={filter.id} >{filter.name} <span className={filterOption.find(f => String(f.id) === String(filter.id)) ? 'active' : ''}>{filterOption.find(f => String(f.id) === String(filter.id)) ? <i className="icon-right"></i> : ''}</span></Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={filter.id}>
                        <Card.Body>
                          {String(filter.type) === 'select' && (
                            <div className="filter-search-wrapper">
                              <div className="category-search-icon">
                                <i className="icon-search"></i>
                              </div>
                              <Form.Control type="text" data-filter_id={filter.id} onChange={(e) => filterCountries(e, filter?.options)} placeholder="search list" />
                            </div>
                          )}
                          <div className="category-name">
                            {String(filter.type) === "select" ? (
                              <ul>
                                {filteredList.find(f => String(f.id) === String(filter.id)) ? (
                                  filteredList.find(f => String(f.id) === String(filter.id))?.data?.slice(0, showNumberOfFilters).map((option, i) => (
                                    <li key={`filtered_list-${i}`} data-filter_value={option.id} data-filter_id={filter.id} onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id)) ? 'active' : ''}><i className="icon-right"></i> {option.value}</li>
                                  ))
                                ) : (
                                  filter?.options?.slice(0, showNumberOfFilters).map((option, i) => (
                                    <li key={`filtered_list1-${i}`} data-filter_value={option.id} data-filter_id={filter.id} onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id)) ? 'active' : ''}><i className="icon-right"></i> {option.value}</li>
                                  ))
                                )}
                                <div className="loadmorebutton">
                                  {filter?.options?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >Load more</Button>)}
                                </div>
                              </ul>
                            ) : (
                              (String(filter.type) === "date_range" ? (
                                <div >
                                  <div>
                                    <Col xs={12} sm={12} md={12} lg={12} className="dateRange-input">
                                      <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">
                                          <Form.Control
                                            onChange={(e) => { handleFirstRangeInput(filter.id, e.target.value) }}
                                            data-filter_id={filter.id}
                                            placeholder="From"
                                            type="text"
                                            data-type='min'
                                            value={minDate}
                                            className="form-control" >
                                          </Form.Control>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">

                                          <Form.Control placeholder="To"
                                            onChange={(e) => { handleSecondRangeInput(filter.id, e.target.value) }}
                                            type="text"
                                            data-type='max'
                                            value={maxDate}
                                            className="form-control" >
                                          </Form.Control>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </div>
                                  <span className="invalid-feedback">
                                    {rangeError}
                                  </span>
                                </div>

                              ) : (

                                <div>

                                  <DateRangePicker onEvent={handleDatepickerValue}
                                    initialSettings={{
                                      singleDatePicker: true,
                                      showDropdowns: true,
                                      startDate: moment().toDate(),
                                      minYear: moment().year(),
                                      maxYear: parseInt(moment().format('YYYY'), 10),
                                    }}  >
                                    <Form.Control
                                      data-type="date"
                                      data-filter_id={filter.id}
                                      type="text" />
                                  </DateRangePicker>
                                </div>
                              ))
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )}
                </div>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideFilter;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import profileService from '../../../services/myProfile'
import { useToasts } from 'react-toast-notifications'
import Switch from "react-switch";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageLoader from '../../pageLoader'
import { useTranslation } from 'react-i18next';


const Notification = ({ active }) => {
	const { t } = useTranslation();
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const token = loginUser.access_token;
	const [formData, setFormData] = useState();
	const [loaded, setLoaded] = useState(true);
	const { addToast } = useToasts();

	// get user profile data
	const getEditProfileData = async () => {
		setLoaded(false);
		await profileService
			.editProfile()
			.then(response => {
				setLoaded(true);
				if (response.success) {
					setFormData({
						email_notification: response.data.user.email_notification,
						app_notification: response.data.user.app_notification,
					});

				} else {
					setLoaded(true);
					addToast(response.message, { appearance: 'error' })
				}

			})
			.catch(error => {
				setLoaded(false);
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})


	}


	useEffect(() => {
		profileService.setToken(token);
		getEditProfileData();
	}, [])

	// onChnage handler for email setting
	const handleEmailNotificationChange = (checked) => {
		const data = {
			...formData,
			email_notification: checked
		};
		setLoaded(false);
		updateUserProfile(data);
	}

	// onChnage handler for app notifiaction  setting
	const handleAppNotificationChange = (checked) => {
		const data = {
			...formData,
			app_notification: checked
		};
		setLoaded(false);
		updateUserProfile(data);
	}

	// update setting api 
	const updateUserProfile = (data) => {
		profileService.setToken(token);
		profileService
			.updateNotification(data)
			.then(response => {
				setLoaded(true);
				if (response.success) {
					setFormData(data);
					setFormData({
						email_notification: response.data.email_notification,
						app_notification: response.data.app_notification,
					});
					addToast(t('Notification settings updated Successfully'), { appearance: 'success' })

				} else {
					addToast(response.result, { appearance: 'error' })
				}
			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {

					if (error.response.status === 422) {

					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}


	if (active === 'Notification preferences') {
		return (
			<>
				<PageLoader isLoaded={loaded} />
				<div className="p-4">
					<div className="verified-dealer-form notification">
						<div className="title">
							<h1>{t('Notifications')}</h1>
						</div>
						<div className="desc">
							<p>{t('Select if you want to receive email or app notifications below.')} <br />{('We wont desturbe you if you dont want to')} :) </p>
						</div>
						<div className="notifications">

							<Row className="no-gutters">
								<Col xs={12} sm={12} md={12} lg={12} >
									<label className="mb-4">
										<span>{t('App Notifications')}</span>
										<Switch onChange={handleAppNotificationChange}
											checked={formData?.app_notification ? formData?.app_notification : false}
											uncheckedIcon={false}
											checkedIcon={false}

										/>
									</label>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} >
									<span>{t('Email Notifications')}</span>
									<label>
										<Switch onChange={handleEmailNotificationChange}
											checked={formData?.email_notification ? formData?.email_notification : false}
											uncheckedIcon={false}
											checkedIcon={false}
										/>
									</label>
								</Col>
							</Row>

						</div>
					</div>
				</div>
			</>
		)
	}
	return (
		<></>
	)

}
export default Notification;



/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from "./include/header";
import Footer from "./include/footer";
// import profileService from '../services/myProfile'
import supportServices from '../services/support'
import PageLoader from './pageLoader'
import SeoTags from './seo/seoTags'
import { useToasts } from 'react-toast-notifications'
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../Contexts/langContext';


var isHomePage = false;
const Support = () => {
   const { t } = useTranslation();
   const { lang } = useLangContext(langContext);
   const [isLoaded, setIsLoaded] = useState(false);
   // eslint-disable-next-line no-unused-vars
   const [supportMenu, setSupportMenu] = useState();
   const [pageName, setPageName] = useState();

   const [supportPageContent, setSupportPageContent] = useState();
   const [supportTopic, setSupportTopic] = useState();
   const [NavItems, setNavItems] = useState([]);
   let location = useLocation();
   const navigate = useNavigate();
   const query = new URLSearchParams(location.search);
   const page = query.get('page') ? query.get('page') : false;
   const topic = query.get('topic') ? query.get('topic') : false;
   const title = query.get('title') ? query.get('title') : false;
   const ttitle = query.get('ttitle') ? query.get('ttitle') : false;

   // contcat us
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;

   const [formData, setFormData] = useState({
      name: loginUser ? `${loginUser.username}` : '',
      email: loginUser ? `${loginUser.email}` : '',
      phone: loginUser ? `${loginUser.phone}` : ''
   });
   const [suggesstionFormData, setSuggesstionFormData] = useState({
      name: loginUser ? `${loginUser.username}` : '',
      idea: '',
      motivation: ''
   });

   const [validationError, setValidationError] = useState();
   const { addToast } = useToasts();

   // comman form onChange handler 
   const handleFromChange = (e) => {
      const { name, value: newValue, type } = e.target;
      const value = type === 'number' ? +newValue : newValue;
      setFormData({
         ...formData,
         [name]: value,
      });
   }
   // comman suggesstion Form onChange handler
   const handleSuggesstionFromChange = (e) => {
      const { name, value: newValue, type } = e.target;
      const value = type === 'number' ? +newValue : newValue;
      setSuggesstionFormData({
         ...suggesstionFormData,
         [name]: value,
      });
   }

   // check element has validation error 
   const hasErrorFor = (field) => {
      if (validationError) {

         const error = validationError.find(element => String(element.field) === String(field));
         if (error)
            return true;
      } else {
         return false;
      }
   }
   // render element`s validation error 
   const renderErrorFor = (field) => {
      if (hasErrorFor(field)) {
         const error = validationError.find(element => String(element.field) === String(field));
         return (
            <div className="error-wrapper">
               <span className='invalid-feedback'>
                  <strong>{error.message}</strong>
               </span>
            </div>
         )
      }
   }

   // handle form submit 
   const handleSubmit = (e) => {
      e.preventDefault();
      setIsLoaded(false)
      supportServices
         .supportTicket(formData)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setFormData({
                  name: loginUser ? `${loginUser.username}` : '',
                  email: loginUser ? `${loginUser.email}` : '',
                  phone: loginUser ? `${loginUser.phone}` : '',
                  message: ''
               });
               setValidationError()
               addToast(response.message, { appearance: 'success' })

            } else {
               addToast(response.result, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  setValidationError(
                     error.response.data.errors
                  )
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }

            }
         })
   }

   // handle suggesstion form submit 
   const handleSuggesstionSubmit = (e) => {
      e.preventDefault();
      setIsLoaded(false);
      //   profileService.setToken(token);

      supportServices
         .postSuggesstions(suggesstionFormData)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setSuggesstionFormData({
                  name: loginUser ? `${loginUser.username}` : '',
                  idea: '',
                  motivation: ''
               });
               setValidationError()
               addToast(response.message, { appearance: 'success' })

            } else {
               addToast(response.result, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  setValidationError(
                     error.response.data.errors
                  )
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }

            }
         })
   }

   useEffect(() => {
      getSupprtMenu(page)
   }, [location])

   // get support menu 
   const getSupprtMenu = async (page) => {
      setIsLoaded(false)
      supportServices
         .supportMenu(lang)
         .then(response => {
            if (response.success) {
               setSupportMenu(response.data)
               let NavData = [];
               for (let index = 0; index < response.data.length; index++) {
                  const element = response.data[index];
                  let NavSubData = [];
                  if (element.faq_sub_category.length > 0) {
                     for (let i = 0; i < element.faq_sub_category.length; i++) {
                        const el = element.faq_sub_category[i];
                        const newNavSub = {
                           title: el.title,
                           itemId: `/support?page=${el.slug}&title=${el.title}`
                        }
                        NavSubData.push(newNavSub);
                     }
                     const newNav = {
                        title: element.title,
                        subNav: NavSubData,
                        itemId: index
                     }
                     NavData.push(newNav);

                  } else {
                     const newNav = {
                        title: element.title,
                        itemId: index
                     }
                     NavData.push(newNav);
                  }



               }
               setNavItems(NavData)
               if (page) {
                  setPageName(`/support?page=${page}&title=${title}`)
                  getSupprtPageContent(page);
               } else {
                  setPageName(`/support?page=${response.data[0].faq_sub_category[0].slug}&title=${response.data[0].faq_sub_category[0].title}`)
                  getSupprtPageContent(response.data[0].faq_sub_category[0].slug)
               }
               if (topic) {
                  getTpoicContent(topic)
               }

            } else {
               // addToast(response.result, { appearance: 'error' })
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               addToast(error.response.data.message, { appearance: 'error' })
            }
         })
   }

   // get page data 
   const getSupprtPageContent = async (page) => {
      supportServices
         .supportPageContent(page, lang)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setSupportPageContent(response.data)
            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               addToast(error.response.data.message, { appearance: 'error' })
            }
         })
   }

   // get topic 
   const getTpoicContent = async (page) => {
      supportServices
         .supportTopic(page, lang)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setSupportTopic(response.data)

            }
         })
         .catch(error => {
            setIsLoaded(true);
            if (error.response) {
               addToast(error.response.data.message, { appearance: 'error' })
            }
         })
   }

   const getTitle = ({ ttitle }) => {
      return ttitle ? `Miltrade | Support | ${page} |${title} | ${topic}&ttitle=${ttitle}` : `Miltrade | Support | ${page} | ${title}`;
   }




   return (
      <>
         <SeoTags title={getTitle(ttitle)}
            description={''}
            category={''}
            url={window.location.href}
            image={''}
         />
         {/* Start of header section */}
         <Header
            data={isHomePage}
            checkAuth={false}
         />

         <PageLoader isLoaded={isLoaded} />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                           <Breadcrumb.Item linkProps={{ to: '/support' }} linkAs={Link} >{t("Support")}</Breadcrumb.Item>
                           <Breadcrumb.Item active={ttitle ? false : true} linkProps={{ to: `/support?page=${page}&title=${title}` }} linkAs={Link} >{title ? title : ''}</Breadcrumb.Item>
                           {ttitle && (<Breadcrumb.Item active linkProps={{ to: `/support?page=${page}&title=${title}&topic=${topic}&ttitle=${ttitle}` }} linkAs={Link} >{ttitle ? ttitle : ''}</Breadcrumb.Item>)}
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>

            <div className="faq-page">
               <Container>
                  <Row>
                     <Col xs={12} sm={12} md={3} lg={3}>
                        <div className="left-side">
                           <h3>Help Center</h3>
                           <Navigation
                              activeItemId={pageName}
                              onSelect={({ itemId }) => {
                                 if (typeof (itemId) != 'undefined') {
                                    navigate(itemId);
                                 }
                              }}
                              items={NavItems}
                           />
                        </div>
                     </Col>
                     {topic ? (
                        <Col xs={12} sm={12} md={9} lg={9}>
                           <div className="right-side">
                              <div className="title">
                                 <h4>  {supportTopic?.title}</h4>

                              </div>
                              <div className="content-render">
                                 {/* {supportTopic?.content} */}

                                 <div dangerouslySetInnerHTML={{ __html: supportTopic?.content }}></div>
                              </div>
                              {supportTopic?.contact_form && (
                                 <div className="contact_us">
                                    <div className="login-form">
                                       <h1>{t("Leave us a message!")}</h1>
                                       <Row>
                                          <Col xs={12} sm={12} md={12} lg={12}>

                                          </Col>
                                       </Row>
                                       <Form onSubmit={handleSubmit}>
                                          <Row>

                                             <Col xs={12} sm={12} md={12} lg={6} >
                                                <div className="form-group">
                                                   <div className="form-group">
                                                      <div className="material-textfield mt-4">
                                                         <span className="icon-name"></span>
                                                         <input placeholder=" "
                                                            name="name"
                                                            className={`${hasErrorFor('name') ? 'is-invalid' : 'valid'}`}
                                                            onChange={handleFromChange}
                                                            value={formData?.name}
                                                            type="text" />
                                                         <label>{t("Enter your full name")} *</label>
                                                      </div>
                                                      {renderErrorFor('name')}
                                                   </div>
                                                </div>
                                             </Col>
                                             <Col xs={12} sm={12} md={12} lg={6} >
                                                <div className="form-group">
                                                   <div className="material-textfield mt-4">
                                                      <span className="icon-email"></span>
                                                      <input placeholder=" "
                                                         className={`${hasErrorFor('email') ? 'is-invalid' : 'valid'}`}
                                                         onChange={handleFromChange}
                                                         value={formData?.email}
                                                         name="email"
                                                         id="email"
                                                         type="text" />
                                                      <label>{t("Enter your email address")} *</label>
                                                   </div>
                                                   {renderErrorFor('email')}
                                                </div>
                                             </Col>
                                             <Col xs={12} sm={12} md={12} lg={6} >
                                                <div className="form-group">
                                                   <div className="material-textfield mt-4">
                                                      <span className="icon-phone"></span>
                                                      <input placeholder=" "
                                                         className={`${hasErrorFor('phone') ? 'is-invalid' : 'valid'}`}
                                                         onChange={handleFromChange}
                                                         value={formData?.phone}
                                                         name="phone"
                                                         id="email"
                                                         type="number" />
                                                      <label>{t("Enter your phone number")} *</label>
                                                   </div>
                                                   {renderErrorFor('phone')}
                                                </div>
                                             </Col>
                                             <Col xs={12} sm={12} md={12} lg={6} >
                                                <div className="form-group">
                                                   <div className="material-textfield mt-4">
                                                      <span className="icon-company_description"></span>
                                                      <div className="textarea1">
                                                         <textarea className={`${hasErrorFor('message') ? 'is-invalid' : 'valid'}`}
                                                            placeholder="  "
                                                            name="message"
                                                            onChange={handleFromChange}
                                                            value={formData?.message}
                                                            rows="3"></textarea>
                                                         <label>{t("Let us know how we can help you")} *</label>
                                                      </div>

                                                   </div>
                                                   {renderErrorFor('message')}
                                                </div>
                                             </Col>
                                             <Col xs={12} sm={12} md={12} lg={12} >
                                                <div className="large-button mt-3"><Button variant="primary" type="submit"> Submit</Button></div>
                                             </Col>
                                          </Row>
                                       </Form>
                                    </div>
                                 </div>
                              )}
                              {supportTopic?.suggesstion_form && (
                                 <div className="contact_us">
                                    <div className="login-form">
                                       <h1>{t("Leave us a suggesstion!")}</h1>
                                       <Form onSubmit={handleSuggesstionSubmit}>
                                          <Row>
                                             <Col xs={12} sm={12} md={12} lg={6} >
                                                <div className="form-group">
                                                   <div className="form-group">
                                                      <div className="material-textfield mt-4">
                                                         <span className="icon-name"></span>
                                                         <input placeholder=" "
                                                            name="name"
                                                            className={`${hasErrorFor('name') ? 'is-invalid' : 'valid'}`}
                                                            onChange={handleSuggesstionFromChange}
                                                            value={suggesstionFormData?.name}
                                                            type="text" />
                                                         <label>{t("Enter your full name")} *</label>
                                                      </div>
                                                      {renderErrorFor('name')}
                                                   </div>
                                                </div>
                                             </Col>
                                             <Col xs={12} sm={12} md={12} lg={6} >
                                                <div className="form-group">
                                                   <div className="material-textfield mt-4">
                                                      <span className="icon-email"></span>
                                                      <input placeholder=" "
                                                         className={`${hasErrorFor('idea') ? 'is-invalid' : 'valid'}`}
                                                         onChange={handleSuggesstionFromChange}
                                                         value={suggesstionFormData?.idea}
                                                         name="idea"
                                                         id="email"
                                                         type="text" />
                                                      <label>{t("Enter your Idea")} *</label>
                                                   </div>
                                                   {renderErrorFor('idea')}
                                                </div>
                                             </Col>

                                             <Col xs={12} sm={12} md={12} lg={12} >
                                                <div className="form-group">
                                                   <div className="material-textfield mt-4">
                                                      <span className="icon-company_description"></span>
                                                      <div className="textarea1">
                                                         <textarea className={`${hasErrorFor('motivation') ? 'is-invalid' : 'valid'}`}
                                                            placeholder=""
                                                            name="motivation"
                                                            onChange={handleSuggesstionFromChange}
                                                            value={suggesstionFormData?.motivation}
                                                            rows="3"></textarea>
                                                         <label>{t("Motivation behind idea")}  *</label>
                                                      </div>

                                                   </div>
                                                   {renderErrorFor('motivation')}
                                                </div>
                                             </Col>
                                             <Col xs={12} sm={12} md={12} lg={12} >
                                                <div className="large-button mt-3"><Button variant="primary" type="submit"> {t("Submit")}</Button></div>
                                             </Col>
                                          </Row>
                                       </Form>
                                    </div>
                                 </div>
                              )}
                           </div>
                        </Col>
                     ) : (
                        <Col xs={12} sm={12} md={9} lg={9}>
                           <div className="right-side">
                              <div className="title">
                                 <h4>{title ? title : "Pick a topic"}</h4>
                              </div>
                              <div className="question-list">
                                 <ul>
                                    {supportPageContent?.map((topic, i) => (
                                       <li key={i} ><Link to={`${pageName}&topic=${topic?.slug}&ttitle=${topic?.title}`}><i className="fa fa-flag-o" aria-hidden="true"></i> {topic?.title}</Link></li>
                                    ))}
                                 </ul>
                              </div>
                           </div>
                        </Col>
                     )}

                  </Row>
               </Container>
            </div>
         </div>


         <Footer />
      </>
   );
}
export default Support;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import Header from "../include/header";
import Footer from "../include/footer";
import MyAds from "./myAds/myAds";
import MyFavorites from "./myFavAds/myFavAds";
import myProfileServices from "../../services/myProfile"
import { useToasts } from 'react-toast-notifications'
import PageLoader from '../pageLoader'
import productSerivces from '../../services/posts';
import Swal from 'sweetalert2'
import SeoTags from '../seo/seoTags'
import { Auth } from '../../Contexts/AuthContext';
import { langContext, useLangContext } from '../../Contexts/langContext';
import { useTranslation } from 'react-i18next';


const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
const token = loginUser.access_token;
var isHomePage = false;

const MyListing = () => {
	const { setWalletBalance } = useContext(Auth);
	const [allPosts, setAllPosts] = useState();
	const [posts, setPosts] = useState();
	const [favPosts, setFavPosts] = useState();
	const [allPostCount, setAllPostCount] = useState(0);
	const [activePostCount, setActivePostCount] = useState(0);
	const [expiredPostCount, setExpiredPostCount] = useState(0);
	const [soldPostCount, setSoldPostCount] = useState(0);

	const [listing, setListing] = useState('MyFavorites')
	const [filterBy, setFilterBy] = useState('ViewAll');
	const [view, setView] = useState('grid');
	const navigate = useNavigate();
	const { addToast } = useToasts();
	const [loaded, setLoaded] = useState(true);
	const { t } = useTranslation()
	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			cancelButton: 'mr-2 btn btn-danger'
		},
		buttonsStyling: false,


	})
	const { lang } = useLangContext(langContext);


	// get user listing 
	const getMyListing = async () => {
		setLoaded(false);
		await myProfileServices
			.getMyListing(lang)
			.then(response => {
				if (response.success) {
					setLoaded(true);
					setAllPosts(response.data)
					setFavPosts(response.data.my_liked_posts)
					setPosts(response.data.my_posts)
					setAllPostCount(response.data.my_posts?.length);
					setActivePostCount(response.data.my_posts.filter(item => !item.expired)?.length);
					setExpiredPostCount(response.data.my_posts.filter(item => item.expired && !item.sold && !item.deleted)?.length);
					setSoldPostCount(response.data.my_posts.filter(item => item.sold)?.length);

				} else {
					addToast(response.message, { appearance: 'error' })
				}

			})
			.catch(error => {

				setLoaded(true)
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})

	}

	// handle filter 
	const handlefilter = (e) => {

		var str = e.target.value || '';
		const lowercasedFilter = str.toLowerCase();
		if (listing === 'MyAds') {
			const data = allPosts.my_posts.filter(item => {
				return item.title.toLowerCase().includes(lowercasedFilter);
			});
			// setPosts(filteredData);
			var status = filterBy;
			HandleFilterByStatusWithSearch(status, data)
		}
		if (listing === 'MyFavorites') {
			const filteredData = allPosts.my_liked_posts.filter(item => {
				return item.title.toLowerCase().includes(lowercasedFilter);
			});
			setFavPosts(filteredData);
		}
	}

	useEffect(() => {
		myProfileServices.setToken(token);
		getMyListing(lang);
	}, [])

	// handle filter status locally 
	const HandleFilterByStatus = (status) => {
		setFilterBy(status);
		if (status === 'ViewAll') {
			setPosts(allPosts.my_posts)
		}
		if (status === 'ActiveAds') {
			var filtered = allPosts.my_posts.filter(item => !item.expired);
			setActivePostCount(filtered?.length);
			setPosts(filtered)
		}
		if (status === 'ExpiredAds') {
			const filtered = allPosts.my_posts.filter(item => item.expired && !item.sold && !item.deleted);
			setExpiredPostCount(filtered?.length);
			setPosts(filtered)
		}
		if (status === 'SoldAds') {
			const filtered = allPosts.my_posts.filter(item => item.sold);
			setActivePostCount(filtered?.length);
			setPosts(filtered)
		}

	}

	// remove post 
	const deletePost = (post_id) => {
		productSerivces.setToken(token)
		swalWithBootstrapButtons.fire({
			title: t('Are you sure?'),
			text: t("You won't be able to revert this!"),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: t('Yes, delete it!'),
			cancelButtonText: t('No, cancel!'),
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
				setLoaded(false);
				productSerivces.deletePost(post_id)
					.then(response => {
						setLoaded(true);
						if (response.success) {
							// setFilteredPosts(filteredPosts.filter(p=>p.postid != post_id))
							setPosts(posts.filter(p => parseInt(p.postid) !== parseInt(post_id)));
							swalWithBootstrapButtons.fire(
								t('Deleted!'),
								t('Your Listing  has been deleted.'),
								'success'
							)
						} else {
							swalWithBootstrapButtons.fire(
								t('Error!'),
								t('Unable to delete this Listing.'),
								'error'
							)
						}

					})
					.catch(error => {

						setLoaded(true);
						swalWithBootstrapButtons.fire(
							t('Error!'),
							error.response.data.message,
							'error'
						)
					})

			} else if (
				/* Read more about handling dismissals below */
				result.dismiss === Swal.DismissReason.cancel
			) {
				swalWithBootstrapButtons.fire(
					t('Cancelled'),
					t('Your Listing is safe :)'),
					'error'
				)
			}
		})
	}

	// mark post sold 
	const markSoldPost = (post_id) => {
		productSerivces.setToken(token)
		setLoaded(false);
		productSerivces.markSoldPost(post_id)
			.then(response => {
				setLoaded(true);
				const currentPost = posts.find(p => parseInt(p.postid) === parseInt(post_id));
				if (typeof (currentPost) != 'undefined') {
					const updatedPost = {
						...currentPost,
						can_delete: false,
						can_edit: false,
						expired: true,
						sold: true

					}
					let ds = posts.map(p => parseInt(p.postid) === parseInt(post_id) ? updatedPost : p);
					setPosts(ds);
				}
				addToast(t('Listing has been marked Sold'), { appearance: 'success' })
			})
			.catch(error => {

				setLoaded(true);
				swalWithBootstrapButtons.fire(
					t('Error!'),
					error.response.data.message,
					'error'
				)
			})
	}

	// handle filter by status & serach
	const HandleFilterByStatusWithSearch = (status, data) => {

		if (status === 'ViewAll') {
			setPosts(data)
		}
		if (status === 'ActiveAds') {
			var filtered = data.filter(item => !item.expired);
			setActivePostCount(filtered?.length);
			setPosts(filtered)
		}
		if (status === 'ExpiredAds') {
			const filtered = data.filter(item => item.expired && !item.sold && !item.deleted);
			setExpiredPostCount(filtered?.length);
			setPosts(filtered)
		}
		if (status === 'SoldAds') {
			const filtered = data.filter(item => item.sold);
			setActivePostCount(filtered?.length);
			setPosts(filtered)
		}

	}

	// Renew post boost
	const renewPost = (post_id, rate) => {
		if (!(loginUser.issubscribed) && (parseInt(allPosts.wallet_balance) < parseInt(rate))) {
			navigate('/wallet')

			swalWithBootstrapButtons.fire({
				title: t('Not enough credit in wallet ..!'),
				text: `${t("Please add")} ${rate} ${t("credit to renew listing")}`,
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'ok',
				closeOnClickOutside: false,
				allowOutsideClick: false,
				reverseButtons: true
			}).then((result) => {
				if (result.isConfirmed) {
				} else if (
					result.dismiss === Swal.DismissReason.cancel
				) {

				}
			})


		} else {
			productSerivces.setToken(token)
			const data = {
				id: post_id,
				rate: rate,
			}
			setLoaded(false);
			productSerivces.renewPost(data)
				.then(response => {
					setLoaded(true);
					const updated_wallet_balance = parseInt(allPosts?.wallet_balance) - parseInt(rate);

					const updatedLoginUser = {
						...loginUser,
						wallet_balance: updated_wallet_balance
					}


					if (!loginUser.issubscribed) {

						setWalletBalance(updated_wallet_balance);
						window.localStorage.setItem('total_balance', updated_wallet_balance);
						window.localStorage.setItem('loggedUser', JSON.stringify(updatedLoginUser))
					}


					swalWithBootstrapButtons.fire({
						title: t('Listing Renewed!'),
						text: t(`Your Listing is live now !!`),
						icon: 'success',
						showCancelButton: false,
						confirmButtonText: 'ok',
						closeOnClickOutside: false,
						allowOutsideClick: false,
						reverseButtons: true
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						} else if (
							result.dismiss === Swal.DismissReason.cancel
						) {
							window.location.reload();
						}
					})
				})
				.catch(error => {

					setLoaded(true);
					swalWithBootstrapButtons.fire(
						t('Error!'),
						error.response.data.message,
						'error'
					)
				})
		}
	}
	// Unlike post 
	const UnlikePost = (postID) => {
		setLoaded(false);
		productSerivces.setToken(token)
		productSerivces.unLikePost(postID)
			.then(res => {
				setLoaded(true);
				setFavPosts(favPosts.filter(fp => parseInt(fp.postid) !== parseInt(postID)));
				addToast(t('Listing Unliked'), { appearance: 'success' })
			})
			.catch(err => {
				setLoaded(true);
				console.log('error in likes');
			})
	}






	return (
		<>
			<SeoTags title={`Miltrade | My Favourite Listing`}
				description={''}
				category={''}
				url={window.location.href}
				image={''}
			/>
			{/* Start of header section */}
			<Header isHomePage={isHomePage} checkAuth={true} />
			{/* end of header section */}
			<div className="footer-bottom1">
				<div className="breadcrum">
					<Container fluid>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Breadcrumb>
									<Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
									<Breadcrumb.Item active>{t("My Favorite listings")}</Breadcrumb.Item>
								</Breadcrumb>
							</Col>
						</Row>
					</Container>
				</div>
				<PageLoader isLoaded={loaded} />
				<div className="favorites-ads">
					<Row className="no-gutters">
						<Col xs={12} sm={6} md={5} lg={5}>
							<ul>
								<li><Link onClick={() => setListing('MyAds')} className={listing === 'MyAds' ? 'link  ads active' : 'link'}>{t("My listings")}</Link></li>
								<li><Link onClick={() => setListing('MyFavorites')} className={listing === 'MyFavorites' ? 'link ads active' : 'link'} >{t("My Favorites")}</Link></li>
							</ul>
						</Col>
						<Col xs={12} sm={12} md={2} lg={2}>
							{listing !== "MyAds" && (
								<div className="filterby py-2 mr-4">
									<div className="grid-list-view my-ads-grid-list-view ml-5">
										<ul>
											<li><span className={view === 'grid' ? 'link  ads active' : 'link ads'} onClick={() => setView('grid')}   ><i className="icon-grid_view"></i></span> </li>
											<li><span className={view === 'list' ? 'link  ads active ml-3' : 'link ads ml-3'} onClick={() => setView('list')}   ><i className="icon-list_view"></i></span> </li>
										</ul>
									</div>
								</div>
							)}
						</Col>
						<Col xs={12} sm={12} md={4} lg={4}>
							<Form>
								<div className="input-group mb-3">
									<input type="text" className="form-control" onChange={handlefilter} placeholder={t("Search by my listings")} />
									<div className="input-group-append">
										<button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="icon-search"></i></button>
									</div>
								</div>
							</Form>
						</Col>
						<Col xs={12} sm={12} md={1} lg={1}>

						</Col>

					</Row>
					{/* </Container> */}
				</div>
				<div className="filterby mylist py-3">
					<Container>
						<Row>
							{listing === "MyAds" && (
								<>
									<Col xs={12} sm={12} md={9} lg={9}>
										<div className="ads-btn">
											<ul>
												<li><span>{t("Filter by")} :</span></li>
												<li><span onClick={() => HandleFilterByStatus('ViewAll')} className={filterBy === 'ViewAll' ? 'link btn btn-primary ads active filters-l cp' : 'filters-l cp'}  >{t("All")} ({allPostCount})</span></li>
												<li><span onClick={() => HandleFilterByStatus('ActiveAds')} className={filterBy === 'ActiveAds' ? 'link btn btn-primary active filters-l cp' : 'filters-l cp'}  >{t("Active")} ({activePostCount})</span></li>
												<li><span onClick={() => HandleFilterByStatus('ExpiredAds')} className={filterBy === 'ExpiredAds' ? 'link btn btn-primary active filters-l cp' : 'filters-l cp'}  >{t("Expired")} ({expiredPostCount})</span></li>
												<li><span onClick={() => HandleFilterByStatus('SoldAds')} className={filterBy === 'SoldAds' ? 'link btn btn-primary active filters-l cp' : 'filters-l cp'}   >{t("Sold")} ({soldPostCount})</span></li>
											</ul>
										</div>
									</Col>
									<Col xs={12} sm={12} md={3} lg={3}>
										<div className="grid-list-view my-ads-grid-list-view ml-5">
											<ul>
												<li><span className={view === 'grid' ? 'link  ads active' : 'link ads'} onClick={() => setView('grid')}   ><i className="icon-grid_view"></i></span> </li>
												<li><span className={view === 'list' ? 'link  ads active ml-3' : 'link ads ml-3'} onClick={() => setView('list')}   ><i className="icon-list_view"></i></span> </li>
											</ul>
										</div>
									</Col>
								</>
							)}

						</Row>
					</Container>
					{/* myfav-post */}
					<div className="post-edit-section mt-2">
						{listing === "MyAds" ? (<MyAds posts={posts} deletePost={deletePost} renewPost={renewPost} markSoldPost={markSoldPost} view={view} />) : (<MyFavorites UnlikePost={UnlikePost} favPosts={favPosts} view={view} />)}

					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default MyListing;



/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';

import Header from "../include/header";
import { useLocation } from "react-router-dom";
import chatService from "../../services/chat"
import { useToasts } from 'react-toast-notifications'
import Image from 'react-bootstrap/Image';
import Conversation from './conversation'
import moment from 'moment';
import PageLoader from '../pageLoader'

import SeoTags from '../seo/seoTags'
import { io } from "socket.io-client";
import { isMobile } from "react-device-detect";
import homePageServices from "../../services/home";
import { Auth } from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../../Contexts/langContext';
import translateText from '../../services/googleTranslate';


require('dotenv').config();

// CommonJS




const Messages = () => {
	const { t } = useTranslation();
	const { lang } = useLangContext(langContext);
	const { setNewMessageCount } = useContext(Auth);
	let ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;
	const { addToast } = useToasts();

	// const io = require("socket.io-client");
	var socket = io(ENDPOINT, {
		transports: ['websocket']
	});
	// var socket = io(ENDPOINT);
	const [loaded, setLoaded] = useState(false);
	const [chatThreds, setChatThreds] = useState([]);
	const [emptyThred, setEmptyThred] = useState(false);
	const [chatThredsMessages, setChatThredsMessages] = useState();
	const [addData, setAddData] = useState([]);
	const [newMessage, setNewMessage] = useState(false);
	const [activeThread, setActiveThread] = useState('');
	// const [filterByStatus, setFilterByStatus] = useState('All');
	const [filterString, setFilterString] = useState('');
	// const [chatType, setChatType] = useState('All');
	const [nav, setNav] = useState(false);
	const [userLeaved, setUserLeaved] = useState(false);
	const [userJoined, setUserJoined] = useState(false);
	const [messageRead, setMessageRead] = useState(false);
	const [readThread, setReadThread] = useState(false);
	const [scrollChat, setScrollChat] = useState(true);
	const [inThreadMessageCount, setInThreadMessageCount] = useState(0);
	const [isArchived, setIsArchived] = useState(false);
	const [hasThreads, setHasThreads] = useState(false);
	const [toggleChatSearch, setToggleChatSearch] = useState(false);
	const [conversationPageNumber, setConversationPageNumber] = useState(1);
	const [chatInLineLoaded, setChatInLineLoaded] = useState(false);
	const [newPageData, setNewPageData] = useState(false);
	const [lastPage, setLastPage] = useState(1);


	const location = useLocation();
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;

	// Mark messages as readed
	const readMessages = (threadId) => {
		chatService.setToken(token);
		chatService
			.readMessages(threadId)
			.then(response => {
				// console.log("ok");
			})
			.catch(error => {

			})
	}

	useEffect(() => {
		if (conversationPageNumber > 1 && lastPage <= conversationPageNumber)
			loadMoreMessages()
	}, [conversationPageNumber])



	// const handleStatusFilter = (status) => {
	// 	// setFilterByStatus(status)
	// 	let filteredData = "";
	// 	setChatType(status);
	// 	if (status === "SELLING") {
	// 		filteredData = addData.filter(d => !Boolean(d.is_buyer))
	// 	} else if (status === "BUYING") {
	// 		filteredData = addData.filter(d => Boolean(d.is_buyer))
	// 	} else {
	// 		filteredData = addData
	// 	}
	// 	if (filteredData) {
	// 		setChatThreds(filteredData)
	// 		// setActiveThread(filteredData[0].id)	
	// 		// setChatThredsMessages(filteredData[0])	
	// 	}

	// }

	// local filter for chat post serach 
	const handleSearchfilter = (e) => {
		var str = e.target.value || '';
		setFilterString(str);
		const lowercasedFilter = str.toLowerCase();
		let filteredData = addData.filter(item => {
			return item.post_title.toLowerCase().includes(lowercasedFilter) || item.user_name.toLowerCase().includes(lowercasedFilter);
		});

		if (filteredData) {
			// if (chatType === "SELLING") {
			// 	filteredData = filteredData.filter(d => d.is_buyer == false)

			// }
			// else if (chatType === "BUYING") {
			// 	filteredData = filteredData.filter(d => d.is_buyer == true)

			// }

			setChatThreds(filteredData)
		}
	}

	// Get messages of thread 
	const getMessages = (thread_id) => {
		const threadReadData = {
			threadId: thread_id,
		}

		socket.emit('markThredRead', threadReadData, (error) => {
			if (error) {
				alert(error);
			}
		});

		chatService
			.threadMessages(thread_id, conversationPageNumber, lang)
			.then(response => {

				if (response.success) {
					setLastPage(response.data.last_page);
					let newMessagesRead = response.data.messages;
					const allMessages = newMessagesRead.map(m => ({
						...m,
						read: true
					}));
					setChatThredsMessages({
						...response.data,
						messages: allMessages,

					});
					setActiveThread(response.data.id)
					window.localStorage.setItem('active_thread', response.data.id);
					let threadId = response.data.id;
					let user_id = loginUser?.id;
					const data = {
						threadId: threadId,
						user_id: user_id
					}
					socket.emit('join', data, (error) => {
						if (error) {
							alert(error);
						}
					});
					readMessages(threadId);
					getMessagesCount()

				} else {
					addToast(response.message, { appearance: 'error' })
				}


			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}
	// Load more of thread 
	const loadMoreMessages = () => {

		chatService
			.threadMessages(activeThread, conversationPageNumber, lang)
			.then(response => {

				if (response.success) {
					if (response.data.messages?.length > 0) {

						let newMessagesRead = response.data.messages;
						const allMessages = newMessagesRead.map(m => ({
							...m,
							read: true
						}));
						const messages = allMessages.concat(chatThredsMessages.messages);
						setChatThredsMessages({
							...response.data,
							messages,
						});
						setChatInLineLoaded(false);
					} else {
						setChatInLineLoaded(false);
					}

					setNewPageData(true);
				}


			})
			.catch(error => {

				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}



	// handle send message 
	const sendMessages = async (thread_id, message, message_time, user_id, user_name, user_photo, receiver_id, msgtype, message_time_formated) => {

		if (msgtype === "text") {
			const socketData = {
				id: String(Date.now()),
				message: msgtype === "file" ? URL.createObjectURL(message) : message,
				message_time: message_time_formated,
				read: false,
				receiver_id: receiver_id,
				threadId: thread_id,
				type: msgtype,
				user_id: String(user_id),
				user_name: user_name,
				user_photo: user_photo
			}

			const data = {
				id: String(Date.now()),
				message: msgtype === "file" ? URL.createObjectURL(message) : message,
				message_time: message_time,
				read: false,
				receiver_id: receiver_id,
				threadId: thread_id,
				type: msgtype,
				user_id: user_id,
				user_name: user_name,
				user_photo: user_photo
			}

			socket.emit('sendMessage', socketData, (error) => {
				if (error) {
					alert(error);
				}
			});
			setScrollChat(true);
			const thread = chatThreds.find(t => parseInt(t.id) === parseInt(thread_id));
			// const newMessagesData = [data].concat(thread.messages);
			const newMessagesData = [data, ...thread.messages];
			const newThread = {
				...thread,
				messages: newMessagesData,
				last_message_at: message_time,
				last_message: message,
				last_message_user_id: socketData.user_id
			}

			setChatThreds(chatThreds.map(ct => parseInt(ct.id) !== parseInt(thread_id) ? ct : newThread));
			setAddData(addData.map(ct => parseInt(ct.id) !== parseInt(thread_id) ? ct : newThread));
			setChatThredsMessages({
				...chatThredsMessages,
				messages: chatThredsMessages.messages.concat([data])
			});
		} else {
			setLoaded(false);
		}

		// var objDiv = document.getElementById("chatbox");
		// 				objDiv.scrollTop = objDiv.scrollHeight;

		const formData = new FormData();
		formData.append(`thread_id`, thread_id)
		formData.append(`message`, message)
		formData.append(`type`, msgtype)
		chatService.setToken(token)
		chatService
			.sendMessages(formData)
			.then(response => {
				setLoaded(true);
				if (response.success) {
					if (msgtype === "file") {

						const newMessage = {
							...response.data.last_message,
							read: false
						}
						const thread = chatThreds.find(t => parseInt(t.id) === parseInt(thread_id));
						// const newMessagesData = [newMessage].concat(thread.messages);
						const newMessagesData = thread.messages.concat([newMessage]);
						const newThread = {
							...thread,
							messages: newMessagesData
						}
						setChatThreds(chatThreds.map(ct => parseInt(ct.id) !== parseInt(thread_id) ? ct : newThread));
						setAddData(addData.map(ct => parseInt(ct.id) !== parseInt(thread_id) ? ct : newThread));
						setChatThredsMessages({
							...chatThredsMessages,
							messages: chatThredsMessages.messages.concat([newMessage])
						});
						// var objDiv = document.getElementById("chatbox");
						// 			objDiv.scrollTop = objDiv.scrollHeight;
						const filedata = {
							...response.data.last_message,
							receiver_id: receiver_id,
							threadId: thread_id,
							type: msgtype,
						}
						socket.emit('sendMessage', filedata, (error) => {
							if (error) {
								console.log(error);
							}
						});
					}


				} else {
					addToast(response.message, { appearance: 'error' })
				}

			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}
				}
			})

	}

	// Open new chat thread in right side  
	const openNewChatThread = (thread) => {

		const unreadMessage = thread.messages.find(m => parseInt(m.user_id) === parseInt(loginUser.id));
		setChatThreds((prevState) => {
			return prevState.map((item) => {
				if (item.id === thread.id) {
					item.thread_unread_count = '0'
				}
				return item
			})
		})
		setAddData((prevState) => {
			return prevState.map((item) => {
				if (item.id === thread.id) {
					item.thread_unread_count = '0'
				}
				return item
			})
		})
		if (typeof (unreadMessage) != "undefined") {
			readMessages(thread.id);
		}
		if (isMobile) {
			setNav(true);
		}

		getMessages(thread.id)

		const makeReadData = {
			id: thread.id,
			threadId: thread.id,
			user_id: loginUser.id,
			read: true
		}
		setScrollChat(true)
		socket.emit('makeRead', makeReadData, (error) => {
			if (error) {
				alert(error);
			}
		});

	}
	// new message  count locally 
	const getMessagesCount = () => {
		var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;
		if (token) {
			homePageServices.setToken(token)
			homePageServices
				.getMessageCount()
				.then(response => {
					setNewMessageCount(response.data.count);
				})
		}
	}

	useEffect(() => {
		chatService.setToken(token);
		chatService
			.userThreads()
			.then(response => {
				setLoaded(true);
				if (response.success) {
					const data = response.data;
					if (data.length > 0) {
						setHasThreads(true);
						setAddData(data)
						if (location?.state?.thread_id) {
							var userThred = data.filter(data => (parseInt(data.id) === parseInt(location.state.thread_id)))
							if (userThred.length > 0) {
								var filteredData = data.filter(d => parseInt(d.id) !== parseInt(userThred[0].id));
								var pushOntop = userThred.concat(filteredData);
								window.localStorage.setItem('active_thread', pushOntop[0].id);
								setChatThreds(pushOntop.map(d => parseInt(d.id) === parseInt(pushOntop[0].id) ? { ...d, thread_unread_count: '0' } : d));
								getMessages(pushOntop[0].id)
							}
						} else {
							window.localStorage.setItem('active_thread', response.data[0].id);
							let threadId = response.data[0].id
							setChatThreds(response.data.map(d => parseInt(d.id) === parseInt(response.data[0].id) ? { ...d, thread_unread_count: '0' } : d));

							getMessages(threadId)

						}
					} else {
						setEmptyThred(true);
						setHasThreads(false);
						window.localStorage.setItem('active_thread', '');
					}

				} else {
					addToast(response.message, { appearance: 'error' })
				}


			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})

		socket.on('newChatThread', data => {
			if (String(data.seller_id) === String(loginUser?.id)) {
				setEmptyThred(false)
				setActiveThread((prevState) => {
					if (!prevState) {
						openNewChatThread(data)
						return data.id
					}
					return prevState
				})
				setChatThreds((prevState) => ([
					data,
					...prevState.filter((item) => item)
				]))
				setAddData((prevState) => ([
					data,
					...prevState.filter((item) => item)
				]))
			}
		})

		socket.on('isDetached', (data) => {
			setUserLeaved(data.user_id);
			if (parseInt(chatThredsMessages?.user_id) === parseInt(userLeaved)) {
				setChatThredsMessages({ ...chatThredsMessages, online: false })
				setChatThreds((prevState) => {
					return prevState.map((thread) => {
						if (thread.user_id === chatThredsMessages.user_id) {
							thread.online = false
						}
						return thread
					})
				})
				setAddData((prevState) => {
					return prevState.map((thread) => {
						if (thread.user_id === chatThredsMessages.user_id) {
							thread.online = false
						}
						return thread
					})
				})
			}
		});

		socket.on('isJoined', data => {
			setUserJoined(data.user_id)
		})

		socket.on('message', (message) => {
			console.log("INCOMMING-MESSAGE-->", message)
			var msg = typeof (message);
			var newMessageTemp = {}
			if (msg === 'string') {
				newMessageTemp = JSON.parse(message);
			}
			else {
				newMessageTemp = message
			}
			setNewMessage(newMessageTemp);
		});

		socket.on('markRead', (data) => {
			if (!data) return
			setMessageRead(data);
		});

		socket.on('threadRead', (data) => {
			setReadThread(data)
		});

		socket.on('disconnect', (data) => {
			console.log("--> NOT CONNECTED", data)
		});

		return () => {
			const adata = {
				user_id: loginUser?.id
			}
			socket.emit('detach', adata, (error) => {
				if (error) {
					alert(error);
				}
			});
			socket.off('disconnect', () => {
				console.log("-->OFF DISCONNECTED")
				socket.disconnect()
			});
			socket.on('disconnect', () => {
				console.log("-->ON DISCONNECTED")
				// socket.disconnect()
			});
		};

	}, [token])

	/**
	 * Socket handling
	 *  ---start--- 
	* */
	useEffect(() => {
		if (parseInt(chatThredsMessages?.user_id) === parseInt(userLeaved)) {
			setChatThredsMessages({ ...chatThredsMessages, online: false })
			setChatThreds((prevState) => {
				return prevState.map((thread) => {
					if (thread.user_id === chatThredsMessages.user_id) {
						thread.online = false
					}
					return thread
				})
			})
			setAddData((prevState) => {
				return prevState.map((thread) => {
					if (thread.user_id === chatThredsMessages.user_id) {
						thread.online = false
					}
					return thread
				})
			})
			return () => {
				const adata = {
					user_id: loginUser?.id
				}
				socket.emit('detach', adata, (error) => {
					if (error) {
						alert(error);
					}
				});
				socket.off('disconnect', () => {
					console.log("-->OFF DISCONNECTED")
					socket.disconnect()
				});
				socket.on('disconnect', () => {
					console.log("-->ON DISCONNECTED")
					// socket.disconnect()
				});
			};
		}
	}, [userLeaved])

	useEffect(() => {
		if (!userJoined) return
		setChatThreds((prevState) => {
			return prevState.map((thread) => {
				if (parseInt(thread.user_id) === parseInt(userJoined)) {
					thread.online = true
				}
				return thread
			})
		})
		setAddData((prevState) => {
			return prevState.map((thread) => {
				if (parseInt(thread.user_id) === parseInt(userJoined)) {
					thread.online = true
				}
				return thread
			})
		})
		if (parseInt(chatThredsMessages?.user_id) === parseInt(userJoined)) {
			setChatThredsMessages({ ...chatThredsMessages, online: true })
		}
		setUserJoined('');
		return () => {
			const adata = {
				user_id: loginUser?.id
			}
			socket.emit('detach', adata, (error) => {
				if (error) {
					alert(error);
				}
			});
			socket.off('disconnect', () => {
				console.log("-->OFF DISCONNECTED")
				socket.disconnect()
			});
			socket.on('disconnect', () => {
				console.log("-->ON DISCONNECTED")
				// socket.disconnect()
			});
		};
	}, [userJoined])

	useEffect(() => {

		if (!messageRead) return
		if (parseInt(messageRead.user_id) !== parseInt(loginUser.id)) {
			let receiverId = messageRead.user_id
			messageRead.user_id = String(loginUser.id)
			messageRead.receiver_id = String(receiverId)
			const findThisMsg = chatThredsMessages?.messages.find(m => String(m.id) === String(messageRead.id));
			const updateMessage = { ...findThisMsg, read: true }
			if (parseInt(chatThredsMessages?.id) === parseInt(messageRead.threadId)) {
				const thisMessage = chatThredsMessages?.messages.map(m => String(m.id) === String(messageRead.id) ? updateMessage : m);
				console.log("all MESSAGEWAS++>", thisMessage);
				setChatThredsMessages({
					...chatThredsMessages,
					messages: thisMessage
				})

			}
			const cthread = chatThreds?.find(t => parseInt(t.id) === parseInt(messageRead.threadId));
			if (typeof (cthread) != "undefined") {
				const thisTMessage = cthread?.messages.map(m => parseInt(m.id) !== parseInt(messageRead.id) ? m : updateMessage);
				const newThreadd = {
					...cthread,
					messages: thisTMessage
				}
				setChatThreds(chatThreds?.map(ct => parseInt(ct.id) === parseInt(messageRead.threadId) ? newThreadd : ct))
				setAddData(addData?.map(ct => parseInt(ct.id) === parseInt(messageRead.threadId) ? newThreadd : ct))
			}
		}
		return () => {
			const adata = {
				user_id: loginUser?.id
			}
			socket.emit('detach', adata, (error) => {
				if (error) {
					alert(error);
				}
			});
			socket.off('disconnect', () => {
				console.log("-->OFF DISCONNECTED")
				socket.disconnect()
			});
			socket.on('disconnect', () => {
				console.log("-->ON DISCONNECTED")
				// socket.disconnect()
			});
		};
	}, [messageRead])



	useEffect(() => {
		async function newMessageReceived() {
			// You can await here
			if (String(newMessage?.receiver_id) === String(loginUser?.id)) {
				let translatedMessage = '';
				if (lang !== 'en') {
					const t_ = await translateText(lang, newMessage.message);
					console.log("Translation ++++++++>", t_);
					if (t_ !== undefined) {
						const transArray = t_[0];
						transArray.forEach(element => {
							translatedMessage = translatedMessage + (element[0])
						});
					} else {
						translatedMessage = newMessage.message;
					}
				} else {
					translatedMessage = newMessage.message;
				}

				let thread = chatThreds.find(ch => parseInt(ch.id) === parseInt(newMessage.threadId));
				if (typeof (thread) != "undefined") {
					const newMessageData = {
						message: translatedMessage,
						read: true,
						message_time: newMessage.message_time,
						user_id: newMessage.user_id,
						user_name: newMessage.user_name,
						user_photo: newMessage.user_photo,
						type: newMessage.type,
						id: newMessage.id,
						threadId: newMessage.threadId
					}
					// console.log("INCOMGMSG==>",newMessageData);
					// let threadMessages = [newMessageData].concat(thread.messages); 
					let threadMessages = thread.messages.concat([newMessageData]);
					console.log("thread Messages==>", threadMessages);
					let newMsg = !Boolean(parseInt(chatThredsMessages.id) === parseInt(newMessage.threadId));

					const newThread = {
						...thread,
						messages: threadMessages,
						newMessage: parseInt(chatThredsMessages.id) === parseInt(newMessage.threadId) ? false : true,
						thread_unread_count: newMsg ? thread.thread_unread_count ? parseInt(thread.thread_unread_count) + 1 : 1 : '0',
						last_message_at: newMessage.message_time,
						last_message: newMessage.message,
						last_message_user_id: newMessage.user_id,
						online: true,
					}
					// console.log("new Messages==>",newThread);

					if (parseInt(chatThredsMessages.id) === parseInt(newMessage.threadId)) {
						const activeThread = {
							...chatThredsMessages,
							messages: chatThredsMessages.messages.concat([newMessageData]),
							newMessage: parseInt(chatThredsMessages.id) === parseInt(newMessage.threadId) ? false : true,
							last_message_at: newMessage.message_time,
							last_message: newMessage.message,
							last_message_user_id: newMessage.user_id,
							online: true,
						}
						setChatThredsMessages(activeThread)

						socket.emit('makeRead', { ...newMessageData, user_id: loginUser.id }, (error) => {
							if (error) {
								alert(error);
							}
						});
						setScrollChat(false);
						setInThreadMessageCount(inThreadMessageCount + 1)
						readMessages(chatThredsMessages.id);
					}
					setChatThreds(prevState => {
						let newArr = prevState.filter((th) => parseInt(th.id) !== parseInt(thread.id))
						return [newThread, ...newArr]
					})
					setAddData(prevState => {
						let newArr = prevState.filter((th) => parseInt(th.id) !== parseInt(thread.id))
						return [newThread, ...newArr]
					})
				}
			}
			// ...
		}
		newMessageReceived();
		return () => {
			const adata = {
				user_id: loginUser?.id
			}
			socket.emit('detach', adata, (error) => {
				if (error) {
					alert(error);
				}
			});
			socket.off('disconnect', () => {
				console.log("-->OFF DISCONNECTED")
				socket.disconnect()
			});
			socket.on('disconnect', () => {
				console.log("-->ON DISCONNECTED")
				socket.disconnect()
			});
		};
	}, [newMessage]);

	useEffect(() => {

		if (parseInt(chatThredsMessages?.id) === parseInt(readThread?.threadId)) {
			const allmessages = chatThredsMessages?.messages?.map(m => ({ ...m, read: true }));
			setChatThredsMessages({ ...chatThredsMessages, messages: allmessages })

		}
		const chatThread = chatThreds.find(t => parseInt(t.id) === parseInt(readThread?.threadId))
		if (typeof (chatThread) != "undefined") {
			const threads = chatThreds?.map(t => parseInt(t.id) === parseInt(readThread?.threadId) ? { ...t, newMessage: false } : t);
			setChatThreds(threads)
		}
		return () => {
			const adata = {
				user_id: loginUser?.id
			}
			socket.emit('detach', adata, (error) => {
				if (error) {
					alert(error);
				}
			});
			socket.off('disconnect', () => {
				console.log("-->OFF DISCONNECTED")
				socket.disconnect()
			});
			socket.on('disconnect', () => {
				console.log("-->ON DISCONNECTED")
				// socket.disconnect()
			});
		};
	}, [readThread])

	/**
	 * Socket handling 
	 * ---end---  
	 * */

	const dateToFromNowDaily = (myDate) => {

		// get from-now for this date
		// var fromNow = moment( myDate ).fromNow();
		var fromNow = moment(myDate).format("DD-MM-YYYY")
		var todaytime = moment(myDate).format("hh:mm")

		// ensure the date is displayed with today and yesterday
		return moment(myDate).calendar(null, {
			// when the date is closer, specify custom values

			lastWeek: "[" + fromNow + "]",
			lastDay: '[Yesterday]',
			sameDay: '[' + todaytime + ']',
			nextDay: '[Tomorrow]',
			nextWeek: 'dddd',
			// when the date is further away, use from-now functionality             
			sameElse: function () {
				return "[" + fromNow + "]";
			}
		});
	}

	// check is reaed message 
	const isRead = (thread) => {
		let last_msg = thread?.messages[thread?.messages?.length - 1];
		if (parseInt(last_msg.user_id) !== parseInt(loginUser?.id)) {
			return "double";
		} else {
			return last_msg.read ? "double" : 'single';
		}
	}

	// mark thread archive 
	const markThreadArchive = (id) => {
		chatService
			.markThreadArchive(id)
			.then(response => {

				if (response.success) {
					let filtredThread = chatThreds.find(th => parseInt(th.id) === parseInt(id));
					const archivedThread = {
						...filtredThread,
						archived: true
					}
					addToast(response.message, { appearance: 'success' })
					let filtredThreadIndex = chatThreds.findIndex(function (t) { return parseInt(t.id) === parseInt(id) })
					if (typeof filtredThreadIndex != "undefined") {
						setChatThreds((prevState) => {
							return prevState.map((item) => {
								if (item.id === id) {
									item.archived = true
								}
								return item
							})
						})
						setAddData((prevState) => {
							return prevState.map((item) => {
								if (item.id === id) {
									item.archived = true
								}
								return item
							})
						})
						setChatThredsMessages(chatThreds[filtredThreadIndex + 1])
						if (chatThreds[filtredThreadIndex]?.id === chatThredsMessages?.id) {


							if (chatThreds[filtredThreadIndex + 1]) {
								window.localStorage.setItem('active_thread', chatThreds[filtredThreadIndex + 1].id);
							} else if (chatThreds[filtredThreadIndex - 1]) {
								setChatThredsMessages(chatThreds[filtredThreadIndex - 1])
								window.localStorage.setItem('active_thread', chatThreds[filtredThreadIndex - 1].id);
							} else {
								setChatThredsMessages()
							}
						}
					}
					setChatThreds(chatThreds.map(t => parseInt(t.id) === parseInt(id) ? archivedThread : t))
					getMessages(id);



				} else {
					addToast(response.message, { appearance: 'error' })
				}


			})
			.catch(error => {

				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}

	// mark thread unarchive 
	const markThreadUnarchive = (id) => {
		chatService
			.markThreadUnarchive(id)
			.then(response => {

				if (response.success) {
					let filtredThread = chatThreds.find(th => parseInt(th.id) === parseInt(id));
					const archivedThread = {
						...filtredThread,
						archived: false
					}
					addToast(response.message, { appearance: 'success' })
					let filtredThreadIndex = chatThreds.findIndex(function (t) { return parseInt(t.id) === parseInt(id) })
					if (typeof filtredThreadIndex != "undefined") {
						setChatThreds((prevState) => {
							return prevState.map((item) => {
								if (item.id === id) {
									item.archived = true
								}
								return item
							})
						})
						setAddData((prevState) => {
							return prevState.map((item) => {
								if (item.id === id) {
									item.archived = true
								}
								return item
							})
						})

					}
					setChatThreds(chatThreds.map(t => parseInt(t.id) === parseInt(id) ? archivedThread : t))
					if (chatThreds.filter(t => t.archived)?.length === 1) {
						setIsArchived(false);
					}

					getMessages(id);

				} else {
					addToast(response.message, { appearance: 'error' })
				}


			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}

	// get number of archived threads
	const getArchiveChatsCount = () => {
		const archiveChats = chatThreds?.filter(t => t.archived);
		return archiveChats.length;
	}

	// archive toggle handling 
	const archiveUnarchive = (current) => {
		if (current) {
			const unarchived = chatThreds?.filter(ct => !ct.archived);
			if (unarchived?.length > 0) {
				setHasThreads(true);
				getMessages(unarchived[0].id);
			} else {
				setHasThreads(false);
			}
			return false;
		} else {
			const archived = chatThreds?.filter(ct => ct.archived);
			if (archived?.length > 0) {
				setHasThreads(true);
				getMessages(archived[0].id);
			} else {
				setHasThreads(false);
			}
			return true;
		}
	}

	// archive toggle
	const handleArchiveToggle = () => {
		setIsArchived((prv) => archiveUnarchive(prv));
	}



	return (
		<>
			<SeoTags title={`Miltrade | Messages`}
				description={''}
				category={''}
				url={window.location.href}
				image={''}
			/>
			{/* Start of header section */}

			<div className="message-header">
				<Header isHomePage={false} checkAuth={true} />
			</div>
			<PageLoader isLoaded={loaded} />
			<main className="_1_dLE _2yGlg container-fluid">
				<div className="_1U6Yu _2nuC5">
					<div id="myDiv" className={`_23_3V _25m0Z wrapper-chat ${nav ? "chat-sidepanel" : "chat-sidepanel1"}`}>
						{emptyThred ? (
							<>
								<div className="text-center">
									<Image src="../images/no_chat.webp" className="img-fluid" alt="user" />
								</div>
							</>

						) : (
							<>

								<div className={`_2RgLO _33y6y ${nav ? "chat-width" : "chat-width2"}`}>
									{/* <button className="chat-nav2 openbtn" onClick={()=>{setNav(!nav);}}>☰</button>  */}
									<div id="mySidepanel" className="chat-sidepanel chat-filters" >
										<div className="ragnarok-3bUN1 undefined">
											<div className="ragnarok-2RK-z">

												<div className="miltrade-chat">
													<div className="chat-message-head-wrapper">
														<div style={{ width: '85%' }}>
															{toggleChatSearch ? (
																<div className='chat-message-head-search'>
																	<input id="search" name="search" type="text" value={filterString} onChange={handleSearchfilter} placeholder={t("What're we looking for ?")} />
																</div>
															) : (
																<div className='chat-head-label'>
																	{isArchived && (<span className="cp"
																		onClick={handleArchiveToggle} ><i className="icon-back"></i> {t("Archived")}</span>)}
																	{!isArchived && (<span>{t("Miltrade Chats")}</span>)}
																</div>
															)}
														</div>
														<div style={{ width: '15%' }} className='cp' >
															<div onClick={() => setToggleChatSearch((prv) => !prv)} className="search-chat "> <i className="icon-search"></i></div>

														</div>
													</div>
													<div className="ragnarok-3I33w">
														{/* <div className="ragnarok-5c2zk"> <span>Chat Filters</span></div>
													
														<div className={`rui-3CaQD ragnarok-3IDu4 rui-yVvzs  ${filterByStatus=='All' ?'active ':''}`} onClick={()=>handleStatusFilter('All')}   role="button" tabIndex="0">
															<div className="">All</div>
														</div>
														<div className={`rui-3CaQD ragnarok-3IDu4 rui-yVvzs ${filterByStatus=='BUYING' ?'active ':''}`} onClick={()=>handleStatusFilter('BUYING')} role="button" tabIndex="0">
															<div>Buying</div>
														</div>
														<div className={`rui-3CaQD ragnarok-3IDu4 rui-yVvzs ${filterByStatus=='SELLING' ?'active ':''}`} onClick={()=>handleStatusFilter('SELLING')}  role="button" tabIndex="0">
															<div>Selling</div>
														</div> */}
														{getArchiveChatsCount() > 0 && (
															<div className={`ragnarok-3t5nL ragnarok-3IP53 ragnarok-2RlGI ${isArchived ? 'active ' : ''}`} onClick={handleArchiveToggle} role="button" tabIndex="0">
																<div> Archived <span className="badge badge-info new-message-label" >{getArchiveChatsCount()} </span></div>
															</div>
														)}
														<span className={`chat-arrow-cross cp ${nav ? "chat-sids" : "chat-sids1"}`} onClick={() => { setNav(!nav) }} >×</span>

													</div>
												</div>
												<div className="ragnarok-1Ze6U ragnarok-3X_Ne">
													<>

														{hasThreads && chatThreds?.filter(t => t.archived === isArchived).map((thread, index) => (
															<div key={index} className="left-chat-thread-list" >

																<div onClick={() => openNewChatThread(thread)} className={`ragnarok-3t5nL ragnarok-3IP53 ragnarok-2RlGI ${String(activeThread) === String(thread?.id) ? 'active' : ''}`} data-aut-id="listItemConversation">

																	<div data-aut-id="imageContainer" className="ragnarok-1bbpA">

																		{thread?.issubscribed ? (
																			<figure className="rui-D_EoZ1 ragnarok-1LyGY thread-list">
																				{loaded && (<Image src={thread?.user_photo} className="img-fluid user-img" alt="user" />)}
																				<Image src="../../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-image-chat" />
																			</figure>

																		) : (
																			<figure className="rui-D_EoZ ragnarok-1LyGY" >
																				{loaded && (<Image src={thread?.user_photo} className="img-fluid" alt="user" />)}
																			</figure>
																		)}



																	</div>
																	<div className="ragnarok-Mfamq">
																		<div data-aut-id="conversationUsername" className="ragnarok-hchi8 ragnarok-NCGOF verticalAlign">
																			{thread?.user_name}
																			<div className={`status-icon ${thread?.online && 'online'}`}></div>
																		</div>
																		<div data-aut-id="conversationItemTitle" className="ragnarok-178qS ragnarok-NCGOF">{thread?.post_title}</div>
																		<div data-aut-id="conversationLastMessage" className="ragnarok-2YNhq ragnarok-NCGOF">
																			<span className="ragnarok-2Z2G3">
																				{(thread?.messages?.length > 0 &&
																					!thread?.isTyping &&
																					parseInt(thread?.last_message_user_id) === parseInt(loginUser.id)) &&
																					(<i className={`icon-chat_right_mark_${isRead(thread)}`}></i>)}
																			</span>
																			<div className="ragnarok-2yNAA">

																				{(thread?.isTyping && parseInt(thread.id) !== parseInt(activeThread)) ? (
																					<strong class="text-primary">
																						typing...
																					</strong>
																				) : thread?.last_message_type === 'text' ? (
																					<>
																						{thread?.last_message}
																					</>

																				) : (

																					thread?.last_message_type === 'file' ? (
																						<>
																							<Image src="../images/picture.webp" className="img-fluid" alt="user" /> {t("Image")}
																						</>
																					) : (
																						<>
																							<Image src="../images/mp3.webp" className="img-fluid" alt="user" /> {t("Audio")}
																						</>
																					)


																				)}

																				{thread?.thread_unread_count > 0 &&
																					(<span className="badge badge-info new-message-label" >{thread.thread_unread_count} {t("new")}</span>)
																				}

																			</div>
																		</div>
																	</div>
																	<div className="ragnarok-3nZN8">
																		<div data-aut-id="lastMessageTimestamp" className="ragnarok-3bZZc"><span>{dateToFromNowDaily(thread?.last_message_at)}</span></div>
																	</div>
																	<div className="ragnarok-36mYB">

																	</div>

																</div>

															</div>
														))}
														{!hasThreads && (
															<div className="ragnarok-3t5nL ragnarok-3IP53 ragnarok-2RlGI " >
																<p> {t("No chat found")}</p>
															</div>
														)}
													</>
												</div>
											</div>
										</div>
									</div>
								</div>
								<button className="chat-nav2 openbtn" onClick={() => { setNav(!nav); }}>☰</button>

								<Conversation
									chatThredsMessages={chatThredsMessages}
									hasThreads={hasThreads}
									sendMessages={sendMessages}
									socket={socket}
									activeThread={activeThread}
									setChatThreds={setChatThreds}
									setAddData={setAddData}
									scrollChat={scrollChat}
									setScrollChat={setScrollChat}
									inThreadMessageCount={inThreadMessageCount}
									setInThreadMessageCount={setInThreadMessageCount}
									markThreadUnarchive={(id) => { markThreadUnarchive(id) }}
									markThreadArchive={(id) => { markThreadArchive(id) }}
									setConversationPageNumber={setConversationPageNumber}
									conversationPageNumber={conversationPageNumber}
									chatInLineLoaded={chatInLineLoaded}
									setChatInLineLoaded={setChatInLineLoaded}
									newPageData={newPageData}
									setNewPageData={setNewPageData}
									lastPage={lastPage}
								/>

							</>
						)}
					</div>
				</div>
			</main >

			{/* <Footer /> */}
		</>
	)
}
export default Messages;
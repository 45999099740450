import React from 'react'
import { Route, Routes } from "react-router-dom";
import {
      Home, ProductCreate, ProductDetails, Wallet,
      Products, MrakListedView, MakeFeaturedProduct,
      ViewProfile, SellerProfile, ProductEdit, Messages
      , Dealers, NotFound, GlobalSearch, BecomeAverifiedDealer,
      Support, SellerReviews, AllDealers, LoginPage, SignUpPage,
      ResetPasswordPage, EmailConfirmation, Setting, TheaterOfWar, VerifyAccount,
      MyListing, MyFavListing, ContactUs, UserNotFound, ListingNotFound, Loader, EditProfile, PayPal,
      PayPalError, CmsPages,
} from "./components";



require('dotenv').config();


const AppRoutes = () => {
      return (
            <Routes>
                  <Route exact path="/" element={<Home />} ></Route>
                  <Route exact path="/product/detail/:id" element={<ProductDetails />}></Route>
                  <Route exact path="/category/:categoryID" element={<Products />}></Route>
                  <Route exact path="/search/:categoryID" element={<GlobalSearch />} ></Route>
                  <Route path="/email_confirmation/:token" element={<EmailConfirmation />} />
                  <Route exact path="/product/add" element={<ProductCreate />}></Route>
                  <Route exact path="/product/edit/:postID" element={<ProductEdit />}> </Route>
                  <Route exact path="/profile/edit" element={<EditProfile />}>  </Route>
                  <Route exact path="/listed" element={<MrakListedView />}></Route>
                  <Route exact path="/featured/:postID" element={<MakeFeaturedProduct />}> </Route>
                  <Route exact path="/setting" element={<Setting />}></Route>
                  <Route exact path="/seller/profile/:id" element={<SellerProfile />}></Route>
                  <Route exact path="/seller/review/:id" element={<SellerReviews />}></Route>
                  <Route exact path="/wallet" element={<Wallet />}></Route>
                  <Route exact path="/my-listing" element={<MyListing />}></Route>
                  <Route exact path="/my-favourite" element={<MyFavListing />}></Route>
                  <Route exact path="/profile" element={<ViewProfile />}></Route>
                  <Route exact path="/payPalAuth/success" element={<PayPal />}></Route>
                  <Route exact path="/payPalAuth/cancel" element={<PayPalError />}></Route>
                  <Route exact path="/dealers" element={<Dealers />}></Route>
                  <Route exact path="/all-dealers" element={<AllDealers />}></Route>
                  <Route exact path="/messages" element={<Messages />}></Route>
                  <Route exact path="/theater-of-war/:option_id" element={<TheaterOfWar />}> </Route>
                  <Route exact path="/contact-us" element={<ContactUs />}></Route>
                  <Route exact path="/verify-account" element={<VerifyAccount />}></Route>
                  <Route exact path="/cms-pages/:page" element={<CmsPages />}></Route>
                  <Route exact path="/support" element={<Support />}></Route>
                  <Route exact path="/become-a-verified-dealer" element={<BecomeAverifiedDealer />}></Route>
                  <Route exact path="/login" element={<LoginPage />}></Route>
                  <Route exact path="/register" element={<SignUpPage />}></Route>
                  <Route exact path="/reset-password" element={<ResetPasswordPage />}></Route>
                  <Route exact path="/user-not-found" element={<UserNotFound />} ></Route>
                  <Route exact path="/listing-not-found" element={<ListingNotFound />}> </Route>
                  <Route exact path="/loader" element={<Loader />}></Route>
                  <Route element={<NotFound />} />
            </Routes>
      )
}

export default AppRoutes;
import React from 'react';
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Encriptor from "../../../services/encriptor";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';



const ListView = ({ posts, deletePost, markSoldPost, renewPost }) => {
   const { t } = useTranslation();



   if (posts) {
      return (
         <>
            <div className="edit-profile-grid-view">

               <Row className="no-gutters">
                  <Col xs={12} sm={12} md={12} lg={12}>
                     <div className="list-post-sections-views">
                        {posts?.map((post, i) => (
                           <div className="product-list-post mt-3">
                              <Row className="no-gutters">
                                 <Col xs={12} sm={12} md={3} lg={3}>
                                    {post?.sold ? (
                                       <div className="featured"><span className="sold">{t("SOLD")}</span></div>
                                    ) : (
                                       (post?.deleted) ? (
                                          <>
                                             <div className="featured"><span className="expired">{t("DELETED")}</span></div>
                                          </>
                                       ) : (
                                          <>
                                             {post?.expired ? (<div className="featured"><span className="expired" >{t("EXPIRED")}</span></div>) : (
                                                <>
                                                   {post?.featured && (<div className="featured"><span >{t("FEATURED")}</span></div>)}
                                                </>
                                             )}

                                          </>
                                       )
                                    )}
                                    <div className="list-post-image">
                                       <div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)} </Link> </span></div>
                                       <Link to={`/product/detail/${post.slug}`} ><Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" /> </Link>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={8} lg={8}>
                                    <h5 className="Product-title">
                                       <Link to={`/product/detail/${post?.slug}`} >
                                          <OverlayTrigger
                                             placement="bottom"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={
                                                <Tooltip id={`tooltip-${post?.id}`}>
                                                   {`${post?.title}`}
                                                </Tooltip>
                                             }
                                          >
                                             {(post?.title?.length) > 100 ? (

                                                <div className="">

                                                   {`${post?.title}`.slice(0, 100)} ...

                                                </div>

                                             ) : (
                                                <div className="">
                                                   {`${post?.title}`}
                                                </div>
                                             )}
                                          </OverlayTrigger>
                                       </Link>
                                    </h5>
                                    <p className="Product-desc">
                                       <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                             <Tooltip className="tooltiptext" id={`tooltip-${post?.id}`}>
                                                {`${post?.short_description} `}
                                             </Tooltip>
                                          }
                                       >
                                          {(post?.short_description.length) > 150 ? (

                                             <div className="">
                                                {`${post?.short_description}`.slice(0, 150)} ...
                                             </div>

                                          ) : (
                                             <div className="">
                                                {`${post?.short_description}`}
                                             </div>
                                          )}
                                       </OverlayTrigger>
                                    </p>
                                    <div className="price-location">
                                       <ul>
                                          <li className=""><strong> {post?.open_to_offer ? `${t('Open to offers')}` : (post?.formatted_price)}</strong> </li>
                                          <li className="view-listings cp"><Link to={`/product/detail/${post?.slug}`} >
                                             {t("View listing")}
                                          </Link></li>
                                       </ul>
                                    </div>
                                 </Col>
                                 <Col xs={12} sm={12} md={1} lg={1} className="owner-details">
                                    <div className="action-btn profile-list-view">
                                       <ul>
                                          {post?.can_edit && (<li><Link className="link" to={`/product/edit/${Encriptor.encrypt(post?.postid)}`}>
                                             <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                   <Tooltip id={`tooltip-edit`}>
                                                      {t("Edit listing")}
                                                   </Tooltip>
                                                }
                                             >
                                                <i className="icon-edit"></i>
                                             </OverlayTrigger>

                                          </Link></li>)}
                                          {post?.can_delete && (<li onClick={() => deletePost(post?.postid)}><Link className="link" >
                                             <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                   <Tooltip id={`tooltip-delete`}>
                                                      {t("Delete listing")}
                                                   </Tooltip>
                                                }
                                             >
                                                <i className="icon-delete"></i>
                                             </OverlayTrigger>
                                          </Link></li>)}
                                          {!post?.expired && (<li onClick={() => markSoldPost(post?.postid)}><Link className="link" >
                                             <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                   <Tooltip id={`tooltip-sold`}>
                                                      {t("Mark sold")}
                                                   </Tooltip>
                                                }
                                             >
                                                <small className="list-sold-icon">{t("Sold")}</small>
                                             </OverlayTrigger>
                                          </Link></li>)}
                                          {post?.can_renew && (<li onClick={() => renewPost(post?.postid, post?.renew_cost)}><Link className="link" >
                                             <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                   <Tooltip id={`tooltip-renew`}>
                                                      {t("Renew listing")}
                                                   </Tooltip>
                                                }
                                             >
                                                <i className="icon-renew"></i>
                                             </OverlayTrigger>
                                          </Link></li>)}
                                       </ul>
                                    </div>
                                 </Col>
                              </Row>
                           </div>
                        ))}
                     </div>
                  </Col>
               </Row>
            </div>
         </>
      )
   }
   return (
      <>
         <div className="text-center my-3">
            <div className="spinner-border" role="status">
               <span className="sr-only">{t("Loading...")}</span>
            </div>
         </div>

      </>
   )
}

export default ListView;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { Image } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
const fb_app_id = process.env.REACT_APP_FACEBOOK_APP_ID

const SocialLogins = ({ onSuccess, handleSocialLoginFailure }) => {
  const { t } = useTranslation();
  const FbLoginBTN = ({ onClick }) => {
    return (
      <li onClick={onClick}>
        <div >
          <Image src={'/images/social-login/facebook.png'} alt="image" className="img-fluid" />
        </div>
        <span>{t("Sign up with Facebook")}</span>
      </li>
    )
  }

  const handleFbLoginSuccess = (data) => {
    const socialLoginData = {
      network: data?.graphDomain,
      social_token: data?.accessToken
    }
    onSuccess(socialLoginData);
  }


  const handleGoogleLoginSuccess = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const data = {
        network: 'google',
        social_token: codeResponse.access_token
      }
      onSuccess(data);
    },
    onError: (error) => handleSocialLoginFailure(error)
  });

  return (
    <div>
      <ul>
        <li onClick={() => handleGoogleLoginSuccess()} >
          <div className="cp">
            <Image src={'/images/social-login/google.png'} alt="image" className="img-fluid" />
          </div>
          <span> {t("Sign in with Google")}</span>
        </li>
        <FacebookLogin
          appId={fb_app_id}
          scope='public_profile'
          onSuccess={(response) => {
            handleFbLoginSuccess(response)
            console.log('Login Success!', response);
          }}
          onFail={(error) => {
            handleSocialLoginFailure(error);
          }}
          render={({ onClick, logout }) => (
            <FbLoginBTN onClick={onClick} onLogoutClick={logout} />
          )}
        />
      </ul>
    </div>
  );
};

export default SocialLogins;
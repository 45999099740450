/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams, useLocation } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/sidebar.css";
import Header from "../../include/header";
import Footer from "../../include/footer";
import categoryService from "../../../services/productCategories";
import postService from "../../../services/posts";
import 'bootstrap-daterangepicker/daterangepicker.css';
import GridView from "../../comman/views/grid";
import ListView from "../../comman/views/list";
import postsServices from "../../../services/posts";
import sellerProfile from '../../../services/sellerProfile'
import { useToasts } from 'react-toast-notifications'
import PageLoader from '../../pageLoader'
import "react-month-picker/css/month-picker.css";
import { isDesktop, isMobile } from "react-device-detect";
import SeoTags from '../../seo/seoTags'
import { Auth } from '../../../Contexts/AuthContext';
import { Spinner } from 'react-bootstrap';
import MobileFilter from '../../comman/mobileFilter';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../../../Contexts/langContext';
import SideFilter from '../../comman/SideFilter';
import UpperFilter from '../../comman/UpperFilter';

var isHomePage = false;


const Products = () => {
   const { t } = useTranslation();
   const { globalFilter, setMenuCategory } = useContext(Auth);

   var location = useLocation();
   const loader = useRef(null);
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   var token = loginUser.access_token;
   const { categoryID } = useParams();
   const [categoryFilterData, setCategoryFilterData] = useState();
   const [loaded, setLoaded] = useState(false);
   const [filterOption, setFilterOption] = useState([]);
   const [showNumberOfFilters, setShowNumberOfFilters] = useState(6);
   const [posts, setPosts] = useState();
   const [issubscribed, setIssubscribed] = useState(false);
   const [isSold, setIsSold] = useState(false);
   const [sortBy, setSortBy] = useState('');
   const [sidebar, setSidebar] = useState({
      leftOpen: isMobile ? false : true,
   });

   const [filterCount, setFilterCount] = useState(0);
   const [filteredList, setFilteredList] = useState([]);
   const [page, setPage] = useState(0);
   const [adsFilter, setAdsFilter] = useState('all');
   const [minDate, setMinDate] = useState('');
   const [maxDate, setMaxDate] = useState('');
   const [rangeError, setRangeError] = useState('');
   const [view, setView] = useState('grid');
   const { addToast } = useToasts();
   const [inlineLoader, setInlineLoader] = useState(false);
   let leftOpen = sidebar.leftOpen ? 'open' : 'closed';
   var text_search = '';
   const { lang } = useLangContext(langContext);

   useEffect(() => {
      async function getCategories() {
         setMenuCategory(categoryID)
         try {
            const res = await categoryService.fiterMenu(categoryID, lang);
            if (res.success) {
               postService.setToken(token);
               setCategoryFilterData(res.data)
               setLoaded(true);
            }
         } catch (error) {
            setLoaded(true);
         }

      }
      getCategories()
   }, [])

   useEffect(() => {
      if (page > 1)
         fetchMorePostData()
   }, [page])

   useEffect(() => {
      getPostData()
   }, [sortBy, isSold, issubscribed, filterOption, globalFilter])


   // production year range filter 
   const handleFirstRangeInput = (id, value) => {
      value = value || '';
      setMinDate(value);
      const minval = value;
      const maxval = maxDate;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      let filter = filterOption.find(f => (String(f.id) === String(id)));
      if (typeof filter !== "undefined") {
         var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (typeof filter !== "undefined") {
               const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)

            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)

            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
         }
      }

   }

   const handleSecondRangeInput = (id, value) => {
      value = value || '';
      setMaxDate(value);
      const minval = minDate;
      const maxval = value;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      let filter = filterOption.find(f => (String(f.id) === String(id)));
      if (typeof filter !== "undefined") {
         var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (typeof filter !== "undefined") {
               const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)

            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)

            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)

         }
      }
   }


   // Product ranger custom validation 
   const checkErrorInRange = (minval, maxval) => {
      let errorss = '';

      if (maxval < minval) {
         errorss = t('To year must be greater or equals than from year');
      }


      if (maxval < 1500) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('To year should be greater than or equals 1500');
      }

      if (minval > new Date().getFullYear()) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('To year should be lesser than ') + new Date().getFullYear();
      }

      if (maxval.length < 4 || maxval.length > 4) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('To year should be 4 digits');
      }

      if (maxval === '') {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('Please enter to year');
      }

      if (minval < 1500) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('From year should be greater than or equals 1500');
      }

      if (minval > new Date().getFullYear()) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('From year should be lesser than or equals ') + new Date().getFullYear();
      }

      if (minval.length < 4 || minval.length > 4) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('From year should be 4 digits');
      }

      if (minval === '') {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('Please enter from year');
      }

      if (minval === '') {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('Please enter from year');
      }
      return errorss;
   }

   // filter bar toggle switch
   const toggleSidebar1 = (event) => {
      let key = `leftOpen`;
      setSidebar({ [key]: !sidebar[key] });
   }

   if (typeof (location.state) != "undefined") {
      text_search = location?.state?.text;
   }

   // Get listings from server
   const getPostData = () => {
      setLoaded(false);
      const formData = new FormData();
      formData.append('category_id', categoryID);
      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }

      if (sortBy) {
         formData.append('sortby', sortBy);
      }
      formData.append('page', 1);
      formData.append('verifiedListing', issubscribed);
      formData.append('soldPost', isSold);
      if (globalFilter?.filter_text) {
         formData.append('text', globalFilter?.filter_text);
      }
      postService.searchPost(formData, lang)
         .then(res => {
            setLoaded(true);
            if (res.success) {
               setPosts(res.data)
               // setAllData(res.data)
               if (!issubscribed && !isSold) {
                  // setAllPostCount(res.data.total)
                  // setVerifiedPostCount(res.data.verified_dealer_post_count);
                  // setSoldPostCount(res.data.sold_posts_count);
               }
            }
         })
         .catch(err => {
            setLoaded(true);
         })
   }

   // Fetach mpore post data  listings from server
   const fetchMorePostData = () => {
      if (page > posts?.last_page && page > 1) {
         setInlineLoader(false);
         return false;
      }
      const formData = new FormData();
      formData.append('category_id', categoryID);
      if (filterOption.length > 0) {
         for (let index = 0; index < filterOption.length; index++) {
            const element = filterOption[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }
      if (sortBy) {
         formData.append('sortby', sortBy);
      }
      if (globalFilter?.filter_text) {
         formData.append('text', globalFilter?.filter_text);
      }
      if (page) {
         formData.append('page', page);
      }
      // if(issubscribed){
      formData.append('verifiedListing', issubscribed);
      formData.append('soldPost', isSold);
      // }
      postService.searchPost(formData, lang)
         .then(res => {
            setInlineLoader(false);
            if (res.success) {
               const updatedPost = posts.data.concat(res.data.data);
               setPosts((prv) => ({ ...res.data, data: updatedPost }));
               // setAllData((prv) => ({ ...res.data, data: updatedPost }));
               if (!issubscribed && !isSold) {
                  // setAllPostCount(res.data.total)
                  // setVerifiedPostCount(res.data.verified_dealer_post_count);
                  // setSoldPostCount(res.data.sold_posts_count);
               }
            }
         })
         .catch(err => {
            setInlineLoader(false);
         })
   }

   // Listing filter selection handler 
   const handleFiltersSelect = (e) => {
      const id = e.target.dataset.filter_id;
      const value = e.target.dataset.filter_value;
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)

         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)
      }
   }

   // Filter  date picker selction handler
   const handleDatepickerValue = (event, picker) => {

      var id = event.currentTarget.dataset.filter_id;
      var startDate = picker.startDate.format('MM/DD/YYYY');
      var endDate = picker.endDate.format('MM/DD/YYYY');
      var value = `${startDate} to ${endDate}`;
      if (event.currentTarget.dataset.type === 'date') {
         value = picker.startDate.format('MM/DD/YYYY')
      }
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            var filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)

         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)

         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)

      }

   }

   // Like Unlike handler
   const LikeUnlikeToggleButton = (postId) => {
      const post = posts.data.find(n => n.postid === postId)
      setLoaded(false);
      postsServices.setToken(token)
      if (post.likedbyme) {
         postsServices.unLikePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = { ...post, likedbyme: res.data.likedbyme, likes: res.data.likes }
               const postPost = posts.data.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost);

               setPosts({
                  ...posts,
                  data: postPost
               });
               addToast('Post Unliked', { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
               console.log('error in unlikes');
            })
      } else {
         postsServices.likePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = { ...post, likedbyme: res.data.likedbyme, likes: res.data.likes }
               const postPost = posts.data.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost);

               setPosts({
                  ...posts,
                  data: postPost
               });
               addToast('Post Liked', { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
               console.log('error in unlikes');
            })
      }



   }

   //  Follow Unfollow handler
   const FollowUnfollowToggle = id => {
      const post = posts.find(p => p.user_id === id)
      const seller = post.user
      setLoaded(false);
      sellerProfile.setToken(token);

      if (seller.followedbyme) {
         sellerProfile.unFollowUser(id)
            .then(res => {
               setLoaded(true);
               const updatedSeller = {
                  ...seller,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               }
               const changedPost = { ...post, user: updatedSeller }
               setPosts(posts.map(p => parseInt(p.user_id) !== parseInt(id) ? p : changedPost));
               addToast('Seller Unfollowed', { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
               console.log('unfollow error');
            })
      } else {
         sellerProfile.followUser(id)
            .then(res => {
               setLoaded(true);
               const updatedSeller = {
                  ...seller,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               }
               const changedPost = { ...post, user: updatedSeller }
               setPosts(posts.map(p => parseInt(p.user_id) !== parseInt(id) ? p : changedPost));
               addToast('Seller Followed', { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
               console.log('follow error');
            })
      }
   }

   // Sortby filter handler
   const handleSortBy = (e) => {
      setSortBy(e.target.value);
   }

   // country list  in filter search operation
   const filterCountries = (e, options) => {
      var str = e.target.value || '';
      // setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = options.filter(item => {
         return item.value.toLowerCase().includes(lowercasedFilter);
      });

      const newData = {
         id: e.currentTarget.dataset.filter_id,
         data: filteredData,
      }
      if (filteredList.length > 0) {
         let filter = filteredList.find(f => String(f.id) === String(e.currentTarget.dataset.filter_id));
         if (typeof filter !== "undefined") {
            var filtereddd = filteredList.map(f => String(f.id) === String(e.currentTarget.dataset.filter_id) ? newData : f);
            setFilteredList(filtereddd)
         } else {
            setFilteredList(filteredList.concat(newData))
         }

      } else {
         setFilteredList(filteredList.concat(newData))
      }

   }

   // Clear all applied filters
   const handleClearFilter = () => {
      text_search = '';
      setFilterOption([]);
      setFilteredList([]);
      setMinDate('');
      setMaxDate('');
      setAdsFilter('all');
      setPage(0)
      setFilterCount(0)
   }



   // listing type filter (all, verified dealer listings)
   const HandleAdFilter = (adType) => {
      setAdsFilter(adType)
      var verified = false;
      var soldAds = false;
      if (adType === "verified_ads") {
         verified = true;
      }
      if (adType === "sold_ads") {
         soldAds = true;
      }
      setIssubscribed(verified)
      setIsSold(soldAds)


   }

   const handlePage = pageId => {
      setPage(pageId)

   }
   //  observer to handle scroll end to fetch more posts 
   const handleObserver = useCallback((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
         setInlineLoader(true);
         setPage((prv) => (prv + 1));
      }
   }, []);

   useEffect(() => {
      const option = {
         root: null,
         rootMargin: "20px",
         threshold: 0
      };
      const observer = new IntersectionObserver(handleObserver, option);
      if (loader.current) observer.observe(loader.current);
   }, [handleObserver]);

   return (
      <>
         {categoryID && (
            <SeoTags title={`Miltrade | Products | ${globalFilter?.filter_category.charAt(0).toUpperCase() + globalFilter?.filter_category.slice(1)}`}
               description={''}
               category={categoryID}
               url={window.location.href}
               image={''}
            />
         )}
         <Header
            data={isHomePage}
            checkAuth={false}
            text={text_search}
            categoryID={categoryID}


         />
         <MobileFilter
            setView={setView}
            view={view}
            setSortBy={setSortBy}
            sortBy={sortBy}
            categoryFilterData={categoryFilterData}
            filterOption={filterOption}
            filterCountries={filterCountries}
            filterCount={filterCount}
            handleClearFilter={handleClearFilter}
            handleFiltersSelect={handleFiltersSelect}
            filteredList={filteredList}
            showNumberOfFilters={showNumberOfFilters}
            setShowNumberOfFilters={setShowNumberOfFilters}
            handleFirstRangeInput={handleFirstRangeInput}
            handleSecondRangeInput={handleSecondRangeInput}
            maxDate={maxDate}
            minDate={minDate}
            rangeError={rangeError}
            handleDatepickerValue={handleDatepickerValue}
            HandleAdFilter={HandleAdFilter}
            adsFilter={adsFilter}
         // allPostCount={allPostCount}
         // verifiedPostCount={verifiedPostCount}
         />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
                           <Breadcrumb.Item active>{globalFilter?.filter_category !== ''  ?  globalFilter?.filter_category.charAt(0).toUpperCase() + globalFilter?.filter_category.slice(1) :'All Categories'}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <PageLoader isLoaded={loaded} />
            <div className="main-layout" id='layout'>
               {isDesktop && (
                  <SideFilter
                     categoryFilterData={categoryFilterData}
                     filteredList={filteredList}
                     filterOption={filterOption}
                     filterCountries={filterCountries}
                     showNumberOfFilters={showNumberOfFilters}
                     handleFiltersSelect={handleFiltersSelect}
                     setShowNumberOfFilters={setShowNumberOfFilters}
                     handleFirstRangeInput={handleFirstRangeInput}
                     minDate={minDate}
                     maxDate={maxDate}
                     handleSecondRangeInput={handleSecondRangeInput}
                     rangeError={rangeError}
                     handleDatepickerValue={handleDatepickerValue}
                     handleClearFilter={handleClearFilter}
                     leftOpen={leftOpen}
                     toggleSidebar1={toggleSidebar1}
                     categoryID={categoryID}
                     filterCount={filterCount}
                  />
               )}
               <div className="product-right-section">
                  <div className='content'>
                     {isDesktop && (
                        <UpperFilter
                           HandleAdFilter={HandleAdFilter}
                           adsFilter={adsFilter}
                           setView={setView}
                           view={view}
                           handleSortBy={handleSortBy}
                        />
                     )}
                     <div className="all-product">
                        {posts?.data?.length > 0 ? (
                           view === 'grid' ?
                              (<GridView posts={posts}
                                 LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                                 FollowUnfollowToggle={FollowUnfollowToggle}
                                 handlePage={handlePage}

                              />) :
                              (<ListView posts={posts}
                                 LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                                 FollowUnfollowToggle={FollowUnfollowToggle}

                              />)
                        ) : (
                           <>
                              <div className="text-center">
                                 <Image src="../images/search_results_no_listings.webp" className="img-fluid" alt="user" />

                              </div>
                           </>
                        )}
                        {inlineLoader && (
                           <div className={`inline-loader`}>
                              <Spinner animation="border" variant="success" />
                           </div>
                        )}
                        <div ref={loader} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />

      </>
   );
}
export default Products;
import React, { useEffect } from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from "./include/header";
import Footer from "./include/footer";
import SeoTags from './seo/seoTags'
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';







const UserNotFound = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <>
      <SeoTags title={'Miltrade | User not found'}
        description={''}
        category={''}
        url={window.location.href}
        image={''}
      />
      <Header
        data={false}
        checkAuth={false}
      />

      <div className="footer-bottom1">

        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs lg="6" className="">
              <div className="NotFound text-center">
                <div className="notfound-image"><Image src="/images/user_not_found.webp" /></div>
              </div>
            </Col>
            <Col xs lg="6" className="">
              <div className="NotFound">
                <h4 className="mb-4">{t("UH OH! You're lost.")}</h4>
                <p>{t("The seller you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage")}.</p>
                <div className="btn-medium mt-4">
                  <Link to="/">{t("Home")}</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </div>
      <Footer />
    </>
  )
}


export default UserNotFound;
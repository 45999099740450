/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/users';
const baseUrl1 = prefix;

let token = null

const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}

var $axiosConfig = {
    headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': true,
    }
}
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        window.localStorage.removeItem('isLoggedIn');
        window.localStorage.removeItem('loggedUser');
        window.localStorage.removeItem('total_balance');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('issubscribed');
        window.localStorage.removeItem('default_currency');
        window.localStorage.removeItem('currency_symbol');
        window.location.reload('/')
    }
    else {
        if (error?.response?.status === 500) {
            return Promise.reject({ ...error, errorMsg: 'Newtork Error' });
        }else{
            return Promise.reject(error); 
        }
    }
});



const logIn = (newObject) => {
    const request = axios.post(baseUrl + `/login`, newObject, $axiosConfig)
    return request.then(response => response.data)
}
const logOut = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/logout`, '', config)
    return request.then(response => response.data)
}
const socialLogin = (newObject) => {
    const request = axios.post(baseUrl1 + `/login/social`, newObject, $axiosConfig)
    return request.then(response => response.data)
}
const otherInfos = () => {
    const request = axios.get(baseUrl + `/currency_data`, $axiosConfig)
    return request.then(response => response.data)
}



export default { setToken, logIn, logOut, socialLogin, otherInfos }
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Encriptor from '../../../services/encriptor'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';



const GridView = ({ posts, deletePost, markSoldPost, renewPost }) => {
	const { t } = useTranslation();
	const [showNumberOfItems, setShowNumberOfItems] = useState(8);

	if (posts) {
		return (
			<>
				<div className="edit-profile-grid-view">
					<Container>
						<Row className=''>
							{posts?.slice(0, showNumberOfItems)?.map((post, i) => (

								<Col xs={6} key={i} sm={6} md={6} lg={3} className="product-display-grid-view-mobile">
									<div className="product-display mt-3">
										<div className="card">
											{post?.sold ? (
												<div className="featured"><span className="sold">{t("SOLD")}</span></div>
											) : (
												(post?.deleted) ? (
													<>
														<div className="featured"><span className="expired">{t("DELETED")}</span></div>
													</>
												) : (
													<>
														{post?.expired ? (<div className="featured"><span className="expired" >{t("EXPIRED")}</span></div>) : (
															<>
																{post?.featured && (<div className="featured"><span >{t("FEATURED")}</span></div>)}
															</>
														)}

													</>
												)
											)}
											<div className="img-card">
												<div className="action-btn_edit_delete">
													<ul>
														{post?.can_edit && (<li><Link className="link" to={`/product/edit/${Encriptor.encrypt(post?.postid)}`}>
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip id={`tooltip-edit`}>
																		{t("Edit Listing")}
																	</Tooltip>
																}
															>
																<i className="icon-edit"></i>
															</OverlayTrigger>
														</Link></li>)}
														{post?.can_delete && (<li onClick={() => deletePost(post?.postid)}><Link className="link" >
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip id={`tooltip-edit`}>
																		{t("Delete Listing")}
																	</Tooltip>
																}
															>
																<i className="icon-delete"></i>
															</OverlayTrigger></Link></li>)}
														{!post?.expired && (<li onClick={() => markSoldPost(post?.postid)}><Link className="link" >
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip id={`tooltip-edit`}>
																		{t("Mark sold")}
																	</Tooltip>
																}
															>
																<small className="sold-icon">{t("Sold")}</small>
															</OverlayTrigger></Link></li>)}
														{post?.can_renew && (<li onClick={() => renewPost(post?.postid, post?.renew_cost)}><Link className="link" >
															<OverlayTrigger
																placement="left"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip id={`tooltip-renew`}>
																		{t("Renew Listing")}
																	</Tooltip>
																}
															>
																<i className="icon-renew"></i>
															</OverlayTrigger>
														</Link></li>)}
													</ul>
												</div>
												<div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)}</Link> </span></div>
												<Link to={`/product/detail/${post.slug}`} >
													<Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" />
												</Link>
											</div>
											<div className="cards">
												<h5 className="Product-title">
													<Link to={`/product/detail/${post?.slug}`} >
														<OverlayTrigger
															placement="bottom"
															delay={{ show: 250, hide: 400 }}
															overlay={
																<Tooltip id={`tooltip-${post?.id}`}>
																	{`${post?.title}`}
																</Tooltip>
															}
														>
															{(post?.title?.length) > 20 ? (

																<div className="">

																	{`${post?.title}`.slice(0, 20)} ...

																</div>

															) : (
																<div className="">
																	{`${post?.title}`}
																</div>
															)}
														</OverlayTrigger>
													</Link>
												</h5>
												<div className="Product-desc">
													<OverlayTrigger
														placement="bottom"
														delay={{ show: 250, hide: 400 }}
														overlay={
															<Tooltip id={`tooltip-${post?.id}`}>
																{`${post?.short_description} `}
															</Tooltip>
														}
													>
														{(post?.short_description.length) > 40 ? (

															<div className="">
																{`${post?.short_description}`.slice(0, 40)} ...
															</div>

														) : (
															<div className="">
																{`${post?.short_description}`}
															</div>
														)}
													</OverlayTrigger>
												</div>
												{/* <div className="price-location">
													<ul>
														<li className="price">{post?.open_to_offer ? ('Open to offers') : (post?.formatted_price)}</li>
														<li className="location">
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip id={`tooltip-${post?.id}`}>
																		{`${post?.address} `}
																	</Tooltip>
																}
															>
																{(post?.address.length) > 11 ? (

																	<div className="">
																		<i className="icon-location" aria-hidden="true"></i> {`${post?.address}`.slice(0, 11)} ...
																	</div>

																) : (
																	<div className="">
																		<i className="icon-location" aria-hidden="true"></i> {`${post?.address}`}
																	</div>
																)}
															</OverlayTrigger>
														</li>
													</ul>
												</div> */}
												<div className="price-location">
													<ul className='d-flex'>
														<li className=""><strong>{post?.open_to_offer ? (`${t('Open to offers')}`) : (post?.formatted_price)}</strong></li>
														<li className="location">
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip id={`tooltip-${post?.id}`}>
																		<span>

																			{`${post?.address} `}
																		</span>
																	</Tooltip>
																}
															>
																<div className="country_location g-1">
																	<img alt={post?.country_flag} src={post?.country_flag} />
																	<span> {(post?.address.length) > 10 ? `${String(post?.address).slice(0, 10)} ...` : `${post?.address}`} </span>
																</div>

															</OverlayTrigger>

														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</Col>

							))}

							<Col>
								{posts.length > showNumberOfItems && (<div className="loadmorebutton py-5"><button onClick={() => setShowNumberOfItems(showNumberOfItems + 5)} type="button" className="btn btn-primary py-2"> {t("View more")} <i className="icon-dropdown"></i></button></div>)}
							</Col>
						</Row>
					</Container>
				</div>
			</>
		)
	}

}
export default GridView;



/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix;

let token = null;
const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}


const getAllCatgories = (lang) => {
    var config = {
        headers: token ? { Authorization: token, "App-Language": lang } : {"App-Language": lang },
    }
    const request = axios.get(baseUrl + `/categories`, config)
    return request.then(response => response.data)
}
const categoryMenus = async (lang) => {
    var config = {
        headers: { "App-Language": lang },
    }
    const request = await axios.get(baseUrl + `/categoryMenu`, config)
    return request.data;
}

const fiterMenu = async (catid, lang) => {
    var config = {
        headers: { "App-Language": lang },
    }
    const response = await axios.get(baseUrl + `/categories/categoryFilters/${catid}`, config)
    return response.data
}

const dealerFilter = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/verified_dealers`, data, config)
    return request.then(response => response.data)
}
const getCurrency = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/users/all/currencies`, config)
    return request.then(response => response.data)
}




export default { getAllCatgories, categoryMenus, fiterMenu, dealerFilter, getCurrency, setToken }
import React from 'react';
import ListView from './list';
import GridView from './grid';
import Image from 'react-bootstrap/Image';



const MyAds = ({ view, posts, deletePost, markSoldPost, renewPost }) => {
	return (
		<>
			{posts?.length > 0 ? (
				view === 'grid' ?
					(<GridView posts={posts} deletePost={deletePost} markSoldPost={markSoldPost} renewPost={renewPost}

					/>) :
					(<ListView posts={posts} deletePost={deletePost} markSoldPost={markSoldPost} renewPost={renewPost}

					/>)
			) : (
				<>
					<div className="text-center">
						<Image src="../images/no_listings_found.webp" className="img-fluid" alt="user" />
					</div>
				</>
			)}
		</>
	)

}
export default MyAds;



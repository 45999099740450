import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from "./include/header";
import Footer from "./include/footer";
import homeServices from '../services/home'
import PageLoader from './pageLoader'
import SeoTags from './seo/seoTags'
import { useTranslation } from 'react-i18next';

var isHomePage = false;
const CmsPages = () => {
   const [data, setData] = useState()
   const [isLoaded, setIsLoaded] = useState(false)
   const { page } = useParams();
   const { t } = useTranslation();

   useEffect(() => {

      homeServices.renderPages(page)
         .then(response => {
            setIsLoaded(true);
            if (response.success) {
               setData(response.data)
            } else {

               // addToast(response.message,{ appearance: 'error' })
            }

         })
         .catch(error => {

            setIsLoaded(true);
            if (error.response) {
               // addToast(error.response.data.message, { appearance: 'error' })
            }
         })


   }, [page])


   return (
      <>
         <SeoTags title={`Miltrade |  ${page.charAt(0).toUpperCase() + page.slice(1)}`}
            description={''}
            category={''}
            url={window.location.href}
            image={''}
         />
         {/* Start of header section */}
         <Header
            data={isHomePage}
            checkAuth={false}

         />

         <PageLoader isLoaded={isLoaded} />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
                           <Breadcrumb.Item active>{page.charAt(0).toUpperCase() + page.slice(1)}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>

            <div className="contact_us_section">
               <Container>
                  <Row>
                     {data?.content && (<div dangerouslySetInnerHTML={{ __html: data?.content }} />)}
                  </Row>
               </Container>
            </div>
         </div>

         <Footer />
      </>
   );
}
export default CmsPages;
/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
require('dotenv').config()

const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/home'
const baseUrl1 = prefix
let token = null



const supportMenu = (lang) => {
    var config = {
        headers: { "App-Language": lang },
    }
    const request = axios.get(baseUrl + `/faq_menu`, config)
    return request.then(response => response.data)
}
const supportPageContent = (page,lang) => {
    var config = {
       headers: { "App-Language": lang },
    }
    const request = axios.get(baseUrl + `/faq_menu_pages/${page}`,config)
    return request.then(response => response.data)
}
const supportTopic = (topic,lang) => {
    var config = {
        headers: { "App-Language": lang },
    }
    const request = axios.get(baseUrl + `/faq_page/${topic}`,config)
    return request.then(response => response.data)
}

const supportTicket = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl1 + `/contact_form`, data, config)
    return request.then(response => response.data)
}
const postSuggesstions = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl1 + `/suggesstions`, data, config)
    return request.then(response => response.data)
}



export default { supportMenu, supportPageContent, supportTopic, supportTicket, postSuggesstions }
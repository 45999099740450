/* eslint-disable import/no-anonymous-default-export */
var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

const kformatter = (number) => {
    var tier = Math.log10(Math.abs(number)) / 3 | 0;
    if (parseInt(tier) === 0) return number;
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);
    var scaled = number / scale;
    return scaled.toFixed(1) + suffix;
}
const encrypt = (str) => {
    return window.btoa(str);
}
const decrypt = (coded) => {
    return window.atob(coded)
}



export default { encrypt, decrypt, kformatter }
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useContext } from "react";
import { Auth } from "../../Contexts/AuthContext";
import { useTranslation } from "react-i18next";

const FooterBottomBar = () => {
  const navigate = useNavigate();
  const { setShowLoginModal } = useContext(Auth);
  const isLogin = window.localStorage.getItem('isLoggedIn') ? window.localStorage.getItem('isLoggedIn') : false;
  
  // handle mobile bottaom navigation menu 
  const checkIsAuthorized = (type) => {
    if (!isLogin) {
      setShowLoginModal(true);
      return;
    }
    let navigation ='/';  
    switch (type) {
      case 'sell':
        navigation ='/product/add';
        break;
      case 'chat':
        navigation = '/messages';
        break;
      case 'profile':
        navigation = '/profile';
        break;
      default:
        break;
    }
    navigate(navigation);
  }

  const { t } = useTranslation();

  return (
    <>
      {isMobile ? (
        <div className="footer-bottom-bar">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <ul>
                  <li><Link to='/'> <Image width={'20px'} src="/images/footer-icon/home.png" alt="Home" className="img-fluid" /> {t("Home")} </Link></li>
                  <li><Link to='/search/All categories'><Image width={'20px'} src="/images/footer-icon/search.png" alt="Search" className="img-fluid" /> {t("Search")} </Link></li>
                  <li onClick={()=>{checkIsAuthorized('sell')}} ><Image width={'20px'} src="/images/footer-icon/sell.png" alt="Sell" className="img-fluid" /> {t("Sell")} </li>
                  <li onClick={()=>{checkIsAuthorized('chat')}}><Image width={'20px'} src="/images/footer-icon/chat.png" alt="Chat" className="img-fluid" /> {t("Chat")} </li>
                  <li onClick={()=>{checkIsAuthorized('profile')}}><Image width={'20px'} src="/images/footer-icon/account.png" alt="Account" className="img-fluid" /> {t("Account")} </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <></>
      )
      }
    </>
  );
}




export default FooterBottomBar;
/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/posts';


let token = null

const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}
const createPost = (newObject) => {
    var config = {
        headers: {
            Authorization: token,
            'content-type': 'multipart/form-data'
        },
    }
    const request = axios.post(baseUrl + `/add`, newObject, config)
    return request.then(response => response.data)
}
const getPost = (id, lang) => {
    var config = {
        headers: token ? { Authorization: token, "App-Language": lang } : { "App-Language": lang },
    }
    const request = axios.get(baseUrl + `/show/${id}`, config)
    return request.then(response => response.data)
}

const checkCharges = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/charges`, newObject, config)
    return request.then(response => response.data)
}

const markFeatured = (newObject) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/markfeatured`, newObject, config)
    return request.then(response => response.data)
}
const similarAds = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/similarads/${id}`, config)
    return request.then(response => response.data)
}
const likePost = (postid) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/like/${postid}`, config)
    return request.then(response => response.data)
}
const unLikePost = (postid) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/unlike/${postid}`, config)
    return request.then(response => response.data)
}
const editPost = (postid) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/editpost/${postid}`, config)
    return request.then(response => response.data)
}
const updatePost = (newData, postid) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/edit/${postid}`, newData, config)
    return request.then(response => response.data)
}
const searchPost = async (newData, lang) => {
    var config = {
        headers: token ? { Authorization: token, "App-Language": lang } : { "App-Language": lang },
    }
    const request = await axios.post(baseUrl + `/search`, newData, config);
    return request.data

}

const recentVisitedPosts = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/recentvisit`, config)
    return request.then(response => response.data)
}
const deletePost = (postId) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/deletepost/${postId}`, config)
    return request.then(response => response.data)
}
const markSoldPost = (postId) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/markpostsold/${postId}`, config)
    return request.then(response => response.data)
}
const renewPost = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/renewpost`, data, config)
    return request.then(response => response.data)
}

const activatePost = (post_id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/makepostactive/${post_id}`, config)
    return request.then(response => response.data)
}
const featuringData = (post_slug) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/featured/price/${post_slug}`, config)
    return request.then(response => response.data)
}



export default {
    setToken, createPost, getPost, checkCharges, markFeatured,
    similarAds, likePost, unLikePost, editPost, updatePost, searchPost,
    recentVisitedPosts, deletePost, markSoldPost, renewPost, activatePost, featuringData,

}
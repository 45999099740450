import React from 'react'
import 'bootstrap-daterangepicker/daterangepicker.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';

import Encriptor from "../../../services/encriptor";

import Slider from "react-slick-slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';




const settings = {
	margin: 10,
	dots: false,
	arrows: false,
	infinite: false,
	speed: 500,
	slidesToScroll: 1,
	responsive: [{ breakpoint: 0, settings: { slidesToShow: 1 } },
	{ breakpoint: 400, settings: { slidesToShow: 2 } },
	{ breakpoint: 767, settings: { slidesToShow: 2 } },
	{ breakpoint: 1024, settings: { slidesToShow: 3 } },
	{ breakpoint: 1025, settings: { slidesToShow: 4 } },
	{ breakpoint: 10000, settings: { slidesToShow: 4 } }
	]
};
const RecentVisitedAds = ({ recentVisitedAds, LikeUnlikeRecentToggleButton, FollowUnfollowRecentAdsToggle }) => {

	const { t } = useTranslation();
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;

	if (recentVisitedAds && loginUser) {
		return (
			<div className="similar-ads">
				<Container>
					<Row>
						<Col xs={12} sm={12} md={6} lg={6}>
							<div className="heading-title">
								<h3>{t('Recently Viewed')}</h3>
							</div>
						</Col>
						<Col xs={4} sm={6} md={6} lg={6}>
							<div className="title-link">
								{/* <a href="#">See More <i className="fa fa-angle-right" aria-hidden="true"></i> </a> */}
							</div>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>

							<Slider className='owl-theme' {...settings}>
								{recentVisitedAds.map((post, index) => (
									<div key={index} className="product-display mt-3">
										<div className="card">
											{post?.featured && (<div className="featured"><span>{('FEATURED')}</span></div>)}
											<div className="heart cp">
												{loginUser && loginUser.id !== post?.user?.id && (
													<span>
														{post.likedbyme ? (
															<div className="red_favourite" onClick={() => LikeUnlikeRecentToggleButton(post.postid)} > <i className="icon-red_favourite active" aria-hidden="true"></i></div>
														) : (
															<div className="favourite1" onClick={() => LikeUnlikeRecentToggleButton(post.postid)} > <i className="icon-favourite" aria-hidden="true"></i></div>
														)}
													</span>
												)}
											</div>
											<div className="img-card">
												<div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)}</Link> </span></div>
												<Link to={`/product/detail/${post.slug}`} >
													<Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" />
												</Link>
											</div>
											<div className="cards">
												<h5 className="Product-title">
													<Link to={`/product/detail/${post?.slug}`} >
														<OverlayTrigger
															placement="bottom"
															delay={{ show: 250, hide: 400 }}
															overlay={
																<Tooltip id={`tooltip-${post?.id}`}>
																	{`${post?.title}`}
																</Tooltip>
															}
														>
															{(post?.title?.length) > 30 ? (

																<div className="">

																	{`${post?.title}`.slice(0, 30)} ...

																</div>

															) : (
																<div className="">
																	{`${post?.title}`}
																</div>
															)}
														</OverlayTrigger>
													</Link>
												</h5>
												<div className="Product-desc">
													<OverlayTrigger
														placement="bottom"
														delay={{ show: 250, hide: 400 }}
														overlay={
															<Tooltip id={`tooltip-${post?.id}`}>
																{`${post?.short_description} `}
															</Tooltip>
														}
													>
														{(post?.short_description.length) > 50 ? (

															<div className="">
																{`${post?.short_description}`.slice(0, 50)} ...
															</div>

														) : (
															<div className="">
																{`${post?.short_description}`}
															</div>
														)}
													</OverlayTrigger>
												</div>
												<div className="price-location">
													<ul className='d-flex'>
														<li className=""><strong>{post?.open_to_offer ? (`${t('Open to offers')}`) : (post?.formatted_price)}</strong></li>
														<li className="location">
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip id={`tooltip-${post?.id}`}>
																		<span>

																			{`${post?.address} `}
																		</span>
																	</Tooltip>
																}
															>
																<div className="country_location g-1">
																	<img alt={post?.country_flag} src={post?.country_flag} />
																	<span> {(post?.address.length) > 10 ? `${String(post?.address).slice(0, 10)} ...` : `${post?.address}`} </span>
																</div>

															</OverlayTrigger>

														</li>
													</ul>
												</div>
												<div className="post-owner-details">
													<Row>
														<Col xs={4} sm={4} md={3} lg={3} className="p-0 pl-1">

															<Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
																<div className='item-seller'>
																	{post?.user?.issubscribed && (
																		<div className="verified-dealer">
																			<Image src="/images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
																		</div>
																	)}

																	<div className={`seller-image-${post?.user?.issubscribed ? 'verified' : 'unverified'}`}>
																		<Image src={post?.user?.photo} alt="1" className="img-fluid" />
																	</div>
																</div>
															</Link>
														</Col>
														<Col xs={8} sm={8} md={5} lg={5} className="p-0">
															<div className={`seller-desc ${(loginUser && parseInt(post?.user_id) !== parseInt(loginUser?.id)) ? '' : 'seller-desc1'}`}>
																<h3>
																	<div className="">
																		<Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
																			<OverlayTrigger
																				placement="bottom"
																				delay={{ show: 250, hide: 400 }}
																				overlay={
																					<Tooltip id={`tooltip-${post?.user?.id}`}>
																						{`${post?.user?.username}`}
																					</Tooltip>
																				}
																			>
																				{(post?.user?.username.length) > 8 ? (

																					<div className="">
																						{`${post?.user?.username}`.slice(0, 8)} ...
																					</div>

																				) : (
																					<div className="">
																						{`${post?.user?.username}`}
																					</div>
																				)}
																			</OverlayTrigger>
																		</Link>
																	</div>
																</h3>
																{post?.user?.issubscribed ? (<h6>{t('Verified Seller')}</h6>) : (<h6 className="private-seller" >{t('Private Seller')}</h6>)}
																{/* {loginUser && post?.user_id != loginUser?.id && (
                                                      <div onClick={()=>FollowUnfollowRecentAdsToggle(post.user_id)} className="followbtn">
                                                         <span className="cp">{post?.user?.followedbyme ?'Unfollow':'Follow'}</span>
                                                   </div>
                                                )} */}
															</div>
														</Col>
														<Col xs={12} sm={12} md={4} lg={4} className="pl-1 pr-2">
															<div className="view">
																<Link ><i className="icon-star_fill" aria-hidden="true"></i> <span> {parseFloat(post?.user?.averagerating).toFixed(1)}</span></Link> ({Encriptor.kformatter(post?.user?.review_count)})
																<div className="profile-visit mt-1"><Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`}>{t('Visit Profile')}</Link></div>
															</div>
														</Col>
													</Row>
												</div>
											</div>
										</div>
									</div>
								))}
							</Slider>

						</Col>
					</Row>
				</Container>
			</div>
		)
	}
	return (
		<>
			{/* Loading... */}
		</>
	)
}

export default RecentVisitedAds;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useLayoutEffect, useState } from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import FooterBottomBar from './footerBottomBar';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const userConsent = window.sessionStorage.getItem('get_app_consent');
  const [showAppDownloadConsent, setShowAppDownloadConsent] = useState(false);

  //handle download app bootm modal  
  useLayoutEffect(() => {
    const fixedTop = 300
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        if (!userConsent) {
          setShowAppDownloadConsent(true)
        }
      }
    }
    window.addEventListener('scroll', fixedHeader)
  }, [])
  const { t } = useTranslation();

  return (
    <>
      <footer>
        <div className="top-footer">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="logo footer-nav-heading">
                  <Link to="/"><Image src="/images/logo/miltrade_logo.svg" width='150' alt="flag" className="img-fluid" /></Link>
                  <ul className="footer-nav">
                    <li><Link to='/support?page=miltrade-com-1&title=Miltrade.com' >{t("About Miltrade")}</Link></li>
                    <li><Link to='/support?page=updates-development-1&title=Updates&topic=updates-improvements-1&ttitle=Updates and Improvements' >{t("Updates & Improvements")}</Link></li>
                    <li><Link to='/support?page=support-contact-1&title=Support&topic=contact-miltrade-1&ttitle=Contact Miltrade' >{t("Contact Miltrade")}</Link></li>
                  </ul>
                </div>
              </Col>
              <Col xs={4} sm={6} md={3} lg={3}>
                <div className="footer-nav-heading">
                  <h3>{t("Help & Support")}</h3>
                  <ul className="footer-nav">
                    <li><Link to='/support?page=selling-process&title=Selling Process' >{t("Selling your item")}</Link></li>
                    <li><Link to='/support?page=buying-process&title=Buying process' >{t("Buying an item")}</Link></li>
                    <li><Link to='/support?page=buyer-protection&title=Buyer protection' >{t("Trust & Safety")}</Link></li>
                    <li><Link to="/support?page=updates-development-1&title=Updates&topic=leave-your-feedback-1&ttitle=Leave your feedback" >{t("Leave your feedback")}</Link></li>
                  </ul>
                </div>
              </Col>
              <Col xs={8} sm={6} md={3} lg={3}>
                <div className="footer-nav-heading">
                  <h3>{t("Company & Legal")}</h3>
                  <ul className="footer-nav">
                    <li><Link to='/support?page=terms-of-service&title=Terms of service&topic=terms-and-conditions&ttitle=Terms and Conditions' >{t("Terms & Conditions")}</Link></li>
                    <li><Link to='/support?page=terms-of-service&title=Terms of service&topic=privacy-policy-1&ttitle=Privacy Policy' >{t("Privacy Policy")}</Link></li>
                    <li><Link to='/support?page=private-data-usage-protection&title=Private%20data%20usage%20&%20protection' >{t("Cookies")}</Link></li>
                    <li><Link to={`/support`} >{t("Help & Support")}</Link></li>

                  </ul>
                </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="footer-nav-heading">
                  <h3>{t("Follow Us")}</h3>
                  <ul className="social-icon">
                    <li><a target="_blank" href="https://www.facebook.com/Miltradecom-100596781985148" rel="noreferrer" ><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/miltrade_com/" rel="noreferrer" ><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://www.youtube.com/channel/UCFJ3gWjH0y3PnEmoY67bM9Q" rel="noreferrer" ><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                    <li><a target="_blank" href="https://nl.pinterest.com/miltrade_com/" rel="noreferrer" ><i className="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bottom-footer">
          <p>© Copyright 2023. All Rights Reserved, Miltrade</p>
        </div>

      </footer>
      {showAppDownloadConsent && (
        <div className="footer2">
          <div className="get-app-url">
            <Container>
              <Row className="no-gutters">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-text">
                    <div className='heading-text'>
                      <h5>{t("Carry on Miltrade")}</h5>
                      <div className='close-image'><Image onClick={() => { window.sessionStorage.setItem('get_app_consent', 'OK'); window.location.reload() }} src="/images/close_circle.svg" alt="" className="img-fluid" /> </div>
                    </div>
                    <div className='open-app'>
                      <div className='left-section'>
                        <Image src="/images/logo.svg" alt="" className="img-fluid" />
                        <p>{t("Open to App")}</p>
                      </div>
                      <div className='btn'>
                        <div className="ok-btn ml-3 cp" onClick={() => {
                          window.sessionStorage.setItem('get_app_consent', 'OK');
                          setShowAppDownloadConsent(false);
                          if (isIOS) {
                            window.open('https://apps.apple.com/gb/app/Miltrade/id1562980510', '_blank');

                          } else if (isAndroid) {
                            window.open('https://play.google.com/store/apps/details?id=com.miltrade.app', '_blank');
                          }
                        }

                        } >
                          {t("Get")}</div>
                      </div>
                    </div>
                    <div className='open-app'>
                      <div className='left-section'>
                        {isIOS ? (
                          <Image src="/images/safari.svg" alt="" className="img-fluid" />
                        ) : (
                          <Image src="/images/chrome.svg" alt="" className="img-fluid" />
                        )
                        }
                        <p>{t("Continue to Browse")}</p>
                      </div>
                      <div className='btn'>
                        <div onClick={() => { window.sessionStorage.setItem('get_app_consent', 'OK'); window.location.reload() }} className="close-btn">{t("Carry On")}</div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                 
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
      <FooterBottomBar />
    </>
  )
}


export default Footer;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import StarRatings from 'react-star-ratings';
import "../../assets/css/sidebar.css";
import Accordion from 'react-bootstrap/Accordion';
import Header from "../include/header";
import Footer from "../include/footer";
import Encriptor from "../../services/encriptor"
import dealerServices from "../../services/sellerProfile"
import PageLoader from '../pageLoader'
import Connections from '../comman/connections/connection'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useToasts } from 'react-toast-notifications'
import {
   isMobile,

} from "react-device-detect";
import SeoTags from '../seo/seoTags'
import { Auth } from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';


var isHomePage = false;
const AllDealers = () => {

   const { setMenuCategory } = useContext(Auth);
   const [sidebar, setSidebar] = useState({
      leftOpen: isMobile ? false : true,
      // rightOpen: true,
   });

   var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;
   const toggleSidebar = (event) => {
      let key = `${event.currentTarget.parentNode.id}Open`;
      setSidebar({ [key]: !sidebar[key] });
   }
   const toggleSidebar1 = (event) => {
      let key = `leftOpen`;
      setSidebar({ [key]: !sidebar[key] });
   }
   let leftOpen = sidebar.leftOpen ? 'open' : 'closed';
   const [countries, setCountries] = useState();
   const [dealers, setDealers] = useState([]);
   const [allData, setAllData] = useState();
   const [loaded, setLoaded] = useState(false);
   const [showNumberOfFilters, setShowNumberOfFilters] = useState(6);
   const [filterOption, setFilterOption] = useState([]);
   const [filterString, setFilterString] = useState('');
   const [dealerfilterString, setDealerFilterString] = useState('');
   const [connectionData, setConnectionData] = useState();
   const [connectionModal, setConnectionModal] = useState(false);
   const [filterCount, setFilterCount] = useState(0);
   const [page, setPage] = useState(1);
   const { addToast } = useToasts();
   const [last_page, setLastpage] = useState(0);
   const [next_page, setNextpage] = useState(0);
   const [previous_page, setPrevpage] = useState(0);
   const [current_page, setCurrentpage] = useState(0);
   const [paginationArray, setPaginationArray] = useState([]);
   const [sortBy, setSortBy] = useState();
   const { t } = useTranslation();




   const getDealers = (options = [], filter = null, page = null, sortby = "reviews descending") => {
      setLoaded(false);
      const formData = new FormData();
      if (options.length > 0) {
         for (let index = 0; index < options.length; index++) {
            const element = options[index];
            // if(element.id == "services"){
            //    formData.append(`service_id[]`,element.value);
            // }
            // if(element.id == "specialities"){
            //    formData.append(`speciality_id[]`,element.value);
            // }
            if (String(element.id) === "country") {
               formData.append(`country_code[]`, element.value);
            }
         }
      }
      if (filter) {
         formData.append(`text`, filter);
      }
      if (page) {
         formData.append(`page`, page);
      }
      if (sortby) {
         formData.append(`sortby`, sortby);
      }
      dealerServices.filterDealersData(formData)
         .then(res => {
            setLoaded(true);
            if (res.success)
               setDealers(res.data)
            setLastpage(res?.data?.last_page);
            setNextpage(parseInt(res?.data?.current_page) + 1);
            setPrevpage(parseInt(res?.data?.current_page) - 1);
            setCurrentpage(res?.data?.current_page)
            let tempArr = [];
            for (let index = 1; index <= res?.data?.last_page; index++) {
               tempArr.push({
                  id: index,
                  active: index === parseInt(res?.data?.current_page) ? true : false
               })

            }
            setPaginationArray(tempArr);

         })
         .catch(err => {
            setLoaded(true);
            // console.log('eooer');
         })
   }

   useEffect(() => {
      setMenuCategory('dealers');
      dealerServices.setToken(token);
      dealerServices.allDealerFilters()
         .then(res => {
            setLoaded(true);
            if (res.success) {
               console.log(res.data);
               setAllData(res.data);
               setCountries(res.data)
               getDealers([]);

            }
         })
         .catch(err => {
            setLoaded(true);
         })

      window.scrollTo(0, 0);
   }, [])

   // handle filters  
   const handleFiltersSelect = (e) => {
      const id = e.target.dataset.filter_id;
      const value = e.target.dataset.filter_value;
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (typeof filter !== "undefined") {
            const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterCount(filtereddd.length)
            setFilterOption(filtereddd);
            getDealers(filtereddd, null, page)
         }
         else {
            setFilterOption(filterOption.concat(newData));
            getDealers(filterOption.concat(newData), null, page)
            setFilterCount(filterOption.concat(newData).length)
         }
      } else {
         setFilterOption(filterOption.concat(newData));
         getDealers(filterOption.concat(newData), null, page)
         setFilterCount(filterOption.concat(newData).length)
      }



   }

   //  Handle country filter 
   const filterCountries = (e) => {
      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = allData.filter(item => {
         return item.name.toLowerCase().includes(lowercasedFilter);
      });
      setCountries(filteredData);
   }
   const handleFollowers = (id) => {
      setLoaded(false);
      dealerServices
         .getFollowers(id)
         .then(response => {
            setLoaded(true);
            if (response.success) {
               setConnectionData({
                  ...connectionData,
                  title: 'Followers',
                  data: response.data,
               })
               setConnectionModal(true);
            } else {
               addToast(response.message, { appearance: 'error' })
            }

         })
         .catch(error => {

            setLoaded(true);
            if (error.response) {
               if (error.response.status === 422) {
                  addToast(error.response.data.data, { appearance: 'error' })
               }
               else {
                  addToast(error.response.data.message, { appearance: 'error' })
               }

            }
         })
   }
   const handleSortBy = (e) => {
      setSortBy(e.target.value);
      getDealers(filterOption, dealerfilterString, page, e.target.value);
      // getPostData(categoryID,filterOption,e.target.value)

   }
   // Clear all filters 
   const handleClearFilter = (e) => {
      setFilterOption([]);
      getDealers([])
      setSortBy('')
      setFilterCount(0)
   }

   // On change page handle  
   const handlePage = pageId => {
      setPage(pageId)
      getDealers(filterOption, null, pageId, sortBy)
   }




   return (
      <>
         <SeoTags title={`Miltrade | All Dealers `}
            description={''}
            category={''}
            url={window.location.href}
            image={''}
         />
         <Header
            data={isHomePage}
            checkAuth={false}
         />
         <PageLoader isLoaded={loaded} />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                           <Breadcrumb.Item active>{t("All Dealers")}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="products-fliter dealers-filter">
               <Row className="no-gutters">
                  <Col xs={12} sm={9} md={5} lg={3} className="p-0 m-0">
                     <div className="product-filter-section">
                        <div className="clear-filter">
                           <Row className="no-gutters">
                              <Col xs={6} sm={6} md={5} lg={4} className="p-0 m-0">
                                 <span onClick={toggleSidebar1} className="cp filters filter-custom-badge-wrapper"><i className="icon-side_baar mr-1"></i> {t("Filters")} {filterCount > 0 && (<span className="badge badge-danger filter-custom-badge">{filterCount}</span>)}</span>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={8} className="p-0 m-0">
                                 <span className="clear-all cp" onClick={handleClearFilter} >{t("Clear all")}</span>
                              </Col>
                           </Row>
                        </div>
                     </div>
                     <div className="toggle-btn">
                        <div className="toggle-bt">
                           <div id='left' className={`${leftOpen} toggle-icon`} >
                              <div className='icon' onClick={toggleSidebar} >
                                 <span className="sidebar-close-icon"><i className="icon-dropdown_left"></i></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Col>
                  <Col xs={12} sm={12} md={2} lg={6}> </Col>
                  <Col xs={12} sm={12} md={5} lg={3} className="mt-1">
                     <div className="vendor-directory right-filter-search">
                        <div className="sort-by-search">
                           <Form>
                              <span className="dropdown-icon"></span>
                              <span className="flter-sort-by"><i className="icon-sort"></i></span>
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                 <Form.Control onChange={handleSortBy} defaultValue={''} as="select">
                                    <option value="" disabled hidden>{t("Sort By")}</option>
                                    <option value="reviews descending">{t("Reviews descending")}</option>
                                    <option value="reviews ascending">{t("Reviews ascending")}</option>
                                    <option value="dealer name A-Z">{t("Dealer name A-Z")}</option>
                                    <option value="dealer name Z-A">{t("Dealer name Z-A")}</option>
                                    <option value="Verified dealers">{t("Verified dealers")}</option>
                                 </Form.Control>
                              </Form.Group>
                           </Form>
                        </div>
                     </div>
                     {/* <div className="vendor-directory dealers-directory right-filter-search mx-3">
                        <Form>
                           <div className="input-group">
                              <input type="text" className="form-control" value={dealerfilterString} onChange={filterDealer}  placeholder="Search by Dealer name" />
                              <div className="input-group-append">
                                 <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="icon-search"></i></button>
                              </div>
                           </div>
                        </Form>
                     </div> */}
                  </Col>
               </Row>
            </div>
            <div id='layout' className="dealers-page1">
               <div id='left' className={`left-sidebar1 ${leftOpen}`}>
                  <div className={`sidebar ${leftOpen}`} >
                     <div className="vendor-directory1">
                        <div className='content left-sidebar'>
                           <Accordion defaultActiveKey="0" >
                              <Card>
                                 <Card.Header className="">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" >{t("Country")} <span className={filterOption.find(f => String(f.id) === "country") ? 'active' : ''}>{filterOption.find(f => String(f.id) === "country") ? <i className="icon-right"></i> : ''}</span></Accordion.Toggle>
                                 </Card.Header>
                                 <Accordion.Collapse eventKey="0" >
                                    <Card.Body>

                                       <Form>
                                          <Form.Group controlId="formBasic">
                                             <div className="category-search-icon">
                                                <i className="icon-search"></i>
                                             </div>
                                             <Form.Control type="text" value={filterString} onChange={filterCountries} placeholder={t("search")} />
                                          </Form.Group>
                                       </Form>
                                       <div className="category-name">
                                          <ul>
                                             {countries?.slice(0, showNumberOfFilters).map((option, i) => (
                                                <li key={i} data-filter_value={option.code} data-filter_id="country" onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.code)) ? 'active' : ''}><i className="icon-right"></i> {option.name}</li>
                                             ))}
                                             <div className="loadmorebutton">
                                                {countries?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                                             </div>
                                          </ul>
                                       </div>
                                    </Card.Body>
                                 </Accordion.Collapse>
                              </Card>

                           </Accordion>
                          

                        </div>
                     </div>
                  </div>
               </div>
               <div id='main' className="dealer-right-section recommended-seller">
                  <div className='content mt-3'>
                     {dealers?.data?.length > 0 ? (
                        <>
                           <div className="vendor-item-box">

                              <>
                                 <Row className="wrapper-dealer-card-box">
                                    {dealers?.data?.map((seller, i) => (
                                       <Col key={i} xs={12} sm={6} md={3} lg={3}>
                                          <div key={i} className='item mt-1'>
                                             {seller.issubscribed ? (
                                                <>
                                                   <div className="verified-dealer">
                                                      <Image src="images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-image" />
                                                   </div>
                                                   <div className="seller-image-verified">
                                                      <Link to={`/seller/profile/${Encriptor.encrypt(seller?.id)}`} >
                                                         <Image src={seller?.photo} alt="1" className="img-fluid" />
                                                      </Link>
                                                   </div>
                                                </>
                                             ) : (
                                                <>
                                                   <div className="seller-image" >
                                                      <Link to={`/seller/profile/${Encriptor.encrypt(seller?.id)}`} >
                                                         <Image src={seller?.photo} alt="1" className="img-fluid" />
                                                      </Link>
                                                   </div>
                                                </>
                                             )}

                                             <div className="myCarousel">
                                                <div className="myCarousel-body">
                                                   {seller?.issubscribed ? (<h6>{t("Verified Dealer")}</h6>) : (<h6 className="private-seller" >{t("Private Seller")}</h6>)}
                                                   <div className="mycarousel-body-text">
                                                      <h3>
                                                         <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                               <Tooltip id={`tooltip-${seller?.id}`}>
                                                                  {`${seller?.username}`}
                                                               </Tooltip>
                                                            }
                                                         >
                                                            {(seller?.username?.length) > 14 ? (

                                                               <div className="input-group-append">
                                                                  <Link to={`/seller/profile/${Encriptor.encrypt(seller?.id)}`} >
                                                                     {`${seller?.username} `.slice(0, 14)} ...
                                                                  </Link>
                                                               </div>

                                                            ) : (
                                                               <div className="input-group-append">
                                                                  <Link to={`/seller/profile/${Encriptor.encrypt(seller.id)}`} >
                                                                     {`${seller?.username} `}
                                                                  </Link>
                                                               </div>
                                                            )}

                                                         </OverlayTrigger>
                                                      </h3>
                                                      <p><span className='cp' onClick={() => handleFollowers(seller?.id)} >{seller?.followers} {seller?.followers <= 1 ? 'follower' : 'followers'}  ,</span> {seller?.postcount}  {seller?.postcount <= 1 ? 'listing' : 'listings'} </p>
                                                      <div className="reviews">
                                                         <StarRatings
                                                            rating={seller?.rating}
                                                            starRatedColor="#FFE156"
                                                            numberOfStars={5}
                                                            starSpacing="2px"
                                                            starDimension="18px"
                                                            name='rating'
                                                         />
                                                         <div className="rcReviewCount"> ({seller?.review_count}) </div>
                                                      </div>

                                                      <div className="location-views">
                                                         <ul>
                                                            <li>
                                                               <Image src={seller?.flag} alt={seller?.flag} className="img-fluid" />
                                                               <OverlayTrigger
                                                                  placement="bottom"
                                                                  delay={{ show: 250, hide: 400 }}
                                                                  overlay={
                                                                     <Tooltip id={`tooltip-${seller?.id}`}>
                                                                        {`${seller?.address}`}
                                                                     </Tooltip>
                                                                  }
                                                               >
                                                                  {(seller?.address?.length) > 18 ? (

                                                                     <span>{`${seller?.address} `.slice(0, 18)} ...</span>
                                                                  ) : (
                                                                     <span> {seller?.address} </span>
                                                                  )}

                                                               </OverlayTrigger>


                                                            </li>
                                                            <li className="view"><i className="icon-eye"></i> {Encriptor.kformatter(seller?.visits)} </li>
                                                         </ul>
                                                      </div>
                                                   </div>
                                                
                                                </div>

                                             </div>
                                          </div>
                                       </Col>
                                    ))}
                                 </Row>
                              </>

                           </div>
                           <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                 <div className="loadmorebutton mt-5">
                                    <div className="text-center my-5">
                                       <div className="pagination">
                                          {last_page > 1 && (
                                             <>
                                                {current_page !== 1 && (<Link onClick={() => handlePage(previous_page)} >&laquo;</Link>)}
                                                {paginationArray?.map((page, i) => (
                                                   (i < 12 && (
                                                      <Link key={i} onClick={() => handlePage(page.id)} className={page.active ? 'active' : ''} >{page.id}</Link>
                                                   ))
                                                )
                                                )}
                                                {last_page > 12 && (<Link  >...</Link>)}
                                                {last_page > 12 && (<Link onClick={() => handlePage(last_page)} >{last_page}</Link>)}
                                                {last_page !== current_page && (<Link onClick={() => handlePage(next_page)}>&raquo;</Link>)}
                                             </>
                                          )}

                                       </div>
                                    </div>
                                 </div>
                              </Col>
                           </Row>
                        </>
                     ) : (
                        <>
                           <div className="text-center">
                              <Image src="../images/no_dealer.webp" className="img-fluid" alt="user" />

                           </div>
                        </>
                     )}
                  </div>

               </div>
            </div>
            {connectionData && (
               <Connections
                  title={connectionData?.title}
                  data={connectionData?.data}
                  connectionModal={connectionModal}
                  setConnectionModal={setConnectionModal}
               />
            )}
         </div>
         <Footer />
      </>
   );
}
export default AllDealers;
/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/users';


const $axiosConfig = {
    headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': true,
    }
}

const register = (newObject) => {
    const request = axios.post(baseUrl + `/register`, newObject, $axiosConfig)
    return request.then(response => response.data)
}
const verifyEmail = (newObject) => {
    const request = axios.post(baseUrl + `/verifyEmail`, newObject, $axiosConfig)
    return request.then(response => response.data)
}
const resetPassword = (newObject) => {
    const request = axios.post(baseUrl + `/reset`, newObject)
    return request.then(response => response.data)
}
const forgotPassword = (newObject) => {
    const request = axios.post(baseUrl + `/forgotpass`, newObject)
    return request.then(response => response.data)
}
const reverifyEmail = (newObject) => {
    const request = axios.post(baseUrl + `/verifyagain`, newObject)
    return request.then(response => response.data)
}



export default { register, verifyEmail, resetPassword, forgotPassword, reverifyEmail }
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const NotFound = () =>{
  const { t } = useTranslation();
    return (
        <>
        <Container>
        <Row className="justify-content-center align-items-center">
        <Col xs lg="6" className="">  
          <div className="NotFound text-center">
            <div className="notfound-image"><Image src="/images/404.webp" /></div>
            </div>
        </Col>
        <Col xs lg="6" className="">  
          <div className="NotFound">
            <h4 className="mb-4">{t("UH OH! You're lost.")}</h4>
            <p>{t("The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.")}</p>
            <div className="btn-medium mt-4">
              <Link to="/">{t("Home")}</Link>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
      </>
    )
} 
export default NotFound;

/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/users';

let token = null

const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}

const sellerProfile = (data, page, lang) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    if (lang !== undefined) {
        config = {
            headers: token ? { Authorization: token, "App-Language": lang } : { "App-Language": lang },
        }
    }

    const request = axios.post(baseUrl + `/otheruserProfile?page=${page}`, data, config)
    return request.then(response => response.data)
}
const followUser = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/followme/${id}`, config)
    return request.then(response => response.data)
}
const unFollowUser = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/unfollowme/${id}`, config)
    return request.then(response => response.data)
}
const reviewUser = (data, id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/review/${id}`, data, config)
    return request.then(response => response.data)
}
const dealerService = (data, id) => {
    const request = axios.get(baseUrl + `/userserivces`)
    return request.then(response => response.data)
}
const getFollowers = (user_id) => {
    const request = axios.get(baseUrl + `/userfollowers/${user_id}`)
    return request.then(response => response.data)
}
const getFollowings = (user_id) => {
    const request = axios.get(baseUrl + `/userfollowings/${user_id}`)
    return request.then(response => response.data)
}
const allDealerFilters = () => {
    const request = axios.get(prefix + `/home/homepage_dealer_filter`)
    return request.then(response => response.data)
}
const filterDealersData = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(prefix + `/home/homepage_dealers`, data, config)
    return request.then(response => response.data)
}




export default { setToken, sellerProfile, followUser, unFollowUser, reviewUser, dealerService, getFollowers, getFollowings, allDealerFilters, filterDealersData }
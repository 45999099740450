import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';



const MyLoader = ({showLoader,title,description}) => {
    const { t } = useTranslation();
	 return (
		<>
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Modal show={showLoader} dialogClassName="modal-90w  product-create-modal" size="lg">
                            <Modal.Body>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">{t("Loading...")}</span>
                                </Spinner>
                                <div className="product-create">
                                    <h3>{title}</h3>
                                    <p>{description}</p>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </Container>
		</>
	)
}
export default MyLoader;
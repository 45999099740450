import React from 'react'
import { ScrollToTop, Authentication } from "./components";
import { ToastProvider } from 'react-toast-notifications'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AppRoutes from './AppRoutes';


require('dotenv').config();

const App = () => {
      return (
            <ToastProvider
                  autoDismiss
                  autoDismissTimeout={6000}
                  placement="bottom-right"
            >
                  <ScrollToTop />
                  <AppRoutes />
                  <Authentication />

            </ToastProvider>

      )
}

export default App;
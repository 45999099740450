/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
require('dotenv').config()
const prefix = process.env.REACT_APP_BASE_URL;
const baseUrl = prefix + '/users';
const baseUrl1 = prefix;


let token = null

const setToken = newToken => {
    if (newToken)
        token = `Bearer ${newToken}`
}


const myProfile = (data = {}, page, lang) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    if (lang !== undefined) {
        config = {
            headers: token ? { Authorization: token, "App-Language": lang } : { "App-Language": lang },
        }
    }
    const request = axios.post(baseUrl + `/myprofile?page=${page}`, data, config)
    return request.then(response => response.data)
}
const editProfile = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/editprofile`, config)
    return request.then(response => response.data)
}
const getCities = (countryCode) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/citydata/${countryCode}`, config)
    return request.then(response => response.data)
}
const updateAdditionalServices = (services) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/user_services`, services, config)
    return request.then(response => response.data)
}
const updateProfile = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/updateprofile`, data, config)
    return request.then(response => response.data)
}
const getMyListing = (lang) => {
    var config = {
        headers: token ? { Authorization: token, "App-Language": lang } : { "App-Language": lang },
    }
    const request = axios.get(baseUrl + `/my_listing`, config)
    return request.then(response => response.data)
}
const setCurrency = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/set_currency`, data, config)
    return request.then(response => response.data)
}

const postSupportTicket = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl1 + `/contact_form`, data, config)
    return request.then(response => response.data)
}


const changePassword = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/change_password`, data, config)
    return request.then(response => response.data)
}
const updateProfileImage = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/updateimageprofile`, data, config)
    return request.then(response => response.data)
}
const subscriptionPlanInfo = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/planinfo`, config)
    return request.then(response => response.data)
}
const subscriptionInfo = () => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/mysubscriptiondetails`, config)
    return request.then(response => response.data)
}
const subscriptionPDF = (id) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl + `/invoice_pdf/${id}`, config)
    return request.then(response => response.data)
}
const updateBusinessProfile = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/updatebusinessprofile`, data, config)
    return request.then(response => response.data)
}
const cancelSubscription = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.get(baseUrl1 + `/cancel/subscription`, config)
    return request.then(response => response.data)
}
const updateNotification = (data) => {
    var config = {
        headers: token ? { Authorization: token } : {},
    }
    const request = axios.post(baseUrl + `/updateNotificationSetting`, data, config)
    return request.then(response => response.data)
}





export default {
    setToken, myProfile, editProfile, getCities, updateAdditionalServices, updateProfile,
    getMyListing, postSupportTicket, changePassword, updateProfileImage, setCurrency,
    subscriptionPlanInfo, subscriptionInfo, subscriptionPDF, updateBusinessProfile, cancelSubscription, updateNotification
}
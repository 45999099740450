import axios from "axios";

const translateText = async (to, data) => {
    const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=${to}&dt=t&q=${encodeURI(data, "UTF-8")}`
    try {
        const response = await axios.get(url).catch(err => err);
        return response.data;

    } catch (error) {
        return error;
    }

}




export default translateText;

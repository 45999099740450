import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { Auth } from '../../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';






const ProductDetailsTable = ({ postData, handleReportModal }) => {
	const { t } = useTranslation();
	const { setShowLoginModal } = useContext(Auth);
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const OptionVlaue = (customField) => {
		return customField.optionvalue;
	}
	if (postData) {
		return (
			<>


				<div className="title">
					<h4>{t('Item Details')}</h4>
					{loginUser ? (loginUser.id !== postData?.user?.id && !(postData?.report_by_me)) && (
						<h5 className="cp" onClick={handleReportModal} >{t('Report listing')}</h5>
					) :
						<h5 className="cp" onClick={() => setShowLoginModal(true)} >{t('Report listing')}</h5>
					}

				</div>
				<Table bordered size="sm">
					<tbody>

						{postData?.details && (
							postData.details.map((cf, index) => (
								<tr key={index}>
									<td>
										<div className="post-sub-category-name"> {cf.name}</div>
									</td>
									<td>
										<div className="post-sub-category-name"> 	{OptionVlaue(cf)}</div>
									</td>
								</tr>

							))
						)}

					</tbody>
				</Table>

			</>

		)
	}

	return (
		<>
			{t('Loading ...')}
		</>
	)
}
export default ProductDetailsTable;
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Encriptor from '../../../services/encriptor'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';



const ListView = ({ posts, deletePost, markSoldPost, renewPost }) => {
   const [showNumberOfItems, setShowNumberOfItems] = useState(10);
   const { t } = useTranslation();
   if (posts) {
      return (
         <>
            <div className="edit-profile-grid-view">
               <Container>
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="list-post-sections-views">
                           {posts?.slice(0, showNumberOfItems)?.map((post, i) => (
                              <div key={`list-view-${i}`} className="product-list-post mt-3">
                                 <Row className="no-gutters">
                                    <Col xs={12} sm={12} md={3} lg={3}>
                                       {post?.sold ? (
                                          <div className="featured"><span className="sold">{("SOLD")}</span></div>
                                       ) : (
                                          (post?.deleted) ? (
                                             <>
                                                <div className="featured"><span className="expired">{t("DELETED")}</span></div>
                                             </>
                                          ) : (
                                             <>
                                                {post?.expired ? (<div className="featured"><span className="expired" >{t("EXPIRED")}</span></div>) : (
                                                   <>
                                                      {post?.featured && (<div className="featured"><span >{t("FEATURED")}</span></div>)}
                                                   </>
                                                )}

                                             </>
                                          )
                                       )}
                                       <div className="list-post-image">
                                          <div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)} </Link> </span></div>
                                          <Link to={`/product/detail/${post.slug}`} ><Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" /> </Link>
                                       </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8}>
                                       <h5 className="Product-title">
                                          <Link to={`/product/detail/${post?.slug}`} >
                                             <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                   <Tooltip id={`tooltip-${post?.id}`}>
                                                      {`${post?.title}`}
                                                   </Tooltip>
                                                }
                                             >
                                                {(post?.title?.length) > 100 ? (

                                                   <div className="">

                                                      {`${post?.title}`.slice(0, 100)} ...

                                                   </div>

                                                ) : (
                                                   <div className="">
                                                      {`${post?.title}`}
                                                   </div>
                                                )}
                                             </OverlayTrigger>
                                          </Link>
                                       </h5>
                                       <div className="Product-desc">
                                          <OverlayTrigger
                                             placement="bottom"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={
                                                <Tooltip className="tooltiptext" id={`tooltip-${post?.id}`}>
                                                   {`${post?.short_description} `}
                                                </Tooltip>
                                             }
                                          >
                                             {(post?.short_description.length) > 150 ? (

                                                <div className="">
                                                   {`${post?.short_description}`.slice(0, 150)} ...
                                                </div>

                                             ) : (
                                                <div className="">
                                                   {`${post?.short_description}`}
                                                </div>
                                             )}
                                          </OverlayTrigger>
                                       </div>
                                       <div className="price-location">
                                          <ul className='d-flex'>
                                             <li className=""><strong>{post?.open_to_offer ? (`${t('Open to offers')}`) : (post?.formatted_price)}</strong></li>
                                             <li className="location">
                                                <div className="country_location g-1">
                                                   <img alt={post?.country_flag} src={post?.country_flag} />
                                                   <span>{post?.address} </span>
                                                </div>
                                             </li>
                                          </ul>
                                       </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={1} lg={1} className="owner-details">
                                       <div className="action-btn">
                                          <ul>
                                             {post?.can_edit && (<li><Link className="link" to={`/product/edit/${Encriptor.encrypt(post?.postid)}`}>
                                                <OverlayTrigger
                                                   placement="left"
                                                   delay={{ show: 250, hide: 400 }}
                                                   overlay={
                                                      <Tooltip id={`tooltip-edit`}>
                                                         {t("Edit Listing")}
                                                      </Tooltip>
                                                   }
                                                >
                                                   <i className="icon-edit"></i>
                                                </OverlayTrigger>
                                             </Link></li>)}
                                             {post?.can_delete && (<li onClick={() => deletePost(post?.postid)}><Link className="link" >
                                                <OverlayTrigger
                                                   placement="left"
                                                   delay={{ show: 250, hide: 400 }}
                                                   overlay={
                                                      <Tooltip id={`tooltip-edit`}>
                                                         {t("Delete Listing")}
                                                      </Tooltip>
                                                   }
                                                >
                                                   <i className="icon-delete"></i>
                                                </OverlayTrigger>
                                             </Link></li>)}
                                             {!post?.expired && (<li onClick={() => markSoldPost(post?.postid)}><Link className="link" >
                                                <OverlayTrigger
                                                   placement="left"
                                                   delay={{ show: 250, hide: 400 }}
                                                   overlay={
                                                      <Tooltip id={`tooltip-edit`}>
                                                         {t("Mark sold")}
                                                      </Tooltip>
                                                   }
                                                >
                                                   {/* <i className="icon-sold"></i> */}
                                                   <small className="list-sold-icon">{t("Sold")}</small>
                                                </OverlayTrigger>
                                             </Link></li>)}
                                             {post?.can_renew && (<li onClick={() => renewPost(post?.postid, post?.renew_cost)}><Link className="link" >
                                                <OverlayTrigger
                                                   placement="left"
                                                   delay={{ show: 250, hide: 400 }}
                                                   overlay={
                                                      <Tooltip id={`tooltip-renew`}>
                                                         {t("Renew Listing")}
                                                      </Tooltip>
                                                   }
                                                >
                                                   <i className="icon-renew"></i>
                                                </OverlayTrigger>
                                             </Link></li>)}
                                          </ul>
                                       </div>
                                    </Col>
                                 </Row>
                              </div>

                           ))}
                           <div className="loadmorebutton mt-5">
                              {posts.length > showNumberOfItems && (<div className="loadmorebutton py-5"><button onClick={() => setShowNumberOfItems(showNumberOfItems + 5)} type="button" className="btn btn-primary py-2"> {t("View more")} <i className="icon-dropdown"></i></button></div>)}
                           </div>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>
         </>
      )
   }

}
export default ListView;



import React, { createContext, useState } from 'react'

export const Auth = createContext();
require('dotenv').config()

const AuthContext = ({ children }) => {

    const language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
  


    const [globalFilter, setGlobalFilter] = useState( {
          filter_text: '',
          filter_name: '',
          filter_category: '',
          serachClicked: false,
    });
    const [menuCategory, setMenuCategory] = useState('home');
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);
    const [newMessageCount, setNewMessageCount] = useState(0);
    const [userProfileData, setUserProfileData] = useState(null);
    const [menuCategories, setMenuCategories] = useState([]);
    const [lang, setLang] = useState(language);

    const changeLang = (code) => {
        localStorage.setItem('lang', code);
        setLang(code);
    }

    // useEffect(() => {
    //     async function checkPathForSelectedCategory() {

    //         const routeName = location.pathname.split('/')[1];

    //         if (['', 'dealers', 'category', 'search'].includes(routeName)) {

    //             // const filterCategory = localStorage.getItem('filterCategory') ? localStorage.getItem('filterCategory') : ''
    //             // let filterText = localStorage.getItem('searchText') ? localStorage.getItem('searchText') : '';
    //             // filterText = routeName === 'search' ? filterText : '';
    //             // setGlobalFilter({
    //             //     filter_text: filterText,
    //             //     filter_name: '',
    //             //     filter_category: filterCategory,
    //             //     serachClicked: false,
    //             // })
    //         } else {
    //             localStorage.removeItem('filterCategory');
    //             localStorage.removeItem('filterText');
    //             setGlobalFilter({
    //                 filter_text: '',
    //                 filter_name: '',
    //                 filter_category: '',
    //                 serachClicked: false,
    //             })
    //         }


    //     }
    //     checkPathForSelectedCategory()
    // }, [location])


    return (
        <Auth.Provider value={{
            globalFilter,
            setGlobalFilter,
            menuCategory,
            setMenuCategory,
            showLoginModal,
            setShowLoginModal,
            walletBalance,
            setWalletBalance,
            newMessageCount,
            setNewMessageCount,
            userProfileData,
            setUserProfileData,
            lang,
            changeLang,
            menuCategories, 
            setMenuCategories
        }}
        >
            {children}
        </Auth.Provider>
    );
}

export default AuthContext;

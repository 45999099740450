/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Header from "../../include/header";
import Footer from "../../include/footer";
import postsService from '../../../services/posts'
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import MyLoader from '../../myloader';
import walletServices from "../../../services/wallet";
import PageLoader from '../../pageLoader'
import { loadStripe } from '@stripe/stripe-js';
import SeoTags from '../../seo/seoTags'
import Swal from 'sweetalert2'
import { Auth } from '../../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
require('dotenv').config()

const MakeFeaturedProduct = () => {
	const { t } = useTranslation(Auth);
	const { setWalletBalance } = useContext(Auth);
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
	var isHomePage = false;
	const { postID } = useParams();
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const token = loginUser.access_token;


	const PostId = postID;
	const [show, setShow] = useState(false);
	const [postData, setPostData] = useState('');
	const [totalAmount, setTotalAmount] = useState('');
	const [requiredCredit, setRequiredCredit] = useState();
	const [featuringChargeCredit, setFeaturingChargeCredit] = useState('');
	const [featuringDays, setFeaturingDays] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [okToCreditFromWallet, setOkToCreditFromWallet] = useState(true);


	const [currentBalance, setCurrentBalance] = useState(0);
	const [completed, setCompleted] = useState(false);
	const [creditError, setCreditError] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [loaderdata, setLoaderdata] = useState({
		title: "",
		message: "",
	});

	const [payWithPaypal, setPayWithPaypal] = useState(false);
	const { addToast } = useToasts();
	let env = process.env.REACT_APP_PAYPAL_ENV;

	const buttonStyles = {
		size: 'medium',
		color: 'black',
		tagline: false,
		shape: 'rect',
		label: 'pay'
	}

	const client = {
		sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
		production: process.env.REACT_APP_PAYPAL_CLIENT_ID
	}

	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			// cancelButton: 'mr-2 btn btn-danger'
		},
		buttonsStyling: false
	})
	const location = useLocation();
	const navigate = useNavigate();
	const query = new URLSearchParams(location.search);
	const paymentStatus = query.get('status') ? query.get('status') : false;
	const post_id = query.get('post_id') ? query.get('post_id') : false;
	const credit = query.get('credit') ? query.get('credit') : false;
	const days = query.get('days') ? query.get('days') : false;


	postsService.setToken(token);

	useEffect(() => {
		const getProductDetail = async (PostId) => {
			postsService
				.featuringData(PostId)
				.then(response => {
					if (response.success) {
						setIsLoaded(true);
						if (response.featured) {
							navigate('/');
						}
						setPostData(response);
						setFeaturingChargeCredit(response.data[0].credit);
						setTotalAmount(response.data[0].amount);
						setFeaturingDays(response.data[0].days);
						setRequiredCredit(response.data[0].required_credit)
					} else {
						setIsLoaded(true);
						addToast(response.message, { appearance: 'error' })
					}

				})
				.catch(error => {

					setIsLoaded(true);
					if (error.response) {
						if (error.response.status === 422) {
							addToast(error.response.data.data, { appearance: 'error' })
						}
						else {
							addToast(error.response.data.message, { appearance: 'error' })
						}

					}
				})

		}

		if (post_id && paymentStatus === "succeeded") {
			setIsLoaded(false);
			featuredProduct()
		}

		if (post_id && paymentStatus === "failed") {
			swalWithBootstrapButtons.fire(
				t('Payment failed'),
				t('please try again'),
				'error'
			)


		}
		getProductDetail(PostId)


	}, [PostId])

	const featuredProduct = async () => {

		const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
		const token = loginUser.access_token;
		postsService.setToken(token);
		const newfeaturedPost = {
			rate: credit ? credit : featuringChargeCredit,
			postId: post_id ? post_id : postData?.post_id,
			days: days ? days : featuringDays,
		}

		await postsService
			.markFeatured(newfeaturedPost)
			.then(response => {

				if (response.success) {

					// addToast("Post marked featured successfully",{ appearance: 'success' })
					window.localStorage.setItem('total_balance', response.wallet_balance);
					setWalletBalance(response.wallet_balance);
					setIsLoaded(true);
					setCompleted(true);
				} else {
					addToast(response.message, { appearance: 'error' })
				}

			})
			.catch(error => {
				setIsLoaded(true);
				addToast(error.response.data.message, { appearance: 'error' })
			})
	}

	const onSuccess = (payment) => {
		CreditWallet(payment);
	}

	const onCancel = (data) => {
		addToast(t('The payment was cancelled!'), { appearance: 'error' });
	}

	const onError = (err) => {
		addToast(`${t('Error')} ${err}`, { appearance: 'error' });
	}

	const checkCreditAndPay = () => {
		if (parseFloat(requiredCredit) > 0) {
			setOkToCreditFromWallet(false);
			setTotalAmount(totalAmount);
			setCreditError(`${('Not enough credit, add')}  ${requiredCredit} ${t('credits in your wallet to proceed')}`);
		} else {
			setIsLoaded(false);
			featuredProduct();
			setCreditError(false);
		}

	}


	const CreditWallet = async (data) => {

		const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
		const token = loginUser.access_token;
		walletServices.setToken(token);
		const newWalletCredit = {
			total_amount: totalAmount,
			payment_method: 'paypal',
			credit: requiredCredit,
			payment_data: data,
		}
		setLoaderdata({
			title: t('Please wait'),
			message: t('Please Wait processing your payment'),
		});
		setIsLoaded(false);
		setShowLoader(true)
		await walletServices
			.creditWallet(newWalletCredit)
			.then(response => {
				setIsLoaded(true);
				if (response.success) {
					window.localStorage.setItem('total_balance', response.wallet_balance);
					setWalletBalance(response.wallet_balance);
					setCurrentBalance(response.wallet_balance)
					setTotalAmount(response.wallet_balance);
					setPaymentMethod('');
					setShowLoader(false)
					setCompleted(false);
					setCreditError(false);
					setPayWithPaypal(false);
					setIsLoaded(false);
					featuredProduct();
				}

			})
			.catch(error => {
				setIsLoaded(true);
				setShowLoader(false)
			})
	}

	const handlePaymentMethod = async (e) => {

		if (e.currentTarget.dataset.value === 'paypal') {
			setPaymentMethod(e.currentTarget.dataset.value)
			setIsLoaded(true);
		} else {
			const data = {
				amount: parseFloat(totalAmount).toFixed(2),
				payment_method: e.currentTarget.dataset.value,
				credit: requiredCredit,
				days: featuringDays,
				post_id: postData?.post_id,
				success_url: `featured/${postData?.slug}`,
				failed_url: `featured/${postData?.slug}`,
			}
			setPaymentMethod(e.currentTarget.dataset.value)
			const stripe = await stripePromise;

			walletServices.setToken(token);
			const response = await walletServices.createPaymentIntent(data);
			const session = await response.data;
			const result = await stripe.redirectToCheckout({
				sessionId: session.session_id,
			});

			if (result.error) {
				swalWithBootstrapButtons.fire(
					t('Payment server error!'),
					result.error.message,
					'error'
				)
			}


		}


	}

	const handlePaymentData = (p) => {
		setFeaturingChargeCredit(p.credit);
		setTotalAmount(p.amount);
		setFeaturingDays(p.days);
		setRequiredCredit(p.required_credit)
	}

	return (
		<>
			<SeoTags title={`Miltrade | Featured `}
				description={''}
				category={''}
				url={window.location.href}
				image={''}
			/>
			<Header isHomePage={isHomePage} checkAuth={true} />
			<PageLoader isLoaded={isLoaded} />
			<div className="footer-bottom1">
				<div className="breadcrum">
					<Container fluid>
						<Row>
							<Col>
								<Breadcrumb>
									<Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
									<Breadcrumb.Item href="#">{t('place listing')}</Breadcrumb.Item>
									<Breadcrumb.Item active>{t('sell faster')}</Breadcrumb.Item>
								</Breadcrumb>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="featured-tag">
					<Container>
						<Row>
							<Col xs={12} sm={12} md={4} lg={3}>
								<div className="featured_tag-img">
									<Image src="/images/slider-image/featured_tag.webp" className="img-fluid" alt="user" />
								</div>

							</Col>
							<Col xs={12} sm={12} md={8} lg={9}>
								<div className="featured_tag-desc">
									<h3>{t('Boost your item')}</h3>
									<p>{t('Enlarge the visibility and chances of selling your item by boosting your item.')} <br /> {t('An item boost can be activated for 5 or 10 days you can always activate')} <br />{t('a new boost after this period.')}</p>
								</div>
								<div className="featured_tag_list">
									<p><i className="icon-right"></i> {t('Receive a "featured" tag on your listing during the entire boost period')}</p>
									<p><i className="icon-right"></i> {t('Show your item on top of all relevant category and search result pages')}</p>
									<p><i className="icon-right"></i> {t('Enlarge your item visibility and thus your chances on making a quick sale!')}</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>

				<div className="featured-tag-detais">
					<Container>
						<Row>
							<Col xs={12} sm={12} md={6} lg={6}>
								<div className="select-listing">
									<h4>{t('Selected listing')}:</h4>
									<p>{postData?.title ? postData?.title : postData?.slug}</p>
									<div className="view-listing-btn mt-2">
										<span className="cp" onClick={() => { navigate(`/product/detail/${postData?.slug}`) }}>{t('view listing')}</span>
									</div>
								</div>
							</Col>
							<Col xs={12} sm={12} md={6} lg={6}>
								<div className="featured-tag-detail1">
									<ul>
										{postData?.data?.map((p, i) => (
											<li key={i}>
												<h3>{p.days} Days</h3>
												<div className="featured-tag-radio1">
													<span className="feature-tag-price">{`${p.credit}`} {t('credits')}</span>
													<div className="radio">
														<input id={`radio-${i}`} name="radio" type="radio" onClick={() => handlePaymentData(p)} checked={String(p.credit) === String(featuringChargeCredit) ? true : false} />
														<label htmlFor={`radio-${i}`} className="radio-label"></label>
													</div>
												</div>
											</li>

										))}
									</ul>
								</div>

							</Col>

							{okToCreditFromWallet ? (
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="featured-tag-btn">
										<p>{t('Total costs')}: <span className="totla-cost"> {featuringChargeCredit} {featuringChargeCredit <= 1 ? 'credit' : 'credits'}</span></p>
										<span onClick={checkCreditAndPay} className="tag-btn cp">{t('Activave item boost')}</span>
									</div>
								</Col>

							) : (
								<div className="payment-methods">

									<Col xs={12} sm={12} md={12} lg={12} >
										<div className="recommended">
											<h5 className="mb-3 mt-3" >{t('Payment for')} {requiredCredit} {requiredCredit <= 1 ? t('credit') : t('credits')}</h5>
											{/* {totalAmount > 0  && (<span>You will be charged <strong style={{color:'var(--green)'}} >{loginUser?.currency_symbol} { totalAmount} </strong> </span>)}	 */}
											<ul>
												<li className='mb-2'>
													<PaypalExpressBtn env={env}
														client={client}
														currency={loginUser?.default_currency ? loginUser?.default_currency : 'EUR'}
														total={totalAmount ? totalAmount : 0.00}
														style={buttonStyles}
														onError={onError}
														onSuccess={onSuccess}
														onCancel={onCancel} />
												</li>

												<li><span onClick={handlePaymentMethod} data-value="card" className={`wallet-card-btn cp ${paymentMethod === 'card' ? 'active' : ''}`}><Image src="/images/payment-methods/card1.svg" alt="card" width="20px" className="mr-1" /> Credit Card</span></li>
												{loginUser?.default_currency === "EUR" && (
													<>
														<li><span onClick={handlePaymentMethod} data-value="ideal" className={`wallet-card-btn cp ${paymentMethod === 'ideal' ? 'active' : ''}`}><Image src="/images/payment-methods/ideal.webp" alt="ideal" width="20px" className="mr-1" /> iDEAL</span></li>
														<li><span onClick={handlePaymentMethod} data-value="sofort" className={`wallet-card-btn cp ${paymentMethod === 'sofort' ? 'active' : ''}`}><Image src="/images/payment-methods/sofort.webp" alt="sofort" width="20px" className="mr-1" /> Sofort</span></li>
														<li><span onClick={handlePaymentMethod} data-value="bancontact" className={`wallet-card-btn cp ${paymentMethod === 'bancontact' ? 'active' : ''}`}><Image src="/images/payment-methods/bancontact.webp" alt="bancontact" width="20px" className="mr-1" /> Bancontact</span></li>
														<li><span onClick={handlePaymentMethod} data-value="giropay" className={`wallet-card-btn cp ${paymentMethod === 'giropay' ? 'active' : ''}`}><Image src="/images/payment-methods/giropay.webp" alt="giropay" width="20px" className="mr-1" /> giropay</span></li>
													</>
												)}
											</ul>
										</div>
									</Col>





								</div>
							)}
						</Row>
					</Container>
				</div>

				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-90w see-example-modal" className="user-login-modal">
								<Modal.Header closeButton className="m-0">
								</Modal.Header>
								<Modal.Body>
									<div className="see-example">
										<h3>{t('Featured Tag Example')} </h3>
										<p>{t('Upgrade your Ad a top position with featured tag')}</p>
									</div>
									<div className="see-example-img">
										<Image src="/images/slider-image/see_example.webp" className="img-fluid" alt="user" />
									</div>
								</Modal.Body>
							</Modal>
						</Col>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Modal show={completed} onHide={() => { setCompleted(false); navigate(`/product/detail/${PostId}`); }} dialogClassName="modal-90w animation_congratulations-modal">
								<Modal.Body>
									<div className="animation_congratulations-img">
										<Image src="/images/animation/animation_congratulations.gif" className="img-fluid" alt="user" />
									</div>
									<div className="cong">
										<h3>{t('Congratulations !')}</h3>
										<p>{t('Your listing is boosted now...')}</p>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<div className="report-add-footer">
										<Button variant="secondary close-btn" onClick={() => { setCompleted(false); navigate(`/product/detail/${PostId}`); }}>{t('Ok')}</Button>
									</div>
								</Modal.Footer>
							</Modal>
						</Col>
					</Row>
				</Container>
				<MyLoader showLoader={showLoader}
					title={loaderdata.title}
					description={loaderdata.message}
				/>
			</div>
			<Footer />
		</>
	)
}
export default MakeFeaturedProduct;
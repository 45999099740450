/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import Image from 'react-bootstrap/Image';
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import profileService from '../../../services/myProfile'
import { useToasts } from 'react-toast-notifications'
import { Link } from "react-router-dom";
import PageLoader from '../../pageLoader'
import getCroppedImg from '../../products/productAdd/croppedImage'
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-easy-crop'
import RangeSlider from 'react-bootstrap-range-slider';
import { Auth } from '../../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';




const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
const token = loginUser.access_token;
const EditProfile = ({ active }) => {

	const { t } = useTranslation();
	const { setUserProfileData } = useContext(Auth);
	const [profile, setProfile] = useState();
	const [formData, setFormData] = useState();
	const [selectedServices, setSelectedServices] = useState();
	const [selectedSpecialities, setSelectedSpecialities] = useState();
	const [filterString, setFilterString] = useState('');
	const [countries, setCountries] = useState([]);
	const [filteredCountries, setFilteredCountries] = useState([]);
	const [newServiceArray, setNewServiceArray] = useState([]);
	const [newSpecialitiesArray, setNewSpecialitiesArray] = useState([]);
	const [filteredPhoneCodes, setFilteredPhoneCodes] = useState([]);
	const [filterPhoneString, setFilterPhoneString] = useState('');
	const [filterCityString, setFilterCityString] = useState('');
	const [cities, setCities] = useState([]);
	const [filteredCities, setFilteredCities] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [tempDefaultCurrency, setTempDefaultCurrency] = useState();
	const [countryFlag, setCountryFlag] = useState();
	const [upImg, setUpImg] = useState();
	const [cropper, setCropper] = useState(false);
	const [rotation, setRotation] = useState(0);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [zoom, setZoom] = useState(1)
	const [phoneCode, setPhoneCode] = useState();
	const [city, setCity] = useState();
	const [crop, setCrop] = useState({
		x: 0, y: 0,
		cropSize: { width: 450, height: 251 },
		aspect: 4 / 3,
	})
	const [validationError, setValidationError] = useState();
	const { addToast } = useToasts();

	// get user profile data 
	const getEditProfileData = async () => {
		setLoaded(true);
		await profileService
			.editProfile()
			.then(response => {
				setLoaded(false);
				if (response.success) {
					setProfile(response.data);
					setFormData(response.data.user);
					setTempDefaultCurrency(response.data.user.currency_symbol);
					setSelectedServices(response.data.selectedServices);
					setSelectedSpecialities(response.data.selectedSpecialities);
					setFilteredCountries(response.data.country);
					setFilteredPhoneCodes(response.data.country);
					setCountries(response.data.country);
					getCities(response.data.user.country_code, response.data.user.city_id)
					if (response.data.user.country_code) {
						let tempData = response.data.country.find(p => String(p.country_code) === String(response.data.user.country_code));
						setCountryFlag(tempData);
					} else {
						let tempData = response.data.country[0];
						setCountryFlag(tempData);
					}
					if (response.data.user.phone_code) {
						let tempData = response.data.country.find(p => String(p.phone_code) === String(response.data.user.phone_code));
						if (typeof (tempData) != 'undefined') {
							setPhoneCode(tempData);
						} else {
							setPhoneCode(response.data.country[0]);
						}
					} else {
						let tempData = response.data.country[0];
						setPhoneCode(tempData);
					}
				} else {
					addToast(response.message, { appearance: 'error' })
				}

			})
			.catch(error => {
				setLoaded(false);
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})


	}

	// handler for country filter locally 
	const handlefilter = (e) => {

		var str = e.target.value || '';
		setFilterString(str);
		const lowercasedFilter = str.toLowerCase();
		const filteredData = countries.filter(item => {
			return item.name.toLowerCase().includes(lowercasedFilter);
		});
		// filteredPhoneCodes,setFilteredPhoneCodes
		setFilteredCountries(filteredData)

	}

	// handler for onChange phone code 
	const handlePhoneCodeSelect = (e) => {
		// update the value of phone_code
		const pcode = e.currentTarget.dataset.value;
		const selectedFilter = countries.find(c => String(c.phone_code) === String(pcode));
		if (typeof (selectedFilter) != "undefined") {
			setPhoneCode(selectedFilter);
		}
	}

	// get cities from api
	const getCities = async (country_code, city_id) => {
		setLoaded(true);
		await profileService
			.getCities(country_code)
			.then(response => {
				setLoaded(false);
				setCities(response.result)
				setFilteredCities(response.result)
				if (response.success) {
					if (city_id) {
						let tempData = response.result.find(c => parseInt(c.id) === parseInt(city_id));
						if (tempData !== undefined) {
							setCity(tempData);
						} else {
							// setCity(response.result[0]);
							setCity(tempData);
						}

					} else {
						let tempData = response.result[0];
						setCity(tempData)
					}

				} else {
					addToast(response.message, { appearance: 'error' })
				}

			})
			.catch(error => {
				setLoaded(false);
				if (error.response) {
					if (error.response.status === 422) {
						addToast(error.response.data.data, { appearance: 'error' })
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}

	useEffect(() => {
		profileService.setToken(token);
		getEditProfileData();
	}, [token])

	// comman form onChange handler 
	const handleFromChange = (e) => {

		const { name, value: newValue, type } = e.target;
		const value = type === 'number' ? +newValue : newValue;
		if (String(name) === 'country_code') {
			getCities(value)
		}
		if (String(name) === 'default_currency') {
			// getCities(value)
			const selectedSymbol = profile?.currencies?.find(currency => String(currency.code) === String(value));
			if (typeof selectedSymbol != "undefined") {
				setTempDefaultCurrency(selectedSymbol?.symbol)
			}
		}

		setFormData({
			...formData,
			[name]: value ? value : '',
		});
	}

	// hander for city change  
	const handleCityChange = (e) => {
		const value = e.currentTarget.dataset.value;
		const find = cities.find(c => String(c.id) === String(value));
		if (typeof (find) != 'undefined') {
			setCity(find);
		}


	}
	// hander for city dropdown filter 
	const handleCityfilter = (e) => {

		var str = e.target.value || '';
		setFilterCityString(str);
		const lowercasedFilter = str.toLowerCase();
		const filteredData = cities.filter(item => {
			return item.name.toLowerCase().includes(lowercasedFilter);
		});
		setFilteredCities(filteredData)

	}

	// check for validation error 
	const hasErrorFor = (field) => {
		if (validationError) {

			const error = validationError.find(element => String(element.field) === String(field));
			if (error)
				return true;
		} else {
			return false;
		}
	}

	//  rendor error for validation error
	const renderErrorFor = (field) => {
		if (hasErrorFor(field)) {
			const error = validationError.find(element => String(element.field) === String(field));
			return (
				<div className="error-wrapper">
					<span className='invalid-feedback'>
						<strong>{error.message}</strong>
					</span>
				</div>
			)
		}
	}

	// select services handler 
	const selectService = id => {

		const find = selectedServices.find(s => String(s) === String(id))
		if (find !== undefined) {
			const newData = selectedServices.filter(s => String(s) !== String(id))
			setSelectedServices(newData);

		} else {
			var services = [...selectedServices, String(id)];
			setSelectedServices(services);
		}
	}

	// select specialities handler 
	const selectSpecialities = id => {
		const find = selectedSpecialities.find(s => String(s) === String(id))
		if (typeof find !== undefined) {
			const newData = selectedSpecialities.filter(s => String(s) !== String(id))
			setSelectedSpecialities(newData);

		} else {
			var services = [...selectedSpecialities, String(id)];
			setSelectedSpecialities(services);
		}
	}

	// updated profile api 
	const updateUserProfile = (e) => {
		e.preventDefault();
		setLoaded(true);
		// saveServices()
		// console.log(formData);
		const newData = {
			...formData,
			specialities: selectedSpecialities,
			services: selectedServices,
			phone_code: phoneCode.phone_code,
			city_id: city ? city.id : '',
		}
		profileService.setToken(token);
		profileService
			.updateProfile(newData)
			.then(response => {
				if (response.success) {
					setLoaded(false);
					setFormData(response.data);
					const UpdatedUser = {
						...loginUser,
						first_name: response.data.first_name,
						last_name: response.data.last_name,
						picture: response.data.photo,
						default_currency: response.data.default_currency,
						currency_symbol: response.data.currency_symbol,
						phone_code: phoneCode.phone_code,
						phone: response.data.phone,
						country_code: response.data.country_code,
						city_id: city?.id,
						address: city?.name + ' , ' + countryFlag?.name
					}
					window.localStorage.setItem('loggedUser', JSON.stringify(UpdatedUser))
					setUserProfileData({
						first_name: response.data.first_name,
						last_name: response.data.last_name,
						image: response.data.photo,
					})
					addToast(t('Profile Updated Successfully'), { appearance: 'success' })

				} else {
					setLoaded(false);
					addToast(response.result, { appearance: 'error' })
				}
			})
			.catch(error => {
				if (error.response) {
					setLoaded(false);
					if (error.response.status === 422) {
						setValidationError(
							error.response.data.errors
						)
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
					}

				}
			})
	}

	// upload  profile api 
	const uploadProfileImage = (croppedImage) => {
		if (croppedImage) {
			setLoaded(true);
			const formData = new FormData();
			formData.append(`profile_img`, croppedImage)
			profileService.setToken(token);
			profileService
				.updateProfileImage(formData)
				.then(response => {
					setLoaded(false);
					if (response.success) {
						const userData = profile.user;
						const updatedUser = {
							...userData,
							photo: response.data

						}
						setProfile({
							...profile,
							user: updatedUser
						})
						const UpdatedUser = {
							...loginUser,
							picture: response.data,

						}

						window.localStorage.setItem('loggedUser', JSON.stringify(UpdatedUser))
						setUserProfileData({
							first_name: loginUser.first_name,
							last_name: loginUser.last_name,
							image: response.data,

						})
						addToast(t('Profile photo updated successfully'), { appearance: 'success' })



					}
				})
				.catch(error => {
					setLoaded(false);
					if (error.response) {
						if (error.response.status === 422) {
							addToast(error.response.data.data, { appearance: 'error' })
						}
						else {
							addToast(error.response.data.message, { appearance: 'error' })
						}

					}
				})
		}
	}

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])


	// country filter select handler 
	const handleCountrySelect = (e) => {
		const pcode = e.currentTarget.dataset.value;
		const pflag = e.currentTarget.dataset.image;
		const pname = e.currentTarget.dataset.name;
		setCountryFlag({
			...countryFlag,
			country_code: pcode,
			name: pname,
			flag: pflag
		})

		setFormData({
			...formData,
			country_code: pcode,
		});
		let tempData = countries.find(p => String(p.country_code) === String(pcode));
		setPhoneCode(tempData);
		getCities(pcode)
	}

	// phonecode  filter select handler 
	const handlePhonefilter = (e) => {

		var str = e.target.value || '';
		setFilterPhoneString(str);
		const lowercasedFilter = str.toLowerCase();
		const filteredData = countries.filter(item => {
			return item.phone_code.toLowerCase().includes(lowercasedFilter);
		});
		setFilteredPhoneCodes(filteredData)

	}

	// handle onSelect file 
	const onSelectFile = (e) => {

		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setUpImg(reader.result)
			}

			);
			reader.readAsDataURL(e.target.files[0]);
			setCropper(true);
		}


	};

	// show cropper preview 
	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				upImg,
				croppedAreaPixels,
				rotation
			)
			uploadProfileImage(croppedImage)
		} catch (e) {
			console.error(e)
		}
	}, [croppedAreaPixels, rotation])

	if (String(active) === "Profile settings") {
		return (
			<>
				<PageLoader isLoaded={!loaded} />
				<div className="create-profile border-0">
					<h3 className="px-4">{t('Edit Profile')}</h3>
					<div className="px-4 edit-profile-data">
						<Form onSubmit={updateUserProfile}>
							<Form.Row>
								<Col xs={12} sm={12} md={3} lg={2}>
									<div className="profile-image-change"> <Form.Label>{t('Profile Image')}<span className=""></span></Form.Label></div>
									{profile?.user?.issubscribed ? (
										<>
											<div className="profile-upload1">
												<div className="image-upload2 file-upload">
													<label htmlFor="file-input"><i className="icon-camera"></i></label>
													<input id="file-input" type="file" name="profile" onChange={onSelectFile} />
												</div>
												<div className="user-prof">
													<Image src={profile?.user?.photo} className="img-fluid" alt="user" />
												</div>
											</div>
											<div className="view-profile-button mb-3">
												<Link className="link btn btn-primary" to="/profile">{t('View Profile')}</Link>
											</div>
										</>
									) : (
										<>
											<div className="profile-upload2">
												<div className="image-upload1 file-upload">
													<label htmlFor="file-input1"><i className="icon-camera"></i></label>
													<input id="file-input1" type="file" name="profile" onChange={onSelectFile} />
												</div>
												<div className="user-prof-unverified">
													<Image src={profile?.user?.photo} className="img-fluid" alt="user" />
												</div>
											</div>
											<div className="view-profile-button mb-3">
												<Link className="link btn btn-primary" to="/profile">{t('View Profile')}</Link>
											</div>
										</>
									)}



								</Col>

							</Form.Row>
							<Form.Row>
								<Col xs={12} sm={12} md={6} lg={6}>

									<Form.Label>{t('First Name')} <span className="required-star">*</span></Form.Label>
									<Form.Group controlId="formGridtext">
										<div className="edit-profile-icon">
											<i className="icon-name"></i>
										</div>
										<Form.Control
											className={`${hasErrorFor('first_name') ? 'is-invalid' : 'valid'}`}
											type="text"
											name="first_name"
											onChange={handleFromChange}
											value={formData?.first_name ? formData?.first_name : ''}
											placeholder={t('Enter User First Name')}
										/>
									</Form.Group>
									{renderErrorFor('first_name')}
								</Col>
								<Col xs={12} sm={12} md={6} lg={6}>
									<Form.Label>{t('Last Name')} <span className="required-star">*</span></Form.Label>
									<Form.Group controlId="formGridtext">
										<div className="edit-profile-icon">
											<i className="icon-name"></i>
										</div>
										<Form.Control
											className={`${hasErrorFor('last_name') ? 'is-invalid' : 'valid'}`}
											type="text"
											name="last_name"
											onChange={handleFromChange}
											value={formData?.last_name ? formData?.last_name : ''}
											placeholder={t('Enter User Last Name')}
										/>
									</Form.Group>
									{renderErrorFor('last_name')}
								</Col>

							</Form.Row>
							<Form.Row>
								<Col xs={12} sm={12} md={6} lg={6}>
									<Form.Label>{t('Email')} <span className=""></span></Form.Label>
									<Form.Group controlId="formGridEmail">
										<div className="edit-profile-icon">
											<i className="icon-email"></i>
										</div>
										<Form.Control
											className={`${hasErrorFor('email') ? 'is-invalid' : 'valid'} fade-input`}
											type="email"
											name="email"
											readOnly={true}
											onChange={handleFromChange}
											value={formData?.email ? formData?.email : ''}
											placeholder={t('Enter Email')}
										/>
									</Form.Group>
									{renderErrorFor('email')}
								</Col>
								<Col xs={12} sm={12} md={6} lg={6}>
									<Form.Label>{t('Username')} <span className=""></span></Form.Label>
									<Form.Group controlId="formGridEmail">
										<div className="edit-profile-icon">
											<i className="icon-name"></i>
										</div>
										<Form.Control
											className={`${hasErrorFor('username') ? 'is-invalid' : 'valid'} fade-input`}
											type="username"
											name="da"
											readOnly={true}
											value={formData?.username ? formData?.username : ''}
											placeholder={t('Enter Username')}
										/>
									</Form.Group>
									{renderErrorFor('username')}
								</Col>

							</Form.Row>
							<Form.Row>
								<Col xs={12} sm={12} md={6} lg={6}>
									<Form.Label>{t('Country')} <span className="required-star">*</span></Form.Label>
									<Form.Group controlId="formGridState">
										<Dropdown className="form-control profileDropDown">
											<div className="subCategoryWrapper">
												<Dropdown.Toggle className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
													<div className={`dropbtn `}>{countryFlag && (
														<>
															<Image className="flag-image" src={countryFlag?.flag} alt={countryFlag?.flag} />
															<span> {countryFlag?.name}</span>
														</>
													)}</div>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<div className="mobile-code-search">
														<div className="form-group">
															<div className="category-search-icon"><i className="icon-search"></i></div>
															<input value={filterString} onChange={handlefilter} placeholder="search list" type="text" className="form-control" />
														</div>
													</div>
													{filteredCountries?.map((country, i) =>
														<Dropdown.Item onClick={handleCountrySelect}
															data-image={country?.flag}
															data-name={country?.name}
															data-value={country?.country_code}
															key={i} > <img src={country?.flag} alt={country?.name} /> {country?.name}
														</Dropdown.Item>
													)}
												</Dropdown.Menu>
											</div>
										</Dropdown>
									</Form.Group>
									{renderErrorFor('country_code')}
								</Col>
								<Col xs={12} sm={12} md={6} lg={6}>
									<Form.Label>City <span className="required-star">*</span></Form.Label>
									<Form.Group controlId="formGridtext">
										<Dropdown className="form-control profileDropDown">

											<div className="subCategoryWrapper">

												<Dropdown.Toggle className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
													<div className={`dropbtn `}>{city && (
														<><i className="icon-city mx-2"></i> <span> {city?.name}</span> </>
													)}</div>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<div className="mobile-code-search">
														<div className="form-group">
															<div className="category-search-icon"><i className="icon-search"></i></div>
															<input value={filterCityString} onChange={handleCityfilter} placeholder="search city" type="text" className="form-control" />
														</div>
													</div>
													{filteredCities?.map((city, i) =>
														<Dropdown.Item onClick={handleCityChange}
															data-name={city?.name}
															data-value={city.id}
															key={i} > {city?.name}
														</Dropdown.Item>
													)}
												</Dropdown.Menu>
											</div>
										</Dropdown>
									</Form.Group>
								</Col>
							</Form.Row>
							<Form.Row>
								<Col xs={12} sm={12} md={6} lg={6}>
									<Form.Label>{t('Phone number')} <span className="required-star">*</span></Form.Label>
									<Form.Group controlId="formGridtext">
										<div className="input-group mb-3">
											<Dropdown>
												<div className="becomeVerifedUser ProfileEditPhoneCode">
													<Dropdown.Toggle disabled={true} className="mobile-number-left-dropdown " variant="success" id="dropdown-basic">
														<div className={`dropbtn `}>{phoneCode && (
															<>
																<Image className="flag" src={phoneCode?.flag} width="20px" />
																<span> {phoneCode?.phone_code}</span>
															</>
														)}</div>

													</Dropdown.Toggle>
													<Dropdown.Menu>
														<div className="mobile-code-search">
															<form className="">
																<div className="form-group">
																	{/* <div className="category-search-icon"><i className="icon-search"></i></div> */}
																	<input value={filterPhoneString} onChange={handlePhonefilter} placeholder="search list" type="text" id="formBasic" className="form-control2 form-control4 form-control" />
																</div>
															</form>
														</div>
														{filteredPhoneCodes?.map((code, i) =>
															<Dropdown.Item onClick={handlePhoneCodeSelect}
																data-image={code.flag}
																data-value={code?.phone_code}
																key={i} > {code?.phone_code}
															</Dropdown.Item>
														)}
													</Dropdown.Menu>
												</div>
											</Dropdown>

											<Form.Control
												className={`form-control2 ${hasErrorFor('phone') ? 'is-invalid' : 'valid'}`}
												type='number'
												name="phone"
												min="0"
												onChange={handleFromChange}
												value={formData?.phone ? formData?.phone : ''}
												placeholder={t('Enter your phone number')}
											/>

										</div>

									</Form.Group>
									{renderErrorFor('phone')}
								</Col>
								<Col xs={12} sm={12} md={6} lg={6}>
									<Form.Label>{t('Currency')} <span className="required-star">*</span></Form.Label>
									<Form.Group controlId="formGridState">
										<div className="edit-profile-icon">
											<span className="wallet-currency-symbol">{tempDefaultCurrency}</span>
										</div>
										<Form.Control as="select" onChange={handleFromChange} defaultValue={profile?.user?.default_currency} name="default_currency" >
											{profile?.currencies?.map((currency, i) => (

												<option key={i} value={currency.code}>{` ${currency.code}`}</option>


											))}
										</Form.Control>
									</Form.Group>
									{renderErrorFor('default_currency')}
								</Col>

							</Form.Row>
							{profile?.user?.issubscribed && (
								<Form.Row>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>{t('VAT number')} <span className=""></span></Form.Label>
										<Form.Group controlId="formGridEmail">
											<div className="edit-profile-icon">
												<i className="icon-vat"></i>
											</div>
											<Form.Control
												className={`${hasErrorFor('vat') ? 'is-invalid' : 'valid'}`}
												type="text"
												name="vat"
												onChange={handleFromChange}
												value={formData?.vat ? formData?.vat : ''}
												placeholder="Enter VAT number"
											/>
										</Form.Group>
										{renderErrorFor('vat')}
									</Col>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>{t('Postal Code')}</Form.Label>
										<Form.Group controlId="formGridEmail">
											<div className="edit-profile-icon">
												<i className="icon-postal_code"></i>
											</div>
											<Form.Control
												className={`${hasErrorFor('postal_code') ? 'is-invalid' : 'valid'}`}
												type="vat"
												name="postal_code"
												onChange={handleFromChange}
												value={formData?.postal_code ? formData?.postal_code : ''}
												placeholder={t('Enter postal code')}
											/>
										</Form.Group>
										{renderErrorFor('postal_code')}
									</Col>

								</Form.Row>
							)}
							{profile?.user?.issubscribed && (
								<Form.Row>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>Company name <span className="required-star">*</span></Form.Label>
										<Form.Group controlId="formGridtext">
											<div className="edit-profile-icon">
												<i className="icon-website"></i>
											</div>
											<Form.Control
												className={`${hasErrorFor('company_name') ? 'is-invalid' : 'valid'}`}
												type="text"
												name="company_name"
												onChange={handleFromChange}
												value={formData?.company_name ? formData?.company_name : ''}
												placeholder={t('Enter Website')}
											/>
										</Form.Group>
										{renderErrorFor('company_name')}
									</Col>

									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>{t('Website')} <span className="required-star">*</span></Form.Label>
										<Form.Group controlId="formGridtext">
											<div className="edit-profile-icon">
												<i className="icon-website"></i>
											</div>
											<Form.Control
												className={`${hasErrorFor('website') ? 'is-invalid' : 'valid'}`}
												type="text"
												name="website"
												onChange={handleFromChange}
												value={formData?.website ? formData?.website : ''}
												placeholder={t('Enter Website')}
											/>
										</Form.Group>
										{renderErrorFor('website')}
									</Col>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>{t('Your address detail')} <span className="required-star">*</span></Form.Label>
										<Form.Group controlId="exampleForm.">
											<Form.Control
												className={`${hasErrorFor('business_address') ? 'is-invalid' : 'valid'}`}
												name="business_address"
												onChange={handleFromChange}
												value={formData?.business_address ? formData?.business_address : ''}
												placeholder="Street name + house number"
											/>

										</Form.Group>
										{renderErrorFor('business_address')}
									</Col>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>{t('other address detail')}  <span className="required-star"></span></Form.Label>
										<Form.Group controlId="exampleForm.">
											<Form.Control
												className={`${hasErrorFor('business_address_1') ? 'is-invalid' : 'valid'}`}

												name="business_address_1"

												onChange={handleFromChange}
												value={formData?.business_address_1 ? formData?.business_address_1 : ''}
												placeholder="address 2"
											/>

										</Form.Group>
										{renderErrorFor('business_address_1')}
									</Col>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>{t('Business Email')} <span className="required-star">*</span></Form.Label>
										<Form.Group controlId="exampleForm.ControlTextarea1">
											<div >
												<Form.Control
													className={`${hasErrorFor('business_email') ? 'is-invalid' : 'valid'}`}
													name="business_email"
													rows={9}
													onChange={handleFromChange}
													value={formData?.business_email ? formData?.business_email : ''}
													placeholder={t('Your buisness email')}
												/>
											</div>
										</Form.Group>
										{renderErrorFor('business_email')}
									</Col>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Label>{t('Business description')} <span className="required-star">*</span></Form.Label>
										<Form.Group controlId="exampleForm.ControlTextarea1">
											<div className="textarea">
												<Form.Control
													className={`${hasErrorFor('business_description') ? 'is-invalid' : 'valid'}`}
													as="textarea"
													name="business_description"
													rows={9}
													onChange={handleFromChange}
													value={formData?.business_description ? formData?.business_description : ''}
													placeholder={t('about your business')}
												/>
											</div>
										</Form.Group>
										{renderErrorFor('business_description')}
									</Col>

								</Form.Row>
							)}
							{profile?.user?.issubscribed && (
								<Form.Row>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Group controlId="formGridState">
											<div className="form-edit-labal">{t('Additional Services')} <span className="required-star">*</span></div>
										</Form.Group>
										<ul className="show-services mt-2">
											{profile?.services.map((service, i) => (
												<li onClick={() => selectService(service.id)} className={selectedServices ? selectedServices.find(element => String(element) === String(service.id)) ? 'active cp' : 'cp' : 'cp'} key={i}><span>{service.title}</span></li>
											))}
											{newServiceArray.map((service, i) => (
												<li className='cp active' key={i}><span>{service.title}</span></li>
											))}
										</ul>
									</Col>
									<Col xs={12} sm={12} md={6} lg={6}>
										<Form.Group controlId="formGridState">
											<div className="form-edit-labal">{t('Additional Specialities')} <span className="required-star">*</span></div>


										</Form.Group>
										<ul className="show-services mt-2">
											{profile?.specialities.map((service, i) => (
												<li onClick={() => selectSpecialities(service.id)} className={selectedSpecialities ? selectedSpecialities.find(element => String(element) === String(service.id)) ? 'active cp' : 'cp' : 'cp'} key={i}><span>{service.title}</span></li>
											))}
											{newSpecialitiesArray.map((service, i) => (
												<li className='cp active' key={i}><span>{service.title}</span></li>
											))}
										</ul>
									</Col>

								</Form.Row>
							)}

							<Form.Row>
								<Col>
									<div className="update-profile-submit">
										<Button variant="primary" type="submit">{t('Update Profile')}</Button>
									</div>
								</Col>
							</Form.Row>
						</Form>
					</div>
				</div>
				<Modal show={cropper} onHide={() => setCropper(false)} dialogClassName="modal-90w " size="lg">
					<div className="image-crop-modal">
						<div className="cropWrapper">
							<Modal.Header closeButton></Modal.Header>
							<div className="modal-content">
								<div className="modal-body">
									<div className="crop-container">
										<Cropper
											image={upImg}
											crop={crop}
											zoom={zoom}
											aspect={4 / 3}
											onCropChange={setCrop}
											onCropComplete={onCropComplete}
											onZoomChange={setZoom}
										/>
									</div>
								</div>


							</div>
							<div className="controls">
								<RangeSlider
									value={zoom}
									min={1}
									max={3}
									step={0.01}
									onChange={changeEvent => setZoom(changeEvent.target.value)}
								/>
							</div>
							<div className="image-crop-btn">
								<Button onClick={() => { showCroppedImage(); setCropper(false) }} >{t('Save')} </Button>
							</div>
						</div>
					</div>
				</Modal>
			</>
		)
	}
	return (
		<>
		</>
	)
}
export default EditProfile;
import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { Link } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';




const WalletTransactions = ({ tranactions, handlePage }) => {
	const { t } = useTranslation();
	if (tranactions?.data?.length <= 0) {
		return (

			<div className="text-center">
				<Image src="../../images/no_paymetnt_and_history.webp" className="img-fluid" alt="user" />
				<p>{t('No transaction found')}</p>
			</div>
		)
	}
	const last_page = tranactions?.last_page;
	const next_page = parseInt(tranactions?.current_page) + 1;
	const previous_page = parseInt(tranactions?.current_page) - 1;
	const current_page = tranactions?.current_page;
	let paginationArray = [];

	for (let index = 1; index <= last_page; index++) {
		paginationArray.push({
			id: index,
			active: index === parseInt(current_page) ? true : false
		})
	}
	
	return (
		<>
			<Table responsive="sm">
				<tbody>
					{tranactions?.data?.map((history, index) => (
						<tr key={index}>
							<td>
								<div className="blance-display1">
									<div className="wallet-icon1"> <span> <i className="icon-wallet"></i></span></div>
									<div className="wallet-text1">
										<p>{history?.description} {history?.slug && (<Link className='cp inovice-btn' to={`/product/detail/${history?.slug}`} > {t("View Listing")}</Link>)}
										</p>
										<h4>{history.created_at} </h4>
										<p className={`${history.type === "deposit" ? 'text-success' : 'text-danger'}`} >{history.type === "deposit" ? '+' : ''} {history.credit} {history.credit < 1 ? `${t('credit')}` : `${t('credits')}`}</p>
									</div>
								</div>
							</td>
							<td> <div className={`history-amount ${history.type === "deposit" ? 'text-success' : 'text-danger'}`}> {history.type === "deposit" ? '+' : ''}{history.currency_code_symbol} {history.amount}</div></td>
						</tr>
					))}
				</tbody>
			</Table>

			<Row>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="loadmorebutton mt-5">
						<div className="text-center my-5">
							<div className="pagination">
								{last_page > 1 && (
									<>
										{parseInt(current_page) !== 1 && (<Link onClick={() => handlePage(previous_page)} >&laquo;</Link>)}
										{paginationArray?.map((page, i) => (
											(i < 12 && (
												<Link key={i} onClick={() => handlePage(page.id)} className={parseInt(current_page) === page.id ? 'active' : ''} >{page.id}</Link>
											))
										)
										)}
										{last_page > 12 && (<Link  >...</Link>)}
										{last_page > 12 && (<Link onClick={() => handlePage(last_page)} className={parseInt(current_page) === parseInt(last_page) ? 'active' : ''} >{last_page}</Link>)}
										{parseInt(last_page) !== parseInt(current_page) && (<Link onClick={() => handlePage(next_page)}>&raquo;</Link>)}
									</>
								)}
							</div>
						</div>
					</div>
				</Col>
			</Row>


		</>
	)
}
export default WalletTransactions;
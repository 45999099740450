import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Encriptor from "../../../../services/encriptor";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';



const GridView = ({ posts, LikeUnlikeToggleButton }) => {

   const { t } = useTranslation();
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
  
   if (posts) {
      return (
         <>
            <Row className="no-gutters-row-only">
               {posts?.map((post, i) => (

                  <Col xs={6} key={i} sm={6} md={6} lg={3} className="">
                     <div className="product-display mt-3">
                        <div className="card">
                           {post?.featured && (<div className="featured"><span>{t('FEATURED')}</span></div>)}
                           {loginUser && parseInt(post?.user_id) !== parseInt(loginUser?.id) && (
                              <div className="heart cp">
                                 <span>
                                    {post.likedbyme ? (
                                       <div className="red_favourite" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-red_favourite active" aria-hidden="true"></i></div>
                                    ) : (
                                       <div className="favourite1" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-favourite" aria-hidden="true"></i></div>
                                    )}
                                 </span>
                              </div>
                           )}
                           <div className="img-card">
                              <div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)}</Link> </span></div>
                              <Link to={`/product/detail/${post.slug}`} >
                                 <Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" />
                              </Link>
                           </div>
                           <div className="cards">
                              <h5 className="Product-title">
                                 <Link to={`/product/detail/${post?.slug}`} >
                                    <OverlayTrigger
                                       placement="bottom"
                                       delay={{ show: 250, hide: 400 }}
                                       overlay={
                                          <Tooltip id={`tooltip-${post?.id}`}>
                                             {`${post?.title}`}
                                          </Tooltip>
                                       }
                                    >
                                       {(post?.title?.length) > 20 ? (

                                          <div className="">

                                             {`${post?.title}`.slice(0, 20)} ...

                                          </div>

                                       ) : (
                                          <div className="">
                                             {`${post?.title}`}
                                          </div>
                                       )}
                                    </OverlayTrigger>
                                 </Link>
                              </h5>
                              <div className="Product-desc">
                                 <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                       <Tooltip id={`tooltip-${post?.id}`}>
                                          {`${post?.short_description} `}
                                       </Tooltip>
                                    }
                                 >
                                    {(post?.short_description.length) > 40 ? (

                                       <div className="">
                                          {`${post?.short_description}`.slice(0, 40)} ...
                                       </div>

                                    ) : (
                                       <div className="">
                                          {`${post?.short_description}`}
                                       </div>
                                    )}
                                 </OverlayTrigger>
                              </div>
                              <div className="price-location">
                                 <ul>
                                    <li className=""><strong>{post?.open_to_offer ? ('Open to offers') : (post?.formatted_price)}</strong> </li>
                                    <Link to={`/product/detail/${post.slug}`} > <li className="view-listing cp"> {t('view listing')} </li></Link>
                                    
                                 </ul>
                              </div>
                              
                           </div>
                        </div>
                     </div>
                  </Col>

               ))}

            </Row>
         
         </>
      )
   }
   return (
      <>
      </>
   )
}
export default GridView;
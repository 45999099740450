/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useParams, useLocation } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../assets/css/sidebar.css";
import Accordion from 'react-bootstrap/Accordion';
import Header from "../include/header";
import Footer from "../include/footer";
import homePageServices from "../../services/home";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import GridView from "./grid";
import ListView from "./list";
import postsServices from "../../services/posts";
import sellerProfile from '../../services/sellerProfile'
import { useToasts } from 'react-toast-notifications'
import PageLoader from '../pageLoader'
import "react-month-picker/css/month-picker.css";
import { isMobile } from "react-device-detect";
import SeoTags from '../seo/seoTags';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../../Contexts/langContext';
var isHomePage = false;


const TheaterOfWar = () => {
   var location = useLocation();
   const { lang } = useLangContext(langContext);
   const { t } = useTranslation();
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   var token = loginUser.access_token;
   const { categoryID } = useParams();
   const { option_id } = useParams();
   const [categoryFilterData, setCategoryFilterData] = useState();
   const [loaded, setLoaded] = useState(false);
   const [filterOption, setFilterOption] = useState([]);
   const [showNumberOfFilters, setShowNumberOfFilters] = useState(6);
   const [posts, setPosts] = useState();
   const [allData, setAllData] = useState();
   const [sortBy, setSortBy] = useState();
   const [monthFilterCount, setMonthFilterCount] = useState(false);
   const [sidebar, setSidebar] = useState({
      leftOpen: isMobile ? false : true,
   });

   const [filterCount, setFilterCount] = useState(0);
   const [filterString, setFilterString] = useState('');
   const [filteredList, setFilteredList] = useState([]);
   const [page, setPage] = useState(1);
   const [adsFilter, setAdsFilter] = useState('all');
   const [minDate, setMinDate] = useState('');
   const [maxDate, setMaxDate] = useState('');
   const [rangeError, setRangeError] = useState('');
   const [view, setView] = useState('grid');
   const { addToast } = useToasts();
   let leftOpen = sidebar.leftOpen ? 'open' : 'closed';
   var text_search = '';

   useEffect(() => {
      homePageServices.warTheaterFilters()
         .then(res => {
            setLoaded(true);

            if (res.success)
               homePageServices.setToken(token);
            setCategoryFilterData(res.data)
            handleClearFilter()
         })
         .catch(err => {
            setLoaded(true);
            console.log('eooer');
         })
   }, [])

   //  product range  filter handlers 
   const handleFirstRangeInput = (e) => {
      const id = e.target.dataset.filter_id;
      var value = e.target.value || '';
      setMinDate(value);
      const minval = value;
      const maxval = maxDate;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      let filter = filterOption.find(f => (String(f.id) !== String(id)));
      if (typeof filter !== "undefined") {
         var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (typeof filter !== "undefined") {
               const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)
               getPostData(categoryID, filtereddd, sortBy, page)
            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)
               getPostData(categoryID, filterOption.concat(newData), sortBy, page)
            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
            getPostData(categoryID, filterOption.concat(newData), sortBy, page)
         }
      }

   }

   const handleSecondRangeInput = (e) => {
      const id = e.target.dataset.filter_id;
      var value = e.target.value || '';
      setMaxDate(value);
      const minval = minDate;
      const maxval = value;
      const error = checkErrorInRange(minval, maxval);
      setRangeError(error);
      const filter = filterOption.find(f => (String(f.id) === String(id)));
      if (filter !== undefined) {
         var filtereddd = filterOption.filter(f => String(f.id) !== String(id));
         setFilterOption(filtereddd);
         setFilterCount(filtereddd.length)
      }
      if (error === '') {
         const newData = {
            id: id,
            value: `${minval} - ${maxval}`,
         }
         if (filterOption.length > 0) {
            let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
            if (typeof filter !== "undefined") {
               const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
               setFilterOption(filtereddd);
               setFilterCount(filtereddd.length)
               getPostData(categoryID, filtereddd, sortBy, page)
            }
            else {
               setFilterOption(filterOption.concat(newData));
               setFilterCount(filterOption.concat(newData).length)
               getPostData(categoryID, filterOption.concat(newData), sortBy, page)
            }
         } else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
            getPostData(categoryID, filterOption.concat(newData), sortBy, page)
         }
      }
   }


   // Product ranger custom validation 
   const checkErrorInRange = (minval, maxval) => {
      let errorss = '';

      if (maxval < minval) {
         errorss = t('To year must be greater or equals than from year');
      }


      if (maxval < 1500) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('To year should be greater than or equals 1500');
      }

      if (minval > new Date().getFullYear()) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('To year should be lesser than ') + new Date().getFullYear();
      }

      if (maxval.length < 4 || maxval.length > 4) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('To year should be 4 digits');
      }

      if (maxval === '') {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('Please enter to year');
      }

      if (minval < 1500) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('From year should be greater than or equals 1500');
      }

      if (minval > new Date().getFullYear()) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('From year should be lesser than or equals ') + new Date().getFullYear();
      }

      if (minval.length < 4 || minval.length > 4) {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('From year should be 4 digits');
      }

      if (minval === '') {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('Please enter from year');
      }

      if (minval === '') {
         // handlemin(selelectedOptionID[1].trim())
         errorss = t('Please enter from year');
      }
      return errorss;
   }

   // filter bar toggle switch
   const toggleSidebar = (event) => {
      let key = `${event.currentTarget.parentNode.id}Open`;
      setSidebar({ [key]: !sidebar[key] });
   }
   const toggleSidebar1 = (event) => {
      let key = `leftOpen`;
      setSidebar({ [key]: !sidebar[key] });
   }


   if (typeof (location.state) != "undefined") {
      text_search = location.state.text;
   }

   // Get listings from server
   const getPostData = (categoryID = null, options = [], sortBy = "New", page = null) => {
      setLoaded(false);
      const formData = new FormData();
      // formData.append('category_id',categoryID);
      if (options.length > 0) {
         for (let index = 0; index < options.length; index++) {
            const element = options[index];
            formData.append(`cf[${element.id}][]`, element.value);
         }
      }
      formData.append('option_id', option_id);
      if (sortBy) {
         formData.append('sortby', sortBy);
      }
      if (text_search) {
         formData.append('text', text_search);
      }
      if (page) {
         formData.append('page', page);
      }
      // postService.searchPost(formData)
      homePageServices.theatreOfWarData(formData)
         .then(res => {
            setLoaded(true);
            if (res.success) {

               setPosts(res.data)
               setAllData(res.data)
            }
         })
         .catch(err => {
            setLoaded(true);
            console.log('eooer');
         })
   }

   // Listing filter selection handler 
   const handleFiltersSelect = (e) => {
      const id = e.target.dataset.filter_id;
      const value = e.target.dataset.filter_value;
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if (filter !== undefined) {
            const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)
            getPostData(categoryID, filtereddd, sortBy)
         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
            getPostData(categoryID, filterOption.concat(newData), sortBy)
         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)
         getPostData(categoryID, filterOption.concat(newData), sortBy)
      }
   }

   // Filter  date picker selction handler
   const handleDatepickerValue = (event, picker) => {

      var id = event.currentTarget.dataset.filter_id;
      var startDate = picker.startDate.format('MM/DD/YYYY');
      var endDate = picker.endDate.format('MM/DD/YYYY');
      var value = `${startDate} to ${endDate}`;
      if (event.currentTarget.dataset.type === 'date') {
         value = picker.startDate.format('MM/DD/YYYY')
      }
      const newData = {
         id: id,
         value: value,
      }
      if (filterOption.length > 0) {
         let filter = filterOption.find(f => (String(f.id) === String(id) && String(f.value) === String(value)));
         if ( filter !== undefined) {
            const filtereddd = filterOption.filter(f => String(f.value) !== String(filter.value));
            setFilterOption(filtereddd);
            setFilterCount(filtereddd.length)
            getPostData(categoryID, filtereddd, sortBy)
         }
         else {
            setFilterOption(filterOption.concat(newData));
            setFilterCount(filterOption.concat(newData).length)
            getPostData(categoryID, filterOption.concat(newData), sortBy);
         }
      } else {
         setFilterOption(filterOption.concat(newData));
         setFilterCount(filterOption.concat(newData).length)
         getPostData(categoryID, filterOption.concat(newData), sortBy)
      }

   }

   // Like Unlike handler
   const LikeUnlikeToggleButton = (postId) => {
      const post = posts.find(n => n.postid === postId)
      setLoaded(false);
      postsServices.setToken(token)
      if (post.likedbyme) {
         postsServices.unLikePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = { ...post, likedbyme: res.data.likedbyme, likes: res.data.likes }
               const postPost = posts.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost);

               setPosts(postPost);
               addToast('Post Unliked', { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
               console.log('error in unlikes');
            })
      } else {
         postsServices.likePost(postId)
            .then(res => {
               setLoaded(true);
               const changedPost = { ...post, likedbyme: res.data.likedbyme, likes: res.data.likes }
               const postPost = posts.map(p => parseInt(p.postid) !== parseInt(postId) ? p : changedPost);

               setPosts(postPost);
               addToast('Post Liked', { appearance: 'success' })
            })
            .catch(err => {
               setLoaded(true);
               console.log('error in unlikes');
            })
      }



   }

   //  Follow Unfollow handler
   const FollowUnfollowToggle = id => {
      const post = posts.find(p => p.user_id === id)
      const seller = post.user
      setLoaded(false);
      sellerProfile.setToken(token);

      if (seller.followedbyme) {
         sellerProfile.unFollowUser(id)
            .then(res => {
               setLoaded(true);
               const updatedSeller = {
                  ...seller,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               }
               const changedPost = { ...post, user: updatedSeller }
               setPosts(posts.map(p => parseInt(p.user_id) !== parseInt(id) ? p : changedPost));
               addToast('Seller Unfollowed', { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
               console.log('unfollow error');
            })
      } else {
         sellerProfile.followUser(id)
            .then(res => {
               setLoaded(true);
               const updatedSeller = {
                  ...seller,
                  followedbyme: res.data.followedbyme,
                  followers: res.data.followers,
               }
               const changedPost = { ...post, user: updatedSeller }
               setPosts(posts.map(p => parseInt(p.user_id) !== parseInt(id) ? p : changedPost));
               addToast('Seller Followed', { appearance: 'success' })
            }).catch(err => {
               setLoaded(true);
               console.log('follow error');
            })
      }
   }

   // Sortby filter handler
   const handleSortBy = (e) => {
      setSortBy(e.target.value);
      getPostData(categoryID, filterOption, e.target.value)

   }

   // country list  in filter search operation
   const filterCountries = (e, options) => {
      var str = e.target.value || '';
      setFilterString(str);
      const lowercasedFilter = str.toLowerCase();
      const filteredData = options.filter(item => {
         return item.value.toLowerCase().includes(lowercasedFilter);
      });

      const newData = {
         id: e.currentTarget.dataset.filter_id,
         data: filteredData,
      }
      if (filteredList.length > 0) {
         let filter = filteredList.find(f => String(f.id) === String(e.currentTarget.dataset.filter_id));
         if (filter !== undefined) {
            var filtereddd = filteredList.map(f => String(f.id) === String(e.currentTarget.dataset.filter_id) ? newData : f);
            setFilteredList(filtereddd)
         } else {
            setFilteredList(filteredList.concat(newData))
         }

      } else {
         setFilteredList(filteredList.concat(newData))
      }

   }

   // Clear all applied filters
   const handleClearFilter = () => {
      text_search = '';
      setFilterOption([]);
      setFilteredList([]);
      setMinDate('');
      setMaxDate('');
      getPostData(categoryID, [], sortBy)
      setMonthFilterCount(false);
      setFilterCount(0)
   }

   // listing type filter (all, verified dealer ,sold listings)
   const HandleAdFilter = (adType) => {
      setAdsFilter(adType)
      if (adType === 'verified_ads') {
         const new_data = allData?.filter(p => p.user.issubscribed)

         setPosts(new_data);
      } else if (adType === 'sold_ads') {
         const new_data = allData?.filter(p => p.sold)

         setPosts(new_data);
      } else {
         setPosts(allData)
      }
   }

   const handlePage = pageId => {
      setPage(pageId)
      getPostData(categoryID, filterOption, sortBy, pageId)
   }

   return (
      <>
         {categoryID && (
            <SeoTags title={`Miltrade | Products | ${categoryID.charAt(0).toUpperCase() + categoryID.slice(1)}`}
               description={''}
               category={categoryID}
               url={window.location.href}
               image={''}
            />
         )}
         <Header
            data={isHomePage}
            checkAuth={false}
            text={text_search}
            categoryID={categoryID}


         />
         <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                        <Breadcrumb>
                           <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >Home</Breadcrumb.Item>
                           <Breadcrumb.Item active>{categoryFilterData?.name}</Breadcrumb.Item>
                        </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <PageLoader isLoaded={loaded} />
            <div className="products-fliter">
               <Row className="no-gutters">
                  <Col xs={12} sm={9} md={5} lg={3} className="p-0 m-0">
                     <div className="product-filter-section">
                        <div className="clear-filter">
                           <Row className="no-gutters">
                              <Col xs={6} sm={6} md={6} lg={4} className="p-0 m-0">
                                 <span onClick={toggleSidebar1} className="cp filters filter-custom-badge-wrapper"><i className="icon-side_baar mr-1"></i> filters {filterCount > 0 && (<span className="badge badge-danger filter-custom-badge">{filterCount}</span>)}</span>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={8} className="p-0 m-0">
                                 <span className="clear-all cp" onClick={handleClearFilter} >Clear all</span>
                              </Col>
                           </Row>
                        </div>
                     </div>
                     <div className="toggle-btn">
                        <div className="toggle-bt">
                           <div id='left' className={`${leftOpen} toggle-icon`} >
                              <div className='icon' onClick={toggleSidebar} >
                                 <span className="sidebar-close-icon"><i className="icon-dropdown_left"></i></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Col>
                  <Col xs={12} sm={6} md={5} lg={4}>
                     <div className='filterby' style={{ padding: 0 }}>
                        <div className="ads-btn post-filter">
                           <ul>
                              <li className="filter-by"><span>Filter by :</span></li>
                              <li className="myfilter"><span onClick={() => HandleAdFilter('all')} className={adsFilter === 'all' ? 'link btn btn-primary ads active' : 'link'}  >All listings ({allData?.length})</span></li>
                              <li className="myfilter"><span onClick={() => HandleAdFilter('verified_ads')} className={adsFilter === 'verified_ads' ? 'link btn btn-primary active' : 'link'}  >Verified Dealer listings ({(allData?.filter(p => p.user.issubscribed)) ? (allData?.filter(p => p.user.issubscribed).length) : 0})</span></li>
                              {/* <li className="myfilter"><span onClick={() => HandleAdFilter('sold_ads')} className={adsFilter === 'sold_ads' ? 'link btn btn-primary active' : 'link'}  >Sold ({(allData?.filter(p => p.sold)) ? (allData?.filter(p => p.sold).length) : 0})</span></li> */}
                           </ul>
                        </div>
                     </div>
                  </Col>

                  <Col xs={0} sm={0} md={6} lg={3} className="grid-list-view-icon-mobile">
                     <div className="grid-list-view-icon">
                        <ul>
                           <li><span onClick={() => setView('grid')} className={view === 'grid' ? 'link  active' : 'link'} ><i className="icon-grid_view"></i></span></li>
                           <li><span onClick={() => setView('list')} className={view === 'list' ? 'link  active' : 'link'} ><i className="icon-list_view"></i></span></li>
                        </ul>
                     </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={2} className=" mt-1">
                     <div className="vendor-directory right-filter-search">
                        <div className="sort-by-search">
                           <Form>
                              <span className="dropdown-icon"></span>
                              <span className="flter-sort-by"><i className="icon-sort"></i></span>
                              <Form.Group onChange={handleSortBy} controlId="exampleForm.ControlSelect1">
                                 <Form.Control as="select">
                                    <option value="" selected disabled hidden>Sort By</option>
                                    <option value="New">Newest items</option>
                                    <option value="Popular">Most popular</option>
                                    <option value="lowtohigh">Price - Low to High</option>
                                    <option value="hightolow">Price - High to Low</option>
                                 </Form.Control>
                              </Form.Group>
                           </Form>
                        </div>
                     </div>
                  </Col>
               </Row>
            </div>

            <div id='layout' className="product-filt">
               <div id='left' className={leftOpen} >
                  <div className={`sidebar ${leftOpen}`} >
                     <div className='content left-sidebar'>
                        <Accordion >
                           {categoryFilterData?.map((filter, i) => (
                              <Card className={String(filter.type) === "select" ? '' : 'dateRangeOverflow'} key={i}>
                                 <Card.Header className="">
                                    <Accordion.Toggle as={Button} variant="link" eventKey={filter.id} >{filter.name} <span className={filterOption.find(f => String(f.id) === String(filter.id)) ? 'active' : ''}>{filterOption.find(f => String(f.id) === String(filter.id)) ? <i className="icon-right"></i> : ''}</span></Accordion.Toggle>
                                 </Card.Header>
                                 <Accordion.Collapse eventKey={filter.id}>
                                    <Card.Body>
                                       {String(filter.type) === 'select' && (
                                          <Form>
                                             <Form.Group controlId="formBasic">
                                                <div className="category-search-icon">
                                                   <i className="icon-search"></i>
                                                </div>
                                                <Form.Control type="text" data-filter_id={filter.id} onChange={(e) => filterCountries(e, filter?.options)} placeholder="search list" />
                                             </Form.Group>
                                          </Form>
                                       )}
                                       <div className="category-name">
                                          {String(filter.type) === "select" ? (
                                             <ul>
                                                {filteredList.find(f => String(f.id) === String(filter.id)) ? (
                                                   filteredList.find(f => String(f.id) === String(filter.id))?.data?.slice(0, showNumberOfFilters).map((option, i) => (
                                                      <li key={i} data-filter_value={option.id} data-filter_id={filter.id} onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id)) ? 'active' : ''}><i className="icon-right"></i> {option.value}</li>
                                                   ))
                                                ) : (
                                                   filter?.options?.slice(0, showNumberOfFilters).map((option, i) => (
                                                      <li key={i} data-filter_value={option.id} data-filter_id={filter.id} onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id)) ? 'active' : ''}><i className="icon-right"></i> {option.value}</li>
                                                   ))
                                                )}
                                                <div className="loadmorebutton">
                                                   {filter?.options?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >Load more</Button>)}
                                                </div>
                                             </ul>
                                          ) : (
                                             (String(filter.type) === "date_range" ? (
                                                <div >
                                                   <div>
                                                      <Col xs={12} sm={12} md={12} lg={12} className="dateRange-input">
                                                         <Row>
                                                            <Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">
                                                               <Form.Control
                                                                  onChange={handleFirstRangeInput}
                                                                  data-filter_id={filter.id}
                                                                  placeholder="From"
                                                                  type="text"
                                                                  data-type='min'
                                                                  value={minDate}
                                                                  className="form-control" >
                                                               </Form.Control>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">

                                                               <Form.Control placeholder="To"
                                                                  onChange={handleSecondRangeInput}
                                                                  type="text"
                                                                  data-filter_id={filter.id}
                                                                  data-type='max'
                                                                  value={maxDate}
                                                                  className="form-control" >
                                                               </Form.Control>
                                                            </Col>
                                                         </Row>
                                                      </Col>

                                                   </div>
                                                   <span className="invalid-feedback">
                                                      {rangeError}
                                                   </span>
                                                </div>

                                             ) : (

                                                <div>

                                                   <DateRangePicker onEvent={handleDatepickerValue}
                                                      initialSettings={{
                                                         singleDatePicker: true,
                                                         showDropdowns: true,
                                                         startDate: moment().toDate(),
                                                         minYear: moment().year(),
                                                         maxYear: parseInt(moment().format('YYYY'), 10),
                                                      }}  >
                                                      <Form.Control
                                                         data-type="date"
                                                         data-filter_id={filter.id}
                                                         type="text" />
                                                   </DateRangePicker>

                                                </div>
                                             ))

                                          )}
                                       </div>
                                    </Card.Body>
                                 </Accordion.Collapse>
                              </Card>
                           ))}
                        </Accordion>
                     </div>
                  </div>
               </div>

               <div id='main' className="product-right-section">
                  <div className='content'>
                     <div className="mb-0">
                        <Row className="row justify-content-end mr-2">

                        </Row>
                     </div>
                     <div className="all-product">
                        {posts?.length > 0 ? (
                           view === 'grid' ?
                              (<GridView posts={posts}
                                 LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                                 FollowUnfollowToggle={FollowUnfollowToggle}
                                 handlePage={handlePage}

                              />) :
                              (<ListView posts={posts}
                                 LikeUnlikeToggleButton={LikeUnlikeToggleButton}
                                 FollowUnfollowToggle={FollowUnfollowToggle}

                              />)
                        ) : (
                           <>
                              <div className="text-center">
                                 <Image src="../images/search_results_no_listings.webp" className="img-fluid" alt="user" />
                                 {/* <p>No Item Found..</p> */}
                              </div>
                           </>
                        )}

                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </>
   );
}
export default TheaterOfWar;
import { isMobile } from "react-device-detect";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Drawer from 'react-modern-drawer';
import moment from 'moment';
import 'react-modern-drawer/dist/index.css'
import { useContext, useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Auth } from "../../Contexts/AuthContext";

const MobileFilter = ({
  setView,
  view,
  setSortBy,
  sortBy,
  categoryFilterData,
  filterOption,
  filterCountries,
  filteredList,
  handleClearFilter,
  handleFiltersSelect,
  showNumberOfFilters,
  setShowNumberOfFilters,
  handleFirstRangeInput,
  handleSecondRangeInput,
  maxDate,
  minDate,
  rangeError,
  handleDatepickerValue,
  filterCount,
  HandleAdFilter,
  adsFilter,
  selectedCategory,
  handleSelectedCategory
  // allPostCount,
  // verifiedPostCount,
}) => {

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false)
  const [isSort, setIsSort] = useState(false)
  const location = useLocation();
  const { menuCategories } = useContext(Auth);
  const routeName = location.pathname.split('/')[1];
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }
  const toggleDrawerSort = () => {
    setIsSort((prevState) => !prevState)
  }


  // Sortby filter handler 
  const handleSortBy = (filter) => {
    setSortBy(filter);
    setIsSort((prevState) => !prevState);

  }
  const activeSortByClass = (filter) => {
    if (String(sortBy) === filter)
      return 'active';
    else return '';
  }


  return (
    <>
      {isMobile ? (
        <div className='mobile-filter'>
          <div className="filter-label">
            {sortBy !== undefined && (
              <div onClick={toggleDrawerSort} className={`filter-name cp ${sortBy === '' ? '' : 'active'}`}><i className="icon-sort"></i> {t("Sort")}</div>
            )}
            <div className="filter-name">
              <div className="grid-list-view-filter">
                <ul>
                  <li><span onClick={() => setView('grid')} className={view === 'grid' ? 'link  active' : 'link'} ><i className="icon-grid_view"></i></span></li>
                  <li><span onClick={() => setView('list')} className={view === 'list' ? 'link  active' : 'link'} ><i className="icon-list_view"></i></span></li>
                </ul>
              </div>
            </div>
            <div onClick={toggleDrawer} className={`filter-name cp ${filterCount > 0 ? 'active' : ''}`}> <i className="icon-side_baar"></i> {t("Filters")} </div>
          </div>
          {adsFilter !== undefined && (
            <Container>
              <Row className="mt-3" >
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div className='filterby' style={{ padding: 0 }}>
                    <div className="ads-btn post-filter">
                      <ul>
                        <li className="myfilter"><span onClick={() => HandleAdFilter('all')} className={adsFilter === 'all' ? 'link btn btn-primary active' : 'link'}  >{t("All listings")} </span></li>
                        <li className="myfilter"><span onClick={() => HandleAdFilter('verified_ads')} className={adsFilter === 'verified_ads' ? 'link btn btn-primary active' : 'link'}  >{t("Verified Dealer listings")}</span></li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}

          <Drawer open={isSort} onClose={toggleDrawerSort} direction='bottom' className='Sort_filters' >
            <div className="Sort_filter_wrapper">
              <ul>
                <li className={activeSortByClass('New')} onClick={(e) => { handleSortBy('New') }} >{t("Newest items")}</li>
                <li className={activeSortByClass('Popular')} onClick={(e) => { handleSortBy('Popular') }} >{t("Most populer")}</li>
                <li className={activeSortByClass('lowtohigh')} onClick={(e) => { handleSortBy('lowtohigh') }} >{t("Price-Low to High")}</li>
                <li className={activeSortByClass('hightolow')} onClick={(e) => { handleSortBy('hightolow') }} >{t("Price-High to Low")}</li>
              </ul>

            </div>
          </Drawer>

          <Drawer open={isOpen} onClose={toggleDrawer} direction='bottom' className='filters_wrapper' >
            <div className="top_heading">
              <h5>{t("Filter by")} {filterCount > 0 && (<span className="badge badge-danger filter-custom-badge">{filterCount}</span>)}</h5>
              <h6 className="cp" onClick={handleClearFilter}>{t("Clear all")} </h6>
            </div>
            <div className="filter_type">
              <Tab.Container id="left-tabs-example" defaultActiveKey={routeName === ''?"filter-custom":"filter-1"}>
                <Row className="no-gutters">
                  <Col sm={6} xs={6}>
                    <Nav variant="pills" className="flex-column left-result-wrapper">
                      <div className="filter_category">
                        {routeName === '' && (
                          <Nav.Link eventKey={`filter-custom`} >Category <span className={selectedCategory !== '' ? 'active items-selected-check' : 'items-selected-check'}>{selectedCategory !== '' ? <i className="icon-right"></i> : ''}</span></Nav.Link>
                        )}
                        {categoryFilterData?.sub_category_fields?.map((filter, i) => (
                          <Nav.Link key={`filter-main-category-${i}`} eventKey={`filter-${filter.id}`} >{filter.name} <span className={filterOption.find(f => String(f.id) === String(filter.id)) ? 'active items-selected-check' : 'items-selected-check'}>{filterOption.find(f => String(f.id) === String(filter.id)) ? <i className="icon-right"></i> : ''}</span></Nav.Link>
                        ))}
                      </div>
                    </Nav>
                  </Col>
                  <Col sm={6} xs={6}>
                    <Tab.Content>
                      <>
                        {routeName === '' && (
                          <Tab.Pane eventKey={`filter-custom`} className="category-mobile-filter"  >
                            <Card  >
                              <Card.Body>

                                <div className="category-name right-result-wrapper">

                                  <ul>
                                    {menuCategories?.map((option, i) => (
                                      <li key={`filtered_list-${i}`} data-filter_value={option.slug} onClick={(e) => handleSelectedCategory(e)} className={(String(selectedCategory) === String(option.slug)) ? 'active' : ''}><i className="icon-right"></i> {option.name}</li>
                                    ))
                                    }
                                  </ul>

                                </div>
                              </Card.Body>
                            </Card>
                          </Tab.Pane>
                        )}

                        {categoryFilterData?.sub_category_fields?.map((filter, i) => (
                          <Tab.Pane eventKey={`filter-${filter.id}`} className="category-mobile-filter" key={`subcategory-filters-${i}`} >
                            <Card className={filter.type === "select" ? '' : 'dateRangeOverflow'} >
                              <Card.Body>
                                {filter.type === 'select' && (
                                  <div className="filter-search-wrapper">
                                    <div className="category-search-icon">
                                      <i className="icon-search"></i>
                                    </div>
                                    <Form.Control type="text" data-filter_id={filter.id} onChange={(e) => filterCountries(e, filter?.options)} placeholder={t("search list")} />
                                  </div>
                                )}
                                <div className="category-name right-result-wrapper">
                                  {filter.type === "select" ? (
                                    <ul>
                                      {filteredList.find(f => String(f.id) === String(filter.id)) ? (
                                        filteredList.find(f => String(f.id) === String(filter.id))?.data?.slice(0, showNumberOfFilters).map((option, i) => (
                                          <li key={i} data-filter_value={option.id} data-filter_id={filter.id} onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id)) ? 'active' : ''}><i className="icon-right"></i> {option.value}</li>
                                        ))
                                      ) : (
                                        filter?.options?.slice(0, showNumberOfFilters).map((option, i) => (
                                          <li key={i} data-filter_value={option.id} data-filter_id={filter.id} onClick={(e) => handleFiltersSelect(e)} className={filterOption.find(f => String(f.value) === String(option.id)) ? 'active' : ''}><i className="icon-right"></i> {option.value}</li>
                                        ))
                                      )}
                                      <div className="loadmorebutton">
                                        {filter?.options?.length > showNumberOfFilters && (<Button onClick={() => setShowNumberOfFilters(showNumberOfFilters + 4)} >{t("Load more")}</Button>)}
                                      </div>
                                    </ul>
                                  ) : (
                                    (filter.type === "date_range" ? (
                                      <div >
                                        <div className="range-slider-wraapper">
                                          <Col xs={12} sm={12} md={12} lg={12} className="dateRange-input">
                                            <Row>
                                              <Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">
                                                <Form.Control
                                                  onChange={(e) => { handleFirstRangeInput(filter.id, e.target.value) }}
                                                  placeholder={t("From")}
                                                  min={1500}
                                                  type="text"
                                                  value={minDate}
                                                  className="form-control" >
                                                </Form.Control>
                                              </Col>
                                              <Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">

                                                <Form.Control
                                                  placeholder={t("To")}
                                                  onChange={(e) => { handleSecondRangeInput(filter.id, e.target.value) }}
                                                  type="text"
                                                  value={maxDate}
                                                  max={new Date().getFullYear()}
                                                  className="form-control" >
                                                </Form.Control>
                                              </Col>
                                            </Row>
                                          </Col>
                                          {/* <MultiRangeSlider
                                          min={1500}
                                          max={new Date().getFullYear()}
                                          onChange={({ min, max }) => handleRangeSlider(filter.id,min,max)}
                                        /> */}
                                        </div>
                                        <span className="invalid-feedback">
                                          {rangeError}
                                        </span>
                                      </div>

                                    ) : (

                                      <div>

                                        <DateRangePicker onEvent={handleDatepickerValue}
                                          initialSettings={{
                                            singleDatePicker: true,
                                            showDropdowns: true,
                                            startDate: moment().toDate(),
                                            minYear: moment().year(),
                                            maxYear: parseInt(moment().format('YYYY'), 10),
                                          }}  >
                                          <Form.Control
                                            data-type="date"
                                            data-filter_id={filter.id}
                                            type="text" />
                                        </DateRangePicker>

                                      </div>
                                    ))

                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          </Tab.Pane>
                        ))}
                      </>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            <div className="close_apply_btn">
              <Button onClick={() => { setIsOpen((prevState) => !prevState) }} className="close-btn">{t("Close")}</Button>
            </div>
          </Drawer>
        </div>
      ) : (
        <></>
      )
      }
    </>
  );
}




export default MobileFilter;